
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import {CoffeeProductionInputFileType, SiloAssignmentInputFile} from "@/scripts/models/calculation";
import {BackendInterface} from "@/scripts/BackendInterface";
import {UserInfo, UserRole} from "@/scripts/auth";
import {createInputFileSummary} from "@/scripts/utils";

export default Vue.extend({
  name: "InputFileSummary",
  components: {},
  props: {
    // solveRequest: {type: Object, required: true},
    inputFiles: {type: Array, required: true}
  },
  data() {
    return {};
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    isAnySuperuser(): boolean {
      for(const modelType of ['sara', 'dori', 'papa']){
        // @ts-ignore
        const role = this.user?.[modelType+'UserRole'];
        if(role === UserRole.SUPERUSER) return true;
      }
      return false;
    },
    summary(): any[] {
      return createInputFileSummary(this.inputFiles as SiloAssignmentInputFile[])
    },

  },
  methods: {
    ...mapMutations([""]),
    downloadFile(fileId: string, useOriginalFilename: boolean) {
      // console.log("downloadFile")
      (this.backendInterface as BackendInterface).downloadFile(fileId, useOriginalFilename)
    }
  },
});
