
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
// @ts-ignore
import solution from "@/data/solution.json";
import {emailValidationRule, emailValid, getEnumKeys} from "@/scripts/utils";
import FileUpload from "@/components/common/FileUpload.vue";
import SaraSummary from "@/components/cellplan/SaraSummary.vue";
import DoriSummary from "@/components/dori/DoriSummary.vue";
import CalculationSection from "@/components/calculation/CalculationSection.vue";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import CoffeeProductionDORISummary from "@/scripts/SiloAssignmentSolution";
import BasketSettings from "@/components/settings/BasketSettings.vue";
import UploadSection from "@/components/calculation/UploadSection.vue";
import SaraSettingsSection from "@/components/calculation/SaraSettingsSection.vue";
import PapaSettingsSection from "@/components/calculation/PapaSettingsSection.vue";
import DoriSettingsSection from "@/components/calculation/DoriSettingsSection.vue";
// import {UserType} from "@/scripts/models/general";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CalculationConfig} from "@/scripts/models/calculation";
import {CoffeeProductionModelType, SolveRequest, SolveRequestRequest} from "@/scripts/models/request";
import {CoffeeProductionDORISolveRequest, CoffeeProductionDORISolveRequestRequest} from "@/scripts/models/dori";
import {UserInfo, UserRole} from "@/scripts/auth";
// @ts-ignore
// import data from "@/data/solution_bn5_sn37_d6.json";

enum ComputationMode {
  IMPROVE,
  RESTART
}

export default Vue.extend({
  name: "CalculationStepper",
  components: {
    FileUpload,
    SaraSummary,
    DoriSummary,
    UploadSection,
    CalculationSection,
    BasketSettings,
    SaraSettingsSection,
    PapaSettingsSection,
    DoriSettingsSection,
  },
  props: {
    modelType: {type: String as PropType<CoffeeProductionModelType>, default: CoffeeProductionModelType.SARA}, // SARA, PAPA, DORI
    // new computation of old plan with the same inputs
    recompute: {type: Boolean as PropType<boolean>, default: false},
    // restart in case of error
    resolve: {type: Boolean as PropType<boolean>, default: false},
    // load a running computation
    loadActiveComputation: {type: Boolean as PropType<boolean>, default: false},
    // improve existing solution
    improvePlan: {type: Boolean as PropType<boolean>, default: false},
  },
  data: function () {
    return {
      // UserType,
      CoffeeProductionModelType,
      solverOutputHash: "",
      saraSummary: null as null | CoffeeProductionSARASummary,
      doriSummary: null as null | CoffeeProductionDORISummary,
      step: this.recompute ? 0 : 1,
      siloAssignmentFound: false,
      stepTitles: ["Daten", "Einstellungen", "Berechnung"],
      showSnackbar: false,
      snackbarText: "",
      loading: false,
      loadingMessage: "",
      summaryId: null as null | string,
      solveRequest: null as null | SolveRequest | CoffeeProductionDORISolveRequest,
      timeRangeStart: null as null | string,
      timeRangeEnd: null as null | string,
      preroastForNextWeek: false,
      jsonSolution: solution,
      solveRequestRequest: null as SolveRequestRequest | null,
      showErrorDialog: false,
      showSoftErrorDialog: false,
      errorObject: {} as any,
      errorMailSent: false,
      errorMailSending: false,
      errorMailSenderEmailAddress: "",
      jdeEmailSuffix: "@jdecoffee.com",
      resolveParams: {
        solveRequestId: null as null|string
      },
      loadActiveComputationParams: {
        solveProcessId: null as null|string
      },
      improvePlanParams: {
        summaryId: undefined as string|undefined
      },
      recomputeParams: {
        summaryId: null as null|string
      },
      doriComputeParams: {
        preselectedSaraPlanId: null as null|string
      },
    };
  },
  created() {
    // console.log(UserType[0])
    // console.log(UserType["USER"])
    // if (this.superuser) {
    //   console.log("this.availableWeekData[0]", this.availableWeekData[0])
    //   console.log("this.availableSolveRequest[0]", this.availableSolveRequest[0])
    //   this.selectWeekData(this.availableWeekData[0]);
    //   this.selectSolveRequest(this.availableSolveRequest[0]);
    // }

    if(this.recompute){
      this.recomputeParams.summaryId = this.$route.params.id;
      // this.summaryId = this.$route.params.id;
    }

    if(this.resolve){
      this.resolveParams.solveRequestId = this.$route.params.id;
    }

    if(this.loadActiveComputation){
      this.loadActiveComputationParams.solveProcessId = this.$route.params.solveProcessId;
      this.step = 3;
    }
    if(this.improvePlan){
      this.improvePlanParams.summaryId = this.$route.params.resultSummaryId;
    }
    if(this.modelType === CoffeeProductionModelType.DORI && !!this.$route.query.saraId){
      // this.doriComputeParams.preselectedSaraPlanId = this.$route.params.preselectedSaraPlanId;
      this.doriComputeParams.preselectedSaraPlanId = this.$route.query.saraId as string
    }
  },
  watch: {
    // userType() {
    //   this.setUserType(this.userType);
    // },
    modelType() {
      this.restart();
    },
    "recomputeParams.summaryId": async function(summaryId: string) {
      if (summaryId === null || summaryId === undefined) return;
      await this.initNewComputationFromSummary(summaryId, ComputationMode.RESTART, 2);
    },
    "improvePlanParams.summaryId": async function(summaryId) {
      if (summaryId === null || summaryId === undefined) return;
      const targetStep = this.isAnySuperuser ? 2 : 3
      await this.initNewComputationFromSummary(summaryId, ComputationMode.IMPROVE, targetStep);
    },
    "resolveParams.solveRequestId": async function(v) {
      if (v === null || v === undefined) return;
      // true if v === '', v === false, v === null, v === undefined
      // if (!v) return;
      this.loading = true;
      const bi = this.backendInterface as BackendInterface;
      this.solveRequest = await bi.getSolveRequest(v, this.modelType);
      this.timeRangeStart = await bi.convertDayOfWeekDateToLocalDate(this.solveRequest.timeRange.startDate);
      this.timeRangeEnd = await bi.convertDayOfWeekDateToLocalDate(this.solveRequest.timeRange.endDate);
      console.log("LOADED: solveRequest", this.solveRequest);
      this.loading = false;
      this.step = 2;
    },
    solveRequestRequest(v) {
      this.timeRangeStart = v.timeRangeStart;
      this.timeRangeEnd = v.timeRangeEnd;
      this.preroastForNextWeek = v.preroastForNextWeek;
    },
  },
  computed: {
    ...mapState([
      "superuser",
      "user",
      // "maxUserType",
      "version",
      "availableWeekData",
      "availableSolveRequest",
      "backendInterface",
    ]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface;
    },
    breadcrumbs(): any[] {
      return [
        {text: this.modelType, disabled: true, to: ""},
        {text: "Plan Berechnen", disabled: true},
      ];
    },
    title(): string {
      const step = this.step;
      if (step === 1) return `${this.modelType}: Daten Hochladen`;
      if (step === 2) return `${this.modelType}: Einstellungen`;
      if (step === 3) return `${this.modelType}: Berechnung`;
      if (step === 4) return "" + this.saraSummary?.getTitle() ?? "";
      return "Neue Berechnung";
    },
    isAnySuperuser(): boolean {
      return this.modelUserRole === UserRole.SUPERUSER;
    },
    modelUserRole(): UserRole {
      const modelType = this.modelType as CoffeeProductionModelType;
      const user = this.user as UserInfo;
      if(modelType === CoffeeProductionModelType.DORI) {
        return user?.doriUserRole ?? UserRole.NONE;
      }
      if(modelType === CoffeeProductionModelType.PAPA) {
        return user?.papaUserRole ?? UserRole.NONE;
      }
      if(modelType === CoffeeProductionModelType.SARA) {
        return user?.saraUserRole ?? UserRole.NONE;
      }
      return UserRole.NONE;
    },
    calculationConfig(): CalculationConfig {
      const bi = this.bi
      // console.log("bi", JSON.stringify(bi, null, 2));

      if (this.modelType === CoffeeProductionModelType.SARA) {
        return {
          fetchSolveStatus: (solveProcessId: string) => bi.sara_GetSolveStatus(solveProcessId),
          fetchSolveResult: (solveProcessId: string) => bi.sara_GetSolveResultBySolveProcessId(solveProcessId),
          fetchSolverOutput: (solveProcessId: string) => bi.sara_GetSolverOutput(solveProcessId),
          fetchSolve: (solveRequest: SolveRequest) => bi.sara_Solve(solveRequest),
        };
      } else if (this.modelType === CoffeeProductionModelType.PAPA) {
        return {
          fetchSolveStatus: (solveProcessId: string) => bi.papa_GetSolveStatus(solveProcessId),
          fetchSolveResult: (solveProcessId: string) => bi.papa_GetSolveResultBySolveProcessId(solveProcessId),
          fetchSolverOutput: (solveProcessId: string) => bi.papa_GetSolverOutput(solveProcessId),
          fetchSolve: (solveRequest: SolveRequest) => bi.papa_Solve(solveRequest),
        };
      } else if (this.modelType === CoffeeProductionModelType.DORI) {
        return {
          fetchSolveStatus: (solveProcessId: string) => bi.dori_GetSolveStatus(solveProcessId),
          fetchSolveResult: (solveProcessId: string) => bi.dori_GetSolveResultBySolveProcessId(solveProcessId),
          fetchSolverOutput: (solveProcessId: string) => bi.dori_GetSolverOutput(solveProcessId),
          fetchSolve: (solveRequest: SolveRequest) => bi.dori_Solve(solveRequest),
          fetchSolverStatistics: (solveProcessId: string) => bi.dori_GetRunningProcessSolverStatistics(solveProcessId),
          fetchLastSolution: (solveRequestId: string) => bi.dori_getLastSolution(solveRequestId),
        };
      }
      throw new Error(`Model type "${this.modelType}" not recognized.`);

    },
  },
  methods: {
    ...mapMutations(["selectWeekData", "selectSolveRequest", "toggleSuperuser", "setUserType"]),
    getEnumKeys,
    emailValidationRule,
    emailValid,
    async initNewComputationFromSummary(summaryId: string, computationMode: ComputationMode, targetStep: 1|2|3){
      // true if v === '', v === false, v === null, v === undefined
      // if (!v) return;
      this.loading = true;
      let summary;
      // SARA
      if (this.modelType === CoffeeProductionModelType.SARA) {
        summary = await this.bi.sara_GetSolutionById(summaryId);
        this.preroastForNextWeek = summary.preroastForNextWeek;
        this.solveRequest = await this.bi.sara_GetUsedSolveRequest(summaryId);
      }
      // PAPA
      else if (this.modelType === CoffeeProductionModelType.PAPA) {
        summary = await this.bi.papa_GetSolutionById(summaryId);
        this.solveRequest = await this.bi.papa_GetUsedSolveRequest(summaryId);
      }
      // DORI
      else if (this.modelType === CoffeeProductionModelType.DORI) {
        summary = await this.bi.dori_GetSolutionById(summaryId);
        this.solveRequest = await this.bi.dori_GetUsedSolveRequest(summaryId);
      } else {
        throw new Error(`Unrecognized modelType: ${this.modelType}`)
      }
      // COMMON
      this.timeRangeStart = summary.timeRangeStart;
      this.timeRangeEnd = summary.timeRangeEnd;
      if(computationMode === ComputationMode.IMPROVE){
        this.solveRequest.parentSummaryId = summaryId;
      } else if (computationMode === ComputationMode.RESTART){
        this.solveRequest.parentSummaryId = null;
      }
      console.log("LOADED: solveRequest", this.solveRequest);
      this.loading = false;
      this.step = targetStep;
    },
    async handle_InputFilesSelected(solveRequestRequest: SolveRequestRequest | CoffeeProductionDORISolveRequestRequest) {
      this.loading = true;
      this.loadingMessage = "Daten werden geladen ...";
      let solveRequest: SolveRequest | CoffeeProductionDORISolveRequest;
      if (this.modelType === CoffeeProductionModelType.SARA) {
        solveRequest = await this.bi.sara_GetSolveRequest(solveRequestRequest);
      } else if (this.modelType === CoffeeProductionModelType.PAPA) {
        solveRequest = await this.bi.papa_GetSolveRequest(solveRequestRequest);
      } else if (this.modelType === CoffeeProductionModelType.DORI) {
        // console.log("DORI solveRequestRequest", solveRequestRequest);
        console.warn("DORI: removing auto-selected input files")
        solveRequestRequest.inputFileIds = []
        solveRequest = await this.bi.dori_GetSolveRequest(solveRequestRequest as CoffeeProductionDORISolveRequestRequest);
      } else {
        throw `Unrecognized modelType: ${this.modelType}`;
      }
      if ("errorMessage" in solveRequest || "error" in solveRequest) {
        this.handleError(solveRequest);
        return;
      }
      //const oldSolution = await bi.sara_SolveResult(solveRequestRequest);
      this.loading = false;
      this.step = 2;
      this.solveRequestRequest = solveRequestRequest;
      // console.log("solveRequestRequest", solveRequestRequest);
      // console.log("solveRequest", solveRequest);
      this.solveRequest = solveRequest;
      //this.oldSolution = oldSolution;
    },
    handle_SettingSaved(solverOutputHash: string): void {
      // console.log("SETTINGS SAVED ");
      console.log("STARTING OPTIMIZATION");
      // this.solverOutputHash = solverOutputHash;
      this.step = 3;
    },
    handle_OptimizationEnded(summaryId: string): void {
      console.log("OPTIMIZATION ENDED");
      console.log({summaryId});
      if (this.modelType === CoffeeProductionModelType.SARA) {
        this.$router.push(`/sara/plan/view/${summaryId ?? null}?source=calculation`);
      } else if (this.modelType === CoffeeProductionModelType.PAPA) {
        this.$router.push(`/papa/plan/view/${summaryId ?? null}?source=calculation`);
      } else if (this.modelType === CoffeeProductionModelType.DORI) {
        this.$router.push(`/dori/plan/view/${summaryId ?? null}?source=calculation`);
      }
    },
    stepClick(step: number) {
      // if (this.userType === UserType.SUPERUSER) {
      //   this.step = step;
      // }
    },
    handleError(errorObject: any) {
      this.showErrorDialog = true;
      this.errorObject = errorObject;
    },
    handleSoftError(errorObject: any) {
      // soft-error
      this.showSoftErrorDialog = true;
      this.errorObject = errorObject;
    },
    restart() {
      console.log("restarting");
      this.loading = false;
      this.showErrorDialog = false;
      if (this.recompute) {
        this.step = 2;
      } else {
        this.step = 1;
      }
    },
    async sendErrorReport() {
      // const canSendEmail = emailValid(this.errorMailSenderEmailAddress);
      const canSendEmail = true;
      if (canSendEmail) {
        this.errorMailSending = true;
        const applicationPhases: any = {
          1: "Data Upload",
          2: "Settings",
          3: "Calculation",
        };
        const bi: BackendInterface = this.backendInterface;
        const senderEmailAddress = this.errorMailSenderEmailAddress.trim() + this.jdeEmailSuffix;
        // console.log("ERROR: sending error report. solveRequest = ", this.solveRequest)
        // console.log({
        //   modelType: this.modelType as CoffeeProductionModelType,
        //   applicationPhase: applicationPhases[this.step] ?? "",
        //   solveRequestId: this.solveRequest?.id ?? "",
        //   errorMessage: `<pre>${JSON.stringify(this.errorObject, null, 2)}</pre>`,
        // })
        const res = await bi.sendErrorReport({
          // senderEmailAddress: senderEmailAddress,
          // userType: this.userType,
          modelType: this.modelType as CoffeeProductionModelType,
          applicationPhase: applicationPhases[this.step] ?? "",
          // solveRequestId: this.solveRequest?.id ?? "",
          solveRequest: this.solveRequest,
          errorMessage: `<pre>${JSON.stringify(this.errorObject, null, 2)}</pre>`,
        });
        console.log("res", res);
        if (res === `Error report e-mail sent`) {
          this.errorMailSent = true;
        }
        this.errorMailSending = false;
      }
    },

    getDisplayErrorMessage(errorObject: any) {
      if(!!errorObject.translatedErrorMessage) {
        return errorObject.translatedErrorMessage
      }
      if(!!errorObject.errorMessage) {
        return errorObject.errorMessage
      }
      if(!!errorObject.error) {
        return errorObject.error
      }
      return "Unbekannter Fehler"
    },
  },
});
