
import Vue, {PropType} from "vue";
import { mapMutations, mapState } from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";

export default Vue.extend({
  name: "Template",
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
  },
  methods: {
    ...mapMutations([""]),
  },
});
