
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {
  CoffeeProductionDORIResult,
  CoffeeProductionDORISummary,
} from "@/scripts/models/dori";
import {RoasterTaskResult} from "@/scripts/models/results";
import {getTaskTime} from "@/scripts/gantt/coffee-gantt";
import {formatDate} from "@/scripts/utils";
import {CoffeeBasket} from "@/scripts/models/coffee";

enum RoastingStatus {
  NotStarted = 0,
  InProgress = 1,
  Finished = 2,
  Failed = 3,
}

const roastingStatusTranslations = {
  [RoastingStatus.NotStarted]: "Nicht gestartet",
  [RoastingStatus.InProgress]: "In Bearbeitung",
  [RoastingStatus.Finished]: "Abgeschlossen",
  [RoastingStatus.Failed]: "Fehlgeschlagen",
};

const roastingStatusColors = {
  [RoastingStatus.NotStarted]: "grey",
  [RoastingStatus.InProgress]: "orange",
  [RoastingStatus.Finished]: "green",
  [RoastingStatus.Failed]: "red",
};

export default Vue.extend({
  name: "DoriRoasterTaskTable",
  components: {},
  props: {
    doriSummary: {type: Object as PropType<CoffeeProductionDORISummary>},
    currentTime: {
      type: Number as PropType<number>,
      default: () => Date.now(),
    },
    showPendingTasks: {type: Boolean as PropType<boolean>, default: false},
    showCompletedTasks: {type: Boolean as PropType<boolean>, default: false},
  },
  data() {
    return {
      roastingStatusTranslations,
      roastingStatusColors,
      headers: [
        {text: "Status", value: "status"},
        {text: "Röster", value: "roasterName"},
        {text: "Basket", value: "basketSpsNr"},
        {text: "Start-Zeit", value: "roastingStartTime_h"},
        {text: "End-Zeit", value: "roastingEndTime_h"},
        // Reserved silos
        {text: "Reservierte Silos", value: "reservedSilos"},
        {text: "Menge", value: "roastingWeight_ton"},
        {text: "Für aktuellen Tag", value: "forMillingWeight_ton"},
        {text: "Für nächste Tage", value: "forPreroastWeight_ton"},
        {text: "Geplante Menge", value: "plannedBasketWeight_ton"},
        {text: "Geplanter Basket", value: "plannedBasketSpsNr"},
        {text: "Plan Abweichung", value: "plannedBasketWeightDeviation_ton"},
      ],
    };
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface;
    },
    roasterTasks(): RoasterTaskResult[] {
      return this.doriSummary?.result?.roasterTaskResults ?? [];
    },
    tableItems(): any[] {
      // return this.roasterTasks
      return this.roasterTasks
          .map((task) => {
            return {
              ...task,
              // reservedSiloSpsNrs: reservedSiloSpsNrs.join(", "),
              status: this.getTaskStatus(task),
            };
          })
          .filter((item) => {
            const pending = item.status === RoastingStatus.NotStarted;
            const completed = item.status === RoastingStatus.Finished;
            if (!this.showPendingTasks && pending) return false;
            if (!this.showCompletedTasks && completed) return false;
            return true;
          });
    },
    basketMap(): Record<string, CoffeeBasket> {
      return this.doriSummary?.baskets.reduce((acc, basket) => {
        acc[basket.basketSpsNr] = basket;
        return acc;
      }, {} as Record<string, CoffeeBasket>);
    }
  },
  methods: {
    ...mapMutations([""]),
    formatDate(date: string | Date) {
      // use intl to format date to "02.05.23, 11:15"
      return new Intl.DateTimeFormat("de-DE", {
        // year: "2-digit",
        // month: "2-digit",
        // day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(date));
    },
    formatAmount(amount: number) {
      return isFinite(amount) ? `${amount.toFixed(1)}t` : "-";
    },
    getTaskStartDate(task: RoasterTaskResult) {
      const date = getTaskTime(this.doriSummary, task.roastingStartTime_h);
      return date;
      // return formatDate(date)
    },
    getTaskEndDate(task: RoasterTaskResult) {
      const date = getTaskTime(this.doriSummary, task.roastingEndTime_h);
      return date;
      // return formatDate(date)
    },
    getTaskStatus(task: RoasterTaskResult): RoastingStatus {
      const start = getTaskTime(
          this.doriSummary,
          task.roastingStartTime_h
      ).getTime();
      const end = getTaskTime(
          this.doriSummary,
          task.roastingEndTime_h
      ).getTime();
      const current = new Date(this.currentTime).getTime();
      // const currentlyRoasting = start <= this.currentTime
      //     && task.roastingEndTime_h >= this.currentTime
      const pending = start > current;
      const completed = end < current;
      // console.log("start", start)
      // console.log("end", end)
      // console.log("current", current)
      if (pending) return RoastingStatus.NotStarted;
      if (completed) return RoastingStatus.Finished;
      return RoastingStatus.InProgress;
    },
    getRoasterColor(task: RoasterTaskResult) {
      // Jetzone, Neptun
      if (
          task.roasterName.startsWith("Jetzone") ||
          task.roasterName.startsWith("Neptun")
      )
        return "gray";
      return "black";
    },
  },
});
