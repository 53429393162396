
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {UserInfo} from "@/scripts/auth";

export default Vue.extend({
  name: "UserInfoListItem",
  components: {},
  props: {
    userInfo: {
      type: Object as PropType<UserInfo>,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
  },
  methods: {
    ...mapMutations([""]),
  },
});
