import {clamp} from "../utils";
import {
  GanttChartItem,
  GanttChartItemProperties,
  GanttChartItemStatus,
  GanttChartItemType,
  GanttChartViewConfiguration,
} from "./gantt";
import {GanttChartItemAggregation} from "./GanttChartItemAggregation";
import {GanttChartItemAggregationGroup} from "./GanttChartItemAggregationGroup";

export default class GanttChartState {
  public config: GanttChartViewConfiguration;
  public updateTrigger: number = 0

  #currentTime: Date = new Date();

  public aggregations: GanttChartItemAggregation[];
  public groups: GanttChartItemAggregationGroup[];
  public timeCursorRelativePosition: number = 0;
  public disableTimeCursorWhenOutside: boolean = false
  public itemsTimespan: [number, number];
  public itemsTimespanNotBlocked: [number, number];
  public initialPosX: number = NaN

  #showTimeCursor: boolean = true;
  #lockTimeCursor: boolean = true;
  #showPendingTasks: boolean = true;
  #showDoneTasks: boolean = true;
  #showBlockedTasks: boolean = true;
  #showRunningTasks: boolean = true;


  constructor(config: GanttChartViewConfiguration) {
    this.config = config;
    this.itemsTimespan = this.calculateItemsTimespan();
    this.itemsTimespanNotBlocked = this.calculateNonBlockedItemsTimespan();
    // this.initialScrollX = this.itemsTimespanNotBlocked[0]
    this.aggregations = this.buildAggregations();
    this.groups = this.buildGroups();
    // this.timeCursorRelativePosition = this.calculateTimeCursorPosition()
    this.updateTimeCursorPosition() // TODO: i'm not in the mood to fix this right now
    this.updateItemStatusType()
  }

  private calculateTimeCursorPosition(timeMs: number): number {
    // const timeMs = typeof time === 'string' ? new Date(time).getTime() : time.getTime()
    // const timeMs = this.#currentTime.getTime()
    const [start, end] = this.itemsTimespan;
    return (timeMs - start) / (end - start);
    // return clamp(pos, 0, 1)
  }

  // get isTimeCursorInItemTimeRange(){
  //   const [start, end] = this.itemsTimespan;
  //   const now = Date.now()
  //   return  now < start || now > end
  // }

  updateTimeCursorPosition(): void {
    // console.log('updateTimeCursorPosition', time)
    const currentTime = this.#currentTime.getTime()
    const pos = this.calculateTimeCursorPosition(currentTime)
    // console.log('pos', pos)
    this.setTimeCursorPosition(pos)
  }

  setTimeCursorPosition(pos: number) {
    const invalidPos = pos < 0 || pos > 1 || isNaN(pos)
    if (this.disableTimeCursorWhenOutside && invalidPos) {
      this.#showTimeCursor = false
      this.#lockTimeCursor = false
      this.updateItemStatusType()
      this.triggerUpdate()
      this.timeCursorRelativePosition = 0
    } else {
      this.timeCursorRelativePosition = clamp(pos, 0, 1)
    }
  }

  buildGroupsAndAggregations(): void {
    this.aggregations = this.buildAggregations();
    this.groups = this.buildGroups();
  }

  get displayedColumns() {
    if (this.config !== null) {
      const displayedColumns = Object.keys(this.config.differentiate).filter(
        // @ts-ignore
        (key) => this.config.groupingKey != key && this.config.visibleCols[key]
      );
      return displayedColumns;
    }
    return [];
  }

  calculateItemsTimespan(): [number, number] {
    let [start, end] = [Infinity, -Infinity];
    const jobs = this.config.items as GanttChartItem[];
    for (let job of jobs) {
      if (job.timeStart.getTime() < start) start = job.timeStart.getTime();
      if (job.timeFinish.getTime() > end) end = job.timeFinish.getTime();
    }
    return [start, end];
  }

  calculateNonBlockedItemsTimespan(): [number, number] {
    let [start, end] = [Infinity, -Infinity];
    const jobs = this.config.items as GanttChartItem[];
    for (let job of jobs) {
      if (job.type === GanttChartItemType.BLOCKED) continue
      if (job.timeStart.getTime() < start) start = job.timeStart.getTime();
      if (job.timeFinish.getTime() > end) end = job.timeFinish.getTime();
    }
    return [start, end];
  }

  updateItemStatusType(): void {
    const items = this.config.items as GanttChartItem[];
    for (let item of items) {
      if (item.type === GanttChartItemType.BLOCKED) {
        if (!this.showBlockedTasks) {
          item.status = GanttChartItemStatus.HIDDEN;
        } else {
          item.status = GanttChartItemStatus.IN_PROGRESS;
        }
      }
    }
    if (!this.showTimeCursor) {
      for (let job of items) {
        if (job.type === GanttChartItemType.TASK) {
          job.status = GanttChartItemStatus.IN_PROGRESS;
        }
      }
      return;
    }
    // const [startTime, endTime] = this.itemsTimespan;
    // if (this.fixedTimePassedAtPercentage) {
    //   this.time = startTime + this.percentageOfTimePassed * (endTime - startTime);
    // } else {
    //   this.time = Date.now();
    // }
    const time = this.#currentTime.getTime()
    for (let item of items) {
      if (item.type === GanttChartItemType.BLOCKED) {
        continue;
      }
      if (item.timeFinish.getTime() < time) {
        item.status = GanttChartItemStatus.DONE;
        if (!this.showDoneTasks) {
          item.status = GanttChartItemStatus.HIDDEN;
        }
      } else if (item.timeStart.getTime() > time) {
        item.status = GanttChartItemStatus.PENDING;
        if (!this.showPendingTasks) {
          item.status = GanttChartItemStatus.HIDDEN;
        }
      } else {
        item.status = GanttChartItemStatus.IN_PROGRESS;
      }
    }
  }

  buildAggregations(): GanttChartItemAggregation[] {
    if (this.config !== null) {
      const config = this.config as GanttChartViewConfiguration;
      return GanttChartItemAggregation.calculateAggregations(config.items, config.differentiate);
    } else {
      return [];
    }
  }

  buildGroups(): GanttChartItemAggregationGroup[] {
    if (this.config !== null) {
      const config = this.config as GanttChartViewConfiguration;
      let groups = GanttChartItemAggregationGroup.calculateGroups(
        this.aggregations,
        config.groupingKey as keyof GanttChartItemProperties
      );
      // for (let i = 0; i < groups.length; i++) {
      // groups[i].setDisplayColor(this.palette[i % this.palette.length]);
      // }
      return groups;
    } else {
      return [];
    }
  }

  triggerUpdate() {
    // console.log("update");
    this.updateTrigger++
  }

  get currentTime() {
    return this.#currentTime;
  }

  set currentTime(v: string | Date | number) {
    this.#currentTime = new Date(v)
    this.updateTimeCursorPosition()
    this.updateItemStatusType();
    this.triggerUpdate()
  }

  get showTimeCursor() {
    return this.#showTimeCursor;
  }

  set showTimeCursor(v: boolean) {
    this.#showTimeCursor = v;
    this.updateItemStatusType();
    this.triggerUpdate()
  }

  get lockTimeCursor() {
    return this.#lockTimeCursor;
  }

  set lockTimeCursor(v: boolean) {
    this.#lockTimeCursor = v;
    this.updateItemStatusType();
    this.triggerUpdate()
  }

  get showPendingTasks() {
    return this.#showPendingTasks;
  }

  set showPendingTasks(v: boolean) {
    this.#showPendingTasks = v;
    this.updateItemStatusType();
    this.triggerUpdate()
  }

  get showDoneTasks() {
    return this.#showDoneTasks;
  }

  set showDoneTasks(v: boolean) {
    this.#showDoneTasks = v;
    this.updateItemStatusType();
    this.triggerUpdate()
  }

  get showBlockedTasks() {
    return this.#showBlockedTasks;
  }

  set showBlockedTasks(v: boolean) {
    this.#showBlockedTasks = v;
    this.updateItemStatusType();
    this.triggerUpdate()
  }

  get showRunningTasks() {
    return this.#showRunningTasks;
  }

  set showRunningTasks(v: boolean) {
    this.#showRunningTasks = v;
    this.updateItemStatusType();
    this.triggerUpdate()
  }
}
