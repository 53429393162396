
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { CoffeeArticlePackagingLine, PackageType } from "@/scripts/models/coffee";
import { WEEKDAYS } from "@/scripts/models/date";
import Template from "../Template.vue";

export default Vue.extend({
  name: "PackagingLineSettings",
  components: { Template },
  props: {
    value: { type: Array, required: true }, // CoffeeArticlePackagingLine[]
    autosave: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    tassimo: { type: Boolean, default: false },
    roastAndGround: { type: Boolean, default: false },
  },
  data() {
    return {
      weekdays: "Mo Di Mi Do Fr Sa So".split(" "),
      packagingLines: [] as CoffeeArticlePackagingLine[],
      defaultValues: null as any,
    };
  },
  created() {
    this.init();
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        this.packagingLines = JSON.parse(JSON.stringify(v));
        if (this.defaultValues === null) {
          this.defaultValues = JSON.parse(JSON.stringify(v));
        }
      },
    },
  },
  computed: {
    ...mapState(["superuser", "user", "backendInterface"]),
    sortedRoasterAvailabilities(): CoffeeArticlePackagingLine[] {
      const s = [...this.packagingLines];
      s.sort((a, b) => (a.packagingLineNumber < b.packagingLineNumber ? -1 : 1));
      return s;
    },
  },
  methods: {
    ...mapMutations([""]),
    init() {
      if (this.value !== null) {
        this.packagingLines = JSON.parse(JSON.stringify(this.value)) as CoffeeArticlePackagingLine[];
      } else {
        this.packagingLines = [];
      }
    },
    inputHandler() {
      // console.log("PackagingLineSettings.inputHandler");
      if (this.autosave) this.save();
    },
    save() {
      // console.log("PackagingLineSettings.save");
      this.$emit("input", this.packagingLines);
    },
    setAvailabilityToZero(lineIndex: number) {
      const la = this.packagingLines[lineIndex];
      for (let i = 0; i < la.workingHoursPerWeekdayIndex.length; i++) {
        la.workingHoursPerWeekdayIndex[i] = 0;
      }
      this.inputHandler();
    },
    resetAvailability(lineIndex: number) {
      const la = this.packagingLines[lineIndex];
      const laDefault = this.defaultValues[lineIndex];
      for (let i = 0; i < la.workingHoursPerWeekdayIndex.length; i++) {
        la.workingHoursPerWeekdayIndex[i] = laDefault.workingHoursPerWeekdayIndex[i];
      }
      this.inputHandler();
    },
    setWeekdayAvailabilityToZero(weekdayIndex: number) {
      for (const la of this.packagingLines) {
        if (this.lineTypeMatching(la)) la.workingHoursPerWeekdayIndex[weekdayIndex] = 0;
      }
      this.inputHandler();
    },
    resetWeekdayAvailability(weekdayIndex: number) {
      for (let r = 0; r < this.packagingLines.length; r++) {
        const la = this.packagingLines[r];
        if (this.lineTypeMatching(la)) {
          const laDefault = this.defaultValues[r];
          la.workingHoursPerWeekdayIndex[weekdayIndex] = laDefault.workingHoursPerWeekdayIndex[weekdayIndex];
        }
      }
      this.inputHandler();
    },
    lineTypeMatching(la: CoffeeArticlePackagingLine): boolean {
      if (!this.tassimo && !this.roastAndGround) {
        return true;
      }
      return (
        (this.tassimo && la.packageType === PackageType.Tassimo) ||
        (this.roastAndGround && la.packageType !== PackageType.Tassimo)
      );
    },
  },
});
