
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { createColorFromNumber } from "@/scripts/utils";
import { MillingSubTaskResult, RoasterTaskResult } from "@/scripts/models/results";
import { WEEKDAYS } from "@/scripts/models/date";
// @ts-ignore
import Plotly from "plotly.js-dist";

export default Vue.extend({
  name: "PackagingTaskPlot",
  components: {},
  props: {
    tasks: { type: Array, default: () => [] },
    xKey: { type: String, required: true },
    yKey: { type: String, required: true },
    groupKey: { type: String, required: true },
    hideSelection: { type: Boolean, default: false },
    mode: { type: String, default: "packaging" },
  },
  data() {
    return {
      traces: {} as any,
      id: `plot_id_${Math.random().toString(16)}`,
      packagingItems: [
        { text: "Artikel", value: "articleNr" },
        { text: "Sorte", value: "blendSpsNr" },
        { text: "Verpackungslinie", value: "millNr" },
        { text: "Wochentag", value: "weekday" },
        // {text: "Menge", value: "articleWeight_ton"},
      ] as Array<{ text: string; value: keyof MillingSubTaskResult | "weekday" }>,
      roastingItems: [
        { text: "Basket", value: "basketSpsNr" },
        // {text: "Artikel", value: "articleNr"},
        // {text: "Sorte", value: "blendSpsNr"},
        { text: "Röster", value: "roasterName" },
        { text: "Wochentag", value: "weekday" },
        // {text: "Menge", value: "roastingWeight_ton"},
      ] as Array<{ text: string; value: keyof MillingSubTaskResult | "weekday" }>,
      keys: {
        x: null,
        y: null,
        group: null,
      } as any,
      baseLayout: {
        barmode: "stack",
        xaxis: {
          type: "category",
        },
        yaxis: {
          ticksuffix: " t",
        },
        height: 220,
        margin: {
          t: 0,
        },
      },
    };
  },
  created() {
    this.initKeys();
    this.updateKeys();
  },
  mounted() {
    this.plot();
  },
  watch: {
    xKey: { immediate: true, handler: "updateKeys" },
    yKey: { immediate: true, handler: "updateKeys" },
    groupKey: { immediate: true, handler: "updateKeys" },
    keys: {
      deep: true,
      handler() {
        this.plot();
      },
    },
    mode: {
      immediate: true,
      handler(mode: string) {
        // setTimeout(() => {this.updateKeys()}, 0)
        // console.log("keys", this.mode, this.keys)
        // this.initKeys()
        this.updateKeys();
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    items(): any[] {
      if (this.mode === "packaging") return this.packagingItems;
      else return this.roastingItems;
    },
  },
  methods: {
    ...mapMutations([""]),
    initKeys() {
      if (this.mode === "packaging") {
        this.keys = {
          x: "weekday" as keyof MillingSubTaskResult | "weekday",
          y: "articleWeight_ton" as keyof MillingSubTaskResult,
          group: "articleNr" as keyof MillingSubTaskResult | "weekday",
        };
      } else {
        this.keys = {
          x: "weekday" as keyof RoasterTaskResult | "weekday",
          y: "roastingWeight_ton" as keyof RoasterTaskResult,
          group: "basketSpsNr" as keyof RoasterTaskResult | "weekday",
        };
      }
    },
    updateKeys() {
      // @ts-ignore
      this.keys.x = this.xKey;
      // @ts-ignore
      this.keys.y = this.yKey;
      // @ts-ignore
      this.keys.group = this.groupKey;
      this.plot();
    },
    plot() {
      if (!document.getElementById(this.id)) {
        // console.log("No DOM element with ID: ", this.id);
        return;
      }
      this.traces = {};
      const tasks = this.tasks as MillingSubTaskResult[];
      const xKey = this.keys.x;
      for (const task of tasks) {
        this.createBarTraces(task, this.keys.x, this.keys.y, this.keys.group);
      }
      // console.log(this.mode, this.traces)
      const layout = { ...this.baseLayout };
      Plotly.newPlot(this.id, Object.values(this.traces), layout, { displayModeBar: false });
    },
    getTimestep(task: any) {
      return task.timeStep
      // if (this.mode === "packaging") {
      //   return Math.floor((task.packagingTaskStartTime_h - 6) / 24);
      // } else {
      //   return Math.floor((task.roastingStartTime_h - 6) / 24);
      // }
    },
    createBarTraces(
      task: MillingSubTaskResult,
      xKey: keyof MillingSubTaskResult | "weekday",
      yKey: keyof MillingSubTaskResult,
      groupKey: keyof MillingSubTaskResult | "weekday"
    ) {
      const timestep = this.getTimestep(task);
      const weekday = WEEKDAYS[timestep];
      const groupValue = groupKey === "weekday" ? weekday : task[groupKey];
      if (groupValue === undefined) {
        return;
      }
      const xValue = xKey === "weekday" ? weekday : task[xKey];
      const yValue = task[yKey];
      const initialXValues = xKey === "weekday" ? [...WEEKDAYS] : [];
      const initialYValues = xKey === "weekday" ? [0, 0, 0, 0, 0, 0, 0] : [];
      // init
      if (!(groupValue in this.traces)) {
        this.traces[groupValue] = {
          name: groupValue,
          x: initialXValues,
          marker: { color: createColorFromNumber(groupValue) },
          y: initialYValues,
          type: "bar",
        };
      }

      const x = this.traces[groupValue].x;
      const y = this.traces[groupValue].y;
      const xIndex = x.indexOf(xValue);
      if (xIndex != -1) {
        y[xIndex] += yValue;
      } else {
        x.push(xValue);
        y.push(yValue);
      }
    },
  },
});
