import { render, staticRenderFns } from "./VerifyUserEmail.vue?vue&type=template&id=b3b4fdf4&scoped=true&"
import script from "./VerifyUserEmail.vue?vue&type=script&lang=ts&"
export * from "./VerifyUserEmail.vue?vue&type=script&lang=ts&"
import style0 from "./VerifyUserEmail.vue?vue&type=style&index=0&id=b3b4fdf4&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3b4fdf4",
  null
  
)

export default component.exports