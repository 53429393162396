
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import Gantt from "@/components/gantt/Gantt.vue";
import FileUpload from "@/components/common/FileUpload.vue";
import GasConsumptionWrapper from "@/components/gas/GasConsumptionWrapper.vue";
// @ts-ignore
import gasModelResults from "@/data/results/solution_2022_cw14_MONDAY_SUNDAY_discrete_cplex.json";
import {AnalyzeGasConsumptionSettings, calculateGasConsumptionPerBasket, GAS_M3_TO_KWH} from "@/scripts/models/gas";
import {
  MillingSubTaskResult,
  RoasterTaskResult,
  SolutionQuality,
  SOLUTION_QUALITY_COLORS,
  SOLUTION_QUALITY_TRANSLATIONS,
} from "@/scripts/models/results";
import {} from "@/scripts/gantt/gantt";
import ProductionPlanTable from "@/components/gas/ProductionPlanTable.vue";
import PackagingSummary from "@/components/gas/PackagingSummary.vue";
import GasConsumptionRange from "@/components/gas/GasConsumptionRange.vue";
import PackagingTaskPlot from "@/components/gas/TaskPlot.vue";
import RoastingSummary from "@/components/gas/RoastingSummary.vue";
import {CoffeeProductionCompliancePlanSingle} from "@/scripts/models/coffee";
import {defaultGasConsumptions} from "@/data/defaultGasConsumptions";
import PackagingPlanOverview from "@/components/gas/PackagingPlanOverview.vue";
import AnalyzeGasConsumptionSection from "@/components/gas/AnalyzeGasConsumptionSection.vue";
import PapaSummaryGasAnalysis from "@/components/gas/PapaSummaryGasAnalysis.vue";
import PapaSettingsSection from "@/components/calculation/PapaSettingsSection.vue";
import DoriSettingsSection from "@/components/calculation/DoriSettingsSection.vue";

import PapaGasConsumptionSettings from "@/components/settings/PapaGasConsumptionSettings.vue";
import RoasterAvailabilitySettings from "@/components/settings/RoasterAvailabilitySettings.vue";
import PackagingLineSettings from "@/components/settings/PackagingLineSettings.vue";
import {
  CoffeeProductionDORISolveRequest,
  CoffeeProductionDORISummary,
  RoastingDeviation,
  RoastingDeviationType,
  SiloUtilizationState
} from "@/scripts/models/dori";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CoffeeProductionModelType, InstantRequest, SolveRequest} from "@/scripts/models/request";
import {WEEKDAYS, weekdayToTimestepMap} from "@/scripts/models/date";
import {
  createGanttChartConfig_RoasterTask,
  createGanttChartConfig_MillingTaskSummary,
  createGanttChartConfig_MillingTask,
  createGanttChartConfig_RoasterSubtask, getTaskTime,
} from "@/scripts/gantt/coffee-gantt";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import SaraSummary from "@/components/cellplan/SaraSummary.vue";
import {formatDate, formatDateShort, getSummaryTitle} from "@/scripts/utils";
import DoriPerformanceDashboard from "@/components/common/DoriPerformanceDashboard.vue";
import {UserInfo, UserRole} from "@/scripts/auth";
import SaveSettingsDialog from "@/components/settings/SaveSettingsDialog.vue";
import DoriRoasterTaskTable from "@/components/dori/DoriRoasterTaskTable.vue";
import GanttChartState from "@/scripts/gantt/GanttChartState";
import SiloUtilization from "@/components/dori/SiloUtilization.vue";
import SolverStatisticsPlot from "@/components/analysis/SolverStatisticsPlot.vue";
import {SolverOutputLineCplex} from "@/scripts/models/statistics";

// import doriResult from "@/data/dori/solution_2022_cw44_MONDAY_SUNDAY_v1_gurobi.json"

enum FilterType {
  TAS_ONLY = "TAS_ONLY",
  RG_ONLY = "RG_ONLY",
  BOTH = "BOTH",
}

export default Vue.extend({
  name: "DoriSummary",
  components: {
    SolverStatisticsPlot,
    SiloUtilization,
    DoriRoasterTaskTable,
    SaveSettingsDialog,
    DoriPerformanceDashboard,
    RoastingSummary,
    PackagingTaskPlot,
    PackagingSummary,
    ProductionPlanTable,
    Gantt,
    GasConsumptionWrapper,
    FileUpload,
    PackagingPlanOverview,
    GasConsumptionRange,
    AnalyzeGasConsumptionSection,
    PapaSummaryGasAnalysis,
    PapaSettingsSection,
    PapaGasConsumptionSettings,
    RoasterAvailabilitySettings,
    PackagingLineSettings,
    SaraSummary,
    DoriSettingsSection,
  },
  props: {
    resultSummary: {
      type: Object as PropType<CoffeeProductionDORISummary>,
      required: true,
    },
  },
  data() {
    return {
      CoffeeProductionModelType,
      solveRequest: null as CoffeeProductionDORISolveRequest | null,
      saraSummary: null as CoffeeProductionSARASummary | null,
      settings: null as any,
      // UserType,
      settingsTab: 0,
      roastingTab2: 0,
      // ganttChartItems: [] as GanttChartItem[],
      packagingTab: null,
      roastingTab: null,
      tab: null,
      // TODO: change any to Type
      ganttChartConfig: null as any,
      // resultSummary: doriResult as unknown as CoffeeProductionDORISummary, //null as CoffeeProductionDORISummary | null,
      packagingDisplayKey: "blendSpsNr",
      breadcrumbs: [
        {text: "DORI", disabled: true, to: ""},
        {text: "Tagesplan", disabled: true},
      ],
      energyMode: "energy",
      energyModes: [
        {text: "Gasvolumen m³", value: "volume"},
        {text: "Energie MWh", value: "energy"},
      ],

      // TEMP
      modes: [
        {text: "Artikel", value: "article"},
        {text: "Sorte", value: "blend"},
      ],
      activeMode: "blend",
      filter: FilterType.BOTH,
      filterTypes: [
        {text: "Tassimo + R&G", value: FilterType.BOTH},
        {text: "Tassimo", value: FilterType.TAS_ONLY},
        {text: "R&G", value: FilterType.RG_ONLY},
      ],
      ganttConfigs: {
        roasterTask: null as any,
        roasterSubtask: null as any,
        millingTask: null as any,
        millingTaskSummary: null as any,
      },
      ganttStates: {
        roasterTask: null as GanttChartState | null,
        roasterSubtask: null as GanttChartState | null,
        millingTask: null as GanttChartState | null,
        millingTaskSummary: null as GanttChartState | null,
      } as Record<string, GanttChartState | null>,
      currentTime: Date.now(),
      roasterShowDoneTasks: false,
      roasterShowPendingTasks: false,
      solverOutputStats: [] as SolverOutputLineCplex[],
    };
  },
  created() {
  },
  mounted() {
    // setInterval(() => {
    //   this.currentTime = Date.now() + Math.random()*1000*60*60;
    //   console.log("setting current time", this.currentTime);
    //   // this.initializeResultSummary();
    //   // this.loadSaraSummaryAndSolveRequest();
    // }, 1_000);
  },
  watch: {
    roasterShowDoneTasks(v) {
      if (!this.ganttStates.roasterTask) return
      this.ganttStates.roasterTask.showDoneTasks = v
    },
    roasterShowPendingTasks(v) {
      if (!this.ganttStates.roasterTask) return
      this.ganttStates.roasterTask.showPendingTasks = v
    },
    resultSummary: {
      immediate: true,
      async handler(v) {
        // console.warn("workshop version: loading of solutions not yet implemented");
        if (this.resultSummary === null) return;
        this.initializeResultSummary();
        this.loadSaraSummaryAndSolveRequest();
        this.loadSolverStatistics();
      }
    },

  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    deviationErrorsAndWarnings() {
      const forCurrentDay: any = {}
      const forFollowingDays: any = {}
      const alreadyPlanned: any = {}
      const dev = this.roastingDeviationsPerWeekday
      for (const weekday in dev) {
        const deviations = dev[weekday]
        for (const deviation of deviations) {
          if (deviation.deviationType === RoastingDeviationType.FOR_NEXT_DAYS) {
            if (!forFollowingDays[weekday]) {
              forFollowingDays[weekday] = []
            }
            forFollowingDays[weekday].push(deviation)
          } else if (deviation.deviationType === RoastingDeviationType.FOR_CURRENT_DAY) {
            if (!forCurrentDay[weekday]) {
              forCurrentDay[weekday] = []
            }
            forCurrentDay[weekday].push(deviation)
          } else if (deviation.deviationType === RoastingDeviationType.ALREADY_PLANNED_TASK) {
            if (!alreadyPlanned[weekday]) {
              alreadyPlanned[weekday] = []
            }
            alreadyPlanned[weekday].push(deviation)
          } else if (deviation.deviationType === null) {
            if (!forFollowingDays[weekday]) {
              forFollowingDays[weekday] = []
            }
            forFollowingDays[weekday].push(deviation)
          }
        }
      }

      const errorsAndWarnings: any = []
      for (const weekday in forCurrentDay) {
        errorsAndWarnings.push({
          title: `Abweichende Röstmengen - ${weekday} für ${weekday}`,
          weekday: weekday,
          chipMessages: forCurrentDay[weekday].map((dev: any) => `${dev.basketSpsNr}: ${dev.actualWeight}/${dev.plannedWeight}t`),
          type: "error"
        })
      }
      for (const weekday in forFollowingDays) {
        errorsAndWarnings.push({
          title: `Abweichende Röstmengen - ${weekday} für Folgetage`,
          weekday: weekday,
          chipMessages: forFollowingDays[weekday].map((dev: any) => `${dev.basketSpsNr}: ${dev.actualWeight}/${dev.plannedWeight}t`),
          type: "warning"
        })
      }
      for (const weekday in alreadyPlanned) {
        errorsAndWarnings.push({
          title: `Abweichungen laufender Röstaufträge - ${weekday}`,
          weekday: weekday,
          chipMessages: alreadyPlanned[weekday].map((dev: any) => `${dev.roasterName === null ? "" : dev.roasterName + ", "}${dev.basketSpsNr}: ${dev.actualWeight}/${dev.plannedWeight}t`),
          type: "warning"
        })
      }

      // milling
      const millDevs = this.deviatingMillingTasksPerWeekday
      for (const weekday in millDevs) {
        errorsAndWarnings.push({
          title: `Abweichende Mühlenaufträge - ${weekday}`,
          weekday: weekday,
          chipMessages: millDevs[weekday].map((dev: any) => `${dev.blendSpsNr}, ${dev.plannedStartTime}: ${dev.actualWeight}/${dev.plannedWeight}t`),
          type: "error"
        })
      }

      return errorsAndWarnings

    },
    roastingDeviationsPerWeekday(): any {
      const devs = this.resultSummary.result?.roastingDeviations ?? [];
      const startDate = this.resultSummary.timeRange.startDate;
      const endDate = this.resultSummary.timeRange.endDate;
      const indices = {
        startDayIndex: weekdayToTimestepMap[startDate.dayOfWeek],
        endDayIndex: weekdayToTimestepMap[endDate.dayOfWeek] + 1,
      };
      const round = (n: number) => Math.round(n * 10) / 10
      let devsFormatted = devs.map(dev => {
        return {
          roasterName: dev.roasterName, //only set for initial roasting deviations
          basketSpsNr: dev.basketSpsNr,
          dayOfWeek: WEEKDAYS[(indices.startDayIndex + dev.timeStep) % 7],
          plannedWeight: round(dev.plannedBasketWeight_ton),
          actualWeight: round(dev.plannedBasketWeight_ton + dev.basketWeightDeviation_ton),
          deviationType: dev.deviationType
        }
      })
      const roastingDeviationsPerWeekday: Record<string, any> = {}
      for (let dev of devsFormatted) {
        if (!roastingDeviationsPerWeekday[dev.dayOfWeek]) {
          roastingDeviationsPerWeekday[dev.dayOfWeek] = []
        }
        roastingDeviationsPerWeekday[dev.dayOfWeek].push(dev)
      }
      // console.log("roastingDeviationsPerWeekday", roastingDeviationsPerWeekday)
      return roastingDeviationsPerWeekday
    },
    deviatingMillingTasksPerWeekday(): any {
      const tasks = this.resultSummary.result?.millingSubTaskResults ?? [];
      const startDate = this.resultSummary.timeRange.startDate;
      const endDate = this.resultSummary.timeRange.endDate;
      const indices = {
        startDayIndex: weekdayToTimestepMap[startDate.dayOfWeek],
        endDayIndex: weekdayToTimestepMap[endDate.dayOfWeek] + 1,
      };
      const round = (n: number) => Math.round(n * 10) / 10
      //ToDo: filter the tasks: only consider them once for a specific 
      //(task.timeStep, task.millNr, task.millingTaskIndex) triple
      const seenTasks = new Set()
      let devsFormatted = tasks
          // filter with tolerance
          .filter(task => Math.abs((task.plannedTaskBlendWeight_ton ?? 0)
              - (task.taskBlendWeight_ton ?? 0)) > 0.05)
          // filter for duplicates
          .filter(task => {
            const hash = `${task.timeStep}_${task.millNr}_${task.millingTaskIndex}`
            if (seenTasks.has(hash)) {
              return false
            } else {
              seenTasks.add(hash)
              return true
            }
          })
          // build formatted object
          .map(task => {
            const startTime = getTaskTime(this.resultSummary, task.plannedTaskStartTime_h!)
            const startTimeFormatted = new Date(startTime).toLocaleTimeString("de-DE", {
              hour: "2-digit",
              minute: "2-digit"
            })
            return {
              millNr: task.millNr,
              millingTaskIndex: task.millingTaskIndex,
              blendSpsNr: task.blendSpsNr,
              dayOfWeek: WEEKDAYS[(indices.startDayIndex + task.timeStep) % 7],
              plannedWeight: round(task.plannedTaskBlendWeight_ton ?? 0),
              actualWeight: round(task.taskBlendWeight_ton ?? 0),
              plannedStartTime: startTimeFormatted
            }
          })
      const deviatingMillingTasksPerWeekday: Record<string, any> = {}
      for (let dev of devsFormatted) {
        if (!deviatingMillingTasksPerWeekday[dev.dayOfWeek]) {
          deviatingMillingTasksPerWeekday[dev.dayOfWeek] = []
        }
        deviatingMillingTasksPerWeekday[dev.dayOfWeek].push(dev)
      }
      // console.log("deviatingMillingTasksPerWeekday", deviatingMillingTasksPerWeekday)
      return deviatingMillingTasksPerWeekday
    },
    utilizationStates(): SiloUtilizationState[] {
      return this.resultSummary?.result?.siloUtilization?.utilizationStates ?? [];
    },
    utilizationStatesComputed(): SiloUtilizationState[] {
      return this.resultSummary?.result?.siloUtilizationComputed?.utilizationStates ?? [];
    },
    demoFixedTime(): number {
      const doriStartDate = this.resultSummary.timeRangeStart
      const offset = 1000 * 60 * 60 * 24 * 1.42 // ~ Do 10:00
      return new Date(doriStartDate).getTime() + offset
    },
    isDoriAdminOrMore(): boolean {
      const role = (this.user as UserInfo)?.doriUserRole
      return role === UserRole.ADMIN || role === UserRole.SUPERUSER;
    },
    isDoriSuperuser(): boolean {
      return (this.user as UserInfo)?.doriUserRole === UserRole.SUPERUSER;
    },
    timeRangeIndices(): { startDayIndex: number; endDayIndex: number } {
      if (this.resultSummary === null) return {startDayIndex: 0, endDayIndex: 7};
      const startDate = this.resultSummary.timeRange.startDate;
      const endDate = this.resultSummary.timeRange.endDate;
      const indices = {
        startDayIndex: weekdayToTimestepMap[startDate.dayOfWeek],
        endDayIndex: weekdayToTimestepMap[endDate.dayOfWeek] + 1,
      };
      return indices;
    },
    energyRangeDefinitions(): any[] {
      return [
        {name: "Gesamt", energyConsumption: this.resultSummary!.result.totalEnergyConsumption},
        {name: "Roast & Ground", energyConsumption: this.resultSummary!.result.roastAndGroundEnergyConsumption},
        {name: "Tassimo", energyConsumption: this.resultSummary!.result.tassimoEnergyConsumption},
      ];
    },
    energyFactor() {
      if (this.energyMode === "energy") {
        return 1;
      } else if (this.energyMode === "volume") {
        return (1 / GAS_M3_TO_KWH) * 1000;
      }
    },
    energyUnit() {
      if (this.energyMode === "volume") {
        return "m³";
      } else if (this.energyMode === "energy") {
        return "MWh";
      }
    },
    offsetPackagingTasks(): { rg: MillingSubTaskResult[]; tas: MillingSubTaskResult[] } {
      const res = {
        rg: [] as MillingSubTaskResult[],
        tas: [] as MillingSubTaskResult[],
      };
      if (this.resultSummary === null) {
        return res;
      }
      const splitLine = 16;
      for (let packagingTask of this.resultSummary.result.millingSubTaskResults) {
        packagingTask = JSON.parse(JSON.stringify(packagingTask));
        // console.log("old index", packagingTask.timeStep);
        packagingTask.timeStep += this.timeRangeIndices.startDayIndex;
        // console.log("new index", packagingTask.timeStep);
        if (packagingTask.millNr < splitLine) {
          res.rg.push(packagingTask);
        } else {
          res.tas.push(packagingTask);
        }
      }
      return res;
    },
    roastingTasks(): RoasterTaskResult[] {
      // console.log("this.timeRangeIndices.startDayIndex", this.timeRangeIndices.startDayIndex);
      if (this.resultSummary === null) return [];
      const tasks = this.resultSummary.result.roasterTaskResults;
      const shiftedTasks = [];
      for (let task of tasks) {
        task = JSON.parse(JSON.stringify(task));
        task.timeStep += this.timeRangeIndices.startDayIndex;
        shiftedTasks.push(task);
      }
      return shiftedTasks;
    },
  },
  methods: {
    formatDateShort,
    formatDate,
    ...mapMutations([""]),
    async loadSolverStatistics() {
      console.log("loadSolverStatistics")
      const bi = this.backendInterface as BackendInterface;
      this.solverOutputStats = await bi.dori_GetSolutionSolverStatisticsById(this.resultSummary.id) ?? [];
      console.log("solverOutputStats", this.solverOutputStats)
    },
    initializeResultSummary() {
      const resultSummary: CoffeeProductionDORISummary = this.resultSummary;
      this.settings = {
        loadedPlans: {
          rg: resultSummary.compliancePlan.planRG,
          tas: resultSummary.compliancePlan.planTAS,
        },
        maxTotalGasConsumption: 10000,
        // gasConsumptionsPerBasket: calculateGasConsumptionPerBasket(resultSummary.inputSettings.gasConsumptions),
      } as AnalyzeGasConsumptionSettings;
      this.ganttConfigs = {
        roasterTask: createGanttChartConfig_RoasterTask(resultSummary),
        roasterSubtask: createGanttChartConfig_RoasterSubtask(resultSummary),
        millingTask: createGanttChartConfig_MillingTask(resultSummary),
        millingTaskSummary: createGanttChartConfig_MillingTaskSummary(resultSummary),
      }
      this.ganttStates = {
        roasterTask: new GanttChartState(this.ganttConfigs.roasterTask),
        roasterSubtask: new GanttChartState(this.ganttConfigs.roasterSubtask),
        millingTask: new GanttChartState(this.ganttConfigs.millingTask),
        millingTaskSummary: new GanttChartState(this.ganttConfigs.millingTaskSummary),
      }

      const doriCalculationStartDate = new Date(resultSummary.timeRangeStart).getTime()
      // check if calculation start date is older than one day
      const olderThan_oneDay = doriCalculationStartDate < Date.now() - 1000 * 60 * 60 * 24 * 1
      // console.log("doriCalculationStartDate", doriCalculationStartDate)

      if (this.isDoriSuperuser && olderThan_oneDay) {
        // set disableTimeCursorWhenOutside to false
        for (const state of Object.values(this.ganttStates)) {
          if (!state) continue
          const [allStart, allEnd] = state.itemsTimespan
          const [realStart, realEnd] = state.itemsTimespanNotBlocked
          const pos = (realStart - allStart) / (allEnd - allStart);
          // console.log(`All Start:  ${new Date(allStart)}`)
          // console.log(`All End:    ${new Date(allEnd)}`)
          // console.log(`Real Start: ${new Date(realStart)}`)
          // console.log("initialScrollX", pos)
          state.initialPosX = pos
          // state.disableTimeCursorWhenOutside = true
          state.showTimeCursor = false
          state.lockTimeCursor = false
          // state.setTimeCursorPosition(pos)
        }
      }
      // console.log("this.ganttConfigs", this.ganttConfigs);
      // console.log("this.ganttStates", this.ganttStates);
    },
    async loadSaraSummaryAndSolveRequest() {
      const bi = this.backendInterface as BackendInterface;
      // const resultSummary = await bi.dori_GetSolutionById(v);
      // this.resultSummary = resultSummary;
      try {
        this.solveRequest = await bi.dori_GetUsedSolveRequest(this.resultSummary.id!);
      } catch (error) {
        throw new Error("Could not load solve request!");
      }
      try {
        this.saraSummary = await bi.sara_GetSolutionById(this.solveRequest.saraSummaryId);
        this.saraSummary.solveRequest = await bi.sara_GetUsedSolveRequest(this.solveRequest.saraSummaryId);
      } catch (error) {
        throw new Error("Could not load SARA summary!");
      }
      console.log("DORI resultSummary", this.resultSummary);
    },
  },
});
