
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import RoasterAvailabilityPreview from "@/components/settings/RoasterAvailabilityPreview.vue";

export default Vue.extend({
  name: "SaraSettingsPreview",
  components: {RoasterAvailabilityPreview},
  props: {
    settingsId: {
      // type: String as PropType<string|null>,
      required: true,},
    modelType: {
      type: String as PropType<CoffeeProductionModelType>,
      default: CoffeeProductionModelType.SARA,
    }
  },
  data() {
    return {
      settings: null as any,
    };
  },
  created() {
  },
  watch: {
    settingsId: {
      immediate: true,
      handler() {
        this.loadSettings()
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
  },
  methods: {
    ...mapMutations([""]),
    async loadSettings() {
      if(!this.settingsId) return
      const settingsId = this.settingsId as string
      this.settings = await this.bi.getUserSettings(this.modelType, settingsId)
      console.log("loadPreview", this.settings)
    },
  },
});
