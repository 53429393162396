import { UserAccessType } from "@/scripts/models/general";
import {
  CoffeeProductionInputData,
  CoffeeProductionInputFileType,
  SiloAssignmentInputFilter,
  CoffeeProductionSARAInputSettings,
  CoffeeProductionSolverParameters,
} from "@/scripts/models/calculation";
import { CoffeeBasket, CoffeeSiloAllocation, CoffeeBlendFulfilledDemand, CoffeeRoaster } from "@/scripts/models/coffee";
import { DayOfWeekDate, DayOfWeekDateRange } from "@/scripts/models/date";
import { CoffeeProductionPAPAInputSettings } from "./papa";

export interface DateRequest {
  // productionSiteUniqueName: string; //String;
  date: Date | string; //LocalDate;
}

export interface DateTimeRequest {
  // productionSiteUniqueName: string; //String;
  localDateTime: Date | string; //LocalDate;
}

export interface InstantRequest {
  // productionSiteUniqueName: string; //String;
  instant: Date | string; //Instant;
}

export interface ModelDateRequest {
  // productionSiteUniqueName: string; //String;
  date: Date | string; //LocalDate;
  modelType: CoffeeProductionModelType;
}

export enum CoffeeProductionModelType {
  PAPA = "PAPA",
  SARA = "SARA",
  DORI = "DORI",
}

export interface FileRequest {
  // productionSiteUniqueName: string;
  timeRangeStart: string; // LocalDate;
  timeRangeEnd: string; // LocalDate;
  fileType: CoffeeProductionInputFileType | null;
  modelType: CoffeeProductionModelType;
}

export interface FileUploadRequest {
  // productionSiteUniqueName: string;
  fileType: CoffeeProductionInputFileType;
  // dataValidityTimeUnit?: string; // TimeUnit;
  dataValidityStartTime: string; // LocalDateTime
  modelType: CoffeeProductionModelType;
}

// TODO: rename accordingly
export interface SolveRequest {
  // MongoDB
  id?: string;
  // input
  inputData: CoffeeProductionInputData;
  inputFilter: SiloAssignmentInputFilter;
  inputSettings: CoffeeProductionSARAInputSettings;
  coffeeRoasters: CoffeeRoaster[];
  // output
  outputHash: string;
  // solve params
  solverParameters: CoffeeProductionSolverParameters[];
  // time range selection
  timeRange: DayOfWeekDateRange;
  defaultSolutionStrategy?: string;
  defaultObjectiveType?: string;
  userSettingsId: string|null;
  userSettingsModified: boolean;
  //parent summary id, if set in the frontend the solver will try to use the
  //solution stored in the parent summary as a start point for the optimization
  parentSummaryId: string|null;
}

export interface SolveRequestRequest {
  // productionSiteUniqueName: string; // String;
  timeRangeStart: string; // LocalDate;
  timeRangeEnd: string; // LocalDate;
  inputFileIds: string[];
  // userType: UserType;
  preroastForNextWeek: boolean;
  substituteMissingBasketsByTestBaskets: boolean;
  userSettingsId: string|null;
  // optional input settings uploaded and parsed from a saved PAPA/SARA/DORI input settings export file
  inputSettings?: string;
}

export interface SARASolveResultRequest {
  // productionSiteUniqueName: string; // String;
  timeRangeStart: string; // LocalDate;
  timeRangeEnd: string; // LocalDate;
  inputFileIds: string[];
  inputSettings: CoffeeProductionSARAInputSettings;
  inputFilter: SiloAssignmentInputFilter;
  solverParameters: CoffeeProductionSolverParameters[];
  coffeeBaskets: CoffeeBasket[];
  coffeeRoasters: CoffeeRoaster[];
  siloAllocations: CoffeeSiloAllocation[];
  fulfilledDemands: CoffeeBlendFulfilledDemand[];
}

export interface PAPASolveResultRequest {
  // productionSiteUniqueName: string; // String;
  timeRangeStart: string; // LocalDate;
  timeRangeEnd: string; // LocalDate;

  inputFileIds: string[];
  inputSettings: CoffeeProductionPAPAInputSettings;
  coffeeBaskets: CoffeeBasket[];
  coffeeRoasters: CoffeeRoaster[];
  siloAllocations: CoffeeSiloAllocation[];
  fulfilledDemands: CoffeeBlendFulfilledDemand[];
  solverParameters: CoffeeProductionSolverParameters;
}

export interface DataStatusesRequest {
  // productionSiteUniqueName: string; // String;
  dataValidityTimeLower: string;// LocalDate;
  dataValidityTimeUpper: string;// LocalDate;
}

export interface ValidityStartRequest {
  // productionSiteUniqueName: string; // String;
  dayOfWeekDate: DayOfWeekDate; // LocalDate;
  timeUnit: string;
}

export interface LastSolutionsRequest {
  // productionSiteUniqueName: string;
  modelType: CoffeeProductionModelType;
  maximumSolutionCount: number;
}

export interface CoffeeProductionSupportRequest {
  // senderEmailAddress: string;
  // userType: UserType;
  ccEmails: string[];
  topic: string;
  htmlMessage: string;
}

export interface SummaryMetadataRequest {
  modelType: CoffeeProductionModelType
  summaryIds: string[]
}

export interface DeleteSettingsRequest {
  modelType: CoffeeProductionModelType;
  // userType: UserType;
}

export interface CreateUserSettingsRequest {
  settingsName: string;
  readAccess: UserAccessType;
  writeAccess: UserAccessType;
  solveRequestId: string;
  solveRequest: SolveRequest;
}

// export interface DORIDeleteSettingsRequest {
//   modelType: CoffeeProductionModelType;
//   userSettingsId: string,
//   deleteTypes: string[] // DORISettingsPart;
// }

export interface DeleteSettingsPartRequest {
  modelType: CoffeeProductionModelType,
  userSettingsId: string,
  deleteTypes: string[] // SARASettingsPart;
}

// export interface PAPADeleteSettingsRequest {
//   modelType: CoffeeProductionModelType;
//   userSettingsId: string,
//   deleteTypes: string[] // PAPASettingsPart;
// }
//
// export interface UserSettingsMetadataChangeRequest {
//   id: string;
//   modelType: CoffeeProductionModelType;
//   //metadata to be changed
//   name: string;
//   readAccess: UserAccessType;
//   writeAccess: UserAccessType;
// }
