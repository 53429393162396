
import Vue, {PropType} from "vue";
import { mapMutations, mapState } from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import RoasterAvailabilityPreview from "@/components/settings/RoasterAvailabilityPreview.vue";
import {MultiExclusion} from "@/scripts/exclusions";
import {Weekday} from "@/scripts/models/date";
import {ROASTER_NAMES} from "@/scripts/models/gas";
import RoasterAvailabilitySettings from "@/components/settings/RoasterAvailabilitySettings.vue";
import AvailabilityExclusionSettings from "@/components/settings/AvailabilityExclusionSettings.vue";
import {CoffeeRoasterAvailabilityExclusion} from "@/scripts/models/dori";
import ProgressInfo from "@/components/common/ProgressInfo.vue";
import {formatSeconds} from "@/scripts/utils";
import { SiloAllocationStateAnalysis } from "@/scripts/models/statistics";
import SiloAllocationStateAnalysisComponent from "@/components/analysis/SiloAllocationStateAnalysis.vue";

export default Vue.extend({
  name: "Sandbox",
  components: {
    SiloAllocationStateAnalysis: SiloAllocationStateAnalysisComponent,
    ProgressInfo, AvailabilityExclusionSettings, RoasterAvailabilitySettings, RoasterAvailabilityPreview},
  props: {},
  data() {
    return {
      startDate: "2023-11-13",
      endDate: "2023-11-20",
    };
  },
  async created() {

  },
  watch: {
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
  },
  methods: {
    ...mapMutations([""]),
    formatSeconds,
  },
});
