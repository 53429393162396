
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import Gantt from "@/components/gantt/Gantt.vue";
import FileUpload from "@/components/common/FileUpload.vue";
import GasConsumptionWrapper from "@/components/gas/GasConsumptionWrapper.vue";
// @ts-ignore
import gasModelResults from "@/data/results/solution_2022_cw14_MONDAY_SUNDAY_discrete_cplex.json";
import { AnalyzeGasConsumptionSettings, calculateGasConsumptionPerBasket, GAS_M3_TO_KWH } from "@/scripts/models/gas";
import {
  CoffeeProductionAssignmentResultSummary,
  CoffeeProductionAssignmentResultSummaryInterface,
  MillingSubTaskResult,
  SolutionQuality,
  SOLUTION_QUALITY_COLORS,
  SOLUTION_QUALITY_TRANSLATIONS,
} from "@/scripts/models/results";
import ProductionPlanTable from "@/components/gas/ProductionPlanTable.vue";
import PackagingSummary from "@/components/gas/PackagingSummary.vue";
import GasConsumptionRange from "@/components/gas/GasConsumptionRange.vue";
import PackagingTaskPlot from "@/components/gas/TaskPlot.vue";
// import { UserType } from "@/scripts/models/general";
import RoastingSummary from "@/components/gas/RoastingSummary.vue";
import { CoffeeProductionCompliancePlanSingle } from "@/scripts/models/coffee";
import { defaultGasConsumptions } from "@/data/defaultGasConsumptions";
import PackagingPlanOverview from "@/components/gas/PackagingPlanOverview.vue";
import AnalyzeGasConsumptionSection from "@/components/gas/AnalyzeGasConsumptionSection.vue";
import PapaSummaryGasAnalysis from "@/components/gas/PapaSummaryGasAnalysis.vue";
import PapaSettingsSection from "@/components/calculation/PapaSettingsSection.vue";
import PapaGasConsumptionSettings from "@/components/settings/PapaGasConsumptionSettings.vue";
import RoasterAvailabilitySettings from "@/components/settings/RoasterAvailabilitySettings.vue";
import PackagingLineSettings from "@/components/settings/PackagingLineSettings.vue";

import { BackendInterface } from "@/scripts/BackendInterface";
import { InstantRequest, SolveRequest } from "@/scripts/models/request";
import { createGanttChartConfig_MillingTask, createGanttChartConfig_MillingTaskSummary, createGanttChartConfig_RoasterSubtask, createGanttChartConfig_RoasterSubtaskSummary } from "@/scripts/gantt/coffee-gantt";
import {UserInfo, UserRole} from "@/scripts/auth";

enum FilterType {
  TAS_ONLY = "TAS_ONLY",
  RG_ONLY = "RG_ONLY",
  BOTH = "BOTH",
}

export default Vue.extend({
  name: "PapaSummaryView",
  components: {
    RoastingSummary,
    PackagingTaskPlot,
    PackagingSummary,
    ProductionPlanTable,
    Gantt,
    GasConsumptionWrapper,
    FileUpload,
    PackagingPlanOverview,
    GasConsumptionRange,
    AnalyzeGasConsumptionSection,
    PapaSummaryGasAnalysis,
    PapaSettingsSection,
    PapaGasConsumptionSettings,
    RoasterAvailabilitySettings,
    PackagingLineSettings
  },
  props: {},
  data() {
    return {
      // UserType,
      settingsTab: 0,
      showSnackbar: false,
      snackbarText: "",
      // ganttChartItems: [] as GanttChartItem[],
      packagingTab: null,
      roastingTab: null,
      tab: null,
      // TODO: change any to Type
      ganttChartConfig: null as any,
      tabConfigs: null as any,
      resultSummary: null as CoffeeProductionAssignmentResultSummary | null,
      settings: null as AnalyzeGasConsumptionSettings | null,
      packagingDisplayKey: "articleNr",
      papaSummaryId: null as null | string,
      breadcrumbs: [
        { text: "PAPA", disabled: true, to: "" },
        { text: "Produktionsplan Ansehen", disabled: true },
      ],
      energyMode: "energy",
      energyModes: [
        { text: "Gasvolumen m³", value: "volume" },
        { text: "Energie MWh", value: "energy" },
      ],

      // TEMP
      modes: [
        { text: "Artikel", value: "article" },
        { text: "Sorte", value: "blend" },
      ],
      activeMode: "article",
      filter: FilterType.RG_ONLY,
      filterTypes: [
        { text: "Tassimo + R&G", value: FilterType.BOTH },
        { text: "Tassimo", value: FilterType.TAS_ONLY },
        { text: "R&G", value: FilterType.RG_ONLY },
      ],
      solveRequest: null as null | SolveRequest,
    };
  },
  created() {
    // this.resultSummary = new CoffeeProductionAssignmentResultSummary(gasModelResults)
    // this.initializeResultSummary()
  },
  mounted() {
    this.papaSummaryId = this.$route.params.id;
  },
  watch: {
    async papaSummaryId(v) {
      if (v === null) return;
      // this.loading = true;
      const bi = this.backendInterface as BackendInterface;
      const resultSummary = await bi.papa_GetSolutionById(v);
      try {
        this.solveRequest = await bi.papa_GetUsedSolveRequest(this.papaSummaryId!);
      } catch (error) {
        throw "Could not load solve request!";
      }
      this.resultSummary = new CoffeeProductionAssignmentResultSummary(resultSummary);
      console.log("this.resultSummary", this.resultSummary);
      // this.loading = false;
    },

    resultSummary: {
      immediate: true,
      handler() {
        if (this.resultSummary === null) return;
        // this.resultSummary = new CoffeeProductionAssignmentResultSummary(this.resultSummary as unknown as any)
        this.initializeResultSummary();
        this.resultSummary.compliancePlan.planRG.setBasketRoasterGasConsumptions(defaultGasConsumptions);
        this.resultSummary.compliancePlan.planTAS.setBasketRoasterGasConsumptions(defaultGasConsumptions);

        //
        // DEBUG
        //
        //         console.log(`Known values:
        // Cw14: 84444 m³
        // Cw15: 34518 m³
        // Cw20: 68301 m³
        //         `);

        //         console.log("resultSummary", this.resultSummary);

        //         // ENERGY TOTAL
        //         // console.log("SOLUTION ENERGY TOTAL")
        //         // console.log("Frontend ", this.resultSummary.calculateTotalEnergyConsumption().toFixed(0))
        //         // console.log("Backend  ", this.resultSummary.result.totalRoasterEnergy.toFixed(0))

        //         // GAS TOTAL
        //         // console.log("SOLUTION GAS TOTAL")
        //         const gasResult_backend = this.resultSummary.result.totalRoasterEnergy / GAS_M3_TO_KWH;
        //         const gasResult_frontend = this.resultSummary.calculateTotalGasConsumption();

        //         // ENERGY MIN
        //         const energyMin_frontend =
        //           this.resultSummary.compliancePlan.planRG.getTotalGasConsumption("min") +
        //           this.resultSummary.compliancePlan.planTAS.getTotalGasConsumption("min");
        //         const energyMin_backend = (this.resultSummary.result.totalRoasterEnergyLower * 1000) / GAS_M3_TO_KWH;

        //         const energyMax_frontend =
        //           this.resultSummary.compliancePlan.planRG.getTotalGasConsumption("max") +
        //           this.resultSummary.compliancePlan.planTAS.getTotalGasConsumption("max");
        //         const energyMax_backend = (this.resultSummary.result.totalRoasterEnergyUpper * 1000) / GAS_M3_TO_KWH;

        //         console.log(
        //           `GAS RANGE FRONTEND: ${gasResult_backend.toFixed(0)} [${energyMin_frontend.toFixed(
        //             0
        //           )}, ${energyMax_frontend.toFixed(0)}]`
        //         );

        //         console.log(
        //           `GAS RANGE BACKEND: ${gasResult_frontend.toFixed(0)} [${energyMin_backend.toFixed(
        //             0
        //           )}, ${energyMax_backend.toFixed(0)}]`
        //         );

        //         const plan = CoffeeProductionCompliancePlanSingle.fromObject(gasModelResults.compliancePlan.planRG);
        //         plan.setBasketRoasterGasConsumptions(gasModelResults.inputSettings.gasConsumptions);
        //         console.time("gas");
        //         plan.calculateGasConsumptions();
        //         console.timeEnd("gas");
        //         console.log("plan", plan);
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    isPapaAdminOrMore(): boolean {
      const role = (this.user as UserInfo)?.papaUserRole
      return role === UserRole.ADMIN || role === UserRole.SUPERUSER;
    },
    isPapaSuperuser(): boolean {
      return (this.user as UserInfo)?.papaUserRole === UserRole.SUPERUSER;
    },
    energyRangeDefinitions(): any[] {
      return [
        { name: "Gesamt", energyConsumption: this.resultSummary!.result.totalEnergyConsumption },
        { name: "Roast & Ground", energyConsumption: this.resultSummary!.result.roastAndGroundEnergyConsumption },
        { name: "Tassimo", energyConsumption: this.resultSummary!.result.tassimoEnergyConsumption },
      ];
    },
    energyFactor() {
      if (this.energyMode === "energy") {
        return 1;
      } else if (this.energyMode === "volume") {
        return 1 / GAS_M3_TO_KWH * 1000;
      }
    },
    energyUnit() {
      if (this.energyMode === "volume") {
        return "m³";
      } else if (this.energyMode === "energy") {
        return "MWh";
      }
    },
    creationTime(): string {
      return new Date(this.resultSummary?.creationTime ?? "").toLocaleString("de-DE");
    },
    pageInfo(): any {
      const solutionQualityTranslation = SOLUTION_QUALITY_TRANSLATIONS[this.solutionQuality as SolutionQuality];
      const solutionQualityColor = SOLUTION_QUALITY_COLORS[this.solutionQuality as SolutionQuality];
      if (this.settings !== null) {
        return {
          "Berechnet am": this.creationTime,
          Planzeitraum: `KW${this.resultSummary?.timeRange.startDate.weekNumber ?? "-"}`,
          // "Compliance RG": this.settings.loadedPlans.rg,
          // "Compliance TAS": this.settings.loadedPlans.tas,
          Lösungsqualität: `<span class="solution-quality-chip" style="color: ${solutionQualityColor}">${solutionQualityTranslation}</span>`,
        };
      } else {
        return {};
      }
    },
    solutionQuality(): string {
      return this.resultSummary?.solutionQuality ?? "";
    },
    packagingTasks(): { rg: MillingSubTaskResult[]; tas: MillingSubTaskResult[] } {
      const res = {
        rg: [] as MillingSubTaskResult[],
        tas: [] as MillingSubTaskResult[],
      };
      if (this.resultSummary === null) {
        return res;
      }
      const splitLine = 16;
      for (const packagingTask of this.resultSummary.result.millingSubTaskResults) {
        if (packagingTask.millNr < splitLine) {
          res.rg.push(packagingTask);
        } else {
          res.tas.push(packagingTask);
        }
      }
      return res;
    },
  },
  methods: {
    ...mapMutations([""]),
    // getRoasterTotals(items: Array<GanttChartItem<CoffeeProductionItemProperties_RoasterSubtask>>){
    //   const res: any = {}
    //   for(const item of items){
    //     res.roastingWeight_ton += item.roastingWeight_ton
    //   }
    // }

    initializeResultSummary() {
      // const resultSummary: CoffeeProductionAssignmentResultSummary = gasModelResults;
      if (this.resultSummary === null) return;
      const resultSummary: CoffeeProductionAssignmentResultSummary = this.resultSummary;
      // console.log("resultSummary", resultSummary)
      // console.log("resultSummary.inputSettings.gasConsumptions", resultSummary.inputSettings.gasConsumptions)
      this.settings = {
        loadedPlans: {
          rg: resultSummary.compliancePlan.planRG,
          tas: resultSummary.compliancePlan.planTAS,
        },
        maxTotalGasConsumption: 10000,
        gasConsumptionsPerBasket: calculateGasConsumptionPerBasket(resultSummary.inputSettings.gasConsumptions),
      } as AnalyzeGasConsumptionSettings;

      this.tabConfigs = [
        {
          name: "Überblick Planung",
          configs: [
            createGanttChartConfig_RoasterSubtaskSummary(resultSummary),
            createGanttChartConfig_MillingTaskSummary(resultSummary),
          ],
        },
        {
          name: "Rösterplanung",
          configs: [createGanttChartConfig_RoasterSubtask(resultSummary)],
        },
        {
          name: "Verpackungsplanung",
          configs: [createGanttChartConfig_MillingTask(resultSummary)],
        },
      ];

      // console.log("tabConfigs", this.tabConfigs)

      // this.ganttChartConfig = configs.packagingSummary
      // this.ganttChartConfig  = createGanttChartConfig_PackagingTask(resultSummary)
      // console.log("ganttChartConfig", this.ganttChartConfig);
      // = ganttChartConfig
    },
    uploadFile(e: any) {
      // console.log(e)
      this.resultSummary = new CoffeeProductionAssignmentResultSummary(e);
    },
    async restart() {
      let source = this.$route.query.source;
      if (source !== "calculation" && source !== "selection") source = "calculation";
      let date = await this.backendInterface.convertInstantToDateTime({
        productionSiteUniqueName: this.productionSiteUniqueName,
        instant: this.resultSummary!.timeRangeStart,
      } as InstantRequest);
      date = date.slice(0, 10);
      this.$router.push({ path: "/papa/plan/" + source + "?date=" + date });
    },
    async saveSettings() {
      console.warn("saveSettings() is disabled for now! (see code below)");
      // let affectedUserTypes: string = "Superuser";
      // if (this.userType === UserType.ADMIN) {
      //   affectedUserTypes = "User + Admin";
      // } else if (this.userType === UserType.USER) {
      //   affectedUserTypes = "User";
      // }
      // if (!confirm(`Möchten Sie die Einstellungen für "${affectedUserTypes}" wirklich speichern?`)) return;
      // // @ts-ignore
      // const solveRequestId = this.solveRequest?.id ?? null;
      // console.log("solveRequestId", solveRequestId);
      //
      // if (solveRequestId === null) {
      //   console.warn("solveRequestId is null");
      //   return;
      // }
      // const bi: BackendInterface = this.backendInterface;
      // await bi.papa_UpdateUserSettingsBySolveRequestId(solveRequestId);
      // // console.log("saved user settings!");
      // if (this.userType === UserType.ADMIN) {
      //   await bi.papa_UpdateUserSettingsBySolveRequestId(solveRequestId);
      //   // console.log("copied user settings!");
      // }
      // this.snackbarText = `Einstellungen gespeichert (${affectedUserTypes})!`;
      // this.showSnackbar = true;
    },
  },
});
