
import Vue from "vue";
import { mapState } from "vuex";
import { camelCaseToWords, formatSeconds, rgbaArrayToString, round } from "@/scripts/utils";

// @ts-ignore
import colors from "vuetify/lib/util/colors";
import { CoffeeBasketDailyValues, LegendEntry, LegendEntryType } from "@/scripts/models/coffee";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import CellplanTable, { CellType, SASTableCell, SASTableCellInfo, TableRowGroups } from "@/scripts/CellplanTable";
// import { UserType } from "@/scripts/models/general";
import DashboardElement from "@/components/common/DashboardElement.vue";
import Legend from "@/components/common/Legend.vue";
import { CoffeeProductionModelSolutionStatus } from "@/scripts/models/results";
import { DayOfWeek } from "@/scripts/models/date";
import {UserInfo, UserRole} from "@/scripts/auth";
import {BackendInterface} from "@/scripts/BackendInterface";

export default Vue.extend({
  name: "SolutionTable",
  props: {
    saraSummary: {
      type: Object, // SiloAssignmentSolution
      required: true,
    },
  },
  components: {
    DashboardElement,
    Legend,
  },
  data() {
    return {
      CellType,
      // UserType,
      CoffeeProductionModelSolutionStatus: CoffeeProductionModelSolutionStatus,
      saraSummary_: {} as CoffeeProductionSARASummary,
      cellplanTable: null as CellplanTable | null,
      silosPerBasket: [] as any[],
      objectiveDescription: "",
      selectedHighlightIndex: 4,
      highlight: ["none", "storage", "inflow", "outflow", "scaleGroup"],
      highlightRGB: {
        none: [0, 0, 0],
        storage: [100, 169, 254],
        inflow: [100, 222, 105],
        outflow: [222, 89, 100],
        leftover: [250, 140, 0],
      } as { [key: string]: number[] },
      rowHighlight: "" as string, // {} as { [key: string]: boolean },
      cellHighlight: "" as string,
      showTooltip: false,
      // showPreproductionDeviations: false,

      tableRowGroups: {} as TableRowGroups,
      tableFooter: [] as Array<Partial<SASTableCell>>,
      showDeviations: {
        demand: true,
        preproduction: false,
      },
      showFulfilledDemands: false,
      showNoInflows: true,
      legendItems: [
        {
          name: "Abweichung von Compliance: Überproduktion",
          color: "cornflowerblue",
          type: LegendEntryType.DOT,
        },
        {
          name: "Abweichung von Compliance: Unterproduktion",
          color: "orangered",
          type: LegendEntryType.DOT,
        },
        {
          name: "Bestand mit chargierter Menge (positiv)",
          color: "limegreen",
          type: LegendEntryType.DOT,
        },
        {
          name: "Bestand mit chargierter Menge (negativ)",
          color: "moccasin",
          type: LegendEntryType.DOT,
        },
        {
          name: "Silozelle: Einfluss gesperrt",
          color: "red",
          type: LegendEntryType.OVERLINE,
        },
        {
          name: "Silozelle: Ausfluss gesperrt",
          color: "red",
          type: LegendEntryType.UNDERLINE,
        },
                {
          name: "Silozelle: Kein Einfluss",
          color: "black",
          type: LegendEntryType.OVERLINE,
        },
        {
          name: "Silozelle: Kein Ausfluss",
          color: "black",
          type: LegendEntryType.UNDERLINE,
        },
        // {name: "Abweichung Vorröstung", color: "orange"},
      ] as LegendEntry[],
    };
  },
  created() {
    // console.log("SolutionTable: SiloAssignmentSolution", this.saraSummary);
    this.init();
  },
  mounted() {},
  watch: {
    data() {
      this.init();
    },
    showDeviations: {
      deep: true,
      handler() {
        // console.log("showDeviations watcher: updating table cell colors");
        this.updateTableCellColors();
      },
    },
    showFulfilledDemands: "updateTableCellColors",
  },
  computed: {
    ...mapState(["user", "translations", "backendUrl", "backendInterface"]),
    title(): string {
      return this.saraSummary_.getTitle();
    },
    highlightItems(): any[] {
      return this.highlight.map((h, i) => ({ text: this.translations[h], value: i }));
    },
    selectedHighlight(): string {
      return this.highlight[this.selectedHighlightIndex];
    },
    objectiveType(): string {
      return this.saraSummary_?.solverParameters?.solverParameterSequence?.[0]?.objectiveType ?? "";
    },
    // solutionInfo(): any {
    //   return {
    //     "Objective Type": this.objectiveType,
    //     "Objective Text": this.objectiveText,
    //     "Solve Time": this.solutionTimeString,
    //     "Objective Description": this.objectiveDescription,
    //   };
    // },
    tableHeaders(): any {
      if (this.cellplanTable === null) return [];
      return this.cellplanTable.getTableHeaders();
    },
    objectiveText(): string {
      return this.saraSummary_?.getObjectiveText();
    },
    solutionTimeString(): string {
      return formatSeconds(this.saraSummary_.results.solutionTimeSec);
    },
    saraUserRole(): UserRole {
      return (this.user as UserInfo)?.saraUserRole ?? UserRole.USER;
    },
    isSaraSuperuser(): boolean {
      return this.saraUserRole === UserRole.SUPERUSER;
    },
  },
  methods: {
    init(): void {
      this.showFulfilledDemands = this.saraUserRole === UserRole.SUPERUSER;
      this.saraSummary_ = this.saraSummary;
      this.cellplanTable = new CellplanTable(this.saraSummary_);
      this.tableFooter = this.cellplanTable.getTableFooter();
      for (const cell of this.tableFooter) {
        cell.tooltip = this.formatTableCellTooltip(cell.tableCellInfo);
      }
      this.tableRowGroups = this.cellplanTable.getTableRowGroups();
      for (const [basketSpsNr, tableRowGroup] of this.tableRowGroups) {
        for (const row of tableRowGroup) {
          for (const cell of row) {
            cell.tooltip = this.formatTableCellTooltip(cell.tableCellInfo);
          }
        }
      }
      this.updateTableCellColors();
      this.fetchObjectiveDescription();
    },
    async fetchObjectiveDescription() {
      console.log("Fetching objectives", this.objectiveType)
      const bi = this.backendInterface as BackendInterface
          bi.sara_getObjectiveTranslations()
        .then((r) => {
          console.log("Fetched objectives", r)
          this.objectiveDescription = r.find((o: any) => o.objectiveType == this.objectiveType)?.description || "";
        })
        .catch((err) => {
          console.error("Failed to fetch objectives");
        })
        .finally(() => {
          // console.log("Done fetching objectives")
        });
    },
    getTableCellHighlightColors(tableCell: SASTableCell): Record<string, string> {
      const styles: any = {};
      styles["border"] = "transparent";
      if (tableCell.type === CellType.Stored) {
        styles["border"] = this.showFulfilledDemands ? tableCell.borderColor : "transparent";
        return styles;
      }
      if (tableCell.type === CellType.Demand) {
        styles["border"] = this.showDeviations.demand ? tableCell.borderColor : "transparent";
        return styles;
      } else if (tableCell.type === CellType.Production) {
        styles["border"] = this.showDeviations.preproduction ? tableCell.borderColor : "transparent";
        return styles;
      }
      if (tableCell.type !== "SiloSpsNr" || tableCell.value === "") {
        styles["none"] = tableCell.color;
        return styles;
      }
      // console.log("getTableCellHighlightColors");
      for (const h in tableCell.utilization) {
        styles[h] = rgbaArrayToString([...this.highlightRGB[h], tableCell.utilization[h] * 0.8]);
      }
      styles["scaleGroup"] =
        tableCell.scaleGroup == 2 ? "#0088ff44" : tableCell.scaleGroup == 3 ? "#00ff8844" : "#ff880044";
      // styles["none"] = tableCell.color;
      styles["none"] = "initial";
      return styles;
    },
    updateTableCellColors() {
      // console.log("updating table cell colors");
      for (const [basketSpsNr, tableRowGroup] of this.tableRowGroups) {
        // const tableRowGroup = this.tableRowGroups[basketSpsNr];
        for (const row of tableRowGroup) {
          for (const tableCell of row) {
            tableCell.highlightColors = this.getTableCellHighlightColors(tableCell);
          }
        }
      }
    },

    formatTableCellTooltip(tableCellInfo: SASTableCellInfo): any {
      if (!tableCellInfo) return {};
      // console.log(tableCellInfo.basketSpsNr, "tableCellInfo", tableCellInfo, tableCellInfo.storedAtDayStart);
      const t = tableCellInfo;
      const f = (x: number | string) => (typeof x == "string" ? x : round(3)(x));
      const g = (s: string) => {
        const s2 = camelCaseToWords(s).replace(/\_/g, " ").replace(/\s+/g, " ").trim();
        // console.log(s2, this.translations, this.translations[s2])
        return (this.translations[s2] ?? s2) + "  ";
      };
      const entries = Object.entries(tableCellInfo);
      const formattedEntries = entries.map(([key, value]) => {
        // console.log(key, g(key), value, f(value))
        return key.startsWith("separator") ? ["<hr>", ""] : [g(key), f(value)];
      });
      return formattedEntries;
    },
    siloCellClickHandler(basketSpsNr: number, siloSpsNr: number): void {
      this.$emit("click-silo-cell", basketSpsNr, siloSpsNr);
    },
    basketCellClickHandler(basketSpsNr: number, basketPlotOnly: boolean): void {
      this.$emit("click-basket-cell", basketSpsNr, basketPlotOnly);
    },
    tableCellClickHandler(tableCell: SASTableCell, basketSpsNr: string, rg: number, r: number, c: number): void {
      this.showTooltip = true;
      this.cellHighlight = `${rg},${r},${c}`;
      this.rowHighlight = `${rg}`;

      if (tableCell.type == "SiloSpsNr" && tableCell.tableCellInfo) {
        this.siloCellClickHandler(tableCell?.tableCellInfo?.basketSpsNr, tableCell?.tableCellInfo?.siloSpsNr);
        this.basketCellClickHandler(tableCell?.tableCellInfo?.basketSpsNr, false);
      } else if (tableCell.type == "BasketSpsNr") {
        this.basketCellClickHandler(+tableCell.value, true);
      } else if (tableCell.type == "Production" || tableCell.type == "Capacity") {
      }
      // this.$forceUpdate();
    },
    isCellHighlighted(rg: number, r: number, c: number): boolean {
      const h = this.cellHighlight;
      const rg_ = "" + rg;
      const r_ = "" + r;
      const c_ = "" + c;
      return `${rg},${r},${c}` == this.cellHighlight;
    },
    resetTooltip() {},

    getLockTypeClass(tableCell: SASTableCell) {
      const outflowLocked = tableCell.tableCellInfo && tableCell.tableCellInfo.outflowLocked;
      const inflowLocked = tableCell.tableCellInfo && tableCell.tableCellInfo.inflowLocked;
      if (inflowLocked && outflowLocked) return `inflow-outflow-lock`;
      else if (inflowLocked) return `inflow-lock`;
      else if (outflowLocked) return `outflow-lock`;
      return "";
    },

    getFlowTypeClass(tableCell: SASTableCell) {
      if(!this.showNoInflows) return ""
      const noInflow = tableCell?.tableCellInfo?.siloInflow < 0.001;
      const noOutflow = tableCell?.tableCellInfo?.siloOutflow < 0.001;
      if (noInflow && noOutflow) return "no-flow";
      else if (noInflow) return "no-inflow";
      else if (noOutflow) return "no-outflow";
      return "";
    },

    getTableCellStyle(tableCell: SASTableCell | any, c: number) {
      //
      // background color
      const backgroundColor = tableCell.highlightColors[this.selectedHighlight] || tableCell.color;
      // color
      const isTestBasket = tableCell.tableCellInfo && tableCell.tableCellInfo.testBasket;
      const baseColor =
        tableCell.highlightColors.border === "transparent" ? "inherit" : tableCell.highlightColors.border;
      const effectiveColor = c === 0 && isTestBasket ? "grey" : baseColor;
      return {
        // "text-decoration": textDecoration,
        // textDecorationStyle,
        "background-color": backgroundColor,
        color: effectiveColor,
        "font-weight": tableCell.highlightColors.border !== "transparent" ? "bold" : "inherit",
        width: tableCell.width || "40px",
        "max-width": tableCell.width || "40px",
      };
    },
  },
});
