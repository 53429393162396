
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {UserInfo} from "@/scripts/auth";
import UserInfoListItem from "@/views/UserInfoListItem.vue";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import {
  SOLUTION_QUALITY_COLORS,
  SOLUTION_QUALITY_TRANSLATIONS,
  SolutionQuality,
  SolveProcessInfo, SolveProcessOutput, SolveProcessStatus
} from "@/scripts/models/results";
import {formatDateShort, getSolutionQualityColor, getSolutionQualityTranslation, roundInverseMipGapValue, roundMipGapValue} from "@/scripts/utils";
import SolutionQualityChip from "@/components/common/SolutionQualityChip.vue";

export default Vue.extend({
  name: "SolveProcessManager",
  components: {SolutionQualityChip, UserInfoListItem},
  props: {},
  data() {
    return {
      SolveProcessStatus,
      CoffeeProductionModelType,
      modelType: CoffeeProductionModelType.DORI,
      userInfos: [] as UserInfo[],
      showLockedUsers: false,
      solveProcessInfos: [] as SolveProcessInfo[],
      headers: [
        {text: "Modul", value: "modelType"},
        {text: "Benutzer", value: "ownerName"},
        {text: "Angelegt", value: "createdTime"},
        {text: "Gestartet", value: "startTime"},
        {text: "Beendet", value: "endTime"},
        {text: "Status", value: "status"},
        {text: "Lösungsqualität", value: "solverOutput.solutionQuality"},
        {text: "", value: "__action"},
      ],
      currentlyStopping: [] as string[],// list of solveProcessIds
    };
  },
  async created() {
    this.update();
    setInterval(() => {
      this.update()
    }, 5000)
    // console.log("userInfos", this.userInfos)
  },
  watch: {
    modelType() {
      this.update()
    }
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },

  },
  methods: {
    ...mapMutations([""]),
    getSolutionQualityColor,
    getSolutionQualityTranslation,
    roundMipGapValue,
    roundInverseMipGapValue,
    getStatusColor(status: SolveProcessStatus) {
      if (status === SolveProcessStatus.RUNNING) {
        return "blue"
      }
      if (status === SolveProcessStatus.FINISHED) {
        return "success"
      }
      if (status === SolveProcessStatus.SCHEDULED) {
        return "warning"
      }
      if (status === SolveProcessStatus.FAILED) {
        return "error"
      }
      if (status === SolveProcessStatus.INTERRUPTED) {
        return "grey"
      }
    },
    formatDateShort(d: string | null) {
      if (d === null) {
        return "-"
      } else {
        return formatDateShort(d)
      }
    },
    async update() {
      this.solveProcessInfos = await this.bi.getSolveProcessInfos(this.modelType)
    },
    async stopSolving(modelType: CoffeeProductionModelType, solveProcessId: string) {
      console.log("STOPPING", solveProcessId, this.solveProcessInfos)
      this.currentlyStopping.push(solveProcessId)
      await this.backendInterface.stopSolving(modelType, solveProcessId);
      await this.update()
      // find index of solveProcessId and remove it
      const index = this.currentlyStopping.indexOf(solveProcessId);
      if (index > -1) {
        this.currentlyStopping.splice(index, 1);
      }
    },
    async gotoResult(modelType: CoffeeProductionModelType, summaryId: string) {
      if (modelType === CoffeeProductionModelType.SARA) {
        this.$router.push(`/sara/plan/view/${summaryId ?? null}?source=calculation`);
      } else if (modelType === CoffeeProductionModelType.PAPA) {
        this.$router.push(`/papa/plan/view/${summaryId ?? null}?source=calculation`);
      } else if (modelType === CoffeeProductionModelType.DORI) {
        this.$router.push(`/dori/plan/view/${summaryId ?? null}?source=calculation`);
      }
    },
    async loadActiveComputation(modelType: CoffeeProductionModelType, solveProcessId: string) {
      await this.$router.push({
        path: `/${modelType.toLowerCase()}/plan/calculation/${solveProcessId}`,
      });
    },

  },
});
