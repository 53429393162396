import { DayOfWeek, DayOfWeekDate, Weekday } from "./models/date";
import { ExclusionReason, CoffeeRoasterAvailabilityExclusion, ExclusionOrigin } from "./models/dori";
import { copy } from "./utils";

const DEBUG = false;

export interface SelectionItem<T = string> {
  text: string;
  value: T;
}

export interface TimeItem {
  hour: number | null;
  minute: number | null;
}

export interface TimeItemRange {
  startTime: TimeItem;
  endTime: TimeItem;
}

export interface ExclusionTimeRange {
  exclusionStartHour: number;
  exclusionEndHour: number;
}

export class MultiExclusion {
  public exclusionReason: ExclusionReason = ExclusionReason.OTHER;
  public exclusionOrigin: ExclusionOrigin = ExclusionOrigin.USER;
  public description: string = "";
  public roasterNames: string[] = [];
  public exclusionStartDays: Weekday[] = [];
  //e.g.,0.0 - 10.5, both bounds greater than 0, meaning 00:00h - 10:30h,
  //in case the model considers several days as a time step it can go over 24.0
  public exclusionTimeRanges: TimeItemRange[] = [];
  constructor() {}

  toCoffeeRoasterAvailabilityExclusion(): CoffeeRoasterAvailabilityExclusion[] {
    const exclusions: CoffeeRoasterAvailabilityExclusion[] = [];
    for (const roasterName of this.roasterNames) {
      for (const exclusionStartDay of this.exclusionStartDays) {
        // console.log("exclusion start dates", exclusionStartDate);
        for (const exclusionTimeRange of this.exclusionTimeRanges) {
          // console.log("exclusionTimeRange", exclusionTimeRange);
          const exclusionStartHour = (exclusionTimeRange.startTime.hour ?? 0) + (exclusionTimeRange.startTime.minute ?? 0) / 60;
          const exclusionEndHour = (exclusionTimeRange.endTime.hour ?? 0) + (exclusionTimeRange.endTime.minute ?? 0) / 60;
          exclusions.push({
            roasterName,
            exclusionOrigin: this.exclusionOrigin,
            exclusionReason: this.exclusionReason,
            description: this.description,
            exclusionStartDay,
            exclusionStartHour: exclusionStartHour,
            exclusionEndHour: exclusionEndHour,
          });
        }
      }
    }
    return exclusions;
  }

  addRoasterName(roasterName: string) {
    if (!this.roasterNames.includes(roasterName)) {
      this.roasterNames.push(roasterName);
    }
  }

  addStartDay(startDay: Weekday) {
    // const { year, weekNumber, dayOfWeek } = startDate;
    for (const date of this.exclusionStartDays) {
      if (startDay === date) {
        return;
      }
    }
    this.exclusionStartDays.push(startDay);
  }

  addTimeRangeFromSingleExclusion(ex: CoffeeRoasterAvailabilityExclusion) {
    const { exclusionStartHour, exclusionEndHour } = ex;
    for (const timeRange of this.exclusionTimeRanges) {
      const startHour = timeRange.startTime.hour! + timeRange.startTime.minute! / 60;
      const endHour = timeRange.endTime.hour! + timeRange.endTime.minute! / 60;
      if (startHour === exclusionStartHour && endHour === exclusionEndHour) {
        return;
      }
    }
    this.exclusionTimeRanges.push(MultiExclusion.exclusionToTimeRange(ex));
  }

  addTimeRange(timeRange: TimeItemRange) {
    for (const tr of this.exclusionTimeRanges) {
      if (
        timeRange.startTime.hour === tr.startTime.hour &&
        timeRange.startTime.minute === tr.startTime.minute &&
        timeRange.endTime.hour === tr.endTime.hour &&
        timeRange.endTime.minute === tr.endTime.minute
      ) {
        return;
      }
    }
    this.exclusionTimeRanges.push(timeRange);
  }

  static doubleToTimeItem(d: number) {
    return {
      hour: Math.floor(d),
      minute: (d - Math.floor(d)) * 60,
    };
  }

  static exclusionToTimeRange(ex: CoffeeRoasterAvailabilityExclusion) {
    return {
      startTime: MultiExclusion.doubleToTimeItem(ex.exclusionStartHour),
      endTime: MultiExclusion.doubleToTimeItem(ex.exclusionEndHour),
    };
  }

  static timeItemRangeEqual(a: TimeItemRange, b: TimeItemRange) {
    return (
      a.startTime.hour === b.startTime.hour &&
      a.startTime.minute === b.startTime.minute &&
      a.endTime.hour === b.endTime.hour &&
      a.endTime.minute === b.endTime.minute
    );
  }

  static fromExclusion(ex: CoffeeRoasterAvailabilityExclusion) {
    const mex = new MultiExclusion();
    mex.exclusionReason = ex.exclusionReason;
    mex.exclusionOrigin = ex.exclusionOrigin;
    mex.description = ex.description;
    mex.addRoasterName(ex.roasterName);
    mex.addStartDay(ex.exclusionStartDay);
    mex.addTimeRangeFromSingleExclusion(ex);
    return mex;
  }

  static equalRoasterNames(ex1: MultiExclusion, ex2: MultiExclusion) {
    const a = [...ex1.roasterNames];
    a.sort();
    const b = [...ex2.roasterNames];
    b.sort();
    if (a.length !== b.length) return false;
    DEBUG && console.log("Comparing roaster names:", a.join(","), b.join(","));
    for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
    DEBUG && console.log("Roaster names equal!");

    return true;
  }

  static equalStartDates(ex1: MultiExclusion, ex2: MultiExclusion) {
    const a = ex1.exclusionStartDays.map((a) => a);
    a.sort();
    const b = ex2.exclusionStartDays.map((a) => a);
    b.sort();
    if (a.length !== b.length) return false;
    DEBUG && console.log("Comparing start dates:", a.join(","), b.join(","));
    for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
    DEBUG && console.log("Start dates equal!");
    return true;
  }

  static equalTimeRanges(ex1: MultiExclusion, ex2: MultiExclusion) {
    const a = ex1.exclusionTimeRanges.map(
      (a) => `${a.startTime.hour}${a.startTime.minute}${a.endTime.hour}${a.endTime.minute}`
    );
    a.sort();
    const b = ex2.exclusionTimeRanges.map(
      (a) => `${a.startTime.hour}${a.startTime.minute}${a.endTime.hour}${a.endTime.minute}`
    );
    b.sort();
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
    return true;
  }

  static equalExceptRoasters(ex1: MultiExclusion, ex2: MultiExclusion) {
    return (
      MultiExclusion.equalReasonAndOriginAndDescription(ex1, ex2) &&
      MultiExclusion.equalTimeRanges(ex1, ex2) &&
      MultiExclusion.equalStartDates(ex1, ex2)
    );
  }

  static equalExceptTimeRanges(ex1: MultiExclusion, ex2: MultiExclusion) {
    return (
      MultiExclusion.equalReasonAndOriginAndDescription(ex1, ex2) &&
      MultiExclusion.equalRoasterNames(ex1, ex2) &&
      MultiExclusion.equalStartDates(ex1, ex2)
    );
  }

  static equalExceptStartDates(ex1: MultiExclusion, ex2: MultiExclusion) {
    return (
      MultiExclusion.equalReasonAndOriginAndDescription(ex1, ex2) &&
      MultiExclusion.equalRoasterNames(ex1, ex2) &&
      MultiExclusion.equalTimeRanges(ex1, ex2)
    );
  }

  static equalReasonAndOriginAndDescription(ex1: MultiExclusion, ex2: MultiExclusion) {
    return ex1.exclusionReason === ex2.exclusionReason && ex1.exclusionOrigin === ex2.exclusionOrigin && ex1.description === ex2.description;
  }

  copy(): MultiExclusion {
    const mex = new MultiExclusion();
    mex.exclusionReason = this.exclusionReason;
    mex.exclusionOrigin = this.exclusionOrigin;
    mex.description = this.description;
    mex.exclusionTimeRanges = copy(this.exclusionTimeRanges);
    mex.roasterNames = copy(this.roasterNames);
    mex.exclusionStartDays = copy(this.exclusionStartDays);
    return mex;
  }

  mergeWith(other: MultiExclusion) {
    for (const roasterName of other.roasterNames) this.addRoasterName(roasterName);
    for (const startDay of other.exclusionStartDays) this.addStartDay(startDay);
    for (const timeRange of other.exclusionTimeRanges) this.addTimeRange(timeRange);
  }

  static merge(multiExclusions: MultiExclusion[], equalityFunction: (a: MultiExclusion, b: MultiExclusion) => boolean) {
    const ignoreMask: boolean[] = [...Array(multiExclusions.length)].map(() => false);
    const classes: MultiExclusion[][] = [...Array(multiExclusions.length)].map(() => []);
    for (let i = 0; i < multiExclusions.length; i++) {
      if (ignoreMask[i]) continue;
      ignoreMask[i] = true;
      classes[i].push(multiExclusions[i]);
      for (let j = i; j < multiExclusions.length; j++) {
        if (ignoreMask[j]) continue;
        const equal = equalityFunction(multiExclusions[i], multiExclusions[j]);
        if (equal) {
          classes[i].push(multiExclusions[j]);
          ignoreMask[j] = true;
        }
      }
    }
    DEBUG && console.log("equality classes", classes);

    // merge classes
    const mergedExclusions = [];
    for (const equalityClass of classes) {
      if (equalityClass.length === 0) continue;
      const mergeTarget = equalityClass[0].copy();
      for (let i = 1; i < equalityClass.length; i++) {
        const equalityClassEntry = equalityClass[i];
        mergeTarget.mergeWith(equalityClassEntry);
      }
      mergedExclusions.push(mergeTarget);
    }
    DEBUG && console.log("mergedExclusions", mergedExclusions);
    return mergedExclusions;
  }

  static fromCoffeeRoasterAvailabilityExclusions(exclusions: CoffeeRoasterAvailabilityExclusion[]): MultiExclusion[] {
    const multiExclusions = exclusions.map((ex) => MultiExclusion.fromExclusion(ex));
    DEBUG && console.log("multiExclusions", multiExclusions);
    let mergedExclusions = [...multiExclusions];
    mergedExclusions = MultiExclusion.merge(mergedExclusions, MultiExclusion.equalExceptRoasters);
    mergedExclusions = MultiExclusion.merge(mergedExclusions, MultiExclusion.equalExceptStartDates);
    mergedExclusions = MultiExclusion.merge(mergedExclusions, MultiExclusion.equalExceptTimeRanges);
    DEBUG && console.log("mergedExclusions", mergedExclusions);
    return mergedExclusions;
  }
}
