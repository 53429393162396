
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import {SiloAssignmentInputFile} from "@/scripts/models/calculation";

export default Vue.extend({
  name: "CompliancePlanSelection",
  components: {},
  props: {
    plans: {type: Array, default: () => []}
  },
  data() {
    return {
      selectedFile: null,
    };
  },
  created() {},
  watch: {
    selectedFile(){
      this.$emit("input", this.selectedFile)
    }
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    fileType(){
      const plans = this.plans as SiloAssignmentInputFile[]
      return plans?.[0]?.fileType ?? null
    }
  },
  methods: {
    ...mapMutations([""]),
    getDisplayFilename(file?: SiloAssignmentInputFile|null) {
      // console.log("getDisplayFilename", file);
      if (file === null || file === undefined) return null;
      // @ts-ignore
      return file.originalFilename || file.orginalFilename || file.baseFilename;
    },
  },
});
