import {
  CoffeeProductionCompliancePlanSingle,
  CoffeeProductionSite,
  defaultSpringOptions,
  SiloAssignmentDailyDataStatus,
  SolutionHashes,
  SpringErrorHandlingType,
  SpringOptions,
  SpringReturnType,
} from "./models/coffee";
import {CoffeeProductionErrorReport, NameValueTuple, UserAccessType} from "./models/general";
import {downloadCsv, getResponseCode} from "./utils";
import CoffeeProductionSARASummary from "./SiloAssignmentSolution";
import {CoffeeProductionSolverParameters, SiloAssignmentInputFile, SolveStatus,} from "@/scripts/models/calculation";
import {
  CoffeeProductionAssignmentResultSummary,
  SiloAssignmentResult, SolveProcessInfo,
  SolveProcessOutput,
  SummaryMetadata,
  UserSettingsInfo
} from "@/scripts/models/results";
import {
  CoffeeProductionModelType,
  CoffeeProductionSupportRequest,
  CreateUserSettingsRequest,
  DataStatusesRequest,
  DateRequest,
  DateTimeRequest,
  DeleteSettingsPartRequest,
  FileRequest,
  FileUploadRequest,
  InstantRequest,
  LastSolutionsRequest,
  ModelDateRequest,
  PAPASolveResultRequest,
  SARASolveResultRequest,
  SolveRequest,
  SolveRequestRequest,
  SummaryMetadataRequest,
  ValidityStartRequest,
} from "@/scripts/models/request";
import {DayOfWeekDate, ProductionDateRange} from "@/scripts/models/date";
import {CoffeeProductionPAPASummary} from "./models/papa";
import {
  CoffeeProductionDORISolveRequest,
  CoffeeProductionDORISolveRequestRequest,
  CoffeeProductionDORISummary,
  DORISolveResultRequest
} from "./models/dori";
import {
  ChangePasswordRequest,
  PasswordResetRequest,
  UserDataAdminRequest,
  UserDataRequest,
  UserInfo,
  UserRole,
  UserSettingsMetadataChangeRequest
} from "@/scripts/auth";
import {parseSpringResponse, prepareSpringOptions} from "@/scripts/utils";
import router from "@/router";
import {SiloAllocationState, SolverOutputLineCplex} from "@/scripts/models/statistics";

// import { DORISolveResultRequest } from "@/scripts/models/dori";

type AuthToken = string //`${string}.${string}.${string}`
type RefreshToken = string //`${string}-${string}-${string}-${string}-${string}`

export class BackendInterface {
  static AUTH_TOKEN_ID = "JDE_COF-AUTH"
  static REFRESH_TOKEN_ID = "JDE_COF-REFRESH"
  private authToken: AuthToken | null = null

  constructor(private backendUrl: string) {
    // this.refreshAuthTokenIfExpired(false)
  }

  async springGet(url: string, springOptions: SpringOptions = {}) {
    // return null
    springOptions = prepareSpringOptions(url, springOptions);
    const fetchOptions = {method: "GET", mode: "cors"} as RequestInit;
    this.setAuthTokenInFetchOptions(fetchOptions)
    const res = await this.fetchWithAuthCheck(url, fetchOptions);
    return parseSpringResponse(url, res, springOptions);
  }

  async springPost(url: string, body: any, springOptions: SpringOptions = {}) {
    // return null
    springOptions = prepareSpringOptions(url, springOptions);
    const fetchOptions = {method: "POST", mode: "cors"} as RequestInit;
    const isForm = body instanceof FormData;
    fetchOptions.body = isForm ? body : JSON.stringify(body);
    // console.log("setting auth header", this.getAuthToken())
    // this.isAuthTokenValid()
    this.setAuthTokenInFetchOptions(fetchOptions)
    if (!isForm) {
      fetchOptions.headers = {
        ...fetchOptions.headers ?? {},
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      console.warn("Using form data")
    }
    // console.log("springPost", springOptions.headers?.Authorization?.slice(0,30))
    let res;
    if (springOptions.autoRefreshToken) {
      res = await this.fetchWithAuthCheck(url, fetchOptions);
    } else {
      res = await fetch(url, fetchOptions);
    }
    return parseSpringResponse(url, res, springOptions);
  }

  setAuthTokenInFetchOptions(fetchOptions: any) {
    const authToken = this.getAuthToken()
    // console.log("setAuthTokenInFetchOptions", authToken?.slice(0,30) )
    if (this.isAuthTokenValid(authToken)) {
      if (typeof fetchOptions.headers === "object") {
        fetchOptions.headers["Authorization"] = authToken
      } else {
        fetchOptions.headers = {Authorization: authToken}
      }
    }
    // console.log("Auth token after setting:", fetchOptions.headers?.Authorization?.slice(0,30))
  }

  async fetchWithAuthCheck(url: string, fetchOptions: RequestInit) {
    // @ts-ignore
    // console.log("fetchWithAuthCheck", fetchOptions.headers?.Authorization?.slice(0,30))
    await this.refreshAuthTokenIfExpired()
    let res = await fetch(url, fetchOptions)
    const responseCode = getResponseCode(res);
    if (responseCode === 403) {
      // throw new Error("Forbidden")
      await this.refreshAuthTokenIfExpired()
      this.setAuthTokenInFetchOptions(fetchOptions)
      res = await fetch(url, fetchOptions)
    }
    // this.needLogin = false
    return res
  }

  //
  // Auth
  //

  isLoggedIn() {
    return this.isAuthTokenValid(this.getAuthToken())
  }

  isAuthTokenValid(token: AuthToken | null) {
    if (token === null || token === undefined || token === "") {
      return false
    }
    const [header, payload, signature] = token.split(".")
    // console.log("isAuthTokenValid", payload)
    try {
      const payloadJson = JSON.parse(atob(payload))
      const now = new Date().getTime() / 1000
      // console.log("isAuthTokenValid", payloadJson.exp, now, payloadJson.exp > now)
      return payloadJson.exp > now
    } catch (e) {
      return false;
    }
  }

  // setAuthTokenToLocalStorage(token: AuthToken | null) {
  //   if (token === null) {
  //     console.log("removing auth token")
  //     // localStorage.removeItem(BackendInterface.AUTH_TOKEN_ID)
  //     this.authToken = null
  //   } else {
  //     // console.log("setting auth token")
  //     // localStorage.setItem(BackendInterface.AUTH_TOKEN_ID, token);
  //     this.authToken = token;
  //   }
  // }

  setRefreshTokenToLocalStorage(token: RefreshToken | null) {
    if (token === null) {
      console.log("removing refresh token")
      localStorage.removeItem(BackendInterface.REFRESH_TOKEN_ID)
    } else {
      // console.log("setting refresh token")
      localStorage.setItem(BackendInterface.REFRESH_TOKEN_ID, token);
    }
  }


// get auth token from local storage
  getAuthToken() {
    return this.authToken
    // return localStorage.getItem(BackendInterface.AUTH_TOKEN_ID);
  }

  getRefreshToken() {
    return localStorage.getItem(BackendInterface.REFRESH_TOKEN_ID);
  }

  logout() {
    this.deleteAuthToken()
    this.deleteRefreshToken()
  }

  private async redirectToLogin() {
    const currentRoute = router.currentRoute.fullPath;
    if (currentRoute.startsWith("/login")) {
      // await router.push({name: "Login", query: {redirect: router.currentRoute.fullPath}})
    } else {
      await router.push({name: "Login", query: {redirect: router.currentRoute.fullPath}})
    }
  }

  async refreshAuthTokenIfExpired(redirectIfNoToken: boolean = false) {
    // console.log("checking if auth token is expired ...")
    const authToken = this.getAuthToken();
    if (!this.isAuthTokenValid(authToken)) {
      // console.log("auth token has expired! refreshing ...")
      await this.refreshAuthToken(redirectIfNoToken)
    }
  }

  async refreshAuthToken(redirectIfNoToken: boolean = false): Promise<void> {
    // let authToken;
    // console.log("refreshing auth token")
    // if (this.authTokenRefreshRequest === null) {
    //   this.authTokenRefreshRequest = this.fetchAuthTokenWithRefreshToken();
    // }
    const authToken = await this.fetchAuthTokenWithRefreshToken(redirectIfNoToken);
    // this.authTokenRefreshRequest = null;
    // const authToken = await this.fetchAuthTokenWithRefreshToken()
    if (this.isAuthTokenValid(authToken)) {
      this.setAuthToken(authToken);
    } else {
      console.error("Returned auth token is not valid:", authToken)
    }
  }

  private async fetchAuthTokenWithRefreshToken(redirectIfNoToken: boolean = false): Promise<AuthToken> {
    const refreshToken = this.getRefreshToken();
    const refreshTokenExists = refreshToken !== null && refreshToken !== "" && refreshToken !== undefined;
    if (!refreshTokenExists) {
      // this.setRefreshTokenToLocalStorage(null)
      if (redirectIfNoToken) {
        await this.redirectToLogin()
        console.error("Refresh token not found in local storage. Redirecting to login page.");
      }
      console.error("Refresh token not found in local storage.");
    }
    const url = `${this.backendUrl}/user/refresh-auth-token/${refreshToken}`;
    const response = await fetch(url, {method: "GET"})
    // refresh token is invalid, redirect to login
    const responseCode = getResponseCode(response);
    if (responseCode !== 200) {
      if (redirectIfNoToken) {
        await this.redirectToLogin()
      }
      // this.setRefreshTokenToLocalStorage(null)
      console.error("Failed to refresh auth token! Login again.");
    }
    return await response.text() as AuthToken;
  }

  deleteAuthToken() {
    this.authToken = null
  }

  deleteRefreshToken() {
    this.setRefreshTokenToLocalStorage(null)
  }

  setAuthToken(authToken: string | null): void {
    if (this.isAuthTokenValid(authToken)) {
      this.authToken = authToken
      // this.setAuthTokenToLocalStorage(authToken)
    } else {
      // await this.redirectToLogin()
      throw new Error("Authorization token is not valid!");
    }
  }


  async login(username: string, password: string): Promise<void> {
    const url = `${this.backendUrl}/login`;
    const body = {username, password}
    // fetch to the specified url and extract the Authorization header from response
    const response = await fetch(url, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(body),
    });
    // set auth token
    const authToken = response.headers.get("Authorization");
    if (this.isAuthTokenValid(authToken)) {
      await this.setAuthToken(authToken)
    } else {
      throw new Error("Authorization header not found in response");
    }
    // set refresh token
    const refresh = response.headers.get("Authorization-Refresh");
    if (refresh) {
      this.setRefreshTokenToLocalStorage(refresh)
    } else {
      throw new Error("Refresh token not found in response");
    }
  }

  async verifyUserEmail(userActivationToken: string): Promise<UserInfo> {
    const url = `${this.backendUrl}/user/verify-email/${userActivationToken}`;
    /// use token to request user info
    const userInfo = await this.springGet(url, {
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
      autoRefreshToken: false,
    });
    return userInfo;
  }

  async activateUser(userActivationToken: string): Promise<UserInfo> {
    const url = `${this.backendUrl}/user/activate/${userActivationToken}`;
    // use token to request user info
    const userInfo = await this.springGet(url, {
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
      autoRefreshToken: false,
    });
    return userInfo;
  }

  async getCurrentUserInfo(): Promise<UserInfo> {
    const url = `${this.backendUrl}/user/current-user-info`;
    return this.springGet(url, {
      autoRefreshToken: false,
    });
  }

  async createNewUser(userDataRequest: UserDataRequest): Promise<void> {
    const url = `${this.backendUrl}/user/create`;
    return this.springPost(url, userDataRequest, {
      autoRefreshToken: false,
      returnType: SpringReturnType.JSON,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async changeUserData(userDataRequest: Partial<UserDataRequest>): Promise<void> {
    const url = `${this.backendUrl}/user/change-user-data`;
    return this.springPost(url, userDataRequest, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async requestPasswordResetEmail(email: string): Promise<void> {
    const url = `${this.backendUrl}/user/request-password-reset/${email}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG
    });
  }

  async userHasDefaultPassword() {
    const url = `${this.backendUrl}/user/current-user-has-default-password`;
    const res = await this.springGet(url, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG
    });
    // console.log("userHasDefaultPassword", res)
    return res === "true";
  }

  async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<void> {
    const url = `${this.backendUrl}/user/change-password`;
    return this.springPost(url, changePasswordRequest, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG
    });
  }

  async resetPassword(request: PasswordResetRequest): Promise<void> {
    const url = `${this.backendUrl}/user/reset-password`;
    return this.springPost(url, request, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG
    });
  }

  //
  // PROD SITE ADMIN
  //
  async getUserInfoAdmin(username: string): Promise<UserInfo> {
    const url = `${this.backendUrl}/user/admin/get-user-info/${username}`;
    return await this.springGet(url);
  }

  async getAllUserInfosAdmin(): Promise<UserInfo[]> {
    const url = `${this.backendUrl}/user/admin/get-all-user-infos`;
    return await this.springGet(url);
  }

  async changeUserDataAdmin(userDataRequest: Partial<UserDataAdminRequest>): Promise<void> {
    const url = `${this.backendUrl}/user/admin/change-user-data`;
    return this.springPost(url, userDataRequest, {
      returnType: SpringReturnType.JSON_OR_NULL,
    });
  }


  //
  // TIME
  //

  // /display-work-day

  async getWorkDate(dateTimeRequest: DateTimeRequest): Promise<string> {
    const url = `${this.backendUrl}/time/get-work-date`;
    const options: SpringOptions = {returnType: SpringReturnType.TEXT};
    return this.springPost(url, dateTimeRequest, options);
  }

  async getWorkDayForDateTime(dateTimeRequest: DateTimeRequest): Promise<number> {
    const url = `${this.backendUrl}/time/display-work-day`;
    const options: SpringOptions = {returnType: SpringReturnType.TEXT};
    return this.springPost(url, dateTimeRequest, options);
  }

  async getCalendarDayForDateTime(dateTimeRequest: DateTimeRequest): Promise<number> {
    const url = `${this.backendUrl}/time/display-day`;
    const options: SpringOptions = {returnType: SpringReturnType.TEXT};
    return this.springPost(url, dateTimeRequest, options);
  }

  async getWeekNumberForDate(dateRequest: DateRequest): Promise<number> {
    const url = `${this.backendUrl}/time/display-week`;
    const options: SpringOptions = {returnType: SpringReturnType.TEXT};
    return this.springPost(url, dateRequest, options);
  }

  async getWorkWeekNumberForDateTime(dateTimeRequest: DateTimeRequest): Promise<number> {
    const url = `${this.backendUrl}/time/display-work-week-for-datetime`;
    const options: SpringOptions = {returnType: SpringReturnType.TEXT};
    return this.springPost(url, dateTimeRequest, options);
  }

  async getValidityStart(validityStartRequest: ValidityStartRequest): Promise<string> {
    const url = `${this.backendUrl}/time/validity-start`;
    return this.springPost(url, validityStartRequest, {returnType: SpringReturnType.TEXT});
  }

  async convertInstantToDateTime(instantRequest: InstantRequest): Promise<string> {
    const url = `${this.backendUrl}/time/instant-to-datetime`;
    return this.springPost(url, instantRequest, {returnType: SpringReturnType.TEXT});
  }

  async getEndOfWeek(dateRequest: DateRequest): Promise<string> {
    const url = `${this.backendUrl}/time/truncate-to-first-week`;
    return this.springPost(url, dateRequest, {returnType: SpringReturnType.TEXT});
  }

  async getCurrentDateTime(): Promise<string> {
    const url = `${this.backendUrl}/time/current-datetime`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  //
  // Interrupt the solving 
  //

  async stopSolving(modelType: CoffeeProductionModelType, solveProcessId: string): Promise<void> {
    const url = `${this.backendUrl}/computation/${modelType}/stop/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }
  //
  // Solution Infos
  //

  async getSolutionInfos(modelType: CoffeeProductionModelType, solutionIds: string[]): Promise<string> {
    const url = `${this.backendUrl}/computation/${modelType}/get-solution-infos`;
    return this.springPost(url, solutionIds, {returnType: SpringReturnType.JSON});
  }


  //
  // GAS CALCULATION
  //

  async sara_GetSolveRequest_ProductionAssignment(solveRequestRequest: SolveRequestRequest): Promise<SolveRequest> {
    const url = `${this.backendUrl}/computation/SARA/solve-production-assignment-request`;
    return this.springPost(url, solveRequestRequest, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  //
  // METADATA
  //

  // async uploadFile(fileUploadUrl: string, formData: FormData): Promise<void> {
  //   const url = fileUploadUrl;
  //   return this.springPost(url, formData, {
  //     ...defaultSpringOptions,
  //     returnType: SpringReturnType.JSON,
  //   });
  // }

  async togglePublicVisible(modelType: CoffeeProductionModelType, summaryId: string): Promise<void> {
    const url = `${this.backendUrl}/metadata/${modelType}/toggle-public-visible/${summaryId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  async listSummaryMetadata(metadataRequest: SummaryMetadataRequest): Promise<SummaryMetadata[]> {
    const url = `${this.backendUrl}/metadata/summary-metadata`;
    return this.springPost(url, metadataRequest);
  }

  async saveSummaryMetadata(metadata: SummaryMetadata): Promise<void> {
    const url = `${this.backendUrl}/metadata/save-summary-metadata`;
    return this.springPost(url, metadata, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.JSON,
    });
  }

  async getFulfilledDemandsValidity(
    fulfilledDemandsFilename: string,
    modelDateRequest: ModelDateRequest
  ): Promise<string> {
    const url = `${this.backendUrl}/metadata/fulfilled-demands-validity/${fulfilledDemandsFilename}`;
    const res = await this.springPost(url, modelDateRequest, {
      returnType: SpringReturnType.TEXT,
    });
    // console.log("getFulfilledDemandsValidity", res)
    return res
  }

  async getCellplanValidity(cellplanFilename: string, modelDateRequest: ModelDateRequest): Promise<string> {
    const url = `${this.backendUrl}/metadata/cellplan-validity/${cellplanFilename}`;
    const res = await this.springPost(url, modelDateRequest, {
      returnType: SpringReturnType.TEXT,
    });
    // console.log("getCellplanValidity", res)
    return res
  }

  async getValidCellplans(fileRequest: FileRequest): Promise<SiloAssignmentInputFile[]> {
    const url = `${this.backendUrl}/metadata/valid-cellplans`;
    return this.springPost(url, fileRequest);
  }

  async getValidFulfilledDemands(fileRequest: FileRequest): Promise<SiloAssignmentInputFile[]> {
    const url = `${this.backendUrl}/metadata/valid-fulfilled-demands`;
    return this.springPost(url, fileRequest);
  }

  async setFrontendVersionIfNewer(version: string): Promise<boolean> {
    const url = `${this.backendUrl}/metadata/set-frontend-version-if-newer/${version}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  async getLatestFrontendVersion(): Promise<string> {
    const url = `${this.backendUrl}/metadata/get-latest-frontend-version`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  async listRoasterExclusionReasons(): Promise<string[]> {
    const url = `${this.backendUrl}/metadata/list-roaster-exclusion-reasons`;
    return this.springGet(url);
  }

  async getProductionSite(): Promise<CoffeeProductionSite> {
    console.log("getProductionSite")
    const url = `${this.backendUrl}/metadata/get-production-site`;
    return this.springGet(url, {
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  async getSiloAllocationStates(startDate: string, endDate: string): Promise<SiloAllocationState[]> {
    const url = `${this.backendUrl}/metadata/fetch-silo-allocation-states`;
    return this.springPost(url, {
      timeRangeStart: startDate,
      timeRangeEnd: endDate,
    }, {
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }


  //
  // FILES
  //

  async getFile_ComplianceRG(
    id: string
  ): Promise<CoffeeProductionCompliancePlanSingle> {
    const url = `${this.backendUrl}/files/compliance-rg/${id}`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  async getFile_ComplianceTAS(
    id: string
  ): Promise<CoffeeProductionCompliancePlanSingle> {
    const url = `${this.backendUrl}/files/compliance-tas/${id}`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  async getFilesByIds(fileIds: string[]): Promise<SiloAssignmentInputFile[]> {
    const url = `${this.backendUrl}/files/input-files-by-id`;
    return this.springPost(url, fileIds);
  }

  async getInputFiles(fileRequest: FileRequest): Promise<SiloAssignmentInputFile[]> {
    const url = `${this.backendUrl}/files/input-files`;
    return this.springPost(url, fileRequest);
  }

  async getFileUploadId(fileUploadRequest: FileUploadRequest): Promise<{ uploadId: string }> {
    const url = `${this.backendUrl}/files/upload/get-id`;
    // const options: SpringOptions = { returnType: SpringReturnType.TEXT };
    return this.springPost(url, fileUploadRequest, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async uploadFile(uploadId: string, file: File): Promise<{ uploadMessage: string }> {
    const url = `${this.backendUrl}/files/upload/${uploadId}`;
    // const options: SpringOptions = { ...defaultSpringOptions, returnType: SpringReturnType.TEXT };
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.springPost(url, formData, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async getLatestBasket(modelDateRequest: ModelDateRequest): Promise<SiloAssignmentInputFile> {
    const url = `${this.backendUrl}/files/latest-basket`;
    return this.springPost(url, modelDateRequest, {
      ...defaultSpringOptions,
      // errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async getLatestRoaster(modelDateRequest: ModelDateRequest): Promise<SiloAssignmentInputFile> {
    const url = `${this.backendUrl}/files/latest-roaster`;
    return this.springPost(url, modelDateRequest, {
      ...defaultSpringOptions,
      // errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  //
  // PAPA
  //

  async papa_GetSettingPartTypes(): Promise<string[]> {
    const url = `${this.backendUrl}/user/settings/PAPA/get-part-types`;
    return this.springGet(url);
  }

  async papa_DeleteAllSettings(): Promise<string[]> {
    const url = `${this.backendUrl}/user/settings/PAPA/delete-all`;
    return this.springGet(url, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.TEXT_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  // async papa_DeleteSettings(deleteSettingsRequest: PAPADeleteSettingsRequest): Promise<string[]> {
  //   const url = `${this.backendUrl}/user/settings/PAPA/delete`;
  //   return this.springPost(url, deleteSettingsRequest, {
  //     ...defaultSpringOptions,
  //     returnType: SpringReturnType.TEXT_OR_NULL,
  //     errorHandling: SpringErrorHandlingType.THROW_ERROR,
  //   });
  // }

  async papa_ListSolutionDays(productionDateRange: ProductionDateRange): Promise<string[]> {
    let url = `${this.backendUrl}/computation/PAPA/list-solution-days`;
    // if (!!userType) url += `/${userType}`;
    return this.springPost(url, productionDateRange);
  }

  async papa_GetDataStatusesTimed(dataStatusesRequest: DataStatusesRequest): Promise<SiloAssignmentDailyDataStatus[]> {
    const url = `${this.backendUrl}/computation/PAPA/data-statuses`;
    return this.springPost(url, dataStatusesRequest, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async papa_GetSolveRequest(solveRequestRequest: SolveRequestRequest): Promise<SolveRequest> { // refactor SolveRequest to SARASolveRequest and PAPA...
    const url = `${this.backendUrl}/computation/PAPA/solve-request`;
    return this.springPost(url, solveRequestRequest, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async papa_GetSolveStatus(solveProcessId: string): Promise<SolveStatus> {
    const url = `${this.backendUrl}/computation/PAPA/solve-status/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async papa_Solve(solveRequest: SolveRequest): Promise<string> {
    const url = `${this.backendUrl}/computation/PAPA/solve`;
    // console.log("papa_Solve", this.backendUrl, url, solveRequest);
    return this.springPost(url, solveRequest, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async papa_GetSolverOutput(solveProcessId: string): Promise<SolveProcessOutput> {
    const url = `${this.backendUrl}/computation/PAPA/solver-output/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async papa_GetSolveResultBySolveProcessId(solveProcessId: string): Promise<SiloAssignmentResult> {
    const url = `${this.backendUrl}/computation/PAPA/solve-result/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async papa_GetSolutionById(solutionId: string): Promise<CoffeeProductionAssignmentResultSummary> {
    const url = `${this.backendUrl}/computation/PAPA/get-solution/${solutionId}`;
    return this.springGet(url, {
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async papa_GetSolutionId(solveRequestRequest: PAPASolveResultRequest): Promise<string | null> {
    const url = `${this.backendUrl}/computation/PAPA/get-solution-id`;
    let res: string = await this.springPost(url, solveRequestRequest, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
    // console.log("papa_GetSolutionId", res);
    if (res === "") return null;
    return res;
  }

  async papa_GetSolution(solveRequestRequest: PAPASolveResultRequest): Promise<CoffeeProductionSARASummary> {
    const url = `${this.backendUrl}/computation/PAPA/get-solution`;
    return this.springPost(url, solveRequestRequest, {
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async papa_GetSolutionHashes(solveRequestRequest: PAPASolveResultRequest): Promise<SolutionHashes> {
    const url = `${this.backendUrl}/computation/PAPA/get-solution-hashes`;
    return this.springPost(url, solveRequestRequest);
  }

  async papa_ListSolutions(dateRange: ProductionDateRange): Promise<CoffeeProductionPAPASummary[]> {
    const url = `${this.backendUrl}/computation/PAPA/list-solutions`;
    // log({dateRange})
    return this.springPost(url, dateRange, {
      // errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async papa_GetUsedSolveRequest(solutionSummaryId: string): Promise<SolveRequest> {
    const url = `${this.backendUrl}/computation/PAPA/get-used-solved-request/${solutionSummaryId}`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  async papa_DeleteSolutionById(solutionId: string) {
    const url = `${this.backendUrl}/computation/PAPA/delete-solution/${solutionId}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  async papa_ListMostRecentSolutions(request: LastSolutionsRequest): Promise<CoffeeProductionSARASummary[]> {
    const url = `${this.backendUrl}/computation/PAPA/last-solutions`;
    return this.springPost(url, request, {});
  }

  // async papa_CopyUserSettings(sourceUserType: UserType, targetUserType: UserType) {
  //   const url = `${this.backendUrl}/user/settings/PAPA/copy/${sourceUserType}/${targetUserType}`;
  //   return this.springGet(url, {returnType: SpringReturnType.TEXT});
  // }

  async papa_UpdateUserSettingsBySolveRequestId(solveRequestId: string) {
    const url = `${this.backendUrl}/user/settings/PAPA/update/${solveRequestId}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  async papa_UpdateUserSettingsBySolveRequest(solveRequest: SolveRequest) {
    const url = `${this.backendUrl}/user/settings/PAPA/update`;
    return this.springPost(url, solveRequest, {returnType: SpringReturnType.TEXT});
  }

  // TODO: this endpoint does not exist - check why
  async papa_ResetUserSettings() {
    const url = `${this.backendUrl}/user/settings/PAPA/reset`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  //
  // SARA
  //


  //
  // DOWNLOAD
  //

  async downloadEndOfWeekCellplan(solutionId: string): Promise<void> {
    const url = `${this.backendUrl}/computation/SARA/get-end-of-week-silo-allocations/${solutionId}`;
    const res = await this.springGet(url, {returnType: SpringReturnType.JSON});
    downloadCsv(res.fileName, res.fileContent)
  }

  async downloadEmptyFulfilledDemands(solutionId: string): Promise<void> {
    const url = `${this.backendUrl}/computation/SARA/get-empty-next-week-fulfilled-demands/${solutionId}`;
    const res = await this.springGet(url, {returnType: SpringReturnType.JSON});
    console.log(res)
    downloadCsv(res.fileName, res.fileContent)
  }

  //NEW
  async createUserSettings(modelType: CoffeeProductionModelType, request: CreateUserSettingsRequest): Promise<string> {
    let url = `${this.backendUrl}/user/settings/${modelType}/create`;
    return this.springPost(url, request, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.TEXT_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  async updateUserSettingsSolveRequest(modelType: CoffeeProductionModelType, userSettingsId: string, solveRequest: any): Promise<string> {
    // /{modelType}/update/{userSettingsId}")
    let url = `${this.backendUrl}/user/settings/${modelType}/update/${userSettingsId}`;
    return this.springPost(url, solveRequest, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.TEXT_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  async updateUserSettingsInfo(request: UserSettingsMetadataChangeRequest): Promise<string> {
    // /{modelType}/update/{userSettingsId}")
    let url = `${this.backendUrl}/user/settings/change-meta-data`;
    return this.springPost(url, request, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.TEXT_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }


  async getUserSettings(modelType: CoffeeProductionModelType, userSettingsId: string): Promise<UserSettingsInfo[]> {
    let url = `${this.backendUrl}/user/settings/${modelType}/get/${userSettingsId}`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  //NEW
  async getOwnedUserSettingInfos(modelType: CoffeeProductionModelType): Promise<UserSettingsInfo[]> {
    let url = `${this.backendUrl}/user/settings/${modelType}/get-owned-user-setting-infos`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  //NEW
  async getReadableUserSettingInfos(modelType: CoffeeProductionModelType): Promise<UserSettingsInfo[]> {
    let url = `${this.backendUrl}/user/settings/${modelType}/get-readable-user-setting-infos`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  //NEW
  async getWriteableUserSettingInfos(modelType: CoffeeProductionModelType): Promise<UserSettingsInfo[]> {
    let url = `${this.backendUrl}/user/settings/${modelType}/get-writeable-user-setting-infos`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  //NEW*
  async getDefaultUserSettingInfo(modelType: CoffeeProductionModelType): Promise<UserSettingsInfo | null> {
    let url = `${this.backendUrl}/user/settings/${modelType}/get-default-user-setting-info`;
    return this.springGet(url, {returnType: SpringReturnType.JSON_OR_NULL});
  }

  //NEW*
  async setDefaultUserSetting(modelType: CoffeeProductionModelType, userSettingId: string): Promise<void> {
    let url = `${this.backendUrl}/user/settings/${modelType}/set-default-user-settings/${userSettingId}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT_OR_NULL});
  }

  async setGlobalDefaultUserSetting(modelType: CoffeeProductionModelType, userSettingId: string, userRole: UserRole): Promise<UserInfo[]> {
    let url = `${this.backendUrl}/user/admin/${modelType}/set-global-default-user-settings/${userSettingId}/${userRole}`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }


  async settingChangeWriteAccess(modelType: CoffeeProductionModelType, userSettingId: string, accessType: UserAccessType): Promise<void> {
    let url = `${this.backendUrl}/user/settings/${modelType}/change-write-access/${userSettingId}/${accessType}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT_OR_NULL});
  }

  async settingChangeReadAccess(modelType: CoffeeProductionModelType, userSettingId: string, accessType: UserAccessType): Promise<void> {
    let url = `${this.backendUrl}/user/settings/${modelType}/change-read-access/${userSettingId}/${accessType}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT_OR_NULL});
  }

  //added userSettingsId
  async sara_UpdateUserSettingsBySolveRequest(userSettingsId: string, solveRequest: SolveRequest) {
    const url = `${this.backendUrl}/user/settings/SARA/update/${userSettingsId}`;
    return this.springPost(url, solveRequest, {returnType: SpringReturnType.TEXT});
  }

  //added userSettingsId
  async sara_UpdateUserSettingsBySolveRequestId(userSettingsId: string, solveRequestId: string) {
    const url = `${this.backendUrl}/user/settings/SARA/update/${userSettingsId}/${solveRequestId}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  //NEW
  async sara_DeleteUserSettings(userSettingsId: string): Promise<string[]> {
    const url = `${this.backendUrl}/user/settings/SARA/delete/${userSettingsId}`;
    return this.springGet(url, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.TEXT_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  async sara_DownloadSolution(saraSummaryId: string): Promise<void> {
    const saraSummary = await this.sara_GetSolutionById(saraSummaryId);
    saraSummary.solveRequest = await this.sara_GetUsedSolveRequest(saraSummary.id);
    const blob = new Blob([JSON.stringify(saraSummary, null, 2)], {type: "application/json"});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const formatDateShort = (date: Date) => {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    };
    link.download = `sara_solution_${saraSummary.id}.json`;
    link.click();
  }


  async deleteUserSettings(modelType: CoffeeProductionModelType, userSettingsId: string): Promise<string[]> {
    const url = `${this.backendUrl}/user/settings/${modelType}/delete/${userSettingsId}`;
    return this.springGet(url, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.TEXT_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  //
  // @PostMapping("/{modelType}/update/{userSettingsId}"
  //NEW
  // async sara_UpdateUserSettingsInfo(userSettingsInfo: UserSettingsInfo): Promise<string[]> {
  //   const url = `${this.backendUrl}/user/settings/SARA/update`;
  //   return this.springGet(url, {
  //     ...defaultSpringOptions,
  //     returnType: SpringReturnType.TEXT_OR_NULL,
  //     errorHandling: SpringErrorHandlingType.THROW_ERROR,
  //   });
  // }

  async getSettingPartTypes(modelType: CoffeeProductionModelType): Promise<string[]> {
    let url = `${this.backendUrl}/user/settings/${modelType}/get-part-types`;
    return this.springGet(url);
  }

  //added settingsId in deleteSettingsRequest
  async deleteUserSettingsParts(modelType: CoffeeProductionModelType, deleteSettingsRequest: DeleteSettingsPartRequest): Promise<string[]> {
    let url = `${this.backendUrl}/user/settings/${modelType}/delete-parts`;
    return this.springPost(url, deleteSettingsRequest, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.TEXT_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  //sourceUserType: UserType, targetUserType: UserType replaced by sourceSettingsId: string, targetSettingsId: string
  async sara_CopyUserSettings(sourceSettingsId: string, targetSettingsId: string) {
    const url = `${this.backendUrl}/user/settings/SARA/copy/${sourceSettingsId}/${targetSettingsId}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  //OBSOLETE, replaced by sara_DeleteUserSettings
  // async sara_DeleteAllSettings(): Promise<string[]> {
  //   const url = `${this.backendUrl}/user/settings/SARA/delete-all`;
  //   return this.springGet(url, {
  //     ...defaultSpringOptions,
  //     returnType: SpringReturnType.TEXT_OR_NULL,
  //     errorHandling: SpringErrorHandlingType.THROW_ERROR,
  //   });
  // }

  //OBSOLETE, already unused?!
  async sara_ResetUserSettings() {
    const url = `${this.backendUrl}/user/settings/SARA/reset`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  //END OF SETTINGS 
  async sara_getObjectiveTranslations(): Promise<any> {
    const url = `${this.backendUrl}/computation/SARA/translation/objective/EN`
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  async sara_ListSolutionDays(productionDateRange: ProductionDateRange): Promise<string[]> {
    let url = `${this.backendUrl}/computation/SARA/list-solution-days`;
    // if (!!userType) url += `/${userType}`;
    return this.springPost(url, productionDateRange);
  }

  async sara_GetDataStatusesTimed(dataStatusesRequest: DataStatusesRequest): Promise<SiloAssignmentDailyDataStatus[]> {
    const url = `${this.backendUrl}/computation/SARA/data-statuses`;
    return this.springPost(url, dataStatusesRequest, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async sara_GetUsedSolveRequest(solutionSummaryId: string): Promise<SolveRequest> {
    const url = `${this.backendUrl}/computation/SARA/get-used-solved-request/${solutionSummaryId}`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  async sara_ListObjectives(): Promise<NameValueTuple[]> {
    const url = `${this.backendUrl}/computation/SARA/objective/list`;
    return this.springGet(url);
  }

  async sara_ListSolutionStrategies(): Promise<NameValueTuple[]> {
    const url = `${this.backendUrl}/computation/SARA/strategy/list`;
    return this.springGet(url);
  }

  async sara_GetSolverParameters(strategy: string, objectiveType: string): Promise<CoffeeProductionSolverParameters[]> {
    const url = `${this.backendUrl}/computation/SARA/solver-parameters/${strategy}/${objectiveType}`;
    return this.springGet(url);
  }

  async sara_GetSolveRequest(solveRequestRequest: SolveRequestRequest): Promise<SolveRequest> {
    const url = `${this.backendUrl}/computation/SARA/solve-request`;
    return this.springPost(url, solveRequestRequest, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async sara_GetSolution(solveRequestRequest: SARASolveResultRequest): Promise<CoffeeProductionSARASummary> {
    const url = `${this.backendUrl}/computation/SARA/get-solution`;
    return this.springPost(url, solveRequestRequest, {
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async sara_GetSolutionId(solveRequestRequest: SARASolveResultRequest): Promise<string | null> {
    const url = `${this.backendUrl}/computation/SARA/get-solution-id`;
    let res: string = await this.springPost(url, solveRequestRequest, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
    // console.log("sara_GetSolutionId", res);
    if (res === "") return null;
    return res;
  }

  async sara_Solve(solveRequest: SolveRequest): Promise<string> {
    const url = `${this.backendUrl}/computation/SARA/solve`;
    // console.log("sara_Solve", this.backendUrl, url, solveRequest);
    return this.springPost(url, solveRequest, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async sara_GetSolverOutput(solveProcessId: string): Promise<SolveProcessOutput> {
    const url = `${this.backendUrl}/computation/SARA/solver-output/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async sara_GetSolveStatus(solveProcessId: string): Promise<SolveStatus> {
    const url = `${this.backendUrl}/computation/SARA/solve-status/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async sara_GetSolveResultBySolveProcessId(solveProcessId: string): Promise<SiloAssignmentResult> {
    const url = `${this.backendUrl}/computation/SARA/solve-result/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async sara_GetSolutionById(solutionId: string): Promise<CoffeeProductionSARASummary> {
    const url = `${this.backendUrl}/computation/SARA/get-solution/${solutionId}`;
    return this.springGet(url, {
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async sara_DeleteSolutionById(solutionId: string) {
    const url = `${this.backendUrl}/computation/SARA/delete-solution/${solutionId}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  async sara_ListSolutions(dateRange: ProductionDateRange): Promise<CoffeeProductionSARASummary[]> {
    const url = `${this.backendUrl}/computation/SARA/list-solutions`;
    // log({dateRange})
    return this.springPost(url, dateRange, {
      // errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async sara_ListMostRecentSolutions(request: LastSolutionsRequest): Promise<CoffeeProductionSARASummary[]> {
    const url = `${this.backendUrl}/computation/SARA/last-solutions`;
    return this.springPost(url, request, {});
  }

  async sara_GetSolutionHashes(solveRequestRequest: SARASolveResultRequest): Promise<SolutionHashes> {
    const url = `${this.backendUrl}/computation/SARA/get-solution-hashes`;
    return this.springPost(url, solveRequestRequest);
  }

  //
  // DORI
  //

  async dori_DeleteSolutionById(solutionId: string) {
    const url = `${this.backendUrl}/computation/DORI/delete-solution/${solutionId}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  async dori_GetSettingPartTypes(): Promise<string[]> {
    const url = `${this.backendUrl}/user/settings/DORI/get-part-types`;
    return this.springGet(url);
  }

  async dori_DeleteAllSettings(): Promise<string[]> {
    const url = `${this.backendUrl}/user/settings/DORI/delete-all`;
    return this.springGet(url, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.TEXT_OR_NULL,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  // async dori_DeleteSettings(deleteSettingsRequest: DORIDeleteSettingsRequest): Promise<string[]> {
  //   const url = `${this.backendUrl}/user/settings/DORI/delete`;
  //   return this.springPost(url, deleteSettingsRequest, {
  //     ...defaultSpringOptions,
  //     returnType: SpringReturnType.TEXT_OR_NULL,
  //     errorHandling: SpringErrorHandlingType.THROW_ERROR,
  //   });
  // }

  async dori_ListSolutionDays(productionDateRange: ProductionDateRange): Promise<string[]> {
    let url = `${this.backendUrl}/computation/DORI/list-solution-days`;
    // if (!!userType) url += `/${userType}`;
    return this.springPost(url, productionDateRange);
  }

  async dori_GetDataStatusesTimed(dataStatusesRequest: DataStatusesRequest): Promise<SiloAssignmentDailyDataStatus[]> {
    const url = `${this.backendUrl}/computation/DORI/data-statuses`;
    return this.springPost(url, dataStatusesRequest, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async dori_GetSolutionId(solveRequestRequest: DORISolveResultRequest): Promise<string | null> {
    const url = `${this.backendUrl}/computation/DORI/get-solution-id`;
    let res: string = await this.springPost(url, solveRequestRequest, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
    // console.log("dori_GetSolutionId", res);
    if (res === "") return null;
    return res;
  }

  async dori_GetSolutionById(solutionId: string): Promise<CoffeeProductionDORISummary> {
    const url = `${this.backendUrl}/computation/DORI/get-solution/${solutionId}`;
    return this.springGet(url, {
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async dori_GetSolutionSolverStatisticsById(solutionId: string): Promise<SolverOutputLineCplex[]> {
    const url = `${this.backendUrl}/computation/DORI/get-solution-solver-statistics/${solutionId}`;
    return this.springGet(url, {
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async dori_GetSolution(solveRequestRequest: DORISolveResultRequest): Promise<CoffeeProductionDORISummary> {
    const url = `${this.backendUrl}/computation/DORI/get-solution`;
    return this.springPost(url, solveRequestRequest, {
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async dori_getLastSolution(solveProcessId: string): Promise<CoffeeProductionDORISummary> {
    const url = `${this.backendUrl}/computation/DORI/get-last-solution/${solveProcessId}`;
    return this.springGet(url, {
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async dori_GetSolutionHashes(solveRequestRequest: DORISolveResultRequest): Promise<SolutionHashes> {
    const url = `${this.backendUrl}/computation/DORI/get-solution-hashes`;
    return this.springPost(url, solveRequestRequest);
  }

  async dori_GetUsedSolveRequest(solutionSummaryId: string): Promise<CoffeeProductionDORISolveRequest> {
    const url = `${this.backendUrl}/computation/DORI/get-used-solved-request/${solutionSummaryId}`;
    return this.springGet(url, {returnType: SpringReturnType.JSON});
  }

  async dori_GetSolveStatus(solveProcessId: string): Promise<SolveStatus> {
    const url = `${this.backendUrl}/computation/DORI/solve-status/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async dori_GetSolveResultBySolveProcessId(solveProcessId: string): Promise<SiloAssignmentResult> {
    const url = `${this.backendUrl}/computation/DORI/solve-result/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async dori_GetSolverOutput(solveProcessId: string): Promise<SolveProcessOutput> {
    const url = `${this.backendUrl}/computation/DORI/solver-output/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async dori_GetRunningProcessSolverStatistics(solveProcessId: string): Promise<SolverOutputLineCplex[]> {
    const url = `${this.backendUrl}/computation/DORI/solver-statistics/${solveProcessId}`;
    return this.springGet(url, {
      returnType: SpringReturnType.JSON_OR_NULL,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async dori_Solve(solveRequest: SolveRequest): Promise<string> {
    const url = `${this.backendUrl}/computation/DORI/solve`;
    // console.log("dori_Solve", this.backendUrl, url, solveRequest);
    return this.springPost(url, solveRequest, {
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  async dori_GetSolveRequest(solveRequestRequest: CoffeeProductionDORISolveRequestRequest): Promise<CoffeeProductionDORISolveRequest> { // refactor SolveRequest to SARASolveRequest and dori...
    const url = `${this.backendUrl}/computation/DORI/solve-request`;
    return this.springPost(url, solveRequestRequest, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  async dori_ListSolutions(dateRange: ProductionDateRange): Promise<CoffeeProductionDORISummary[]> {
    const url = `${this.backendUrl}/computation/DORI/list-solutions`;
    // log({dateRange})
    return this.springPost(url, dateRange, {
      // errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async dori_ListInputSaraSolutions(dateRange: ProductionDateRange): Promise<CoffeeProductionDORISummary[]> {
    const url = `${this.backendUrl}/computation/DORI/list-input-sara-solutions`;
    // log({dateRange})
    return this.springPost(url, dateRange, {
      // errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW,
    });
  }

  async dori_ListMostRecentSolutions(request: LastSolutionsRequest): Promise<CoffeeProductionSARASummary[]> {
    const url = `${this.backendUrl}/computation/DORI/last-solutions`;
    return this.springPost(url, request, {});
  }

  async dori_ResetUserSettings() {
    const url = `${this.backendUrl}/user/settings/DORI/reset`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  async dori_UpdateUserSettingsBySolveRequestId(solveRequestId: string) {
    const url = `${this.backendUrl}/user/settings/DORI/update/${solveRequestId}`;
    return this.springGet(url, {returnType: SpringReturnType.TEXT});
  }

  async dori_UpdateUserSettingsBySolveRequest(solveRequest: CoffeeProductionDORISolveRequest) {
    const url = `${this.backendUrl}/user/settings/DORI/update`;
    return this.springPost(url, solveRequest, {returnType: SpringReturnType.TEXT});
  }

  //
  // OTHER
  //

  async sendErrorReport(errorReport: CoffeeProductionErrorReport) {
    const url = `${this.backendUrl}/email/send-error-report`;
    return this.springPost(url, errorReport, {returnType: SpringReturnType.TEXT});
  }

  async sendSupportRequest(supportRequest: CoffeeProductionSupportRequest) {
    const url = `${this.backendUrl}/email/send-support-request`;
    return this.springPost(url, supportRequest, {returnType: SpringReturnType.TEXT});
  }


  async downloadFile(inputFileId: string, useOriginalFilename: boolean) {
    const url = `${this.backendUrl}/backup/download-file/${inputFileId}`;
    const response = await this.springGet(url, {returnType: SpringReturnType.RAW});
    // get file name from response headers, filename=\""+ fileName + "\""
    let filename;
    if (useOriginalFilename) {
      filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0].replace(/"/g, '');
    } else {
      filename = response.headers.get('Filename-On-Server').replace(/"/g, '');
    }
    // console.log("contentDisposition", filename)
    // console.log("response", response)
    const blob = await response.blob()
    // console.log("blob", blob)
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename
    a.click();
  }

  async downloadSettings(modelType: CoffeeProductionModelType, solveRequestId: string) {
    const url = `${this.backendUrl}/backup/download-settings/${modelType}/${solveRequestId}`;
    const response = await this.springGet(url, {returnType: SpringReturnType.RAW});
    // get file name from response headers, filename=\""+ fileName + "\""
    let filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0].replace(/"/g, '');
    // console.log("contentDisposition", filename)
    // console.log("response", response)
    const blob = await response.blob()
    // console.log("blob", blob)
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename
    a.click();
  }


  async getSolveRequest(solveRequestId: string, modelType: CoffeeProductionModelType): Promise<CoffeeProductionDORISolveRequest> { // refactor SolveRequest to SARASolveRequest and dori...
    const url = `${this.backendUrl}/computation/${modelType}/get-solve-request/${solveRequestId}`;
    return this.springGet(url, {
      ...defaultSpringOptions,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }

  //

  async convertDayOfWeekDateToLocalDate(dayOfWeekDate: DayOfWeekDate): Promise<string> { // refactor SolveRequest to SARASolveRequest and dori...
    const url = `${this.backendUrl}/time/day-of-week-to-local-date`;
    return this.springPost(url, dayOfWeekDate, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.TEXT,
      errorHandling: SpringErrorHandlingType.THROW_ERROR,
    });
  }

  async getSolveProcessInfos(modelType: CoffeeProductionModelType): Promise<SolveProcessInfo[]> { // refactor SolveRequest to SARASolveRequest and dori...
    const url = `${this.backendUrl}/computation/${modelType}/solve-processes`;
    return this.springGet(url, {
      ...defaultSpringOptions,
      returnType: SpringReturnType.JSON,
      errorHandling: SpringErrorHandlingType.RETURN_ERROR_AND_LOG,
    });
  }
}
