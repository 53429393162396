
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {CoffeeBasketPreroastingSettings, CuringTimeDependentPreroastingFactors} from "@/scripts/models/calculation";
import {WEEKDAYS} from "@/scripts/models/date";

export default Vue.extend({
  name: "PreroastingSettings",
  components: {},
  props: {
    value: {type: Object, required: true}, //CoffeeBasketPreroastingSettings
    autosave: {type: Boolean as PropType<boolean>, default: true},
    readonly: {type: Boolean as PropType<boolean>, default: false},
  },
  data() {
    return {
      weekdays: "Mo Di Mi Do Fr Sa So".split(" "),
      preroastingSettings: null as null|CoffeeBasketPreroastingSettings,
      defaultValues: null as null|CoffeeBasketPreroastingSettings,
    };
  },
  created() {
    this.init();
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        this.preroastingSettings = JSON.parse(JSON.stringify(v));
        if (this.defaultValues === null) {
          this.defaultValues = JSON.parse(JSON.stringify(v));
        }
      },
    },
  },
  computed: {
    ...mapState(["superuser", "user", "backendInterface"]),
    preroastingFactors(): CuringTimeDependentPreroastingFactors[] {
      return this.computePreroastingFactors(this.preroastingSettings)
    },
    defaultPreroastingFactors(): CuringTimeDependentPreroastingFactors[] {
      return this.computePreroastingFactors(this.defaultValues)
    },
    // sortedRoasterAvailabilities(): CuringTimeDependentPreroastingFactors[] {
    //   const s = [...this.preroastingSettings];
    //   s.sort((a, b) => (a.roasterName < b.roasterName ? -1 : 1));
    //   return s;
    // },
  },
  methods: {
    ...mapMutations(["toggleSuperuser"]),
    computePreroastingFactors(preroastingSettings: null|CoffeeBasketPreroastingSettings): CuringTimeDependentPreroastingFactors[]{
      if(preroastingSettings === null) return []
      const cbpf = preroastingSettings;
      return [
        ...cbpf.roastAndGroundPreroastingFactors,
        ...cbpf.tassimoPreroastingFactors,
      ];
    },
    init() {
      if (this.value !== null) {
        this.preroastingSettings = JSON.parse(JSON.stringify(this.value)) as CoffeeBasketPreroastingSettings;
      } else {
        this.preroastingSettings = null;
      }
    },
    inputHandler() {
      console.log("PreroastingSettings.inputHandler");
      if (this.autosave) this.save();
      const a = this.preroastingFactors
    },
    save() {
      console.log("PreroastingSettings.save");
      this.$emit("input", this.preroastingSettings);
    },
    setPreroastingToZero(factorIndex: number) {
      const pf = this.preroastingFactors[factorIndex]
      for (let i = 0; i < pf.weekdayPreroastingFactors.length; i++) {
        pf.weekdayPreroastingFactors[i] = 0
      }
      this.inputHandler();
    },
    resetPreroasting(factorIndex: number) {
      const pf = this.preroastingFactors[factorIndex]
      const pfDefault = this.defaultPreroastingFactors[factorIndex]
      for (let i = 0; i < pf.weekdayPreroastingFactors.length; i++) {
        pf.weekdayPreroastingFactors[i] = pfDefault.weekdayPreroastingFactors[i]
      }
      this.inputHandler();
    },
    setWeekdayFactorToZero(weekdayIndex: number) {
      for (const pf of this.preroastingFactors) {
        pf.weekdayPreroastingFactors[weekdayIndex] = 0
      }
      this.inputHandler();
    },
    resetWeekdayFactor(weekdayIndex: number) {
      for (let r = 0; r < this.preroastingFactors.length; r++) {
        const pf = this.preroastingFactors[r]
        const pfDefault = this.defaultPreroastingFactors[r]
        pf.weekdayPreroastingFactors[weekdayIndex] = pfDefault.weekdayPreroastingFactors[weekdayIndex]
      }
      this.inputHandler();
    },
    getFactorName(pf: CuringTimeDependentPreroastingFactors){
      return (pf.roastAndGround ? "R&G ":"Tassimo ") + pf.minCuringTime_h +"h"
    },
    getFactorLabel(pf: CuringTimeDependentPreroastingFactors){
      return (pf.roastAndGround ? "rg_":"tassimo_") + pf.minCuringTime_h
    }
  },
});
