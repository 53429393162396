
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CoffeeRoasterAvailabilityExclusion} from "@/scripts/models/dori";
import {ROASTER_NAMES} from "@/scripts/models/gas";
import {Weekday, weekdayEnumToGer, weekdayToTimestepMap} from "@/scripts/models/date";

interface RoasterTimelineItem {
  startHour: number
  endHour: number
  duration: number
  isAvailable: boolean
  name?: string
  tooltip?: Record<string, string>
}

export default Vue.extend({
  name: "RoasterAvailabilityPreview",
  components: {},
  props: {
    exclusions: {type: Array as PropType<CoffeeRoasterAvailabilityExclusion[]>},
    width: {type: Number as PropType<number>, default: -1},
  },
  data() {
    return {
      ROASTER_NAMES,
      hoursPerWeek: 24 * 7,
      chartWidth: 500,
      timelineRowHeight: 20,
      timelineRowHeightPadding: 6,
      firstTableColumnWidth: 100,
    };
  },
  created() {
    // this.chartWidth
    // console.log("this.exclusions", this.exclusions)
    // console.log("timelinePerRoaster", this.timelinePerRoaster)
  },
  mounted() {
    if(+this.width > 0) {
      this.chartWidth = +this.width
    } else {
      // @ts-ignore
      this.chartWidth = this.$refs.chart.clientWidth
    }
    // window.addEventListener("resize", () => {
    //   // @ts-ignore
    //   this.chartWidth = this.$refs.chart.clientWidth
    // })
  },
  // destroyed() {
  //   window.removeEventListener("resize", () => {
  //     // @ts-ignore
  //     this.chartWidth = this.$refs.chart.clientWidth
  //   })
  // },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    exclusionsPerRoaster(): { [roasterName: string]: CoffeeRoasterAvailabilityExclusion[] } {
      const exclusionsPerRoaster = {} as { [roasterName: string]: CoffeeRoasterAvailabilityExclusion[] }
      for (const exclusion of this.exclusionsOrPlaceholder) {
        if (!exclusionsPerRoaster[exclusion.roasterName]) {
          exclusionsPerRoaster[exclusion.roasterName] = []
        }
        exclusionsPerRoaster[exclusion.roasterName].push(exclusion)
      }
      return exclusionsPerRoaster
    },
    timelinePerRoaster(): { [roasterName: string]: RoasterTimelineItem[] } {
      const timelinePerRoaster = {} as { [roasterName: string]: RoasterTimelineItem[] }
      for (const roasterName of this.ROASTER_NAMES) {
        const exclusionsForRoaster = this.exclusionsPerRoaster[roasterName] || []
        timelinePerRoaster[roasterName] = this.createTimelineForRoaster(exclusionsForRoaster)
      }
      return timelinePerRoaster
    },
    timelineHeader(): RoasterTimelineItem[] {
      return this.createTimelineHeader()
    },
    chartHeight(): number {
      const nRows = Object.keys(this.timelinePerRoaster).length + 1
      const rowHeight = this.timelineRowHeight + this.timelineRowHeightPadding
      return nRows * rowHeight
    },
    exclusionsOrPlaceholder(): CoffeeRoasterAvailabilityExclusion[] {
      return this.exclusions ?? []
    }
  },
  methods: {
    ...mapMutations([""]),
    weekdayToIndex(weekday: Weekday) {
      return weekdayToTimestepMap[weekday]
    },
    createTimelineHeader() {
      const timelineHeader: RoasterTimelineItem[] = []
      const weekdays = Object.keys(weekdayToTimestepMap) // MONDAY, TUESDAY, ...
      for (const weekday of weekdays) {
        timelineHeader.push({
          name: weekdayEnumToGer[weekday as Weekday],
          startHour: this.weekdayToIndex(weekday as Weekday) * 24,
          endHour: this.weekdayToIndex(weekday as Weekday) * 24 + 24,
          duration: 24,
          isAvailable: true,
        })
      }
      return timelineHeader
    },
    getExclusionStartHour(exclusion: CoffeeRoasterAvailabilityExclusion) {
      const weekdayIndex = this.weekdayToIndex(exclusion.exclusionStartDay)
      const weekdayOffset = weekdayIndex * 24
      return weekdayOffset + exclusion.exclusionStartHour;
    },
    getDoubleTimeDisplayString(time: number) {
      const hour = Math.floor(time)
      const minute = Math.round((time - hour) * 60)
      return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`
    },
    createExclusionTooltip(exclusion: CoffeeRoasterAvailabilityExclusion) {

      const weekdayIndex = this.weekdayToIndex(exclusion.exclusionStartDay)
      const diff = exclusion.exclusionEndHour - exclusion.exclusionStartHour
      const exclusionDuration = diff// + (dayIsWrapping ? 24 : 0)
      // tooltip
      const dayIsWrapping = exclusion.exclusionStartHour + exclusionDuration > 24
      // console.log("dayIsWrapping", dayIsWrapping, diff)
      const startDayGer = weekdayEnumToGer[exclusion.exclusionStartDay]
      // const dayIsWrapping =
      let endDayGer = startDayGer;
      if (dayIsWrapping) {
        const weekdays = Object.keys(weekdayToTimestepMap) // MONDAY, TUESDAY, ...
        const nextDayIndex = (weekdayIndex + 1) % weekdays.length
        const nextDay = weekdays[nextDayIndex]
        endDayGer = weekdayEnumToGer[nextDay as Weekday]
      }
      // console.log("exclusion.exclusionStartHour", exclusion.exclusionStartHour)
      const tooltip = {
        "Röster": exclusion.roasterName,
        "Sperrgrund": exclusion.exclusionReason,
        "Beschreibung": exclusion.description || "",
        "Start": `${startDayGer}, ${this.getDoubleTimeDisplayString(exclusion.exclusionStartHour)}`,
        "Ende": `${endDayGer}, ${this.getDoubleTimeDisplayString(exclusion.exclusionEndHour % 24)}`,
      }
      if (exclusion.description === "") {
        // @ts-ignore
        delete tooltip["Beschreibung"]
      }
      return tooltip
    },
    createTimelineForRoaster(exclusionsForRoaster: CoffeeRoasterAvailabilityExclusion[]): RoasterTimelineItem[] {
      // build sections of available and excluded time
      const timeline: RoasterTimelineItem[] = []
      let lastExclusionEndHour = 0
      const fn = (a: any) => this.getExclusionStartHour(a)
      const sortedExclusions = [...exclusionsForRoaster]
          .sort((a, b) => fn(a) - fn(b))
      // for every exclusion, there must be an available section before it
      for (const exclusion of sortedExclusions) {
        const weekdayIndex = this.weekdayToIndex(exclusion.exclusionStartDay)
        const weekdayOffset = weekdayIndex * 24
        const exclusionStartHour = weekdayOffset + exclusion.exclusionStartHour;
        const diff = exclusion.exclusionEndHour - exclusion.exclusionStartHour
        const exclusionDuration = diff// + (dayIsWrapping ? 24 : 0)
        // add available part
        timeline.push({
          startHour: lastExclusionEndHour,
          endHour: exclusionStartHour,
          duration: exclusionStartHour - lastExclusionEndHour,
          isAvailable: true,
        })
        // add excluded part
        timeline.push({
          startHour: exclusionStartHour,
          endHour: exclusionStartHour + exclusionDuration,
          duration: exclusionDuration,
          isAvailable: false,
          tooltip: this.createExclusionTooltip(exclusion),
        })
        lastExclusionEndHour = exclusionStartHour + exclusionDuration
      }
      // add last available part
      if (lastExclusionEndHour < this.hoursPerWeek) {
        timeline.push({
          startHour: lastExclusionEndHour,
          endHour: this.hoursPerWeek,
          duration: this.hoursPerWeek - lastExclusionEndHour,
          isAvailable: true,
        })
      }
      return timeline
    },
    // getChartWidth(): number {
    //   // @ts-ignore
    //   return this.$refs?.chart?.clientWidth ?? 500
    // },
    getTimelineItemStyle(timelineItem: RoasterTimelineItem) {
      const {isAvailable} = timelineItem
      const transparencyModifier = `cc`
      return {
        ...this.getTimelineItemWidthAndTransform(timelineItem),
        backgroundColor: (isAvailable ? "#5cb85c" : "#d9534f") + transparencyModifier,
      }
    },
    getTimelineHeaderStyle(headerItem: RoasterTimelineItem) {
      return {
        ...this.getTimelineItemWidthAndTransform(headerItem),
        // backgroundColor: "#ccc",
      }
    },
    getTimelineItemWidthAndTransform(timelineItem: RoasterTimelineItem) {
      const {startHour, endHour, duration, isAvailable} = timelineItem
      const rightBorderWidth = 2
      // const chartWidth = 500 // px
      const chartWidth = (this.chartWidth - this.firstTableColumnWidth);
      const width = (duration / this.hoursPerWeek) * chartWidth - rightBorderWidth
      const left = (startHour / this.hoursPerWeek) * chartWidth
      return {
        width: `${width.toFixed(4)}px`,
        transform: `translateX(${left.toFixed(4)}px)`,
      }
    },
  },
});
