
import AnalyzeGasConsumptionSection from "@/components/gas/AnalyzeGasConsumptionSection.vue"
import GasConsumptionSettings from "@/components/settings/GasConsumptionSettings.vue"

export default {
  name: 'GasConsumptionWrapper',
  components: {AnalyzeGasConsumptionSection, GasConsumptionSettings},
  props: {
    settings: {},
    showUpload: {type: Boolean},
    readonly: {type: Boolean},

  },
  data() {
    return {
      tab: null
    }
  }
}
