
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
// import { UserType } from "@/scripts/models/general";

export default Vue.extend({
  name: "PageTitle",
  components: { Breadcrumbs },
  props: {
    title: { type: String, required: true },
    breadcrumbs: { type: Array, required: true },
    pageInfo: { type: Object, required: true },
  },
  data() {
    return {
      // UserType,
    };
  },
  async created() {},
  async mounted() {},
  watch: {},
  computed: {
    ...mapState(["user"]),
  },
  methods: {},
});
