
import Vue, { PropType } from "vue";
import {mapMutations, mapState} from "vuex";
import ProductionPlanTable from "@/components/gas/ProductionPlanTable.vue";
// @ts-ignore
import Plotly from "plotly.js-dist";
// import {UserType} from "@/scripts/models/general";
import PackagingTaskPlot from "@/components/gas/TaskPlot.vue";
import { MillingSubTaskResult } from "@/scripts/models/results";
import { copy } from "@/scripts/utils";


export default Vue.extend({
  name: "PackagingSummary",
  components: {PackagingTaskPlot, ProductionPlanTable},
  props: {
    title: {type: String, default: 'Packaging'},
    tasks: {type: Array as PropType<MillingSubTaskResult[]>, default: () => []},
    // taskIndexOffset: {type: Number as PropType<number>, default: 0}
  },
  data() {
    return {
      lines: [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
        '22', '23', '24', '25', '26', '27', '28'
      ],
    };
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      // this.plot()
    }, 0)
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    hideSelection(): boolean {
      return true
      // return this.userType !== UserType.SUPERUSER;
    },
    // tasksWithOffset(): PackagingTaskResult[] {
    //   console.log("this.tasks", this.tasks);
      
    //   const t: PackagingTaskResult[] = copy(this.tasks)
    //   for(const task of t){
    //     task.timeStep += 0
    //   }
    //   return t
    // },
  },
  methods: {
    ...mapMutations([""]),

  },
});
