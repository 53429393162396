
import {BackendInterface} from "@/scripts/BackendInterface";
import Vue, {PropType} from "vue";
import {mapState} from "vuex";
import {CalculationConfig, SolveStatus} from "@/scripts/models/calculation";
import {
  SiloAssignmentResult,
  SOLUTION_QUALITY_COLORS,
  SOLUTION_QUALITY_TRANSLATIONS,
  SolutionQuality,
  SolveProcessOutput
} from "@/scripts/models/results";
import {UserInfo, UserRole} from "@/scripts/auth";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import ProgressInfo from "@/components/common/ProgressInfo.vue";
import {formatSeconds, roundInverseMipGapValue, roundMipGapValue} from "../../scripts/utils";
import SolutionQualityChip from "@/components/common/SolutionQualityChip.vue";
import {SolverOutputLineCplex} from "@/scripts/models/statistics";
import SolverStatisticsPlot from "@/components/analysis/SolverStatisticsPlot.vue";
import Gantt from "@/components/gantt/Gantt.vue";
import {createGanttChartConfig_RoasterTask} from "@/scripts/gantt/coffee-gantt";
import GanttChartState from "@/scripts/gantt/GanttChartState";

export default Vue.extend({
  name: "CalculationSection",
  props: {
    solveRequest: {required: true},
    existingSolveProcessId: {type: String as PropType<string>, default: undefined},
    showSolverOutput: {type: Boolean, default: false},
    solverOutputFetchingEnabled: {type: Boolean, default: false},
    calculationConfig: {type: Object, required: true},
    modelType: {type: String as PropType<CoffeeProductionModelType>, required: true},
  },
  components: {Gantt, SolverStatisticsPlot, SolutionQualityChip, ProgressInfo},
  data: function () {
    return {
      SolveStatus,
      CoffeeProductionModelType,
      tab2: null,
      solverOutput: "",
      phases: [] as string[],
      solveProcessId: "",
      solution: null as SiloAssignmentResult | null,
      requestTimeoutS: 2,
      numRequests: 0,
      oldSolution: null as SiloAssignmentResult | null,
      processOutput: null as SolveProcessOutput | null,
      solveStatus: null as string | null,
      solvingInterruptedByUser: false,
      lockSolverOutputConsoleToBottom: false,
      loadUpdates: true,
      solverOutputStats: [] as SolverOutputLineCplex[],
      autoUpdateLivePreview: false,
      // DORI Live preview
      ganttConfigRoasterTask: null as any,
      ganttStateRoasterTask: null as any,
      currentTime: Date.now(),
    };
  },
  created() {
  },
  mounted() {
    if (this.solveRequest && !this.existingSolveProcessId) {
      this.startSolving();
    } else if (!!this.existingSolveProcessId) {
      this.solveProcessId = this.existingSolveProcessId
    }
    // check if query param with solveProcessId is set
  },
  // on before destroy, stop the interval
  beforeDestroy() {
    this.loadUpdates = false;
  },
  watch: {
    async solveProcessId() {
      const bi: BackendInterface = this.backendInterface;
      const cc: CalculationConfig = this.calculationConfig
      console.log("solveProcessId", this.solveProcessId);

      if (this.solveProcessId) {
        const timeout = async (ms: number) => new Promise((r) => setTimeout(r, ms));
        while (this.solverOutputFetchingEnabled && this.loadUpdates) {
          this.numRequests += 1;
          // console.log("solution", solution);
          // const solveStatus = await bi.sara_GetSolveStatus(this.solveProcessId);
          this.solveStatus = await cc.fetchSolveStatus(this.solveProcessId);
          // console.log("solveStatus:", this.solveStatus);
          if (this.solveStatus === SolveStatus.Scheduled) {
            // TODO: display a popup or something during notification rework
            console.log("Solving has been scheduled!");
          }
          if (this.solveStatus === SolveStatus.Solving) {
            // console.log("SOLVING");
            await this.updateSolverOutput();
            if (this.autoUpdateLivePreview) {
              await this.getLastSolution();
            }
          }
          if (this.solveStatus === SolveStatus.Finished) {
            // const solution = await bi.sara_GetSolveResultBySolveProcessId(this.solveProcessId);
            await this.updateSolverOutput();
            const solution = await cc.fetchSolveResult(this.solveProcessId);
            // console.log("FINISHED, solution:", solution);
            this.solution = solution;
            this.$emit("solution-found", this.solution?.id ?? null);
            this.loadUpdates = false
          }
          if (this.solveStatus === SolveStatus.UnknownProcessId) {
            console.warn("SolveStatus.Error");
            // TODO: display a popup or something during notification rework
            this.loadUpdates = false
          }
          if (this.solveStatus === SolveStatus.Error) {
            // TODO: display a popup or something during notification rework
            console.error("SolveStatus.Error");
            const solution = await cc.fetchSolveResult(this.solveProcessId);
            this.$emit("error", solution);
            this.loadUpdates = false
          }


          await timeout(this.requestTimeoutS * 1000);
        }
        // TODO: delete hash
        // await fetch(`${this.backendUrl}/sara/computation/delete-solver-output/${this.solveProcessId}`, {
        //   mode: "cors",
        // });
      }
    },
  },
  computed: {
    ...mapState(["backendUrl", "user", "backendInterface"]),
    isAdminOrMore(): boolean {
      if (this.modelType === CoffeeProductionModelType.SARA) {
        return this.user.saraUserRole === UserRole.ADMIN || this.user.saraUserRole === UserRole.SUPERUSER
      }
      if (this.modelType === CoffeeProductionModelType.PAPA) {
        return this.user.papaUserRole === UserRole.ADMIN || this.user.papaUserRole === UserRole.SUPERUSER
      }
      if (this.modelType === CoffeeProductionModelType.DORI) {
        return this.user.doriUserRole === UserRole.ADMIN || this.user.doriUserRole === UserRole.SUPERUSER
      }
      return false;
    },
    solutionQuality(): any {
      const solutionQualityColor = SOLUTION_QUALITY_COLORS[this.processOutput?.solutionQuality as SolutionQuality];
      const solutionQualityTranslation = SOLUTION_QUALITY_TRANSLATIONS[this.processOutput?.solutionQuality as SolutionQuality]
      return {solutionQualityColor, solutionQualityTranslation}
    },
    maxNumRequests(): number {
      return (10 * 60) / this.requestTimeoutS + 5;
    },
    phaseDict() {
      const phaseDict: { [key: string]: string[] } = {};
      for (const phase of this.phases) {
        let phaseName = phase.match(/\[Schritt [\d]+\.?[\d]*\]/)?.[0] ?? "";
        const phaseText = phase.slice(phaseName.length + 2);
        phaseName = phaseName.slice(1, -1);
        if (phaseName in phaseDict) phaseDict[phaseName].push(phaseText);
        else phaseDict[phaseName] = [phaseText];
      }
      return phaseDict;
    },
    isSuperuser(): boolean {
      const ui = this.user as UserInfo;
      switch (this.modelType) {
        case CoffeeProductionModelType.PAPA:
          return ui?.papaUserRole === UserRole.SUPERUSER;
        case CoffeeProductionModelType.SARA:
          return ui?.saraUserRole === UserRole.SUPERUSER;
        default:
          return ui?.doriUserRole === UserRole.SUPERUSER;
      }
    },
  },
  methods: {
    formatSeconds,
    roundMipGapValue,
    roundInverseMipGapValue,
    roundObjectiveValue(value: number | "NaN" | "Infinity"): string {
      if (value === "NaN" || value === "Infinity") {
        return '-'
      }
      // use m or k as a postfix for big values
      if (value > 1_000_000) {
        return (value / 1_000_000).toFixed(1) + "m";
      } else if (value > 1_000) {
        return (value / 1_000).toFixed(1) + "k";
      } else if (value > 10) {
        return value.toFixed(1);
      } else {
        return value.toFixed(2);
      }
    },
    async updateSolverOutput() {
      const solverOutputDom = this.$refs.solverOutput as HTMLElement;

      const cc: CalculationConfig = this.calculationConfig

      // Solver output statistics
      try {
        this.solverOutputStats = await cc.fetchSolverStatistics?.(this.solveProcessId) ?? [];
      } catch (e) {
        console.warn("Error fetching solver statistics");
      }
      // console.log("solverOutputStats", this.solverOutputStats)

      // Solver Text Output
      // let resText = await this.backendInterface.sara_GetSolverOutput(this.solveProcessId);
      const processOut = await cc.fetchSolverOutput(this.solveProcessId) as SolveProcessOutput;
      this.processOutput = processOut
      // console.log("processOut", processOut)
      const resText = processOut.solutionProcessOutput;
      const scrolledToBottom =
          solverOutputDom &&
          Math.abs(solverOutputDom.scrollHeight - solverOutputDom.scrollTop - solverOutputDom.clientHeight) < 1;
      if (resText == null || resText < this.solverOutput) {
        return false;
      }
      this.phases = resText.match(/(\[Schritt [\d]+\.?[\d]*\]).*/g)?.map((r: any) => r) ?? [];
      this.solverOutput = resText;
      if (scrolledToBottom || this.lockSolverOutputConsoleToBottom) {
        setTimeout(() => (solverOutputDom.scrollTop = solverOutputDom.scrollHeight), 0);
      }
      return true;
    },
    async startSolving() {

      const cc: CalculationConfig = this.calculationConfig
      console.log("STARTING TO SOLVE");
      this.$emit("optimization-started", this.solveProcessId);
      const solveRequest = JSON.parse(JSON.stringify(this.solveRequest));
      // this.solveProcessId = await this.backendInterface.sara_Solve(solveRequest);
      this.solveProcessId = await cc.fetchSolve(solveRequest);
    },
    async interruptSolving() {
      this.solvingInterruptedByUser = true
      console.log("interrupt " + this.modelType + ": solveProcessId " + this.solveProcessId);
      await this.backendInterface.stopSolving(this.modelType, this.solveProcessId);
    },
    async getLastSolution() {
      try {
        const lastSolution = await this.calculationConfig.fetchLastSolution?.(this.solveProcessId);
        if(lastSolution != null && this.modelType === CoffeeProductionModelType.DORI){
          this.ganttConfigRoasterTask = createGanttChartConfig_RoasterTask(lastSolution)
          this.ganttStateRoasterTask = new GanttChartState(this.ganttConfigRoasterTask)
        }
      } catch (e) {
        console.error("Error fetching last solution", e);
      }
    },
  },
});
