
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import UserSettingsAccessOptions from "@/components/settings/UserSettingsAccessOptions.vue";
import {UserAccessType} from "@/scripts/models/general";
import {CoffeeProductionModelType, CreateUserSettingsRequest, SolveRequest} from "@/scripts/models/request";
import SettingsListItem from "@/components/settings/UserSettingsListItem.vue";
import {UserSettingsInfo} from "@/scripts/models/results";
import {CoffeeProductionDORISolveRequest} from "@/scripts/models/dori";

export default Vue.extend({
  name: "SaveSettingsDialog",
  components: {SettingsListItem, UserSettingsAccessOptions},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    solveRequest: {
      type: Object as PropType<SolveRequest|CoffeeProductionDORISolveRequest>,
      required: true,
    },
    modelType: {
      type: String as PropType<CoffeeProductionModelType>,
      default: CoffeeProductionModelType.SARA,
    },
  },
  data() {
    return {
      CoffeeProductionModelType,
      showDialog: false,
      settingsName: "",
      settingsToSave: {
        settingsName: "",
        writeAccess: UserAccessType.SUPERUSER,
        readAccess: UserAccessType.SUPERUSER,
      } as any,
      errors: [] as string[],
      writeableSettings: [] as UserSettingsInfo[],
    };
  },
  async created() {
    this.writeableSettings = await this.bi.getWriteableUserSettingInfos(this.modelType)
  },
  watch: {
    value(val) {
      this.showDialog = val;
    },
    "settingsToSave.settingsName": {
      handler: function (val) {
        this.updateInput()
      },
    },
    // solveRequest(val) {
    //   this.settingsToSave.solveRequest = val;
    //   this.settingsToSave.solveRequestId = val.id;
    // }
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
  },
  methods: {
    ...mapMutations([""]),
    getWriteableSetting(settingName: string): UserSettingsInfo | undefined {
      // console.log("writeable settings: ", this.writeableSettings);
      const settingToOverwrite = this.writeableSettings.find((s: UserSettingsInfo) => s.name === settingName)
      return settingToOverwrite
    },
    updateInput(){
      // console.log("update input", this.settingsToSave)
      const writeableSettings = this.getWriteableSetting(this.settingsToSave.settingsName)
      // console.log("found writeable settings: ", writeableSettings, " for name: ", this.settingsToSave.settingsName, " in ", this.writeableSettings, "")
      if(writeableSettings !== undefined){
        // console.log("update input access rights: ", writeableSettings.writeAccess)
        this.settingsToSave.writeAccess = writeableSettings.writeAccess
        this.settingsToSave.readAccess = writeableSettings.readAccess
        // console.log("update input access rights: ", this.settingsToSave)
      }
    },
    async save() {
      // @ts-ignore
      const el = this.$refs.settingsNameInput.$el
      // console.log("NAME ELEMENT" ,el)
      el.blur()
      setTimeout(async () => {
        this.errors = [];
        const s = this.settingsToSave;
        if (s.settingsName === "") {
          this.errors.push("Bitte geben Sie einen Namen für die Einstellungen an.")
          return;
        }
        // set solve request id
        s.solveRequestId = this.solveRequest.id;
        // find if settings with this name already exist
        const settingToOverwrite = this.getWriteableSetting(s.settingsName)
        // update settings
        if (settingToOverwrite !== undefined) {
          console.log("update settings: ", s)
          const res = await this.bi.updateUserSettingsSolveRequest(this.modelType, settingToOverwrite.id, this.solveRequest)
          await this.bi.settingChangeWriteAccess(this.modelType, settingToOverwrite.id, s.writeAccess)
          await this.bi.settingChangeReadAccess(this.modelType, settingToOverwrite.id, s.readAccess)
        }
        // create new settings
        else {
          console.log("create new settings: ", s)
          s.solveRequestId = this.solveRequest.id;
          const res = await this.bi.createUserSettings(this.modelType, s as CreateUserSettingsRequest)
        }
        this.showDialog = false
      }, 0)
      // this.settingsToSave.solveRequest = this.solveRequest;
    }
  },
});
