// store auth token in local storage
import {defaultSpringOptions} from "@/scripts/models/coffee";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import {UserAccessType} from "@/scripts/models/general";

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE"
}

export enum UserRole {
  NONE = "NONE",
  USER = "USER",
  ADMIN = "ADMIN",
  SUPERUSER = "SUPERUSER",
}

// export enum UserAccessType {
//   PRIVATE = "PRIVATE",
//
//   USER = "USER",
//   ADMIN = "ADMIN",
//   SUPERUSER = "SUPERUSER",
//
//   USER_ADMIN = "USER_ADMIN",
//   USER_SUPERUSER = "USER_SUPERUSER",
//   ADMIN_SUPERUSER = "ADMIN_SUPERUSER",
//
//   PUBLIC = "PUBLIC",
// }

export interface UserInfo {
  firstName: string
  familyName: string
  gender: Gender
  email: string
  jobTitle: string
  productionSiteUniqueName: string
  username: string
  enabled: boolean // indicates if the user is enabled
  accountNonExpired: boolean
  accountNonLocked: boolean
  papaUserRole: UserRole
  saraUserRole: UserRole
  doriUserRole: UserRole
  productionSiteAdmin: boolean
  applicationOwner: boolean
  createdAt: string
  resultsPublicByDefault: boolean
  defaultUserSettingsIds: {
    [key in CoffeeProductionModelType]: string
  }
}

export interface UserSettingsMetadataChangeRequest {
  id: string;
  modelType: CoffeeProductionModelType;
  name: string;
  readAccess: UserAccessType;
  writeAccess: UserAccessType;
}

export interface UserDataRequest {
  username: string //required field
  password: string //required field
  email: string //required field
  jobTitle: string
  productionSiteUniqueName: string //required field
  firstName: string //required field
  familyName: string //required field
  gender: Gender
  resultsPublicByDefault?: boolean
  // Florian Spinkler
  // florian.spinkler@gmail.com
}

export interface UserDataAdminRequest {
  username: string
  enabled?: boolean|null
  accountNonLocked?: boolean|null
  accountNonExpired?: boolean|null
  productionSiteAdmin?: boolean|null
  papaUserRole?: UserRole|null
  saraUserRole?: UserRole|null
  doriUserRole?: UserRole|null
}


export interface PasswordResetRequest {
  newPasswordRepeat: string
  newPassword: string
  passwordResetToken: string
}

export interface ChangePasswordRequest {
  newPasswordRepeat: string
  newPassword: string
  oldPassword: string
}
