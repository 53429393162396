
import Vue from "vue";
import { mapState } from "vuex";
import { BackendInterface } from "@/scripts/BackendInterface";
import { SiloAssignmentInputFile, CoffeeProductionInputFileType } from "@/scripts/models/calculation";
import { CoffeeProductionModelType } from "@/scripts/models/request";
import {TimeUnit} from "@/scripts/models/date";
import { ExceptionResponse } from "@/scripts/models/results";

export default Vue.extend({
  name: "FulfilledDemandsUpload",
  props: {
    // dataValidityStartTime: {type: String, required: true},
    // selectedFileName: {type: String},
    items: { type: Array, default: () => [] },
    value: { type: Object },
    date: { type: String },
    modelType: { type: String, default: CoffeeProductionModelType.SARA },
  },
  data() {
    return {
      drag: {
        currentlyDraggingOver: false,
        startTime: 0,
        intervalId: -1,
      },
      fulfilledDemands: {
        file: null as File | null,
        loading: false,
      } as any,
    };
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    v: {
      get(): string {
        return this.value;
      },
      set(v): void {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    async handleFulfilledDemands(event: any) {
      this.fulfilledDemands.loading = true;
      this.fulfilledDemands.file = event.target.files[0];
      this.$forceUpdate();
      setTimeout(async () => {
        await this.uploadFulfilledDemands();
        this.fulfilledDemands.loading = false;
        // @ts-ignore
        this.$refs[`fulfilledDemandsFileInput`].value = "";
        this.$forceUpdate();
      }, 0);
    },
    async uploadFulfilledDemands() {
      this.drag.currentlyDraggingOver = false;
      const productionSiteUniqueName = this.productionSiteUniqueName;
      const file: File = this.fulfilledDemands.file;
      // console.log(this.fulfilledDemands.files)
      // log({ file });
      if (file === undefined) return;
      // do file upload request
      const bi = this.backendInterface as BackendInterface;

      // const date = this.dates[0];
      // const hour = this.fulfilledDemands.time.hour;
      // const minute = this.fulfilledDemands.time.minute;
      // const dataValidityStartTime = `${date}T${hour}:${minute}`;
      // console.log("calculation filename", file.name);
      const dataValidityStartTime = await bi.getFulfilledDemandsValidity(file.name, {
        // productionSiteUniqueName,
        date: this.date,
        modelType: this.modelType as CoffeeProductionModelType,
      });
      // console.log("dataValidityStartTime", dataValidityStartTime);
      // return;

      if (dataValidityStartTime == null || dataValidityStartTime.length > 100) {
        this.$emit(
          "upload-error",
            {
              translatedErrorMessage:
                  `Röstkaffee-Chargierung konnte nicht hochgeladen werden (Fehler: Zeitpunkt der Röstkaffee-Chargierung ist ungültig oder passt nicht zum selektierten Datum).`
            }
        );
        return;
      }

      // get upload id
      const uploadIdResult = await bi.getFileUploadId({
        // productionSiteUniqueName,
        fileType: CoffeeProductionInputFileType.FulfilledDemands,
        dataValidityStartTime, //: "2021-11-08T08:00:00.000Z", // LocalDateTime
        // dataValidityTimeUnit: TimeUnit.SECOND, // TimeUnit,
        modelType: this.modelType as CoffeeProductionModelType,
      });
      console.log({ uploadIdResult });
      if ("error" in uploadIdResult || "errorMessage" in uploadIdResult) {
        this.$emit(
          "upload-error",
            {
              translatedErrorMessage:
                  `Röstkaffee-Chargierung konnte nicht hochgeladen werden (Fehler: Upload ID konnte nicht geladen werden).`
            }
        );
        return;
      }
      const uploadId = uploadIdResult.uploadId;

      // do file upload
      const uploadResult = await bi.uploadFile(uploadId, file);
      this.fulfilledDemands.file = null;

      if ("error" in uploadResult || "errorMessage" in uploadResult) {
        const errorObject = uploadResult as unknown as ExceptionResponse;
        const msg = errorObject.translatedErrorMessage ? 
                errorObject.translatedErrorMessage: errorObject.errorMessage
        this.$emit(
          "upload-error",
            {
              translatedErrorMessage:
                  "Röstkaffee-Chargierung konnte nicht hochgeladen werden (Fehler: " + msg + ")."
            }
        );
        return;
      }

      // await this.updateInputFiles(false);

      this.$emit("upload-success", "Röstkaffee-Chargierung-Datei wurde erfolgreich hochgeladen.");
      // this.datesWithAvailableFulfilledDemands = await bi.fetch_fulfilledDemandsDates(this.productionSiteUniqueName);
    },

    getDisplayFilename(file: SiloAssignmentInputFile | null | undefined) {
      // console.log("getDisplayFilename", file);
      if (file === null || file === undefined) return null;
      // @ts-ignore
      return file.originalFilename || file.orginalFilename || file.baseFilename;
    },
    dragHandler() {
      this.drag.startTime = new Date().getTime();
      this.drag.currentlyDraggingOver = true;
      // console.log("setting startTime");
      if (this.drag.intervalId === -1) {
        this.drag.intervalId = setInterval(() => {
          // console.log("checking time since dragover");
          if (new Date().getTime() - this.drag.startTime > 2000) {
            // console.log("time since dragover too big, resetting");
            this.drag.currentlyDraggingOver = false;
            clearInterval(this.drag.intervalId);
            this.drag.intervalId = -1;
          }
        }, 1000);
      }
    },
  },
});
