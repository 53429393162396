
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {getEnumKeys} from "@/scripts/utils";
import {UserRole} from "@/scripts/auth";
import {UserSettingsInfo} from "@/scripts/models/results";
import {UserAccessType} from "@/scripts/models/general";
interface AccessMap {
  user: boolean,
  admin: boolean,
  superuser: boolean,
}
export default Vue.extend({
  name: "UserSettingsAccessOptions",
  components: {},
  props: {
    userSettingsInfo: {
      type: Object as PropType<UserSettingsInfo>,
      required: true,
    }
  },
  data() {
    return {
      readAccess: {
        user: false,
        admin: false,
        superuser: true,
      },
      writeAccess: {
        user: false,
        admin: false,
        superuser: true,
      },
    };
  },
  created() {
  },
  watch: {
    readAccess: {deep: true, handler: "updateAccess"},
    writeAccess: {deep: true, handler: "updateAccess"},
    userSettingsInfo: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        this.readAccess = this.convertAccessFieldsToAccessMap(newVal.readAccess)
        this.writeAccess = this.convertAccessFieldsToAccessMap(newVal.writeAccess)
        // console.log("readAccess", newVal.readAccess, this.readAccess)
        // console.log("writeAccess", newVal.writeAccess, this.writeAccess)
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    userRoles(): string[] {
      return Object.keys(this.readAccess)
      //return getEnumKeys(UserRole)
    },
  },
  methods: {
    ...mapMutations([""]),
    getDisplayString(userRole: string) {
      return userRole.charAt(0).toUpperCase() + userRole.slice(1)
    },
    updateAccess() {
      for(const key in this.writeAccess){
        if(this.writeAccess[key as keyof AccessMap]){
          this.readAccess[key as keyof AccessMap] = true
        }
      }
      this.userSettingsInfo.readAccess = this.convertAccessMapToAccessFields(this.readAccess)
      this.userSettingsInfo.writeAccess = this.convertAccessMapToAccessFields(this.writeAccess)
      // console.log("\n")
      // console.log("readAccess", this.userSettingsInfo.readAccess)
      // console.log("writeAccess", this.userSettingsInfo.writeAccess)
    },
    convertAccessMapToAccessFields(accessMap: AccessMap){
      const {user, admin, superuser} = accessMap
      if(user && admin && superuser){
        return UserAccessType.PUBLIC
      }
      if(user && admin && !superuser){
        return UserAccessType.USER_ADMIN
      }
      if(user && !admin && superuser){
        return UserAccessType.USER_SUPERUSER
      }
      if(!user && admin && superuser){
        return UserAccessType.ADMIN_SUPERUSER
      }
      if(user && !admin && !superuser){
        return UserAccessType.USER
      }
      if(!user && admin && !superuser){
        return UserAccessType.ADMIN
      }
      if(!user && !admin && superuser){
        return UserAccessType.SUPERUSER
      }
      return UserAccessType.PRIVATE
    },
    convertAccessFieldsToAccessMap(userAccessType: UserAccessType){
      const accessMap: AccessMap = {
        user: false,
        admin: false,
        superuser: false,
      }
      switch (userAccessType) {
        case UserAccessType.PUBLIC:
          accessMap.user = true
          accessMap.admin = true
          accessMap.superuser = true
          break
        case UserAccessType.USER_ADMIN:
          accessMap.user = true
          accessMap.admin = true
          break
        case UserAccessType.USER_SUPERUSER:
          accessMap.user = true
          accessMap.superuser = true
          break
        case UserAccessType.ADMIN_SUPERUSER:
          accessMap.admin = true
          accessMap.superuser = true
          break
        case UserAccessType.USER:
          accessMap.user = true
          break
        case UserAccessType.ADMIN:
          accessMap.admin = true
          break
        case UserAccessType.SUPERUSER:
          accessMap.superuser = true
          break
        case UserAccessType.PRIVATE:
          break
      }
      return accessMap
    },
  },
});
