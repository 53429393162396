import {CoffeeSiloAllocation} from "@/scripts/models/coffee";
import {DayOfWeekDate} from "@/scripts/models/date";
import {SolutionQuality} from "@/scripts/models/results";

export interface SiloAllocationState {
  timestamp: number // Instant
  dayOfWeekDate: DayOfWeekDate
  saraPlanId: string //String
  doriPlanId: string //String
  doriResult: any //SolverResult with solution quality etc.
  siloAllocations: CoffeeSiloAllocation[]
  doriSolutionQuality: SolutionQuality
}


export class SiloAllocationStateAnalysis {
  constructor(public siloAllocationStates: SiloAllocationState[]) {}

  getStates(){
    return this.siloAllocationStates
  }

  getStatesAfter(state: SiloAllocationState){
    const baseTime = new Date(state.timestamp).getTime()
    return this.siloAllocationStates
      .filter(s => new Date(s.timestamp).getTime() > baseTime)
  }

  calculateAllocationDeltas(state: SiloAllocationState){
    let otherStates = this.siloAllocationStates.filter(s => s.saraPlanId !== state.saraPlanId)
    // optional: look only at states that come later
    const baseTime = new Date(state.timestamp).getTime()
    otherStates = otherStates.filter(s => new Date(s.timestamp).getTime() > baseTime)
    otherStates.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())

    // const deltas = Object.entries(otherStates).map(s => this.getSiloAllocationDelta(state, s))
    const res = []
    for(const s of otherStates){
      res.push({
        state: s,
        delta: this.getSiloAllocationDelta(state, s)
      })
    }
    return res

  }

  isAllocated(s: CoffeeSiloAllocation){
    return s.storedBasketSpsNr !== null
  }

  isAllocatedToSame(a: CoffeeSiloAllocation, b: CoffeeSiloAllocation) {
    return this.isAllocated(a) && this.isAllocated(b) && a.storedBasketSpsNr === b.storedBasketSpsNr
  }

  findSAByBasketSpsNr(s: CoffeeSiloAllocation[], basketSpsNr: number): CoffeeSiloAllocation|undefined {
    return s.find(a => a.storedBasketSpsNr === basketSpsNr)
  }

  findSABySiloSpsNr(s: CoffeeSiloAllocation[], siloSpsNr: number): CoffeeSiloAllocation|undefined {
    return s.find(a => a.siloSpsNr === siloSpsNr)
  }

  getSingleSiloAllocationDelta(a: SiloAllocationState, b: SiloAllocationState, siloSpsNr: number){
    const saA = this.findSABySiloSpsNr(a.siloAllocations, siloSpsNr)
    const saB = this.findSABySiloSpsNr(b.siloAllocations, siloSpsNr)
    if(saA === undefined || saB === undefined){
      return 0
    }
    if(this.isAllocatedToSame(saA, saB)) return 0
    else return 1
  }

  getSiloAllocationDelta(a: SiloAllocationState, b: SiloAllocationState){
    const allSiloSpsNrs = new Set([
      ...a.siloAllocations.map(s => s.siloSpsNr), 
      ...b.siloAllocations.map(s => s.siloSpsNr)])
    let delta = 0
    for(const siloSpsNr of allSiloSpsNrs){
      delta += this.getSingleSiloAllocationDelta(a, b, siloSpsNr)
    }

    // console.log(delta);
    return delta
  }

}


export interface SolverOutputLineCplex {
  solutionFound: boolean //boolean;
  node: number //int;
  left: number //int;
  objective: number //double;
  iinf: number //int;
  bestInteger: number //double;
  bestBound: number //double;
  itCnt: number //double;
  relativeMipGap: number //double;
  elapsedTimeEstimate: number //double;
}

