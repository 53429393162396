
import Vue from "vue";
// @ts-ignore
import Plotly from "plotly.js-dist";
// @ts-ignore
// import siloAssignmentSolutionData from "@/data/data_solutions_solution_bn22_sn36_tn6_objMinSiloAllocAndScaleGrBalS.json";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import DashboardElement from "@/components/common/DashboardElement.vue";
import {CoffeeProductionBasketWeekdayPlan} from "@/scripts/models/results";
import {Palette} from "@/scripts/models/general";
import {WEEKDAYS, weekdayToTimestepMap} from "@/scripts/models/date";

const round = (p: number) => (v: number) => Math.round((v ?? 0) * 10 ** p) / 10 ** p;
const format = (v: number) => `${round(4)(v)} t`;

export default Vue.extend({
  name: "AnalyzeProductionAndDemand",
  props: {
    saraSummary: {
      type: Object,
      required: true,
    },
  },
  components: {
    DashboardElement,
  },
  data() {
    return {
      saraSummary_: {} as CoffeeProductionSARASummary,
      silosPerBasket: [] as any[],
    };
  },
  created() {
    this.saraSummary_ = this.saraSummary;
  },
  mounted() {
    this.plotDpTableCharts();
  },
  watch: {},
  computed: {
    dpTableHeaders(): any {
      const saraSummary = this.saraSummary_ as CoffeeProductionSARASummary
      return [
        {text: "Basket SPS Nr", value: "Basket SPS Nr"},
        ...saraSummary.shiftedWeekdays.map((w) => ({text: w, value: w})),
        {text: "Summe", value: "Total"},
      ];
    },
    demandTableItems(): any {
      if (!this.saraSummary) return [];
      return this.getTableRows("demand");
    },
    productionTableItems(): any {
      if (!this.saraSummary) return [];
      return this.getTableRows("production");
    },
  },
  methods: {
    plotDpTableCharts() {
      this.plotDpTableChart("demand");
      this.plotDpTableChart("production");
    },
    getTableRows(key: string) {
      // key can either be "demand" or "production"
      const saraSummary = this.saraSummary_ as CoffeeProductionSARASummary
      const rows = [];
      const r = round(2);
      const unique = (val: any, pos: number, arr: any[]) => arr.indexOf(val) === pos;
      const basketSpsNrs = saraSummary.results.basketWeekdayResults.map((bwr) => bwr.basketSpsNr).filter(unique);
      for (const basketSpsNr of basketSpsNrs) {
        let rowTotal = 0;
        const rowEntries: any = [["Basket SPS Nr", basketSpsNr]];
        const bwrs = saraSummary.results.basketWeekdayResults.filter((bwr) => +bwr.basketSpsNr === +basketSpsNr);
        for (const bwr of bwrs) {
          const dayOfWeek = WEEKDAYS[weekdayToTimestepMap[bwr.dayOfWeek]];
          if (!!dayOfWeek) {
            const value = bwr[key as keyof CoffeeProductionBasketWeekdayPlan] as number;
            rowEntries.push([dayOfWeek, r(value)]);
            rowTotal += value;
          }
        }
        rowEntries.push(["Total", r(rowTotal)]);
        rows.push(Object.fromEntries(rowEntries));
      }
      // compute total
      const rowSum: { [key: string]: number | string } = {};
      for (const row of rows) {
        for (const key in row) {
          if (!(key in rowSum)) rowSum[key] = 0.0;
          rowSum[key] += row[key];
        }
      }
      for (const key in rowSum) {
        rowSum[key] = r(rowSum[key] as number);
      }

      rowSum["Basket SPS Nr"] = "Summe";
      rows.push(rowSum);
      // log({rows});
      return rows;
    },
    plotDpTableChart(mode: string) {
      let domId;
      let data: any = [];
      if (mode == "demand") {
        domId = "demand_table_plot";
        data = this.demandTableItems;
      } else {
        domId = "production_table_plot";
        data = this.productionTableItems;
      }
      if (!document.querySelector("#" + domId)) {
        console.log("no dom element found", domId);
        return;
      }
      const x = Object.keys(data[0]).slice(1, -1);
      const traces = data
          .filter((d: any) => d["Basket SPS Nr"] != "Summe")
          .map((d: any, i: number) => {
            return {
              name: `${Object.values(d)[0]}`,
              x: x,
              y: Object.values(d).slice(1, -1),
              type: "bar",
              marker: {color: Palette[i]},
            };
          });

      const layout = {
        showlegend: false,
        barmode: "stack",
        yaxis: {
          ticksuffix: " t",
        },
        height: 250,
        margin: {
          t: 0,
        },
      };
      console.log("traces pd", traces)
      Plotly.newPlot(domId, traces, layout, {displayModeBar: false});
    },
  },
});
