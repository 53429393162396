
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import SolutionItem from "./SolutionItem.vue";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import {AvailabilityStatus, SummaryMetadata} from "@/scripts/models/results";
import {UserInfo, UserRole} from "@/scripts/auth";
// import {UserType} from "@/scripts/models/general";

export default Vue.extend({
  name: "SolutionSelection",
  components: {SolutionItem},
  props: {
    value: {required: false}, // null or sara summary
    label: {type: String as PropType<string>, default: "Solution"},
    modelType: {type: String as PropType<CoffeeProductionModelType>, default: CoffeeProductionModelType.SARA},
    solutions: {type: Array as PropType<any[]>, required: true},
    autoSelectFirst: {type: Boolean as PropType<boolean>, default: false},
  },
  data() {
    return {
      selectedSolution: null as any,
      metadataMap: {} as Record<string, SummaryMetadata>,
      items: [] as Array<{ summary: any, id: string, disabled: boolean, metadata: SummaryMetadata }>
    };
  },
  created() {
  },
  watch: {
    userType: "updateItems",
    value: {
      immediate: true,
      handler(v) {
        // console.log("value changed", v);
        this.selectedSolution = v;
      },
    },
    solutions: {
      immediate: true,
      async handler() {
        // console.log("solutions changed");
        if(this.solutions.length === 0){
          this.items = []
        }
        if (this.solutions.length > 0 && this.autoSelectFirst) {
          await this.loadSummaryMetadataMap(this.solutions)
          this.updateItems()
          // console.log("Selecting first");

          this.$forceUpdate();

          // this.selectedSolution = this.solutions[0];
          //.filter(i => i.metadata.availabilityStatus !== AvailabilityStatus.DELETED)
        }
        // @ts-ignore
        const passedSolutionId = this.value?.id
        if(this.value !== null && this.solutions.find(s => s.id === passedSolutionId) !== undefined){
          this.selectedSolution = this.value
        }
      },
    },
    selectedSolution(v) {
      // console.log("selected", v);
      this.$emit("selected-solution", v);
      this.$emit("selected-solution-id", v.id);
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    modelUserRole(): UserRole {
      const modelType = this.modelType as CoffeeProductionModelType;
      const user = this.user as UserInfo;
      if(modelType === CoffeeProductionModelType.DORI) {
        return user?.doriUserRole ?? UserRole.NONE;
      }
      if(modelType === CoffeeProductionModelType.PAPA) {
        return user?.papaUserRole ?? UserRole.NONE;
      }
      if(modelType === CoffeeProductionModelType.SARA) {
        return user?.saraUserRole ?? UserRole.NONE;
      }
      return UserRole.NONE;
    },
  },
  methods: {
    ...mapMutations([""]),
    updateItems() {
      this.items = this.solutions.map(summary => {
        const metadata = this.metadataMap[summary.id] ?? {}
        const disabled = metadata.availabilityStatus === AvailabilityStatus.DELETED
        const hidden = disabled && this.modelUserRole === UserRole.USER
        return {
          id: summary.id,
          summary,
          metadata,
          disabled,
          hidden,
        }
      })
          .filter(item => !item.hidden)
      for (const item of this.items.reverse()) {
        if (!item.disabled) this.selectedSolution = item.summary
      }
    },
    async loadSummaryMetadataMap(solutions: any[]): Promise<void> {
      const bi = this.backendInterface as BackendInterface
      const metadata = await bi.listSummaryMetadata({
        summaryIds: solutions.map(s => s.id),
        modelType: this.modelType!,
      })
      for (const m of metadata) this.metadataMap[m.summaryId] = m
    }
  },
});
