
import Vue from "vue";

export default Vue.extend({
  name: "GenericSettings",
  props: {
    propData: {
      required: true,
    },
    settings: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data: function() {
    return {};
  },
  created() {},
  watch: {
    settings: {
      immediate: true,
      handler: function() {
        console.log("settings changed", this.settings)
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    access(parts: string[]) {
      let res: any = this.propData;
      for (const part of parts) {
        res = res[part];
      }
      return res;
    },
    set(parts: string, value: any, type: string = "") {
      let res: any = this.propData;
      const l = parts.length - 1;
      for (let i = 0; i < l; i++) {
        res = res[parts[i]];
        // console.log(`res ${parts[i]}: `, res) // debugging
      }
      res[parts[l]] = type == "number" ? +value : value;
      this.$emit("update")
    },
  },
});
