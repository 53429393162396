
import Vue from "vue";
import GasConsumptionSettings from "@/components/settings/GasConsumptionSettings.vue"
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import {mapState} from "vuex";
import AnalyzeGasConsumptionSection from "@/components/gas/AnalyzeGasConsumptionSection.vue";
import {gasConsumptions} from "@/data/gasConsumptions";
import {
  AnalyzeGasConsumptionSettings,
  calculateGasConsumptionPerBasket,
  GasConsumptionsPerBasket
} from "@/scripts/models/gas";
import GasConsumptionWrapper from "@/components/gas/GasConsumptionWrapper.vue";
import {defaultGasConsumptions} from "@/data/defaultGasConsumptions";
export default Vue.extend({
  name: "GasConsumptionView",
  props: ["data"],
  components: {
    GasConsumptionWrapper,
    AnalyzeGasConsumptionSection,
    GasConsumptionSettings
  },
  data() {
    return {
      tab: null,
      loading: true,
      sas: null as CoffeeProductionSARASummary | null,
      cellplanId: null as null | string,
      // TEMP
      breadcrumbs: [
        // { text: "Produktionsplan", disabled: true, to: "" },
        // { text: "Ansehen", disabled: true },
      ],
      settings: {
        loadedPlans: {rg: null, tas: null},
        maxTotalGasConsumption: 10000,
        gasConsumptionsPerBasket: {} as Record<string, GasConsumptionsPerBasket>,
      } as AnalyzeGasConsumptionSettings,
    };
  },
  created() {
    this.settings.gasConsumptionsPerBasket = calculateGasConsumptionPerBasket(defaultGasConsumptions);
  },
  mounted() {},
  watch: {
    data() {},
  },
  computed: {
    ...mapState(["superuser", "userType", "backendInterface"]),
    pageInfo(): any {
      return {
        "Compliance RG": this.settings.loadedPlans.rg,
        "Compliance TAS": this.settings.loadedPlans.tas,
      }
    }
  },
  methods: {},
});
