
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";

export default Vue.extend({
  name: "ActivateUser",
  components: {},
  props: {
    userActivationToken: {type: String as PropType<string>},
  },
  data() {
    return {
      loading: true,
      userActivationStatus: {
        activated: false,
        message: "",
        emailNotYetVerified: false,
        tokenAlreadyUsed: false,
        error: false,
        notAuthorized: false,
      }
    };
  },
  created() {
    console.log("CREATED")
  },
  watch: {
    userActivationToken: {
      immediate: true,
      async handler(newVal, oldVal) {
        console.log("WATCHER")
        this.loading = true
        const bi = this.backendInterface as BackendInterface;
        await bi.refreshAuthToken(false)
        console.log("is logged in", bi.isLoggedIn());
        if(!bi.isLoggedIn()) {
          this.userActivationStatus.notAuthorized = true;
          this.loading = false
          return;
        }
        bi.activateUser(newVal).then((res: any) => {
          console.log("ActivateUser", res);


          // const stringRes = JSON.stringify(res);
          // this.userActivationStatus.message = JSON.stringify(res);
          if ('errorMessage' in res) {
            this.userActivationStatus.message = res.error;
            const emailNotYetVerified = res.errorMessage.includes("email is not yet verified");
            const tokenAlreadyUsed = res.errorMessage.includes("token already used");
            if (emailNotYetVerified) {
              this.userActivationStatus.emailNotYetVerified = true;
            } else if (tokenAlreadyUsed) {
              this.userActivationStatus.tokenAlreadyUsed = true;
            } else {
              this.userActivationStatus.error = true;
            }
          } else {
            this.userActivationStatus.activated = true;
            this.userActivationStatus.message = "Benutzer wurde freigeschaltet";
          }
          this.loading = false
        });
      },
    }
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
  },
  methods: {
    ...mapMutations([""]),
  },
});
