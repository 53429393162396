import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Page from "@/components/common/Page.vue"
import DashboardElement from "@/components/common/DashboardElement.vue"
import PageActionBar from "@/components/common/PageActionBar.vue"

Vue.config.productionTip = false

Vue.component('Page', Page);
Vue.component('DashboardElement', DashboardElement);
Vue.component('PageActionBar', PageActionBar);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
