
import Vue from "vue";
import {mapState} from "vuex";
import PageTitle from "@/components/common/PageTitle.vue";

export default Vue.extend({
  name: "Page",
  components: {PageTitle},
  props: {
    title: {type: String, required: true},
    breadcrumbs: {type: Array, required: false, default: () => []},
    pageInfo: {type: Object, required: false, default: () => {}},
    loading: {type: Boolean, required: false},
  },
  data() {
    return {};
  },
  async created() {
  },
  async mounted() {
  },
  watch: {},
  computed: {
    ...mapState(["user"]),
  },
  methods: {},
});
