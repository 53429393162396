
import { CoffeeProductionErrorReport } from "@/scripts/models/general";
import { CoffeeProductionModelType, CoffeeProductionSupportRequest } from "@/scripts/models/request";
import { emailValidationRule, emailValid } from "@/scripts/utils";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "Support",
  components: {},
  props: {},
  data() {
    return {
      senderEmailAddress: "",
      sending: false,
      breadcrumbs: [{ text: "Support", disabled: true, to: "" }],
      // selectedEmails: [] as string[],
      showEmailSuccess: false,
      showEmailError: false,
      emails: [
        // "jde@dagopt.com",
        // "spinkler@dagopt.com",
        "andreas.nawrath@jdecoffee.com",
        "carsten.fischer@jdecoffee.com",
        "mustafa.demirkol@jdecoffee.com",
        "rene.koenig@jdecoffee.com",
      ],
      email: {} as CoffeeProductionSupportRequest,
      jdeEmailSuffix: "@jdecoffee.com"
    };
  },
  created() {
    this.initEmail();
    // this.selectedEmails = [...this.emails];
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    // href(): string {
    //   return "mailto:" + this.selectedEmails.join(",");
    // },
  },
  methods: {
    ...mapMutations([""]),
    emailValidationRule,
    emailValid,
    initEmail() {
      this.email = {
        // userType: UserType.SUPERUSER,
        senderEmailAddress: "",
        ccEmails: [],
        topic: "",
        htmlMessage: "",
      } as CoffeeProductionSupportRequest;
    },
    // copyEmails() {
    //   navigator.clipboard.writeText(this.selectedEmails.join(","));
    // },
    async sendMail() {
      // const canSendEmail = emailValid(this.senderEmailAddress);
      const canSendEmail = true
      if(canSendEmail) {
        this.sending = true;
        // this.email.senderEmailAddress = this.senderEmailAddress.trim() + this.jdeEmailSuffix;
        const res = await this.backendInterface.sendSupportRequest(this.email);
        // const res = ":"
        console.log("email response:", res);
        if (res === "Support request e-mail sent") {
          this.showEmailSuccess = true;
          this.initEmail();
        } else {
          this.showEmailError = true;
        }
        this.sending = false;
      }
    },
  },
});
