const translations = {
  "Silo Sps Nr": "Silo SPS",
  "Silo Inflow": "Röstung (t)",
  "Silo Outflow": "Verpackung (t)",
  "Silo Capacity": "Silokapazität (t)",
  "Stored At Day Start Raw": "Silozellen: Anfangsbestand (t)",
  "Stored At Day End Raw": "Silozellen: Endbestand (t)",
  "Total Silo Outflow Raw": "Silozellen: Ausfluss (t)",
  "Total Silo Inflow Raw": "Silozellen: Einfluss (t)",

  "Available Fulfilled Demand": "Chargierte Mengen: Anfangsbestand (t)",
  "Used Fulfilled Demand": "Chargierte Mengen: Verbrauch (t)",
  "Remaining Fulfilled Demand": "Chargierte Mengen: Endbestand (t)",

  "Stored At Day Start": "Anfangsbestand (Silo+Chargiert) (t)",
  "Stored At Day End": "Endbestand (Silo+Chargiert) (t)",
  "Stored At Day Start Silo": "Anfangsbestand (t)",
  "Stored At Day End Silo": "Endbestand (t)",
  "Storage Utilization At Day End": "Füllstand zu Tagesende (%)",
  "Basket Min Curing Time": "Basket Lagerzeit: Min (h)",
  "Basket Max Curing Time": "Basket Lagerzeit: Max (h)",
  "Silo Scale Group": "Waagengruppe",
  "Total Basket Production": "Gesamte Röstung für Basket",
  "Total Basket Demand": "Gesamte Verpackung für Basket",
  "Timestep": "Zeitschritt",
  "Start Of Week Silo Allocation": "Gelagert zu Beginn der Woche (t)",
  "Stored At Day Start (density adjusted)": "Anfangsbestand (mit Dichtenumrechnung) (t)",

  "Inflow Locked": "Silo Einfluss Blockiert",
  "Outflow Locked": "Silo Ausfluss Blockiert",

  "Fulfilled Demand": "Chargierte Menge (t)",
  // "Used Fulfilled Demand": "Benutzte Chargierte Menge (t)",
  // "Remaining Fulfilled Demand": "Verbleibende Chargierte Menge (t)",
  "Weekday Totals:": "Zusammenfassung Wochentag",
  "Basket Sps Nr": "Basket SPS",
  "Basket Production": "Röstung (t)",
  "Demand": "Verpackung (t)",
  "Demand Deviation": "Verpackung: Abweichung (t)",
  "Preproduction": "Vorröstung Folgetag (t)",
  "Preproduction Deviation": "Vorröstung Folgetag: Abweichung (t)",
  "Next Week Preroasting": "Vorröstung Nächste Woche (t)",
  "Next Week Preroasting Deviation": "Vorröstung Nächste Woche: Abweichung (t)",
  "Total Silo Inflow": "Silozellen: Einfluss (t)",
  "Total Silo Outflow": "Verpackung (SARA) (t)",
  "Basket Demand Raw": "Verpackung (Compliance) (t)",
  "Basket Demand": "Verpackung (Compliance korrigiert) (t)",
  "Inflow - Outflow": "Röstung - Verpackung",
  "Demand - Outflow": "Verpackung (Compliance) - Verpackung",
  "Total Silo Capacity": "Silozellen: Reservierte Kapazität (t)",
  "Free At Day Start": "Frei zu Beginn des Tages (t)",
  "Free At Day End": "Frei am Ende des Tages (t)",
  "Scale Group Imbalance": "Waagengruppen-Balance",


  "Is Decaf": "Decaf",
  "Caf Type": "Kaffeetyp",
  "Preroasting Duration h": "Vorröstzeit (h)",
  "Min Curing Duration h": "Lagerzeit min (h)",
  "Max Curing Duration h": "Lagerzeit max (h)",
  "Density Weight Scaling Factor": "Massenumrechnungsfaktor",

  "none": "-",
  "storage": "Endbestand (t)",
  "inflow": "Röstung (t)",
  "outflow": "Verpackung (t)",
  "scaleGroup": "Waagengruppe",

  "Silos Allocated": "Reservierte Silozellen",
  "Silos Total": "Silozellen gesamt",
  "Capacity Used At End Of Day": "Belegte Silokapazität zu Tagesende (t)",
  "Capacity Allocated": "Reservierte Silokapazität (t)",
  "Capacity Total": "Gesamte Silokapazität (t)",
  "Free Silos": "Freie Silozellen",
  "Silo Inflows Blocked": "Einfluss-Blockierte Silozellen",
  "Silo Outflows Blocked": "Ausfluss-Blockierte Silozellen",

  "InfeasibleProblem": "Keine Lösung",
  "SolverError": "Fehler",


  // Gantt Chart

  "articleNr": "Artikel",
  "blendSpsNr": "Sorte",
  "roasterName": "Röster",
  "basketSpsNr": "Basket",
  "packagingLineNr": "Linie",


  "millNr": "Mühle",




}

export default translations;