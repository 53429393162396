
import { CoffeeBasket } from "@/scripts/models/coffee";
import { anyNull } from "@/scripts/utils";
import Vue, {PropType} from "vue";
import { mapMutations, mapState } from "vuex";
import FileUpload from "@/components/common/FileUpload.vue";
import { CoffeeArticlePriorityItem } from "@/scripts/models/gas";
// import {PropType} from "vue/types/v3-component-props";
// @ts-ignore
// import testBaskets from "@/data/articlePriorities.json";
// import { SolveRequest } from "@/scripts/models/request";
// import data from "@/data/solution_bn5_sn37_d6.json";

export default Vue.extend({
  name: "ArticlePrioritySettings",
  props: {
    value: { type: Array, required: true }, // CoffeeArticlePriorityItem[]
    // articles: { type: Array, required: true },
    autosave: { type: Boolean as PropType<boolean>, required: false, default: false },
    readonly: { type: Boolean as PropType<boolean>, required: false, default: false },
  },

  components: {},
  data: function() {
    return {
      articlePriorities_: [] as CoffeeArticlePriorityItem[],
      search: "",
      headers: [
        { text: "Artikelnummer", value: "articleNr" },
        { text: "Priorität", value: "productionTimePriorityFactor" },
        { text: "Strafe positive Abweichung", value: "productionDeviationPricePlus" },
        { text: "Strafe negative Abweichung", value: "productionDeviationPriceMinus" },
      ],
    };
  },
  created() {
    // this.init();
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value !== null) {
          this.articlePriorities_ = JSON.parse(JSON.stringify(this.value)) as CoffeeArticlePriorityItem[];
        } else {
          this.articlePriorities_ = [];
        }
      },
    },
  },
  computed: {
    ...mapState(["backendUrl", "superuser", "availableSolveRequest", "selectedSolveRequest", "availableBackends"]),
  },
  methods: {
    ...mapMutations(["selectWeekData", "selectSolveRequest", "selectBackend"]),
    save() {
      this.$emit("input", this.articlePriorities_);
    },
    inputHandler() {
      if(this.autosave) this.save();
    },
  },
});
