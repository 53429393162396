
import Vue from "vue";
// @ts-ignore
import Plotly from "plotly.js-dist";
import {formatSeconds, round} from "@/scripts/utils";

import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import SolutionTable from "@/components/cellplan/SolutionTable.vue";
import AnalyzeProductionAndDemand from "@/components/cellplan/AnalyzeProductionAndDemand.vue";
// @ts-ignore
import data from "@/data/data_solutions_solution_bn22_sn35_tn6_objMinSiloAllocAndScaleGrBalS.json";
import {mapState} from "vuex";
// import { UserType } from "@/scripts/models/general";
import DashboardElement from "@/components/common/DashboardElement.vue";
import SolutionDayView from "@/components/cellplan/SolutionDayView.vue";
import RoasterTables from "@/components/settings/RoasterTables.vue";
import RoasterAvailabilitySettings from "@/components/settings/RoasterAvailabilitySettings.vue";
import {CoffeeProductionModelSolutionStatus,} from "@/scripts/models/results";
import OptimizationSettings from "@/components/settings/OptimizationSettings.vue";
import SaraSettingsSection from "@/components/calculation/SaraSettingsSection.vue";
import WeekdayTable from "@/components/WeekdayTable.vue";
import Legend from "@/components/common/Legend.vue";
import PreroastingView from "@/components/cellplan/PreroastingView.vue";
import {UserInfo, UserRole} from "@/scripts/auth";

const format = (v: number) => `${round(4)(v)} t`;

export default Vue.extend({
  name: "SaraSummary",
  props: ["data"],
  components: {
    PreroastingView,
    WeekdayTable,
    SolutionTable,
    AnalyzeProductionAndDemand,
    DashboardElement,
    SolutionDayView,
    RoasterTables,
    RoasterAvailabilitySettings,
    OptimizationSettings,
    SaraSettingsSection,
    Legend,
  },
  data() {
    return {
      // UserType,
      CoffeeProductionModelSolutionStatus: CoffeeProductionModelSolutionStatus,
      saraSummary: {} as CoffeeProductionSARASummary,
      objectiveText: "",
      solutionTimeString: "",
      silosPerBasket: [] as any[],
      hideEmptyRows: true,
      basketChartPlotted: false,
      siloChartPlotted: false,
      tab: null,
      roasterTab: null,
      highlight: {
        utilization: false,
        roaster: false,
        scaleGroup: false,
      },

      //showPreRoasting: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  watch: {
    data() {
      this.init();
    },
  },
  computed: {
    ...mapState(["superuser", "user", "backendInterface", "productionSiteUniqueName"]),
    showPreroastingTab(): boolean {
      return this.saraSummary.preroastForNextWeek && this.saraSummary.results.basketWeekdayPreroastingResults !== null;
    },
    isSaraSuperuser(): boolean {
      return (this.user as UserInfo)?.saraUserRole === UserRole.SUPERUSER
    },
  },
  methods: {
    getWeekdayTableItemStyle(item: any) {
      return {
        fontWeight: Math.abs(item.preroastingDeviation) > 0.0001 ? "bold" : "medium",
        color:
          item.preroastingDeviation > 0.0001
            ? "cornflowerblue"
            : item.preroastingDeviation < -0.0001
            ? "orangered"
            : "inherit",
      };
    },
    init(): void {
      this.saraSummary = new CoffeeProductionSARASummary(this.data);
      // console.log("SiloAssignmentSolution", this.saraSummary);
      this.setObjectiveText();
      this.solutionTimeString = formatSeconds(this.saraSummary.results.solutionTimeSec);
    },
    reload() {
      window.location.reload();
    },

    setObjectiveText(): void {
      if (!this.saraSummary.solverParameters.objectives || !this.saraSummary.solverParameters.objectives.length) {
        // console.log("No saraSummary.objectives: ", this.saraSummary.solverParameters.objectives);
        return;
      }
      const n = this.saraSummary.solverParameters.objectives.length;
      const times = ` \u00B7 `;
      const plus = ` + `;
      // this.objectiveText = "";
      let objectiveText = "";
      let addParentheses: boolean;
      let val = 0;
      for (let i = 0; i < n; i++) {
        const obj = this.saraSummary.solverParameters.objectives[i];
        const weight = this.saraSummary.solverParameters.objectiveWeights[i];
        // sum
        if (i != 0) objectiveText += plus;
        // add weight multiplier
        if (weight != 1) objectiveText += weight + times;
        // objective name and value
        const inner = `"${obj.displayName}" = ${obj.yVal}`;
        // add parenthesis if weight is not 1 or it is not the first entry
        addParentheses = weight != 1 || i != 0;
        objectiveText += addParentheses ? `(${inner})` : inner;
        // update objective value
        val += weight * obj.yVal;
      }
      if (n > 1) {
        objectiveText = `${val} = ${objectiveText}`;
      }
      this.objectiveText = objectiveText;
    },

    siloCellClickHandler(basketSpsNr: number, siloSpsNr: number): void {
      const x = this.saraSummary.shiftedWeekdays;
      const siloInflows: number[] = [];
      const siloOutflows: number[] = [];
      const siloStored: number[] = [];
      const usableCapacity = this.saraSummary.siloAssignment.initialSiloAllocationMap[siloSpsNr].usableCapacity_t;
      for (let weekday = 0; weekday < x.length; weekday++) {
        const dayOfWeek = this.saraSummary.getWeekdayFromTimestep(weekday);
        const bwsr = this.saraSummary.findCoffeeProductionBasketWeekdaySiloPlan(basketSpsNr, dayOfWeek, siloSpsNr);
        siloInflows[weekday] = bwsr?.inflow ?? 0;
        siloOutflows[weekday] = bwsr?.outflow ?? 0;
        siloStored[weekday] = bwsr?.storedAtDayEnd ?? 0;
      }

      const barWidth = 0.2;
      const traces = [
        {
          name: "Einfluss",
          x: x,
          y: siloInflows,
          type: "bar",
          width: barWidth,
          marker: { color: "#64de69" },
        },
        {
          name: "Ausfluss",
          x: x,
          y: siloOutflows,
          line: { shape: "linear" },
          type: "bar",
          width: barWidth,
          marker: { color: "#de5964" },
        },
        {
          name: "Gelagert am Ende des Tages",
          x: x,
          y: siloStored,
          line: {
            shape: "hv",
            width: 4,
            color: "#888",
            dash: "dash",
          },
          type: "scatter",
          mode: "lines",
          ticksuffix: "t",
        },
        {
          name: "Kapazität",
          x: x,
          y: x.map((d) => usableCapacity),
          line: { shape: "linear", width: 1, color: "black", dash: "line" },
          type: "scatter",
          mode: "lines",
        },
      ];

      const layout = {
        title: `Basket ${basketSpsNr}, Silo ${siloSpsNr}`,
        bargap: 0.5,
        height: 350,
        yaxis: {
          ticksuffix: " t",
        },
        margin: {
          l: 30,
          r: 30,
          b: 20,
          t: 40,
        },
        showlegend: true,
        legend: { orientation: "h" },
      };
      this.siloChartPlotted = true;
      setTimeout(() => Plotly.newPlot("silo_plot", traces, layout, { displayModeBar: false }), 0);
    },

    basketCellClickHandler(basketSpsNr: number, basketPlotOnly = false): void {
      if (basketPlotOnly) {
        this.siloChartPlotted = false;
      }
      const x = ["Vorgeröstet"].concat(this.saraSummary.shiftedWeekdays);
      const dayOfWeek = this.saraSummary.getWeekdayFromTimestep(0);
      const bwr0 = this.saraSummary.findCoffeeProductionBasketWeekdayPlan(basketSpsNr, dayOfWeek);
      const productions: number[] = [bwr0?.storedAtDayStart ?? 0];
      const demands: number[] = [0];
      const reservedBaseCapacities: number[] = [bwr0?.reservedBaseCapacity ?? 0];
      // console.log(productions, demands);
      for (let weekday = 0; weekday < x.length; weekday++) {
        const dayOfWeek = this.saraSummary.getWeekdayFromTimestep(weekday);
        const bwr = this.saraSummary.findCoffeeProductionBasketWeekdayPlan(basketSpsNr, dayOfWeek);
        productions[weekday + 1] = bwr?.production ?? 0;
        demands[weekday + 1] = bwr?.demand ?? 0;
        reservedBaseCapacities[weekday + 1] = bwr?.reservedBaseCapacity ?? 0;
      }
      const barWidth = 0.2;
      const traces = [
        {
          name: "Röstung",
          x: x,
          y: productions,
          line: { shape: "linear" },
          type: "bar",
          width: barWidth,
          marker: { color: "#64a9fe" },
        },
        {
          name: "Verpackung",
          x: x,
          y: demands,
          type: "bar",
          width: barWidth,
          marker: { color: "#de5964" },
        },
        {
          name: "Reservierte Kapazität",
          x: x,
          y: reservedBaseCapacities,
          line: { shape: "hv", width: 1, color: "black", dash: "line" },
          type: "scatter",
          mode: "lines",
        },
      ];

      const layout = {
        title: `Basket ${basketSpsNr}`,
        bargap: 0.5,
        height: 350,
        yaxis: {
          ticksuffix: " t",
          // range: [0, 25],
        },
        margin: {
          l: 30,
          r: 30,
          b: 20,
          t: 40,
        },
        showlegend: true,
        legend: { orientation: "h" },
      };
      this.basketChartPlotted = true;
      this.$forceUpdate();
      Plotly.newPlot("basket_plot", traces, layout, { displayModeBar: false });
    },
  },
});
