
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import {CoffeeRoaster, CoffeeRoasterAvailability} from "@/scripts/models/coffee";
import {WEEKDAYS} from "@/scripts/models/date";

export default Vue.extend({
  name: "RoasterAvailabilitySettings",
  components: {},
  props: {
    value: {type: Array, required: true}, //RoasterAvailability[]
    autosave: {type: Boolean, default: true},
    readonly: {type: Boolean, default: false},
  },
  data() {
    return {
      weekdays: "Mo Di Mi Do Fr Sa So".split(" "),
      roasterAvailabilities: [] as CoffeeRoasterAvailability[],
      defaultValues: null as any,
    };
  },
  created() {
    this.init();
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        this.roasterAvailabilities = JSON.parse(JSON.stringify(v));
        if (this.defaultValues === null) {
          this.defaultValues = JSON.parse(JSON.stringify(v));
        }
      },
    },
  },
  computed: {
    ...mapState(["superuser", "user", "backendInterface"]),
    sortedRoasterAvailabilities(): CoffeeRoasterAvailability[] {
      const s = [...this.roasterAvailabilities];
      s.sort((a, b) => (a.roasterName < b.roasterName ? -1 : 1));
      return s;
    },
  },
  methods: {
    ...mapMutations(["toggleSuperuser"]),
    init() {
      if (this.value !== null) {
        this.roasterAvailabilities = JSON.parse(JSON.stringify(this.value)) as CoffeeRoasterAvailability[];
      } else {
        this.roasterAvailabilities = [];
      }
    },
    inputHandler() {
      console.log("RoasterAvailabilitySettings.inputHandler");
      if (this.autosave) this.save();
    },
    save() {
      console.log("RoasterAvailabilitySettings.save");
      this.$emit("input", this.roasterAvailabilities);
    },
    setRoasterAvailabilityToZero(roasterIndex: number) {
      const ra = this.roasterAvailabilities[roasterIndex]
      for (let i = 0; i < ra.weeklyMaxRoastingHoursPerDay[0].length; i++) {
        ra.weeklyMaxRoastingHoursPerDay[0][i] = 0
      }
      this.inputHandler();
    },
    resetRoasterAvailability(roasterIndex: number) {
      const ra = this.roasterAvailabilities[roasterIndex]
      const raDefault = this.defaultValues[roasterIndex]
      for (let i = 0; i < ra.weeklyMaxRoastingHoursPerDay[0].length; i++) {
        ra.weeklyMaxRoastingHoursPerDay[0][i] = raDefault.weeklyMaxRoastingHoursPerDay[0][i]
      }
      this.inputHandler();
    },
    setWeekdayAvailabilityToZero(weekdayIndex: number) {
      for (const ra of this.roasterAvailabilities) {
        ra.weeklyMaxRoastingHoursPerDay[0][weekdayIndex] = 0
      }
      this.inputHandler();
    },
    resetWeekdayAvailability(weekdayIndex: number) {
      for (let r = 0; r < this.roasterAvailabilities.length; r++) {
        const ra = this.roasterAvailabilities[r]
        const raDefault = this.defaultValues[r]
        ra.weeklyMaxRoastingHoursPerDay[0][weekdayIndex] = raDefault.weeklyMaxRoastingHoursPerDay[0][weekdayIndex]
      }
      this.inputHandler();
    },
  },
});
