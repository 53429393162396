
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {createInputFileSummary, formatDate, formatDateShort, WEEKDAY_TRANSLATIONS} from "@/scripts/utils";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import {AvailabilityStatus, CoffeeProductionModelSolutionStatus, SummaryMetadata} from "@/scripts/models/results";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import {BackendInterface} from "@/scripts/BackendInterface";
import translations from "../../data/translations";

export default Vue.extend({
  name: "SolutionItem",
  components: {},
  props: {
    saraSummary: {type: Object as PropType<any>},
    metadata: {type: Object as PropType<SummaryMetadata>},
  },
  data() {
    return {
      CoffeeProductionModelSolutionStatus,
      CoffeeProductionModelType,
      AvailabilityStatus,
      translations,
      compliancePlanNames: {
        rg: null, tas: null
      } as any
    };
  },
  created() {
    // console.log("saraSummary", this.saraSummary)
  },
  watch: {
    saraSummary: {
      immediate: true,
      handler(){
        // console.log("saraSummary", this.saraSummary)
        this.loadCompliancePlanNames()
      }
    }
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    isDeleted(): boolean {
      return this.metadata?.availabilityStatus === AvailabilityStatus.DELETED
    },
  },
  methods: {
    // formatDate,
    formatDateShort,
    ...mapMutations([""]),
    getStartDateString(sas: CoffeeProductionSARASummary): string {
      // @ts-ignore
      const weekdaySlice = WEEKDAY_TRANSLATIONS[sas.timeRange.startDate.dayOfWeek].slice(0, 2);
      if(sas.firstDayStartTime !=null){
        const firstDayDate = new Date(sas.firstDayStartTime);
        const dateString = firstDayDate.toLocaleDateString("de-DE", {day: "2-digit", month: "2-digit", year: "2-digit"});
        const timeString = firstDayDate.toLocaleTimeString("de-DE", {hour: "2-digit", minute: "2-digit"});
        return `${weekdaySlice}, ${dateString}, ${timeString}`;
      } else {
        //fallback for old solution summary versions
        const dateString = new Date(sas.timeRangeStart).toLocaleDateString("de-DE");
        return `${weekdaySlice}, ${dateString}`;
      }
    },
    getEndDateString(sas: CoffeeProductionSARASummary): string {
      // @ts-ignore
      const weekdaySlice = WEEKDAY_TRANSLATIONS[sas.timeRange.endDate.dayOfWeek].slice(0, 2);
      const dateString = new Date(sas.timeRangeEnd).toLocaleDateString("de-DE", {day: "2-digit", month: "2-digit", year: "2-digit"});
      return `${weekdaySlice}, ${dateString}`;
    },
    async loadCompliancePlanNames(){
      // TODO: this only works for sara
      const inputFileIds = this.saraSummary?.inputFileIds ?? []
      if(inputFileIds.length === 0) {
        console.log("no input files")
        return
      }
      const inputFiles = await this.backendInterface.getFilesByIds(this.saraSummary.inputFileIds);
      const inputFileSummary = createInputFileSummary(inputFiles);
      // find compliance rg
      const complianceRG = inputFileSummary.find(ifs => ifs.name === "Compliance RG")
      const complianceTAS = inputFileSummary.find(ifs => ifs.name === "Compliance Tassimo")
      // console.log("complianceRG", complianceRG)
      this.compliancePlanNames = {
        rg: complianceRG?.value,
        tas: complianceTAS?.value
      }
    }
  },
});
