import { StringToNumberMap } from "./models/general";
import { SiloAssignment, SolutionHashes, } from "./models/coffee";
import { SASTableCell } from "./CellplanTable";
import {formatDateShort, getSummaryTitle, toNumber} from "./utils";
import { SolverInfo } from "@/scripts/models/calculation";
import {
    CoffeeProductionBasketPlan,
    CoffeeProductionBasketWeekdayPlan,
    CoffeeProductionBasketWeekdaySiloPlan,
    SiloAssignmentResult,
    CoffeeProductionModelSolutionStatus,
    CoffeeProductionWeekdayPlan,
    SolutionQuality
} from "@/scripts/models/results";
import {
    DayOfWeek,
    DayOfWeekDate,
    Weekday,
    weekdayEnumToGer,
    WEEKDAYS,
    weekdayToTimestepMap
} from "@/scripts/models/date";
import { SolveRequest } from "@/scripts/models/request";


// this weekday handling is a complete mess, but I'm afraid to touch it


/**
 * This is an extension of CoffeeProductionSARASummary.java with additional fields and methods.
 *
 * @export
 * @class SiloAssignmentSolution
 */
export default class CoffeeProductionSARASummary {
    id: string;
    solverParameters: SolverInfo;
    siloAssignment: SiloAssignment;
    results: SiloAssignmentResult;

    solveRequest: SolveRequest;
    creationTime: string;
    timeRangeStart: string;
    timeRangeEnd: string;
    timeRange: {
        startDate: DayOfWeekDate;
        endDate: DayOfWeekDate;
    };
    firstDayStartTime: string
    hashes: SolutionHashes;
    preroastForNextWeek: boolean;
    solutionStatus: CoffeeProductionModelSolutionStatus;
    solutionQuality?: SolutionQuality;
    // userType?: UserType;

    weekdayOffset: number; // monday: 0, tuesday: 1, ...
    shiftedWeekdays: string[];
    solutionJsonString: string;
    weekdayToDayOfWeekNumberMap: StringToNumberMap;
    userId: string
    publicVisible: boolean
    inputFileIds: string[]
    //parent summary id. If not null the computation which resulted in this summary, used 
    //the minimizer stored in the parent summary as the start point for the optimization 
    parentSummaryId: string | null;

    constructor(sas: CoffeeProductionSARASummary) {
        // TODO: should take the json blob from the backend
        // general info
        this.id = sas.id;
        this.solverParameters = sas.solverParameters;
        this.siloAssignment = sas.siloAssignment;
        this.results = sas.results;

        this.solveRequest = sas.solveRequest;
        this.creationTime = sas.creationTime;
        this.timeRangeStart = sas.timeRangeStart;
        this.timeRangeEnd = sas.timeRangeEnd;
        this.timeRange = sas.timeRange;
        this.hashes = sas.hashes;
        this.preroastForNextWeek = sas.preroastForNextWeek;
        this.solutionStatus = sas.solutionStatus;
        this.solutionQuality = sas.solutionQuality;
        // this.userType = sas.userType;
        this.firstDayStartTime = sas.firstDayStartTime;

        // calculated values, not in json
        this.weekdayOffset = weekdayToTimestepMap[this.timeRange.startDate.dayOfWeek];
        this.shiftedWeekdays = WEEKDAYS.slice(this.weekdayOffset);
        this.solutionJsonString = JSON.stringify(sas);
        this.weekdayToDayOfWeekNumberMap = weekdayToTimestepMap;
        this.userId = sas.userId;
        this.publicVisible = sas.publicVisible;
        this.inputFileIds = sas.inputFileIds;
        this.parentSummaryId = sas.parentSummaryId;
    }

    getWeekdayFromTimestep(timestep: number): DayOfWeek {
        //TODO Florian fix this
        const startTimestep = weekdayToTimestepMap[this.timeRange.startDate.dayOfWeek];
        const dayOfWeek = Object.keys(weekdayToTimestepMap)[Math.min(startTimestep + timestep, WEEKDAYS.length)];
        // log({dayOfWeek})
        // @ts-ignore
        return dayOfWeek;
    }

    getSilosForBasketAndTimestep(basketSpsNr: number, timestep: number): number[] {
        const dayOfWeek = this.getWeekdayFromTimestep(timestep);
        const bwr = this.findCoffeeProductionBasketWeekdayPlan(basketSpsNr, dayOfWeek);
        if (!bwr) {
            console.error(
                `getSilosForBasketAndTimestep: No CoffeeProductionBasketWeekdaySiloPlan for {basketSpsNr: ${basketSpsNr}, dayOfWeek: ${dayOfWeek}}`
            );
            return [];
        }
        return bwr.silosAllocated;
    }

    getAllBasketSpsNrs(): number[] {
        return Object.keys(this.siloAssignment.basketMap).map(toNumber);
    }

    findCoffeeProductionBasketWeekdaySiloPlan(
        basketSpsNr: number,
        dayOfWeek: DayOfWeek,
        siloSpsNr: number
    ): CoffeeProductionBasketWeekdaySiloPlan | undefined {
        return this.results.basketWeekdaySiloResults.find(
            (bwsr) => bwsr.basketSpsNr === basketSpsNr && bwsr.siloSpsNr === siloSpsNr && bwsr.dayOfWeek === dayOfWeek
        );
    }

    findCoffeeProductionBasketWeekdayPlan(basketSpsNr: number, dayOfWeek: DayOfWeek): CoffeeProductionBasketWeekdayPlan | undefined {
        return this.results.basketWeekdayResults.find(
            (bwr) => bwr.basketSpsNr === basketSpsNr && bwr.dayOfWeek === dayOfWeek
        );
    }

    findCoffeeProductionBasketPlan(basketSpsNr: number): CoffeeProductionBasketPlan | undefined {
        return this.results.basketResults.find(
            (br) => br.basketSpsNr === basketSpsNr
        );
    }

    findCoffeeProductionWeekdayPlan(dayOfWeek: DayOfWeek): CoffeeProductionWeekdayPlan | undefined {
        return this.results.weekdayResults.find((bwr) => bwr.dayOfWeek === dayOfWeek);
    }

    addDayStartValues(weekdaySiloCells: SASTableCell[][], weekday: number): void {
        const key = "Stored At Day Start (density adjusted)";
        for (const siloTableCell of weekdaySiloCells[weekday]) {
            if (siloTableCell.tableCellInfo) {
                if (weekday == 0) {
                    siloTableCell.tableCellInfo.storedAtDayStart = siloTableCell.tableCellInfo.storedAtDayStart;
                    // siloTableCell.tableCellInfo[key] = siloTableCell.tableCellInfo[key]
                } else {
                    for (const prevSiloTableCell of weekdaySiloCells[weekday - 1]) {
                        if (prevSiloTableCell.value == siloTableCell.value) {
                            // @ts-ignore
                            siloTableCell.tableCellInfo.storedAtDayStart = prevSiloTableCell.tableCellInfo?.storedAtDayEnd ?? 0;
                            break;
                        }
                    }
                }
            }
        }
    }

    isSiloInUse(siloSpsNr: number, basketSpsNr: number, weekday: number): boolean {
        const dayOfWeek = this.getWeekdayFromTimestep(weekday);
        const bwsr = this.findCoffeeProductionBasketWeekdaySiloPlan(basketSpsNr, dayOfWeek, siloSpsNr);
        if (!bwsr) {
            console.error(
                `isSiloInUse: No CoffeeProductionBasketWeekdaySiloPlan for {basketSpsNr: ${basketSpsNr}, dayOfWeek: ${dayOfWeek}, siloSpsNr: ${siloSpsNr}}`
            );
            return false;
        }
        return bwsr.inflow !== 0 || bwsr.outflow !== 0 || bwsr.storedAtDayEnd !== 0;
    }

    getSilos() {
        const results = [];
        for (const siloAllocation of Object.values(this.siloAssignment.initialSiloAllocationMap)) {
            results.push({
                siloSpsNr: siloAllocation.siloSpsNr,
                scaleGroupNr: siloAllocation.scaleGroupNr,
                capacity_t: siloAllocation.usableCapacity_t,
            });
        }
        return results;
    }

    getObjectiveText() {
        if (!this.solverParameters.objectives || !this.solverParameters.objectives.length) {
            console.warn("No sas.objectives: ", this.solverParameters.objectives);
            return "";
        }
        const n = this.solverParameters.objectives.length;
        const times = ` \u00B7 `;
        const plus = ` + `;
        let objectiveText = "";
        let addParentheses: boolean;
        let val = 0;
        for (let i = 0; i < n; i++) {
            const obj = this.solverParameters.objectives[i];
            const weight = this.solverParameters.objectiveWeights[i];
            // sum
            if (i != 0) objectiveText += plus;
            // add weight multiplier
            if (weight != 1) objectiveText += weight + times;
            // objective name and value
            const inner = `"${obj.displayName}" = ${obj.yVal}`;
            // add parenthesis if weight is not 1 or it is not the first entry
            addParentheses = weight != 1 || i != 0;
            objectiveText += addParentheses ? `(${inner})` : inner;
            // update objective value
            val += weight * obj.yVal;
        }
        if (n > 1) {
            objectiveText = `${val} = ${objectiveText}`;
        }
        return objectiveText;
    }

    download() {
        const filename = `zellenplan_kw${this.timeRange.startDate.weekNumber}_${this.shiftedWeekdays[0]}.json`.toLowerCase();
        const text = this.solutionJsonString;
        const element = document.createElement("a");
        element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    getTitle(): string {
        return getSummaryTitle(this)
    }

    getStartDateString() {
        return formatDateShort(this.timeRangeStart).slice(0,8)
        // return new Date(this.timeRangeStart).toLocaleDateString("de-DE");
    }

    getEndDateString() {
        return formatDateShort(this.timeRangeEnd).slice(0,8)
        // return new Date(this.timeRangeEnd).toLocaleDateString("de-DE");
    }
}
