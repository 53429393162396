import {BackendInterface} from "./BackendInterface";
import {CoffeeProductionInputFileType, SiloAssignmentInputFile} from "./models/calculation";
import {CoffeeProductionModelType, FileRequest} from "./models/request";

// File List
export type InputFilesByType = {
  [key in CoffeeProductionInputFileType]: SiloAssignmentInputFile[];
};

export type InputFilesByWeekAndType = Record<string, InputFilesByType>;

// Single File
export type InputFileByType = {
  [key in CoffeeProductionInputFileType]: SiloAssignmentInputFile | null;
};
export type InputFileByWeekAndType = Record<string, InputFileByType>;

export interface InputFileManagerSettings {
  productionSiteUniqueName: string;
  modelType: CoffeeProductionModelType;
}

export class InputFileManager {
  settings: InputFileManagerSettings = {
    productionSiteUniqueName: "JDE_Berlin",
    modelType: CoffeeProductionModelType.SARA,
  };
  backendInterface: BackendInterface;
  availableFiles: InputFilesByWeekAndType | null;
  selectedFiles: InputFileByWeekAndType | null;
  uniqueFileTypes = [
    CoffeeProductionInputFileType.Cellplan,
    CoffeeProductionInputFileType.FulfilledDemands,
    CoffeeProductionInputFileType.Basket,
    CoffeeProductionInputFileType.Roaster,
  ];
  dateRange: [string, string];

  constructor(backendInterface: BackendInterface, settings: InputFileManagerSettings) {
    this.backendInterface = backendInterface;
    this.availableFiles = {};
    this.selectedFiles = {};
    this.dateRange = ["", ""];
    this.settings = {
      ...this.settings,
      ...settings,
    };
  }

  setDateRange(dateRange: [string, string]) {
    this.dateRange = this.getSortedDates(dateRange);
  }

  isFiletypeSelected(fileType: CoffeeProductionInputFileType): boolean {
    if (!this.uniqueFileTypes.includes(fileType)) {
      throw new Error(
        `Provided CoffeeProductionInputFileType can have multiple selected values, which is currently not supported.`
      );
    }
    return (this.selectedFiles?.["-1"]?.[fileType] ?? null) !== null;
  }

  getDisplayFilename(file: SiloAssignmentInputFile | null | undefined) {
    // console.log("getDisplayFilename", file);
    if (file === null || file === undefined) return null;
    // @ts-ignore
    return file.originalFilename || file.orginalFilename || file.baseFilename;
  }

  gatherSelectedInputFileIds(): string[] {
    const selectedInputFileIds: string[] = [];
    for (const week in this.selectedFiles) {
      for (const fileType in CoffeeProductionInputFileType) {
        if (!isNaN(+fileType)) continue;
        const ft = fileType as CoffeeProductionInputFileType;
        const file = this.selectedFiles[week][ft];
        if (file != null) {
          selectedInputFileIds.push(file.id);
        }
      }
    }
    return selectedInputFileIds;
  }

  gatherSelectedComplianceFiles(): SiloAssignmentInputFile[] {
    const selectedInputFiles: SiloAssignmentInputFile[] = [];
    for (const week in this.selectedFiles) {
      for (const fileType in CoffeeProductionInputFileType) {
        if (!isNaN(+fileType)) continue;
        const ft = fileType as CoffeeProductionInputFileType;
        if (
          fileType !== CoffeeProductionInputFileType.ComplianceRg &&
          fileType !== CoffeeProductionInputFileType.ComplianceTas &&
          fileType !== CoffeeProductionInputFileType.ComplianceRgPreroast &&
          fileType !== CoffeeProductionInputFileType.ComplianceTasPreroast
        ) {
          continue;
        }
        const file = this.selectedFiles[week][ft];
        if (file != null) {
          selectedInputFiles.push(file);
        }
      }
    }
    return selectedInputFiles;
  }

  getSortedDates(dateRange: [string, string]): [string, string] {
    // if(this.dateRange === undefined) return [];
    const truncatedDates = dateRange;
    if (truncatedDates.length === 2) {
      let dates = truncatedDates.map((d) => new Date(d));
      if (dates[0].getTime() < dates[1].getTime()) {
        return [truncatedDates[0], truncatedDates[1]];
      } else {
        return [truncatedDates[1], truncatedDates[0]];
      }
    } else {
      throw new Error(`Invalid dateRange: ${dateRange}`);
    }
  }

  async updateInputFiles(keepSelected: boolean = false) {
    const sortedDates = this.dateRange;
    const inputFiles = await this.fetchAllInputFiles(sortedDates);
    const {availableFiles, selectedFiles} = await this.processInputFiles(inputFiles, sortedDates);
    // console.log("updateInputFiles", availableFiles)
    const oldSelectedFiles = this.selectedFiles;
    this.selectedFiles = selectedFiles;
    this.availableFiles = availableFiles;
    if (keepSelected && this.selectedFiles !== null) {
      for (const week in oldSelectedFiles) {
        if (week in this.selectedFiles) {
          for (const fileType in [CoffeeProductionInputFileType.Basket, CoffeeProductionInputFileType.Roaster]) {
            if (!isNaN(+fileType)) continue;
            const ft = fileType as CoffeeProductionInputFileType;
            const oldFile = oldSelectedFiles[week][ft];
            if (oldFile) {
              this.selectedFiles[week][ft] = oldFile;
            }
          }
        }
      }
    }
  }

  async fetchAllInputFiles(sortedDates: [string, string]) {
    const typesToFetch = [
      CoffeeProductionInputFileType.ComplianceRg,
      CoffeeProductionInputFileType.ComplianceTas,
    ]
    if(this.settings.modelType === CoffeeProductionModelType.SARA) {
      typesToFetch.push(
        CoffeeProductionInputFileType.Basket,
        CoffeeProductionInputFileType.Roaster,
      )
    }
    if(this.settings.modelType === CoffeeProductionModelType.PAPA) {
      typesToFetch.push(
        CoffeeProductionInputFileType.Basket,
        CoffeeProductionInputFileType.Roaster,
      )
    }
    return this.fetchInputFiles(sortedDates, typesToFetch);
  }

  async fetchInputFiles(
    sortedDates: [string, string],
    typesToFetch: CoffeeProductionInputFileType[]
  ): Promise<InputFilesByType> {
    const bi = this.backendInterface as BackendInterface;
    const [startDate, endDate] = sortedDates;
    // check if endDate is monday or sunday
    const endDateDay = new Date(endDate).getDay();
    // if (endDateDay === 0) {
    //   console.log("endDate is sunday");
    // } else if (endDateDay === 1) {
    //   console.log("endDate is monday");
    // }
    const isTimeRange = startDate !== endDate;
    const endDateIsMonday = endDateDay === 1
    const preroasting = endDateIsMonday && isTimeRange;

    const getFileRequestForDay = (fileType: CoffeeProductionInputFileType, date: string = startDate): FileRequest => ({
      // productionSiteUniqueName: this.settings.productionSiteUniqueName,
      timeRangeStart: date,
      timeRangeEnd: date,
      fileType: fileType,
      modelType: this.settings.modelType,
    });
    const inputFiles: InputFilesByType = {} as InputFilesByType;
    let fileRequest;
    let modelDateRequest = {
      productionSiteUniqueName: this.settings.productionSiteUniqueName,
      date: startDate,
      modelType: this.settings.modelType,
    };
    // ComplianceRg
    if (typesToFetch.includes(CoffeeProductionInputFileType.ComplianceRg)) {
      const request = getFileRequestForDay(CoffeeProductionInputFileType.ComplianceRg);
      inputFiles.ComplianceRg = await bi.getInputFiles(request)
      if (preroasting) {
        const request = getFileRequestForDay(CoffeeProductionInputFileType.ComplianceRgPreroast, endDate);
        // console.log("NORMAL", compliancePlans)
        // console.log("PREROASTING", compliancePlans2)
        inputFiles.ComplianceRgPreroast = await bi.getInputFiles(request)
      }
      // fileRequest = getFileRequestForDay(CoffeeProductionInputFileType.ComplianceRg);
      // inputFiles.ComplianceRg = await bi.getInputFiles(fileRequest);
    }
    // ComplianceTas
    if (typesToFetch.includes(CoffeeProductionInputFileType.ComplianceTas)) {
      const request = getFileRequestForDay(CoffeeProductionInputFileType.ComplianceTas);
      inputFiles.ComplianceTas = await bi.getInputFiles(request)
      if (preroasting) {
        const request = getFileRequestForDay(CoffeeProductionInputFileType.ComplianceTasPreroast, endDate);
        inputFiles.ComplianceTasPreroast = await bi.getInputFiles(request)
      }
      // fileRequest = getFileRequestForDay(CoffeeProductionInputFileType.ComplianceTas);
      // inputFiles.ComplianceTas = await bi.getInputFiles(fileRequest);
    }
    // Cellplan
    if (typesToFetch.includes(CoffeeProductionInputFileType.Cellplan)) {
      fileRequest = getFileRequestForDay(CoffeeProductionInputFileType.Cellplan);
      inputFiles.Cellplan = await bi.getValidCellplans(fileRequest);
    }
    // FulfilledDemands
    if (typesToFetch.includes(CoffeeProductionInputFileType.FulfilledDemands)) {
      fileRequest = getFileRequestForDay(CoffeeProductionInputFileType.FulfilledDemands);
      inputFiles.FulfilledDemands = await bi.getValidFulfilledDemands(fileRequest);
    }
    // Basket
    if (typesToFetch.includes(CoffeeProductionInputFileType.Basket)) {
      const basket = await bi.getLatestBasket(modelDateRequest);
      inputFiles.Basket = basket !== null ? [basket] : [];
    }
    // Roaster
    if (typesToFetch.includes(CoffeeProductionInputFileType.Roaster)) {
      const roaster = await bi.getLatestRoaster(modelDateRequest);
      inputFiles.Roaster = roaster !== null ? [roaster] : [];
    }

    // log({ inputFiles });
    return inputFiles;

    // this.processInputFiles(inputFiles);
  }

  async getWorkWeekRange(sortedDates: [string, string]): Promise<[number, number]> {
    const bi = this.backendInterface;
    const [startDate, endDate] = sortedDates;
    // log({ startDate });
    const startWeek: number = await bi.getWeekNumberForDate({
      // productionSiteUniqueName: this.settings.productionSiteUniqueName,
      date: startDate,
    });
    const endWeek: number = await bi.getWeekNumberForDate({
      // productionSiteUniqueName: this.settings.productionSiteUniqueName,
      date: endDate,
    });
    return [Number(startWeek), Number(endWeek)];
  }

  async getSelectedCellplanAndFulfilledDemandsTimeDifference(date: string): Promise<number> {
    if (this.selectedFiles === null) throw new Error(`Cannot check cellplan and fulfilled demands: selectedFiles is null`)
    const cellplan = this.selectedFiles["-1"]?.Cellplan ?? null
    const fulfilledDemands = this.selectedFiles["-1"]?.FulfilledDemands ?? null
    if (cellplan === null || fulfilledDemands === null) {
      return Infinity
    }
    // console.log("selected cellplan:", cellplan)
    // console.log("selected fulfilledDemands:", fulfilledDemands)
    const cellplanValidityStartTime = await this.backendInterface.getCellplanValidity(cellplan?.originalFilename!, {
      date,
      // productionSiteUniqueName: this.settings.productionSiteUniqueName,
      modelType: this.settings.modelType,
    });
    // console.log("cellplan validity:", cellplanValidityStartTime)
    const fulfilledDemandsValidityStartTime = await this.backendInterface.getFulfilledDemandsValidity(fulfilledDemands?.originalFilename!, {
      date,
      // productionSiteUniqueName: this.settings.productionSiteUniqueName,
      modelType: this.settings.modelType,
    });
    // console.log("fulfilledDemands validity:", fulfilledDemandsValidityStartTime)
    const cellplanValidityDate = new Date(cellplanValidityStartTime)
    const fulfilledDemandsValidityDate = new Date(fulfilledDemandsValidityStartTime)
    const diff = cellplanValidityDate.getTime() - fulfilledDemandsValidityDate.getTime()
    // console.log(`validity diff: ${diff}ms`)
    return Math.abs(diff)
    // const diffAcceptable = 1 //10 * 1000 // ten seconds
    // return diff <= diffAcceptable
  }

  async processInputFiles(inputFiles: InputFilesByType, sortedDates: [string, string]): Promise<any> {
    // console.log("Input Files", inputFiles)
    const bi = this.backendInterface as BackendInterface;
    const availableFiles: InputFilesByWeekAndType = {};
    const selectedFiles: InputFileByWeekAndType = {};
    const [startWeek, endWeek] = await this.getWorkWeekRange(sortedDates);

    const sortByCreationTime = (a: SiloAssignmentInputFile, b: SiloAssignmentInputFile) =>
      new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime();

    // fill object with weeks as keys
    // TODO: get weeks from server fix before 2026
    const weekSpan = startWeek <= endWeek ? (endWeek - startWeek + 1) : (52 + endWeek - startWeek + 1);
    const weeks = [...Array(weekSpan)].map((_, w) => (+startWeek + w) % 53);
    weeks.unshift(-1);
    // console.log("weeks", weeks)
    // log({ weeks });
    // console.log("Start creating", weeks)
    for (const week of weeks) {
      // create
      availableFiles[week] = {} as InputFilesByType;
      selectedFiles[week] = {} as InputFileByType;
      // fill by type
      for (const fileType in CoffeeProductionInputFileType) {
        if (!isNaN(+fileType)) continue;
        const ft = fileType as CoffeeProductionInputFileType;
        availableFiles[week][ft] = [];
        selectedFiles[week][ft] = null;
      }
    }
    // console.log("Done creating")

    // insert files to correct weeks
    const weekCache: Record<string, string|number> = {}
    for (let fileType in CoffeeProductionInputFileType) {
      if (!isNaN(+fileType)) continue;
      const ft = fileType as CoffeeProductionInputFileType;
      // console.log("inputFiles[ft]", ft, inputFiles[ft])
      for (const file of inputFiles[ft] ?? []) {
        // console.log(file);
        let week: number|string = -1
        if (!this.uniqueFileTypes.includes(file.fileType)) {
          const dateString = file.dataValidityStartTime as string
          if (dateString in weekCache) {
            week = weekCache[dateString]
          } else {
            week = await bi.getWorkWeekNumberForDateTime({
              // productionSiteUniqueName: this.settings.productionSiteUniqueName,
              localDateTime: dateString,
            });
            if(fileType === CoffeeProductionInputFileType.ComplianceRgPreroast
              || fileType === CoffeeProductionInputFileType.ComplianceTasPreroast) {
              // TODO: fix this hack at some point in the future
              week = `${Number(week) + 1}`
            }
            weekCache[dateString] = week
          }
          // console.log(`${fileType} "${file.baseFilename}" with date ${file.dataValidityStartTime} is in week ${week}`)
        }
        if (week in availableFiles) {
          availableFiles[week][ft].push(file);
        } else {
          throw new Error(`The week received from "/time/display-week" for the input file "${file.baseFilename}" is ${week}, which is not in the selected week range of [${weeks.slice(1).join(", ")}]. File: ${JSON.stringify(file, null, 2)}`)
        }
      }
    }
    // console.log("Done inserting")

    // sort
    for (const week of weeks) {
      for (const fileType in CoffeeProductionInputFileType) {
        if (!isNaN(+fileType)) continue;
        const ft = fileType as CoffeeProductionInputFileType;
        availableFiles[week][ft].sort(sortByCreationTime);
        selectedFiles[week][ft] = availableFiles[week][ft][0];
      }
    }

    // log({ availableFiles });
    // log({ selectedFiles });
    // console.log("hello");
    // this.$forceUpdate()
    return {availableFiles, selectedFiles};
  }
}
