
import { BackendInterface } from "@/scripts/BackendInterface";
import Vue from "vue";
import { mapState } from "vuex";
import CompliancePlanSelection from "@/components/common/CompliancePlanSelection.vue";
import ArticleDemandList from "@/components/gas/ArticleDemandList.vue";

// @ts-ignore
import Plotly from "plotly.js-dist";
import {
  AnalyzeGasConsumptionSettings,
  BasketRoasterGasConsumption,
  GasConsumptionAggregation,
  GasConsumptionPerRoaster,
  GasConsumptionsPerBasket,
} from "@/scripts/models/gas";
import { SiloAssignmentInputFile, CoffeeProductionInputFileType } from "@/scripts/models/calculation";
import { CoffeeProductionCompliancePlanSingle, ItemInfo, ItemInfoStatsKey, PackageType } from "@/scripts/models/coffee";
import { defaultGasConsumptions } from "@/data/defaultGasConsumptions";
import { debounce, round, throttle } from "@/scripts/utils";
import { CoffeeProductionModelType } from "@/scripts/models/request";
import { CoffeeProductionAssignmentResultSummary } from "@/scripts/models/results";
import GasConsumptionRange from "@/components/gas/GasConsumptionRange.vue";

const r = round(2)

export default Vue.extend({
  name: "PapaSummaryGasAnalysis",
  props: {
    papaSummary: { type: Object, required: true },
  },
  components: {
    CompliancePlanSelection,
    ArticleDemandList,
    GasConsumptionRange,
  },
  data: function() {
    return {
      tab: null,
      loading: false,
      baseLayout: {
        title: "Energieverbrauch pro Artikel",
        font: { size: 10 },
        barmode: "stack",
        // barmode: "group",
        legend: { orientation: "h", x: 0, y: 1.2 },
        xaxis: {
          type: "category",
          tickmode: "linear",
        },
        yaxis: {
          ticksuffix: " MWh",
          automargin: true,
        },
        height: 200,
        autosize: true,
        // width: 700,
        margin: {
          t: 30,
          r: 0,
          l: 50,
          b: 80,
        },
      },
      color: {
        rg: "#1f77b4",
        tas: "#ff7f0e",
      },
      plotAll_throttled: () => {},
      plotAll_debounced: () => {},
      plotAll_deferred: () => {},
    };
  },
  async created() {},
  mounted() {},
  watch: {
    papaSummary: {
      immediate: true,
      handler(papaSummary: CoffeeProductionAssignmentResultSummary) {
        const gasConsumptions = papaSummary.inputSettings.gasConsumptions as BasketRoasterGasConsumption[];
        papaSummary.compliancePlan.planRG.setBasketRoasterGasConsumptions(gasConsumptions);
        papaSummary.compliancePlan.planTAS.setBasketRoasterGasConsumptions(gasConsumptions);
        this.$nextTick(() => {
          this.plotArticleDemands();
          this.plotBlendDemands();
          this.plotBasketDemands();
        });
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    energyRangeDefinitions(): any[] {
      return [
        { name: "Gesamt", energyConsumption: this.papaSummary.result.totalEnergyConsumption },
        { name: "Roast & Ground", energyConsumption: this.papaSummary.result.roastAndGroundEnergyConsumption },
        { name: "Tassimo", energyConsumption: this.papaSummary.result.tassimoEnergyConsumption },
      ];
    },
    sortedArticleDemands(): any {
      // if (!this.selectedPlan.rg) return [];
      // this.papaSummary_.
      const ps = this.papaSummary as CoffeeProductionAssignmentResultSummary;
      const adsRG = Object.values(ps.compliancePlan.planRG.articleDemands ?? {});
      adsRG.sort((a: any, b: any) => a.index - b.index);
      const adsTAS = Object.values(ps.compliancePlan.planTAS.articleDemands ?? {});
      adsTAS.sort((a: any, b: any) => a.index - b.index);
      console.log("adsRG", adsRG);

      return { rg: adsRG, tas: adsTAS };
      // return ads.map(ad => [ad.articleNumber, ad])
    },
  },
  methods: {
    round(n: number) {
      return Math.round(n * 10) / 10;
    },
    mapToTrace(map: Record<string, ItemInfo>, key: ItemInfoStatsKey) {
      const res: any = { x: [], y: [] };
      for (const itemKey in map) {
        res.x.push(itemKey);
        res.y.push(map[itemKey].gasConsumption[key]);
      }
      return res;
    },
    //
    // Plotting
    //
    plotAll() {
      // if (this.sortedArticleDemands.rg !== null || this.sortedArticleDemands.tas !== null) return;
      // console.log("plotAll")
      const ps = this.papaSummary as CoffeeProductionAssignmentResultSummary;
      const planRG = ps.compliancePlan.planRG;
      const planTAS = ps.compliancePlan.planTAS;
      if (planRG === null || planTAS === null) return;
      this.plotArticleDemands();
      this.plotBlendDemands();
      this.plotBasketDemands();
      this.plotSummaryTraces(
        planRG.gasConsumptions?.perArticle ?? {},
        planTAS.gasConsumptions?.perArticle ?? {},
        "articleBar",
        "Gasverbrauch pro Artikel",
        "average"
      );
      this.plotSummaryTraces(
        planRG.gasConsumptions?.perBlend ?? {},
        planTAS.gasConsumptions?.perBlend ?? {},
        "blendBar",
        "Gasverbrauch pro Sorten",
        "average"
      );
      this.plotSummaryTraces(
        planRG.gasConsumptions?.perBasket ?? {},
        planTAS.gasConsumptions?.perBasket ?? {},
        "basketBar",
        "Gasverbrauch pro Baskets",
        "average"
      );
      // this.plotSummaryTraces();
      // this.plotSummaryTraces();
    },

    plot(id: string, traces: any, layout: any) {
      try {
        Plotly.newPlot(id, traces, layout, {
          displayModeBar: false,
        });
      } catch (error) {
        console.warn(error);
      }
    },
    plotArticleDemands() {
      // if (!this.selectedPlan) return;
      const ps = this.papaSummary as CoffeeProductionAssignmentResultSummary;
      const planRG = ps.compliancePlan.planRG;
      const planTAS = ps.compliancePlan.planTAS;
      // if (planRG === null || planTAS === null) return;
      // console.log("plotArticleDemands");
      const rg: any = { x: [], y: [] };
      const tas: any = { x: [], y: [] };
      let sum = 0
      for (const cat of ps.result.curedArticleTaskResults) {
        if (cat.tassimo) {
          tas.x.push(cat.articleNr);
          tas.y.push(round(0)(cat.roasterEnergyConsumed_kWh/1000));
        } else {
          rg.x.push(cat.articleNr);
          rg.y.push(round(0)(cat.roasterEnergyConsumed_kWh)/1000);
        }
      }

      


      // const rg = this.mapToTrace(planRG.gasConsumptions!.perArticle, "average");
      // const tas = this.mapToTrace(planTAS.gasConsumptions!.perArticle, "average");
      console.log("rg", rg);

      // const tas = this.mapToTrace(this.getArticleDemandsGasConsumption(planTAS, "average"));
      const traces = [
        {
          name: `Roast & Ground`,
          ...rg,
          type: "bar",
          marker: { color: rg.x.map(() => this.color.rg) },
        },
        {
          name: `Tassimo`,
          ...tas,
          type: "bar",
          marker: { color: tas.x.map(() => this.color.tas) },
        },
      ];
      // console.log("article max", this.max.article, this.max);

      const layout = {
        ...this.baseLayout,
        // yaxis: {range: [0, this.max.article]},
        xaxis: { ...this.baseLayout.xaxis, tickfont: { size: 10 } },
        // width: 700,
      };
      this.plot("articleDemandPlot", traces, layout);
    },

    plotBlendDemands() {
      const ps = this.papaSummary as CoffeeProductionAssignmentResultSummary;
      const planRG = ps.compliancePlan.planRG;
      const planTAS = ps.compliancePlan.planTAS;
      if (planRG === null || planTAS === null) return;
      // const rg = this.mapToTrace(planRG.gasConsumptions!.perBlend, "average");
      // const tas = this.mapToTrace(planTAS.gasConsumptions!.perBlend, "average");
      const rg: any = { x: [], y: [] };
      const tas: any = { x: [], y: [] };
      const blendsRG: Record<string, number> = {};
      const blendsTAS: Record<string, number> = {};
      for (const cat of ps.result.curedArticleTaskResults) {
        if (cat.tassimo) {
          if (!(cat.blendSpsNr in blendsTAS)) blendsTAS[cat.blendSpsNr] = 0;
          blendsTAS[cat.blendSpsNr] += cat.roasterEnergyConsumed_kWh;
        } else {
          if (!(cat.blendSpsNr in blendsRG)) blendsRG[cat.blendSpsNr] = 0;
          blendsRG[cat.blendSpsNr] += cat.roasterEnergyConsumed_kWh;
        }
      }
      for (const blendSpsNr in blendsRG) {
        rg.x.push(blendSpsNr);
        rg.y.push(round(0)(blendsRG[blendSpsNr])/1000);
      }
      for (const blendSpsNr in blendsTAS) {
        tas.x.push(blendSpsNr);
        tas.y.push(round(0)(blendsTAS[blendSpsNr]/1000));
      }
      const traces = [
        {
          name: `Roast & Ground`,
          ...rg,
          type: "bar",
          marker: { color: rg.x.map(() => this.color.rg) },
        },
        {
          name: `Tassimo`,
          ...tas,
          type: "bar",
          marker: { color: tas.x.map(() => this.color.tas) },
        },
      ];
      const layout = {
        ...this.baseLayout,
        title: "Energieverbrauch pro Sorte",
        // yaxis: {range: [0, this.max.blend]},
        xaxis: { ...this.baseLayout.xaxis, tickfont: { size: 10 } },
        // width: 700,
      };
      this.plot("blendDemandPlot", traces, layout);
    },

    plotBasketDemands() {
      return;
      // const ps = this.papaSummary as CoffeeProductionAssignmentResultSummary;
      // const planRG = ps.compliancePlan.planRG;
      // const planTAS = ps.compliancePlan.planTAS;
      // if (planRG === null || planTAS === null) return;
      // const rg: any = { x: [], y: [] };
      // const tas: any = { x: [], y: [] };
      // const basketsRG: Record<string, number> = {};
      // const basketsTAS: Record<string, number> = {};
      // for (const rs of ps.result.roasterSubtaskResults) {
      //   const article =
      //     ps.compliancePlan.planRG.articles[rs.articleNr] ?? ps.compliancePlan.planTAS.articles[rs.articleNr] ?? null;
      //   if (article === null) {
      //     console.warn("article is null");
      //     continue;
      //   }
      //   const isTassimo = article.packageTypes[0] === PackageType.Tassimo
      //   if (isTassimo) {
      //     if (!(rs.basketSpsNr in basketsTAS)) basketsTAS[rs.basketSpsNr] = 0;
      //     basketsTAS[rs.basketSpsNr] += rs.energyConsumption_kWh;
      //   } else {
      //     if (!(rs.basketSpsNr in basketsRG)) basketsRG[rs.basketSpsNr] = 0;
      //     basketsRG[rs.basketSpsNr] += rs.energyConsumption_kWh;
      //   }
      // }
      // for (const blendSpsNr in basketsRG) {
      //   rg.x.push(blendSpsNr);
      //   rg.y.push(round(0)(basketsRG[blendSpsNr])/1000);
      // }
      // for (const blendSpsNr in basketsTAS) {
      //   tas.x.push(blendSpsNr);
      //   tas.y.push(round(0)(basketsTAS[blendSpsNr]/1000));
      // }
      // const traces = [
      //   {
      //     name: `Roast & Ground`,
      //     ...rg,
      //     type: "bar",
      //     marker: { color: rg.x.map(() => this.color.rg) },
      //   },
      //   {
      //     name: `Tassimo`,
      //     ...tas,
      //     type: "bar",
      //     marker: { color: tas.x.map(() => this.color.tas) },
      //   },
      // ];
      // const layout = {
      //   ...this.baseLayout,
      //   title: "Energieverbrauch pro Basket",
      //   // yaxis: {range: [0, this.max.basket]},
      //   xaxis: { ...this.baseLayout.xaxis, tickfont: { size: 10 } },
      //   // width: 700,
      // };
      // this.plot("basketDemandPlot", traces, layout);
    },
    plotSummaryTraces(
      itemsRG: Record<string, ItemInfo>,
      planTAS: Record<string, ItemInfo>,
      target: string,
      title: string,
      key: ItemInfoStatsKey
    ) {
      // const gcRG = itemsRG; //this.getBlendDemandsGasConsumption(itemsRG, "average");
      // const gcTAS = itemsTAS; //this.getBlendDemandsGasConsumption(itemsTAS, "average");
      const gc: Record<string, number> = {};
      for (const itemKey in itemsRG) {
        const v = itemsRG[itemKey].gasConsumption[key];
        if (!(itemKey in gc)) gc[itemKey] = v;
        else gc[itemKey] += v;
      }
      for (const itemKey in planTAS) {
        const v = planTAS[itemKey].gasConsumption[key];
        if (!(itemKey in gc)) gc[itemKey] = v;
        else gc[itemKey] += v;
      }

      const traces = [];
      // const annotations = [];
      // let annotationX = 0;
      // let i = 0;
      const items = Object.entries(gc);
      items.sort((a, b) => b[1] - a[1]);
      let acc = 0;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const x = item[1];
        const y = "";
        traces.push({
          name: item[0],
          type: "bar",
          x: [x],
          y: [y],
          orientation: "h",
          mode: "markers",
          text: [item[0]],
          textposition: "inside",
          width: [0.8],
          // base: acc,
          marker: {
            line: { width: 1, color: "white" },
            color: `rgba(31,119,180,${0.4 + (0.6 * (items.length - i)) / items.length})`,
          },
        });
        acc += x + 60;
      }

      // if (!this.selectedPlan) return;

      // console.log("this.max.sum", this.max.sum);

      const layout = {
        title: title,
        font: { size: 10 },
        height: 40,
        showlegend: false,
        barmode: "stack",
        margin: { l: 50, r: 0, b: 0, t: 20 },
        // xaxis: {range: [0, this.max.sum], showgrid: false},
        yaxis: { showgrid: false },
        bargap: 0.05,
        // shapes: [
        //   {
        //     type: "line",
        //     x0: this.settings.maxTotalGasConsumption,
        //     y0: 0,
        //     x1: this.settings.maxTotalGasConsumption,
        //     yref: "paper",
        //     y1: 1,
        //     line: {
        //       color: "red",
        //       width: 2,
        //       // dash: "dot",
        //     },
        //   },
        // ],
      };

      this.plot(target, traces, layout);
    },
  },
});
