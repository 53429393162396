import {SiloAssignmentInputFile} from "@/scripts/models/calculation";
import {CoffeeBasket, CoffeeBasketDailyValues, CoffeeSiloAllocation} from "@/scripts/models/coffee";
import { CoffeeProductionModelType } from "./request";

// NEW

export type StringToNumberMap = {
    [key: string]: number
}

export interface NameValueTuple {
    name: string
    value: string
}

export interface WeekColumnData {
    available: {
        basket: SiloAssignmentInputFile[]
        cellplan: SiloAssignmentInputFile[]
        fulfilledDemands: SiloAssignmentInputFile[]
        complianceRG: SiloAssignmentInputFile[]
        complianceTAS: SiloAssignmentInputFile[]
    },
    selected: {
        basket: SiloAssignmentInputFile
        cellplan: SiloAssignmentInputFile
        fulfilledDemands: SiloAssignmentInputFile
        complianceRG: SiloAssignmentInputFile
        complianceTAS: SiloAssignmentInputFile
    }
}

export enum DashboardStatus {
    UNSET,
    SUCCESS,
    ERROR,
    LOADING
}

//ToDo: remove later
// export enum UserType {
//     USER = "USER",
//     ADMIN = "ADMIN",
//     SUPERUSER = "SUPERUSER"
// }

export enum UserAccessType{
    PRIVATE = "PRIVATE",
    USER = "USER",
    ADMIN = "ADMIN",
    SUPERUSER = "SUPERUSER",
    USER_ADMIN = "USER_ADMIN",
    USER_SUPERUSER = "USER_SUPERUSER",
    ADMIN_SUPERUSER = "ADMIN_SUPERUSER",
    PUBLIC = "PUBLIC",
}

//
// MAPS
//

export type BasketMap = {
    // basketSpsNr
    [key: string]: CoffeeBasket;
};
export type CoffeeSiloAllocationMap = {
    // siloSpsNr
    [key: string]: CoffeeSiloAllocation;
};
export type DailyBasketValueMap = {
    // basketSpsNr
    [key: string]: CoffeeBasketDailyValues;
};
export type CoffeeBasketMap = {
    // basketSpsNr
    [key: string]: CoffeeBasket;
};
export type BasketDailyValueMap = {
    // basket nr
    [key: string]: CoffeeBasketDailyValues;
};
export type SiloBasketDailyValueMap = {
    // silo nr
    [key: string]: BasketDailyValueMap;
};
export type SiloSiloAllocationMap = {
    // silo nr
    [key: string]: CoffeeSiloAllocation;
};
export type SiloTimestepSiloAllocationMap = {
    // silo nr
    [key: string]: {
        // timestep nr
        [key: string]: CoffeeSiloAllocation;
    };
};

export enum Color {
    BLACK = "#000",
    DARK_GRAY = "#333",
    TRANSPARENT = "#ffffff00",
    WHITE = "#ffffff",
    RUBY = "#da3e54", //"#D8334A",
    GRAPEFRUIT = "#ED5565",
    BITTERSWEET = "#FC6E51",
    SUNFLOWER = "#FFCE54",
    STRAW = "#E8CE4D",
    GRASS = "#8fcc4c", //"#A0D468",
    TEAL = "#A0CECB",
    MINT = "#48CFAE",
    AQUA = "#35b6e4", //"#4EBFE7",
    BLUEJEANS = "#5D9CEC",
    LAVENDAR = "#8468A6",
}

export const Palette = [
    Color.AQUA,
    Color.BITTERSWEET,
    Color.GRASS,
    Color.GRAPEFRUIT,
    Color.LAVENDAR,
    Color.MINT,
    Color.SUNFLOWER,
    Color.BLUEJEANS,
    // Color.RUBY,
];

export interface CoffeeProductionErrorReport {
    // senderEmailAddress: string
    // userType: UserType
    modelType: CoffeeProductionModelType
    applicationPhase: string
    // solveRequestId?: string|null
    solveRequest: any
    errorMessage: string
}

// export interface CoffeeProductionSite {
//      id: string
//      uniqueName: string
//      name: string
//      timeZoneId: string
//      locale: string
//     //  company: 
// }
