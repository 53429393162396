
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import colors from 'vuetify/lib/util/colors';
import {DashboardStatus} from "@/scripts/models/general";
import HelpDialog from "@/components/common/HelpDialog.vue";


export default Vue.extend({
  name: "DashboardElement",
  props: {
    title: {type: String, required: true},
    status: {required: false, default: null},
    placeholderText: {type: String, default: "Keine Daten"},
    helpTextKey: {type: String, default: null, required: false}
  },
  components: {HelpDialog},
  data() {
    return {
      colors,
      DashboardStatus,
      elementStatus: DashboardStatus.UNSET,
      showHelpDialog: false,
    };
  },
  created() {
  },
  watch: {
    status() {
      if (this.status != null) {
        this.elementStatus = (this.status as unknown) as DashboardStatus;
      }
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations([""]),

  },
});
