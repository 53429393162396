
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {Gender, UserDataRequest} from "@/scripts/auth";
import {BackendInterface} from "@/scripts/BackendInterface";

export default Vue.extend({
  name: "SignUp",
  components: {},
  props: {},
  data() {
    return {
      confirmPassword: "",
      newUser: {
        username: "", //"Florian",
        password: "", //"1234",
        email: "", //"florian.spinkler+user@gmail.com",
        jobTitle: "",
        productionSiteUniqueName: "JDE_Berlin",
        firstName: "", //"Florian",
        familyName: "", //"Spinkler",
        resultsPublicByDefault: true,
        gender: Gender.MALE,
      } as UserDataRequest,
      newUserPlaceholders: {
        username: "mustermann", //"Florian",
        password: "", //"1234",
        email: "max.mustermann@jdecoffee.com", //"florian.spinkler+user@gmail.com",
        productionSiteUniqueName: "JDE_Berlin",
        firstName: "Max", //"Florian",
        familyName: "Mustermann", //"Spinkler",
        gender: Gender.MALE,
      } as UserDataRequest,
      errors: [] as string[],
      successMessages: [] as string[],
      creatingUser: false
    };
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    registerButtonEnabled(): boolean {
      return this.newUser.username.length > 0
          && this.newUser.password.length > 0
          && this.newUser.email.length > 0
          && this.newUser.firstName.length > 0
          && this.newUser.familyName.length > 0
    }
  },
  methods: {
    ...mapMutations([""]),
    goToLogin() {
      this.$router.push("/login");
    },
    async handleSignup() {
      this.errors = []
      // validate password
      if (this.newUser.password !== this.confirmPassword) {
        console.log("Passwords do not match");
        this.errors.push("Passwörter stimmen nicht überein!")
      }

      // validate email
      if (!this.newUser.email.includes("@")) {
        console.log("Invalid email");
        this.errors.push("Ungültige E-Mail-Adresse!")
      }

      // validate username
      if (this.newUser.username.length < 3) {
        console.log("Username too short");
        this.errors.push("Benutzername ist zu kurz!")
      }

      if (this.errors.length > 0) return;

      const bi = this.backendInterface as BackendInterface;
      this.creatingUser = true
      this.newUser.email = this.newUser.email.toLowerCase()
      const res: any = await bi.createNewUser(this.newUser)
      this.creatingUser = false
      console.log("SignUp", res);

      if (res?.translatedErrorMessage) {
        this.errors.push(res.translatedErrorMessage)
        // return;
      } else if (res?.errorMessage) {
        this.errors.push(res.errorMessage)
      } else {
        this.successMessages.push("Benutzer angelegt! Es wurde ihnen ein Email auf " + this.newUser.email + " zuseschickt. Bitte bestätigen Sie ihre Email Adresse.")
      }

      // handle errors
      // if ('trace' in res && res.trace.includes('User already registered')) {
      //   this.errors.push("Benutzername ist bereits vergeben!")
      // }
      // else if ('trace' in res && res.trace.includes('The email')) {
      //   this.errors.push("Email Adresse ist bereits vergeben!")
      // }
      // else if ('error' in res) {
      //   this.errors.push(res.error)
      // }
      // else if ('errorMessage' in res) {
      //   this.errors.push(res.errorMessage)
      // }

    },
  },
});
