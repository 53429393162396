
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "PageActionBar",
  props: {},
  components: {},
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    ...mapState(["superuser", "user"]),
  },
  methods: {
    ...mapMutations(["toggleSuperuser"]),
  },
});
