
import {generateOutputHash, hash_java} from "@/scripts/utils";
import {camelCaseToWords} from "@/scripts/utils";
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import BasketSettings from "@/components/settings/BasketSettings.vue";
import SiloSettings from "@/components/settings/SiloSettings.vue";
import OptimizationSettings from "@/components/settings/OptimizationSettings.vue";
import SolverParameterSettings from "@/components/settings/SolverParameterSettings.vue";
import DashboardElement from "@/components/common/DashboardElement.vue";
import DataSettings from "@/components/settings/DataSettings.vue";
import RoasterAvailabilitySettings from "@/components/settings/RoasterAvailabilitySettings.vue";
import PackagingLineSettings from "@/components/settings/PackagingLineSettings.vue";
import PreroastingSettings from "@/components/settings/PreroastingSettings.vue";
// import {UserType} from "@/scripts/models/general";
import {BackendInterface} from "@/scripts/BackendInterface";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import {SiloAssignmentInputFile} from "@/scripts/models/calculation";
import {CoffeeProductionModelType,} from "@/scripts/models/request";
import InputFileSummary from "@/components/upload/InputFileSummary.vue";
import SliderTableRow from "@/components/settings/SliderTableRow.vue";
import ArticlePrioritySettings from "@/components/settings/ArticlePrioritySettings.vue";
import AvailabilityExclusionSettings from "@/components/settings/AvailabilityExclusionSettings.vue";
import PapaGasConsumptionSettings from "@/components/settings/PapaGasConsumptionSettings.vue";
import {
  CoffeeProductionDORISolveRequest,
  CoffeeProductionDORISummary,
  DORISolveResultRequest,
} from "@/scripts/models/dori";
import SolutionItem from "@/components/common/SolutionItem.vue";
import {UserInfo, UserRole} from "@/scripts/auth";
import UserSettingsDisplay from "@/components/settings/UserSettingsDisplay.vue";
import SaveSettingsDialog from "@/components/settings/SaveSettingsDialog.vue";
import RoasterAvailabilityPreview from "@/components/settings/RoasterAvailabilityPreview.vue";

export default Vue.extend({
  name: "DoriSettingsSection",
  props: {
    value: {
      type: Object as PropType<CoffeeProductionDORISolveRequest>,
      required: true,
    },
    timeRangeStart: {
      type: String as PropType<string>,
      required: true,
    },
    timeRangeEnd: {
      type: String as PropType<string>,
      required: true,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    recompute: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RoasterAvailabilityPreview,
    SaveSettingsDialog,
    UserSettingsDisplay,
    SolutionItem,
    BasketSettings,
    OptimizationSettings,
    SiloSettings,
    DashboardElement,
    SolverParameterSettings,
    DataSettings,
    RoasterAvailabilitySettings,
    PreroastingSettings,
    InputFileSummary,
    SliderTableRow,
    ArticlePrioritySettings,
    PapaGasConsumptionSettings,
    PackagingLineSettings,
    AvailabilityExclusionSettings,
  },
  data: function () {
    return {
      CoffeeProductionModelType,
      // UserType,
      tab: 0,
      solveRequest: null as CoffeeProductionDORISolveRequest | null, // TODO: rename
      // objectiveSettingsCopy: {} as any,
      inputFiles: [] as SiloAssignmentInputFile[],
      existingSolution: null as CoffeeProductionDORISummary | null, // TODO: rename
      solutionId: null as string | null,
      loading: true,
      otherSettingsKeys: ["dayStartHour", "hoursInTimeStep", "maxTimeStepNum", "saraDecidesRoastingWeights",
       "rgAndTassimoActualizationTimeMaxDiffInMinutes",
       "minRoasterTaskNum", "maxRoasterTaskNum",
       "maxSplitsPerMillingTask", "millingTaskSplitNumberRatio",
       "millingTaskCriticalDifficultyThreshold", "millingTaskMinimalDifficultyThreshold"],
      otherBooleanSettingsKeys: ["saraDecidesRoastingWeights"],
      saraSummary: null as CoffeeProductionSARASummary | null,
      userSettings: null as UserInfo | null,
      // objectiveWeightConfigs: [
      //   { key: "objectiveWeightTotalRoasterEnergy", name: "Total Roaster Energy", min: 0, max: 200, step: 1 },
      //   { key: "objectiveWeightTotalRoasterTaskNum", name: "Total Roaster Task Num", min: 0, max: 10, step: 0.1 },
      // { key: "objectiveWeightTotalPackagingTaskNum", name: "Total Packaging Task Num", min: 0, max: 10, step: 0.1 },
      // {
      //   key: "objectiveWeightWeightedTaskStartTimeSum",
      //   name: "Weighted Task Start Time Sum",
      //   min: 0,
      //   max: 10,
      //   step: 0.1,
      // },
      // {
      //   key: "objectiveWeightTotalWeightedProductionDeviation",
      //   name: "Total Weighted Production Deviation",
      //   min: 0,
      //   max: 10000,
      //   step: 100,
      // },
      // ],
      // sliderConfigs: [
      // { key: "weeklyProductionHours", name: "Verfügbare Produktionszeit (h)", min: 0, max: 168, step: 1 },
      // { key: "productionSubTaskNum", name: "Production Sub Task Num", min: 0, max: 10, step: 1 },
      // { key: "roasterSubtaskNum", name: "Roaster Subtask Num", min: 0, max: 20, step: 1 },
      // { key: "firstDayStartHour", name: "First Day Start Hour", min: 0, max: 24, step: 1 },
      // ],
    };
  },
  async created() {
    await this.init();
  },
  watch: {
    value: "init",
    solveRequest: {
      deep: true,
      // immediate: true,
      async handler(v) {
        this.loading = true;
        // console.log("CHANGED: solveRequest", JSON.stringify(v,null,2));
        console.log("CHANGED: solveRequest", v);
        // if(v !== null)
        await this.loadExistingSolution();
        this.loading = false;
      },
    },
    "solveRequest.solverParameters.maxRuntimeSec": {
      immediate: true,
      handler(v) {
        if (!!this.solveRequest?.solverParameters?.maxSecondsBetweenSolutions) {
          this.solveRequest.solverParameters.maxSecondsBetweenSolutions = Math.max(4 * 60, 0.8 * v)
        }
      },
    },
    "solveRequest.inputSettings.buildSiloUtilizationModel": {
      immediate: true,
      handler(v) {
        if (!!this.solveRequest?.solverParameters?.maxRuntimeSec) {
          this.solveRequest.solverParameters.maxRuntimeSec = v ? 20 * 60 : 5 * 60
        }
      }
    }
  },
  computed: {
    ...mapState(["superuser", "user", "backendInterface", "productionSiteUniqueName"]),
    isDoriAdminOrMore(): boolean {
      const role = (this.user as UserInfo)?.doriUserRole
      return role === UserRole.ADMIN || role === UserRole.SUPERUSER;
    },
    isDoriSuperuser(): boolean {
      return (this.user as UserInfo)?.doriUserRole === UserRole.SUPERUSER;
    },
    hasSolution(): boolean {
      return this.solutionId !== null && !this.recompute;
    },
    settingsTitle(): string {
      if (this.solveRequest?.inputData.firstDayStartTime) {
        const firstDayDate = new Date(this.solveRequest.inputData.firstDayStartTime);
        const dateString = firstDayDate.toLocaleDateString("de-DE");
        const timeString = firstDayDate.toLocaleTimeString("de-DE");
        return "Zusammenfassung (" + dateString + " " + timeString + ")";
      } else {
        return "Zusammenfassung";
      }
    },
    firstObjectiveDeviationIndex(): number {
      if (this.solveRequest === null) return 0;
      const objectives = Object.keys(this.solveRequest.inputSettings.objectiveSettings)
      return objectives.findIndex(o => o.toLowerCase().includes("deviation"))
    },
  },
  methods: {
    camelCaseToWords,
    ...mapMutations([""]),
    findBooleanToObjective(key: string): string {
      // Taken from class: CoffeeProductionDORIObjectiveSettings.java
      const map: Record<string, string> = {
        "objectiveWeightTotalRoasterEnergy": "omitObjectiveTotalRoasterEnergy",
        "objectiveWeightTotalRoasterTaskNum": "omitObjectiveTotalRoasterTaskNum",
        "objectiveWeightTotalRoasterUtilization": "omitObjectiveTotalRoasterUtilization",
        "objectiveWeightRoasterTaskTimeGap": "omitObjectiveRoasterTaskTimeGap",
        "objectiveWeightCafDecafRoastingChanges": "omitObjectiveCafDecafRoastingChanges",
        "objectiveWeightAssignedFreeSilos": "omitObjectiveAssignedFreeSilos",
        //deviations
        "objectiveWeightWeightedMillingTaskDeviation": "fixObjectiveWeightedMillingTaskDeviation",
        "objectiveWeightCuredBlendProvisioningStartTime": "fixObjectiveCuredBlendProvisioningStartTime",
        "objectiveWeightInitialRoasterAssignmentWeightDeviation": "fixObjectiveInitialRoasterAssignmentWeightDeviation",
        "objectiveWeightBasketPreroastingNeedDecrease": "fixObjectiveBasketPreroastingNeedDecrease",
        "objectiveWeightBasketPreroastingNeedIncrease": "fixObjectiveBasketPreroastingNeedIncrease",
        "objectiveWeightRoastingMinLotSizeDecrease": "fixObjectiveRoastingMinLotSizeDecrease",
        "objectiveWeightPreroastProvisioningTimeIncrease": "fixObjectivePreroastProvisioningTimeIncrease",
        "objectiveWeightCuredBasketUsageDeviation": "fixObjectiveCuredBasketUsageDeviation",
        "objectiveWeightSiloUtilizationStorageOverflow": "fixObjectiveSiloUtilizationStorageOverflow",
      }
      return map[key] ?? ""
    },
    async init() {
      this.loading = true;
      // console.log("initializing");

      if (this.value !== null) {
        this.solveRequest = JSON.parse(JSON.stringify(this.value)) as CoffeeProductionDORISolveRequest;
        //copy the objective settings
        // this.objectiveSettingsCopy = JSON.parse(JSON.stringify(this.solveRequest.inputSettings.objectiveSettings));
        const bi = this.backendInterface as BackendInterface;
        await this.loadUserSettingsInfo();
        this.inputFiles = await bi.getFilesByIds(this.solveRequest.inputData.inputFileIds);
        this.saraSummary = await bi.sara_GetSolutionById(this.solveRequest.saraSummaryId)
      }
      await this.loadExistingSolution();
      this.loading = false;
    },
    handleObjectiveInput(key: string, value: number | string | null) {
      if (this.solveRequest !== null) {
        const val = value ? Number(value) : 0;
        //@ts-ignore
        this.solveRequest.inputSettings.objectiveSettings[key] = val;
        // this.objectiveSettingsCopy[key] = val;
      }
    },
    // handleObjectiveCheckboxInput(key:string, value:boolean){
    //   if(this.solveRequest !== null){
    //     // const val = value ? null : this.objectiveSettingsCopy[key];
    //     //@ts-ignore
    //     this.solveRequest.inputSettings.objectiveSettings[key] = val;
    //   }
    // },
    async loadUserSettingsInfo() {
      const userSettingsId = this.solveRequest?.userSettingsId ?? null;
      if (userSettingsId !== null && userSettingsId !== "") {
        // console.log("userSettingsId", userSettingsId)
        this.userSettings = await this.backendInterface.getUserSettings(CoffeeProductionModelType.DORI, userSettingsId)
        // console.log("userSettings", this.userSettings)
      }
    },

    emitSettingsSaved() {
      // @ts-ignore
      delete this.solveRequest.sarasummary;
      this.$emit("input", this.solveRequest);
      this.$emit("settings-saved");
    },
    emitBack() {
      this.$emit("back");
    },
    async loadExistingSolution() {
      const bi: BackendInterface = this.backendInterface;
      // TODO: rename
      const solveRequest = this.solveRequest as CoffeeProductionDORISolveRequest;
      // console.log("solveRequest", solveRequest);

      const request: DORISolveResultRequest = {
        saraSummaryId: solveRequest.saraSummaryId, //"638a174370c78a70c4da376a", //"placeholder-id",
        productionSiteUniqueName: this.productionSiteUniqueName,
        timeRangeStart: this.timeRangeStart,
        timeRangeEnd: this.timeRangeEnd,
        inputFileIds: solveRequest.inputData.inputFileIds,
        inputSettings: solveRequest.inputSettings,
        coffeeBaskets: solveRequest.inputData.baskets,
        coffeeRoasters: solveRequest.inputData.roasters,
        siloAllocations: solveRequest.inputData.siloAllocations,
        fulfilledDemands: solveRequest.inputData.fulfilledDemands,
        solverParameters: solveRequest.solverParameters,
      };
      this.solutionId = await bi.dori_GetSolutionId(request);
      //
      // HASH CHECK: ENABLE IF NEEDED
      //
      // const request2: DORISolveResultRequest = {
      //   saraSummaryId: solveRequest.saraSummaryId,
      //   productionSiteUniqueName: this.productionSiteUniqueName,
      //   timeRangeStart: this.timeRangeStart,
      //   timeRangeEnd: this.timeRangeEnd,
      //   inputFileIds: solveRequest.inputData.inputFileIds,
      //   inputSettings: solveRequest.inputSettings,
      //   coffeeBaskets: solveRequest.inputData.baskets,
      //   coffeeRoasters: solveRequest.inputData.roasters,
      //   siloAllocations: solveRequest.inputData.siloAllocations,
      //   fulfilledDemands: solveRequest.inputData.fulfilledDemands,
      //   solverParameters: solveRequest.solverParameters,
      // };
      const existingSolution = await bi.dori_GetSolution(request);
      this.existingSolution = existingSolution;
      // if (existingSolution === null) {
      //   const solutionHashes = await bi.dori_GetSolutionHashes(request);
      //   // console.warn("No solution found. Solution hashes:", JSON.stringify(solutionHashes, null, 2));
      //   const solutionHashes2 = await bi.dori_GetSolutionHashes(request2);
      //   // console.warn("No solution found. Solution hashes 2:", JSON.stringify(solutionHashes2, null, 2));
      // }
    },
    async emitExistingSolutionLoaded() {
      this.$emit("load-existing-solution", this.solutionId);
    },
    getObjectiveWeightName(objectiveName: string) {
      return camelCaseToWords(objectiveName).split(" ").slice(2).join(" ");
    },
    async downloadSaraSolution(saraSummaryId: string) {
      const bi = this.backendInterface as BackendInterface;
      return await bi.sara_DownloadSolution(saraSummaryId)
    },
    async saveSettings() {
      // if (this.solveRequest === null) {
      //   console.warn("solveRequestId is null");
      //   return;
      // }
      // let affectedUserTypes: string = "Superuser";
      // if (this.userType === UserType.ADMIN) {
      //   affectedUserTypes = "User + Admin";
      // } else if (this.userType === UserType.USER) {
      //   affectedUserTypes = "User";
      // }
      // if (!confirm(`Möchten Sie die Einstellungen für "${affectedUserTypes}" wirklich speichern?`)) return;
      // // @ts-ignore
      // // const solveRequestId = this.solveRequest?.id ?? null;
      // // console.log("solveRequestId", solveRequestId);
      //
      // // TODO: find out what to do with this
      // const bi: BackendInterface = this.backendInterface;
      // await bi.dori_UpdateUserSettingsBySolveRequest(this.solveRequest);
      // if (this.userType === UserType.ADMIN) {
      //   await bi.dori_UpdateUserSettingsBySolveRequest(this.solveRequest);
      // }
      // // TODO: replace with snackbar
      // alert(`Einstellungen gespeichert (${affectedUserTypes})!`);
      // // this.snackbarText = `Einstellungen gespeichert (${affectedUserTypes})!`;
      // // this.showSnackbar = true;
    },
    downloadSettings() {
      console.log("downloadSettings")
      const solveRequest = this.solveRequest as CoffeeProductionDORISolveRequest;
      if (solveRequest && solveRequest.id)
        (this.backendInterface as BackendInterface).downloadSettings(CoffeeProductionModelType.DORI, solveRequest.id);
    }
  },
});
