
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {UserSettingsInfo} from "@/scripts/models/results";
import UserSettingsListItem from "@/components/settings/UserSettingsListItem.vue";
import UserSettingsAccessOptions from "@/components/settings/UserSettingsAccessOptions.vue";
import UserSettingsDisplay from "@/components/settings/UserSettingsDisplay.vue";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import SaraSettingsPreview from "@/components/settings/preview/SaraSettingsPreview.vue";

interface SettingsSection {
  title: string,
  settings: UserSettingsInfo[],
  canChangeAccess: boolean,
  readonly: boolean,
  canReset: boolean,
  showHeader: boolean
}

export default Vue.extend({
  name: "ModuleUserSettingsManager",
  components: {SaraSettingsPreview, UserSettingsDisplay, UserSettingsAccessOptions, UserSettingsListItem},
  props: {
    modelType: {
      type: String as PropType<CoffeeProductionModelType>,
      required: true,
      default: CoffeeProductionModelType.SARA,
    }
  },
  data() {
    return {
      CoffeeProductionModelType,
      tab: 1,
      title: "Einstellungen",
      loading: false,
      showAccessOptionsDialog: false,
      ownedSettings: [] as UserSettingsInfo[],
      readableSettings: [] as UserSettingsInfo[],
      writeableSettings: [] as UserSettingsInfo[],
      defaultUserSettings: null as UserSettingsInfo | null,
      settingsToEdit: null as UserSettingsInfo | null,
      userSettingsInfoIdToPreview: "" as string,
    };
  },
  created() {
    this.loadSettings()
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    ownedSettingIds(): Set<string> {
      return new Set(this.ownedSettings.map(s => s.id))
    },
    writeableSettingIds(): Set<string> {
      return new Set(this.writeableSettings.map(s => s.id))
    },
    defaultUserSettingsId(): string | null {
      return this.defaultUserSettings?.id ?? null
    },
    writeableAndNotOwnedSettings(): UserSettingsInfo[] {
      return this.writeableSettings.filter(s => !this.ownedSettingIds.has(s.id))
    },
    readableAndNotOwnedAndNotWriteableSettings(): UserSettingsInfo[] {
      return this.readableSettings.filter(s => !this.ownedSettingIds.has(s.id) && !this.writeableSettingIds.has(s.id))
    },
    settingsSections(): Array<SettingsSection> {
      return [
        {
          title: "Meine Einstellungen",
          settings: this.ownedSettings,
          canChangeAccess: true,
          canReset: true,
          readonly: false,
          showHeader: true
        },
        {
          title: "Andere Einstellungen",
          settings: this.writeableAndNotOwnedSettings,
          canChangeAccess: false,
          canReset: true,
          readonly: false,
          showHeader: true
        },
        {
          title: "Andere Einstellungen (nur lesbar)",
          settings: this.readableAndNotOwnedAndNotWriteableSettings,
          canChangeAccess: false,
          canReset: false,
          readonly: true,
          showHeader: false
        },]
    },
  },
  methods: {
    ...mapMutations([""]),
    async loadSettings() {
      this.loading = true
      this.ownedSettings = await this.bi.getOwnedUserSettingInfos(this.modelType)
      this.readableSettings = await this.bi.getReadableUserSettingInfos(this.modelType)
      this.writeableSettings = await this.bi.getWriteableUserSettingInfos(this.modelType)
      this.defaultUserSettings = await this.bi.getDefaultUserSettingInfo(this.modelType)

      // this.defaultUserSettings = this.ownedSettings[0]
      console.log("defaultUserSettings", this.defaultUserSettings)
      // this.defaultUserSettingsId = defaultUserSettings?.id ?? null
      this.loading = false
      // console.log(this.ownedSettings)
    },
    findSettingsById(id: string): UserSettingsInfo | undefined {
      if (this.ownedSettingIds.has(id)) {
        return this.ownedSettings.find(s => s.id === id)
      } else if (this.writeableSettingIds.has(id)) {
        return this.writeableSettings.find(s => s.id === id)
      } else {
        return this.readableSettings.find(s => s.id === id)
      }
    },
    openAccessOptionsDialog(settingsId: string) {
      this.settingsToEdit = this.findSettingsById(settingsId)!
      this.showAccessOptionsDialog = true
    },
    async saveAccessOptions() {
      // TODO: implement
      const request = {
        id: this.settingsToEdit!.id,
        name: this.settingsToEdit!.name,
        modelType: this.settingsToEdit!.modelType,
        writeAccess: this.settingsToEdit!.writeAccess,
        readAccess: this.settingsToEdit!.readAccess
      }
      await this.bi.updateUserSettingsInfo(request)
      // await this.bi.sara_SettingChangeWriteAccess(this.settingsToEdit!.id, this.settingsToEdit!.writeAccess)
      // await this.bi.sara_SettingChangeReadAccess(this.settingsToEdit!.id, this.settingsToEdit!.readAccess)
      this.showAccessOptionsDialog = false
      await this.loadSettings()
    }
  },
});
