
import { BackendInterface } from "@/scripts/BackendInterface";
import { DataStatus, SiloAssignmentDailyDataStatus } from "@/scripts/models/coffee";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { CoffeeProductionModelType, DateRequest, DataStatusesRequest } from "@/scripts/models/request";

export default Vue.extend({
  name: "DatePicker",
  components: {},
  props: {
    value: { type: [Array, String], required: true },
    range: { type: Boolean, default: false },
    rangeFromStartOfWeek: { type: Boolean, default: false },
    rangeToEndOfWeek: { type: Boolean, default: false },
    rangeToNextMonday: { type: Boolean, default: false },
    modelType: { type: String, default: CoffeeProductionModelType.SARA }, // SARA, PAPA, DORI
    initialDate: { type: String },
    updateParams: { type: Boolean, default: false },
    hideLegend: { type: Boolean, default: false },
  },
  data() {
    return {
      dates: [] as string[] | string | null,
      //datesWithAvailableCellplan: [] as string[],
      locale: "de-DE",
      truncatedDates: [] as string[],
      datesLocked: false,
      dateRangeLegend: [
        { color: "#ff7665", name: "Daten unzulässig" },
        { color: "#ffd845", name: "Daten unvollständig" },
        { color: "#86ca8a", name: "Daten vollständig" },
        { color: "#008aff", name: "Lösung berechnet" },
      ],
      dataStatuses: [] as SiloAssignmentDailyDataStatus[],
      solutionDays: [] as string[],
      currentMonth: null as null|string,
    };
  },
  async created() {
    await this.update();
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.dates = v;
      },
    },
    initialDate: {
      immediate: true,
      async handler(v: string) {
        // console.log("initialDate watcher");
        if (v) {
          this.dates = this.range ? [this.initialDate] : this.initialDate;
          await this.updateRanges();
        }
      },
    },
    // async dates() {
    //   // console.log("DatePicker watcher dates");
    //   await this.updateRanges();
    // },
    async rangeToEndOfWeek() {
      // console.log("rangeToEndOfWeek", this.dates);
      if (this.dates !== null && this.dates.length > 0) await this.updateRanges();
    },
    async rangeToNextMonday() {
      // console.log("rangeToNextMonday", this.dates !== null && this.dates);
      if (this.dates !== null && this.dates.length > 0) await this.updateRanges();
    },
    userType() {
      this.update();
    },
    async modelType() {
      // this.dates = this.range ? [this.initialDate] : this.initialDate;
      // await this.updateRanges();
      this.dates = this.range ? [] : null;
      this.update();
    },
  },
  computed: {
    ...mapState(["superuser", "user", "backendInterface", "productionSiteUniqueName"]),
    eventMap(): string[] {
      const determineColor = (dataStatus: SiloAssignmentDailyDataStatus) => {
        // @ts-ignore
        const status = DataStatus[dataStatus.status];
        if (status === DataStatus.FullyFilledIn) return "#86ca8a";
        if (status === DataStatus.NotFullyFilledIn) return "#ffd845";
        if (status === DataStatus.Invalid) return "#ff7665";
        return "#ff5645bb";
      };
      // console.log("dataStatuses", typeof this.dataStatuses)
      const eventMap: any = Object.fromEntries(this.dataStatuses.map((ds) => [ds.startDayDate, determineColor(ds)]));
      // log({eventMap})
      return eventMap;
    },
  },
  methods: {
    ...mapMutations(["toggleSuperuser"]),
    async update() {
      await this.updateCurrentMonthData()
      // // await this.updateCurrentMonth()
      // const productionSiteUniqueName = this.productionSiteUniqueName
      // const bi = this.backendInterface as BackendInterface;
      // //this.datesWithAvailableCellplan = await bi.fetch_cellplanDates(this.productionSiteUniqueName);
      // const dateNow = new Date().toISOString().slice(0,10);
      // const endOfWeekString = await bi.getEndOfWeek({
      //   productionSiteUniqueName,
      //   date: dateNow
      // })
      // const endOfWeekDate = new Date(endOfWeekString)
      // // const endOfNextWeekDate = new Date(endOfWeekDate.getTime() + 7 * 24 *  60 * 60 * 1000)
      // // const endOfNextWeekString = endOfNextWeekDate.toISOString().slice(0,10)
      // // console.log("END OF NEXT WEEK", endOfNextWeekString);
      //
      // const days32 = 32 * 24 * 60 * 60 * 1000;
      // const datePickerRangeRequest: DataStatusesRequest = {
      //   productionSiteUniqueName: this.productionSiteUniqueName,
      //   dataValidityTimeLower: new Date(endOfWeekDate.getTime() - days32).toISOString(),
      //   dataValidityTimeUpper: new Date(endOfWeekDate.getTime()).toISOString(),
      // }
      // await this.updateInDateRange(datePickerRangeRequest)
    },
    async getEndOfNextWeekString(): Promise<string> {
      const bi = this.backendInterface as BackendInterface;
      const productionSiteUniqueName = this.productionSiteUniqueName

      const dateNow = new Date().toISOString().slice(0,10);
      const endOfWeekString = await bi.getEndOfWeek({
        // productionSiteUniqueName,
        date: dateNow
      })
      const endOfWeekDate = new Date(endOfWeekString)
      const endOfNextWeekDate = new Date(endOfWeekDate.getTime() + 7 * 24 *  60 * 60 * 1000)
      const endOfNextWeekString = endOfNextWeekDate.toISOString().slice(0,10)
      return endOfNextWeekString;
    },
    async updateInDateRange(datePickerRangeRequest: DataStatusesRequest){
      // console.log("updating in range: ", datePickerRangeRequest.dataValidityTimeLower, datePickerRangeRequest.dataValidityTimeUpper)
      const bi = this.backendInterface as BackendInterface;
      const productionSiteUniqueName = this.productionSiteUniqueName
      const endOfNextWeekString = await this.getEndOfNextWeekString()
      const solutionDaysRequest = {
        productionSiteUniqueName,
        timeRangeStart: datePickerRangeRequest.dataValidityTimeLower,
        timeRangeEnd: datePickerRangeRequest.dataValidityTimeUpper,
      };
      if (this.modelType === CoffeeProductionModelType.SARA) {
        this.dataStatuses = await bi.sara_GetDataStatusesTimed(datePickerRangeRequest);
        this.solutionDays = await bi.sara_ListSolutionDays(solutionDaysRequest);
      } else if (this.modelType === CoffeeProductionModelType.PAPA) {
        this.dataStatuses = await bi.papa_GetDataStatusesTimed(datePickerRangeRequest);
        this.solutionDays = await bi.papa_ListSolutionDays(solutionDaysRequest);
      } else if (this.modelType === CoffeeProductionModelType.DORI) {
        this.dataStatuses = await bi.dori_GetDataStatusesTimed(datePickerRangeRequest);
        this.solutionDays = await bi.dori_ListSolutionDays(solutionDaysRequest);
        // throw new Error(`Logic for modelType=${this.modelType} has not yet been implemented.`);
      }
    },
    functionEvents(date: string) {
      const first = this.eventMap[date as any] ?? "";
      const second = this.solutionDays.includes(date) ? "#008aff" : null;
      return [first, second];
    },
    async updateRanges() {
      if (!this.range) {
        // console.log("dates is not a range");
        this.$emit("input", this.dates);
        return;
      }
      // @ts-ignore
      let dates: [string, string] = [...this.dates];
      // if(this.dates.length === 2){
      //   dates = [this.dates[0], this.dates[1]]
      // }

      const bi = this.backendInterface as BackendInterface;
      const oneDay = 24 * 60 * 60 * 1000;
      const oneWeek = oneDay * 7;
      if (this.dates != null && this.range) {
        if (this.rangeFromStartOfWeek) {
          const endOfPrevWeek = await bi.getEndOfWeek({
            productionSiteUniqueName: this.productionSiteUniqueName,
            date: new Date(new Date(dates[0]).getTime() - oneWeek),
          } as DateRequest);
          const startOfWeek = new Date(new Date(endOfPrevWeek).getTime() + oneDay);
          const startOfWeekDate = startOfWeek.toISOString().slice(0, 10);
          // to avoid an infinite loop, we don't want this to be reactive
          dates[0] = startOfWeekDate;
          // this.$forceUpdate();
        }
        if (this.rangeToEndOfWeek) {
          const endDate = await bi.getEndOfWeek({
            productionSiteUniqueName: this.productionSiteUniqueName,
            date: dates[0],
          } as DateRequest);
          // to avoid an infinite loop, we don't want this to be reactive
          dates[1] = endDate;
          // this.$forceUpdate();
        } else if (this.rangeToNextMonday) {
          const endDate = await bi.getEndOfWeek({
            productionSiteUniqueName: this.productionSiteUniqueName,
            date: dates[0],
          } as DateRequest);
          let d = new Date(endDate).getTime() + oneDay;
          let d2 = new Date(d).toISOString().slice(0, 10);
          // to avoid an infinite loop, we don't want this to be reactive
          dates[1] = d2;
          // this.$forceUpdate();
        }
      }
      this.dates = dates;
      // this.$nextTick(() => {
      this.$emit("input", this.dates);
      // })
    },
    updateCurrentMonthData(){
      if(this.currentMonth === null) {
        // console.warn("currentMonth is null")
        return;
      }
      const monthStartDate = new Date(this.currentMonth)
      const oneMonth = 31 * 24 * 60 * 60 * 1000
      const monthEndDate = new Date(monthStartDate.getTime() + oneMonth)

      const datePickerRangeRequest: DataStatusesRequest = {
        // productionSiteUniqueName: this.productionSiteUniqueName,
        dataValidityTimeLower: monthStartDate.toISOString(),
        dataValidityTimeUpper: monthEndDate.toISOString(),
      }
      this.updateInDateRange(datePickerRangeRequest)
    },
    handleMonthClick(event: any){
      // TODO: improve this check to use regex
      const isMonthChange = event.length === 7
      if(isMonthChange){
        this.currentMonth = event
        this.updateCurrentMonthData()
      }
      // console.log("month event", event)
    },
  },
});
