
import Vue, {PropType} from "vue";

import {
  DifferentiationEntries,
  GanttChartItemStatus,
  GanttChartItem,
  GanttChartItemProperties,
  UnitStatus,
  UnitStatusType,
  GanttChartViewConfiguration,
} from "@/scripts/gantt/gantt";
import {palette, timeSorters} from "@/scripts/utils";
import GanttChartBar from "./GanttChartBar.vue";
import {GanttChartItemAggregationGroup} from "@/scripts/gantt/GanttChartItemAggregationGroup";
import {WEEKDAYS} from "@/scripts/models/date";
import GanttChartState from "@/scripts/gantt/GanttChartState";

export default Vue.extend({
  name: "GroupGanttChart",
  components: {
    GanttChartBar,
  },
  props: {
    config: {type: Object as PropType<GanttChartViewConfiguration>},
    state: {type: Object as PropType<GanttChartState>},
    chartId: {type: String as PropType<string>},
    group: {type: Object as PropType<GanttChartItemAggregationGroup>},
    timeResolution: {type: Number as PropType<number>, default: 60 * 60 * 1000},
    pixelsPerTimeResolutionStep: {type: Number as PropType<number>, default: 70},
    timeCursorTime: {type: Number as PropType<number>, default: () => Date.now()},
  },
  data: () => ({
    // timeCursorRelativePosition: 0,
    // showTimeCursor: true,
    // lockTimeCursor: true,
    chartRowHeight: 24,
    viewWidth: 1000,
    chipInfo: null,
    chartPaddingLeft: 0, //px
    scroll: {
      isDown: false,
      mouseStartX: 0,
      scrollX: 0,
      mousePrevX: 0,
    },
    // scrollX: 0,
  }),
  beforeCreate() {
  },
  created() {
  },
  mounted() {
    // console.log("this.group", this.group);
    // @ts-ignore
    this.viewWidth = this.$refs["ganttView"].getBoundingClientRect().width;
    // this.updateTimeCursorRelativePosition();
    // this.state.updateTimeCursorPosition()
    // this.updateChartPositionToTimeCursor();
    // this.$forceUpdate();
    // this.state.currentTime = new Date(this.timeCursorTime)
    // this.state.updateTimeCursorPosition();
    // this.updateChartPositionToTimeCursor();
    this.state.updateTimeCursorPosition();
    this.updateChartPositionToTimeCursor();
    setInterval(() => {
      this.state.currentTime = Date.now()
      // console.log("update time cursor position", this.timeCursorTime)
      // this.state.updateTimeCursorPosition();
      // this.updateChartPositionToTimeCursor();
    }, 10_000);

    if(!this.state?.showTimeCursor && !isNaN(this.state.initialPosX)){
      this.scroll.scrollX = -this.state.initialPosX * this.chartWidth + 20;
      this.setChartXTranslation(this.scroll.scrollX);
      this.$forceUpdate();
    }
  },
  watch: {
    "state.updateTrigger": function (v) {
      // console.log("state.updateTrigger");
      // TODO: fix
      this.updateChartPositionToTimeCursor();
      this.$forceUpdate();
    },
    // timeCursor() {
    //   // console.log("updated time cursor", this.timeCursor);
    //   this.$forceUpdate();
    // },
  },
  computed: {
    chartHeight(): number {
      return this.group.aggregations.length * this.chartRowHeight;
    },
    ganttChartBarProps(): any {
      return {
        config: this.config,
        differentiate: this.config.differentiate,
        itemsTimespan: this.state.itemsTimespan,
        chartWidth: this.chartWidth,
        scroll: this.scroll,
      };
    },
    insetShadow(): string {
      // console.log(this.scroll.scrollX);
      return this.scroll.scrollX < 0 ? "box-shadow: inset 100px 0px 100px -100px rgba(0,0,0,0.1);" : "";
    },
    timelineTicks(): any[] {
      // @ts-ignore
      // let ts: number[] =
      let [t0, t1]: [number, number] = this.state.itemsTimespan;
      const usableChartWidth = this.chartWidth - this.chartPaddingLeft;

      // find first tick that aligns with time granularity
      let d0 = new Date(t0);
      let [y, m, d] = [d0.getFullYear(), d0.getMonth(), d0.getDate()];
      let t = new Date(y, m, d, 0, 0).getTime();
      while (t < t0) t += this.timeResolution;

      // const convertDate = (d: Date) => {
      //   const dateString = d.toLocaleString("de-DE", { dateStyle: "short", timeStyle: "short" } as any);
      //   const [date, time] = dateString.split(", ");
      //   return `<span>${date}</span><span class="mx-1">${time}</span>`;
      // };
      let ticks = [];
      let prevDayIndex = -1;
      let prevShift = "";
      const createTick = (t: number) => {
        // TIME
        let d = new Date(t);
        let h = d.getHours().toString();
        let m = d.getMinutes().toString();
        if (m.length == 1) m = `0${m}`;
        if (h.length == 1) h = `0${h}`;
        let time = `${h}:${m}`;
        // console.log("day", day);

        // SHIFT
        let shift = "";
        // console.log(h, h === "06");

        if (h === "06") {
          shift = "FS";
        } else if (h === "14") {
          shift = "SS";
        } else if (h === "22") {
          shift = "NS";
        } else {
          shift = prevShift;
        }
        const shiftChanged = shift !== prevShift;
        prevShift = shift;

        // DAY
        let dayIndex = d.getDay(); // day starts at sunday = 0
        if (dayIndex === 0) dayIndex = 6;
        else dayIndex--;
        const day_ger = WEEKDAYS[dayIndex];
        const dayChanged = dayIndex !== prevDayIndex;
        prevDayIndex = dayIndex;

        // BUILD STRING
        let text = "";
        if (shiftChanged) {
          text = `<span class="font-weight-bold">${shift} ${day_ger.slice(0, 2)}</span>${time}`;
        } else {
          text = ` <br>${time}`;
        }

        return {
          pos: ((t - t0) / (t1 - t0)) * usableChartWidth + this.chartPaddingLeft,
          date: d,
          text,
          color: shiftChanged ? "black" : "#666",
          // text: convertDate(d)
        };
      };
      do ticks.push(createTick(t));
      while ((t += this.timeResolution) < t1);

      return ticks;
    },
    chartWidth(): number {
      // @ts-ignore
      let ts = this.state.itemsTimespan[1] - this.state.itemsTimespan[0];
      let tg = this.timeResolution;
      return (ts / tg) * this.pixelsPerTimeResolutionStep;
    },
  },
  methods: {
    updateChartPositionToTimeCursor() {
      // return;
      if (!this.state.lockTimeCursor) return;
      // this.updateTimeCursorRelativePosition();
      this.scroll.scrollX = -this.state.timeCursorRelativePosition * this.chartWidth + 200;
      this.setChartXTranslation(this.scroll.scrollX);
      this.$forceUpdate();
    },
    // updateTimeCursorRelativePosition(): void {
    //   let [start, end] = this.state.itemsTimespan;
    //   this.timeCursorRelativePosition = (Date.now() - start) / (end - start);
    // },
    startHorizontalScroll(e: any) {
      this.scroll.isDown = true;
      this.scroll.mousePrevX = e.pageX;
    },
    stopHorizontalScroll(e: any) {
      this.scroll.isDown = false;
    },

    horizontalScroll(e: any) {
      e.preventDefault();
      // console.log(this.viewWidth);
      if (!this.scroll.isDown) return;
      if (this.state.lockTimeCursor) this.state.lockTimeCursor = false;
      let {mouseStartX, mousePrevX} = this.scroll;
      // let el = this.$refs["chart"] as any;
      let dx_mouse = e.pageX - mousePrevX;
      dx_mouse *= 4;
      this.scroll.mousePrevX = e.pageX;

      let x = this.scroll.scrollX + dx_mouse;
      // let x_min = this.viewWidth - this.chartWidth - 1000
      let x_min = -this.chartWidth
      let x_max = 0;
      x = Math.min(x_max, Math.max(x_min, x));

      this.scroll.scrollX = x;
      // console.log("x", x);

      // el.style.transform = `translate3d(${x}px,0,0)`;
      this.setChartXTranslation(x);
    },
    setChartXTranslation(x: number) {
      // console.log(x)
      let el = this.$refs["chart"] as any;
      try {
        el.style.transform = `translate3d(${x}px,0,0)`;
      } catch (e) {
      }
    },
  },
});
