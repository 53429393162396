<template>
  <div>
    <v-breadcrumbs :items="items" class="pa-0">
      <template v-slot:divider>
        <v-icon>mdi-circle-small</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "Breadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
</script>

<style lang="sass">
@import "src/assets/styles/variables"

.v-breadcrumbs
  font-weight: 400
  .v-breadcrumbs__item
    color: red !important
  .v-breadcrumbs__divider
    padding: 0 !important
    color: grey !important
  .v-breadcrumbs__item--disabled
    color: #3d3a3b !important
</style>
