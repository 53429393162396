
import Vue, {PropType} from "vue";
import { mapMutations, mapState } from "vuex";
import {formatDate, formatDateShort} from "@/scripts/utils";
import {UserSettingsInfo} from "@/scripts/models/results";



export default Vue.extend({
  name: "UserSettingsDisplay",
  components: {},
  props: {
    userSettingsInfo: {
      type: Object as PropType<UserSettingsInfo>,
      required: true,
    },
    defaultSettingsId: {
      type: String,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    lastUpdated(): string {
      return formatDateShort(this.userSettingsInfo.lastUpdated)
    },
    ownerUsername(): string {
      if('owner' in this.userSettingsInfo){
        // @ts-ignore
        const owner = this.userSettingsInfo.owner
        return owner.firstName + ' ' + owner.familyName
      }
      return this.userSettingsInfo.ownerUsername ?? 'Unbekannt'
    },
  },
  methods: {
    ...mapMutations([""]),
  },
});
