import CoffeeProductionSARASummary from "../SiloAssignmentSolution";
import {CoffeeProductionInputData, CoffeeProductionSolverParameters, CoffeeBasketValues} from "./calculation";
import {
  CoffeeRoasterAvailability,
  CoffeeArticlePackagingLine,
  SolutionHashes,
  CoffeeBasket,
  CoffeeRoaster,
  CoffeeProductionCompliancePlanFull,
  CoffeeArticleProductionAssignment,
  CoffeeSiloAllocation,
  CoffeeBlendFulfilledDemand
} from "./coffee";
import {DayOfWeekDate, DayOfWeekDateRange, Weekday} from "./date";
import {CoffeeArticlePriorityItem} from "./gas";
// import { UserType } from "./general";
import {CoffeeRoasterGasConsumption, ProductionPlanHandlingType, PreroastedBasketAssignmentResult} from "./papa";
import {CoffeeProductionModelType} from "./request";
import {
  CoffeeProductionModelSolutionStatus,
  SolutionQuality,
  RoasterTaskResult,
  RoasterSubtaskResult,
  CuredArticleTaskResult,
  MillingSubTaskResult,
  CoffeeRoasterEnergyConsumption
} from "./results";
import {UserRole} from "@/scripts/auth";

export interface CoffeeProductionDORIInputSettings {
  /**
   * The start hour of the computation "day" default is 6 since JDE considers
   * a work day 6:00-6:00. Not changed by the frontend.
   */
  dayStartHour: number // double
  /**
   * The maximum difference between the roast and ground and Tassimo
   * compliance plan actualization times in minutes.
   * Changed only by superuser in the frontend.
   */
  rgAndTassimoActualizationTimeMaxDiffInMinutes: number; // double
  /**
   * Roaster availability exclusion time ranges for the computation day range,
   * e.g., if the real computation day range is tuesday-friday then we could
   * have an exclusion time for Jetzone 1, tuesday, 10:30-11:45 and for
   * Jupiter 9, wednesday, 1:00-4:30.
   */
  roasterAvailabilityExclusions: CoffeeRoasterAvailabilityExclusion[];
  /**
   * The number of hours in a time step, currently only a multiple of 24 is
   * supported.
   */
  hoursInTimeStep: number // int
  /**
   * The max number of time steps, this constant and the computation time range
   * determines the number of time steps considered in DORI, e.g., if
   * time range = tuesday-sunday, maxTimeStepNum = 2 and hoursInTimeStep = 48,
   * DORI will compute 2 time steps, each 48h, meaning the real computation
   * day range is tuesday-friday. Changed only by superuser in the frontend.
   */
  maxTimeStepNum: number // int
  /** Force DORI to fulfill the exact roasting instructions given by SARA */
  saraDecidesRoastingWeights: boolean
  /** Article priorities used for weighting deviations from the packaging plan */
  articlePriorities: CoffeeArticlePriorities[];
  /** Roaster gas consumptions */
  gasConsumptions: CoffeeRoasterGasConsumption[];
  /** Packaging line information */
  packagingLines: CoffeeArticlePackagingLine[];
  /** Setting for the objective (weights) */
  objectiveSettings: CoffeeProductionDORIObjectiveSettings;
  /**
   * The minimal number of roaster tasks allowed, per time step and roaster.
   * Changed only by superuser in the frontend.
   */
  minRoasterTaskNum: number // int
  /**
   * The maximal number of roaster tasks allowed, per time step and roaster.
   * Changed only by superuser in the frontend.
   */
  maxRoasterTaskNum: number // int
  /**
   * The maximal number of splits allowed per milling task.
   */
  maxSplitsPerMillingTask: number;// int
  /**
   * The maximal number of total milling task splits allowed is this ratio times
   * the number of milling tasks.
   */
  millingTaskSplitNumberRatio: number;// double
  /**
   * Above this milling task difficulty the milling task split number will
   * be as high as possible.
   */
  millingTaskCriticalDifficultyThreshold: number;// double
  /**
   * Below this milling task difficulty the milling task will not be split.
   */
  millingTaskMinimalDifficultyThreshold: number;// double
  /**
   * Use multi-stage solving for the DORI model, using different objective
   * part deactivation and deviation bound fixing strategies.
   */
  useMultiStageSolving?: boolean | false
}

export enum ExclusionReason {
  OTHER = "OTHER",
  CLEANING_A = "CLEANING_A",
  CLEANING_B = "CLEANING_B",
  CLEANING_C = "CLEANING_C",
  CLEANING_J = "CLEANING_J",
  MAINTENANCE = "MAINTENANCE",
}

export enum ExclusionOrigin {
  SARA = "SARA",
  USER = "USER",
}

export interface CoffeeRoasterAvailabilityExclusion {
  roasterName: string // String;
  exclusionOrigin: ExclusionOrigin
  exclusionReason: ExclusionReason // ExclusionReason;
  description: string // String;
  exclusionStartDay: Weekday // DayOfWeekDate;
  //e.g.,0.0 - 10.5, both bounds greater than 0, meaning 00:00h - 10:30h,
  //in case the model considers several days as a time step it can go over 24.0
  exclusionStartHour: number // double;
  exclusionEndHour: number // double;
}

export interface CoffeeArticlePriorities {
  articleNr: string // String;
  productionTimePriorityFactor: number // double; //value in (0,1]
  productionDeviationPricePlus: number // double; //value in (0,1]
  productionDeviationPriceMinus: number // double; //value in (0,1]
}

export interface CoffeeProductionDORIObjectiveSettings {
  objectiveWeightTotalRoasterEnergy: number // double;
  objectiveWeightTotalRoasterTaskNum: number // double;
  objectiveWeightTotalRoasterUtilization: number // double;
  objectiveWeightRoasterTaskTimeGap: number // double;
  objectiveWeightCuredArticleProvisioningStartTime: number // double;
  objectiveWeightWeightedPackagingTaskDeviation: number // double;
  objectiveWeightRoastedAndCuredBasketsDeviation: number // double;
  objectiveWeightBasketPreroastingNeedDeviation: number // double;
  objectiveWeightCuredBasketUsageDeviation: number // double;
  //turn off all deviations?
  noDeviations: boolean // boolean;
}

export interface DORISolveResultRequest {
  // userSettingsId: string
  productionSiteUniqueName: string; // String;
  timeRangeStart: string; // LocalDate;
  timeRangeEnd: string; // LocalDate;

  inputFileIds: string[];
  saraSummaryId: string;
  inputSettings: CoffeeProductionDORIInputSettings;
  coffeeBaskets: CoffeeBasket[];
  coffeeRoasters: CoffeeRoaster[];
  siloAllocations: CoffeeSiloAllocation[];
  fulfilledDemands: CoffeeBlendFulfilledDemand[];
  solverParameters: CoffeeProductionSolverParameters;
}

export interface CoffeeProductionDORISolveRequestRequest {
  saraSummaryId: string
  productionSiteUniqueName: string; // String;
  timeRangeStart: string; // LocalDate;
  timeRangeEnd: string; // LocalDate;
  inputFileIds: string[];
  // userType: UserType;
  preroastForNextWeek: boolean;
  substituteMissingBasketsByTestBaskets: boolean;
  userSettingsId: string;
}

export interface CoffeeProductionDORISolveRequest {
  userSettingsId: string
  id: string // String @Id;
  modelType: CoffeeProductionModelType.DORI
  creationTime: Date | string; // LocalDateTime;
  userType: UserRole// CoffeeProductionUserType;
  productionSiteUniqueName: string // String;
  timeRange: DayOfWeekDateRange// DayOfWeekDateRange;
  inputData: CoffeeProductionInputData //CoffeeProductionInputData
  inputSettings: CoffeeProductionDORIInputSettings //CoffeeProductionDORIInputSettings
  solverParameters: CoffeeProductionSolverParameters //CoffeeProductionSolverParameters
  saraSummaryId: string
  // SARASummary: CoffeeProductionSARASummary;
  //parent summary id, if set in the frontend, the solver will try to use the
  //solution stored in the parent summary as a start point for the optimization
  parentSummaryId: string | null;
}

export interface CoffeeProductionDORISummary {
  id: string // String ;
  name: string // String ;
  description: string // String ;
  creationTime: Date | string; // LocalDateTime;
  productionSiteUniqueName: string // String ;
  userType: UserRole // CoffeeProductionUserType ;
  timeRangeStart: string // Instant ;
  timeRangeEnd: string //Instant ;
  hashes: SolutionHashes // CoffeeProductionAssignmentSummaryHashes ;
  timeRange: DayOfWeekDateRange // DayOfWeekDateRange ;
  baskets: CoffeeBasket[] // CoffeeBasket[] ;
  roasters: CoffeeRoaster[] // CoffeeRoaster[] ;
  initialStoredBasketWeights: CoffeeBasketValues // CoffeeBasketValues ;
  compliancePlan: CoffeeProductionCompliancePlanFull // CoffeeProductionCompliancePlanFull;
  inputSettings: CoffeeProductionDORIInputSettings // CoffeeProductionDORIInputSettings;
  solverParameters: CoffeeProductionSolverParameters // CoffeeProductionSolverParameters;
  result: CoffeeProductionDORIResult //CoffeeProductionDORIResult;
  solutionStatus: CoffeeProductionModelSolutionStatus
  solutionQuality: SolutionQuality;
  firstDayStartTime: string // Instant ;
  //parent summary id. If not null the computation which resulted in this summary, used 
  //the minimizer stored in the parent summary as the start point for the optimization 
  parentSummaryId: string | null;
}

export interface SolverResult {
  //objective value
  objectiveValue: number // double;
  bestObjectiveBound: number // double;
  relativeMipGap: number // double;
  //solution status
  solutionStatus: CoffeeProductionModelSolutionStatus // CoffeeProductionModelSolutionStatus;
  //solution process info
  solverType: string // MILPSolverType;
  solutionHash: number // double;
  solutionTimeMS: number // long;
  solverOutput: string // String;
  //objective parts
  totalRoasterEnergy: number // double;
  totalRoasterTaskNum: number // int;
  totalRoasterUtilization: number // int;
  roasterTaskStartTimeSum: number // int;
  weightedCuringTaskStartTimeSum: number // double;
  totalWeightedProductionDeviation: number // double;
}

export interface RoasterTaskGap {
  roasterName: string // String ;
  timeStep: number // int ;
  exclusionReason: ExclusionReason // ExclusionReason ;
  description: string // String ;
  startTime_h: number // double ;
  duration_h: number // double ;
  endTime_h: number // double ;
}

export interface MillingTaskGap {
  millNumber: number // int ;
  timeStep: number // int ;
  exclusionReason: ExclusionReason // ExclusionReason ;
  description: string // String ;
  startTime_h: number // double ;
  duration_h: number // double ;
  endTime_h: number // double ;
}

export interface CoffeeSiloUtilization {
  nb: number // int ;
  nt: number // int ;
  utilizationStates: SiloUtilizationState[]
}

export interface SiloUtilizationState {
  basketSpsNr: number //int, the basket SPS number
  basketIndex: number //int, the basket index (only used for building caches)
  timeStep: number //int, the time step (usually days)
  sampleTimeInTimeStep_h: number //double, the elapsed hours during the time step
  sampleTime_h: number //double, the elapsed hours counted from the start day of the computation
  capacity_ton: number //double, the silo capacity reserved by SARA for the basket
  reservedSiloSpsNrs: number[] //int[], the SPS numbers of the reserved silos reserved by SARA
  extendedCapacity_ton: number //double, the silo capacity reserved by SARA plus the capacity of the additional silos
  additionalSiloSpsNrs: number[] //int[], the SPS numbers of the additional silos reserved by DORI
  storedWeight_ton: number //double, the stored weight in the silos
  sampleTimeTypes: SiloSampleTimeType[] //the sample time types
}

export enum SiloSampleTimeType {
  START_OF_ROASTER_TASK = "StartOfRoasterTask",
  START_OF_MILLING_TASK = "StartOfMillingTask",
  END_OF_ROASTER_TASK = "EndOfRoasterTask",
  END_OF_MILLING_TASK = "EndOfMillingTask",
}

export interface CoffeeProductionDORIResult {
  id: String;
  /** solver result information, e.g., solution quality */
  solverResult: SolverResult;
  /** the total roaster energy consumption information */
  totalEnergyConsumption: CoffeeRoasterEnergyConsumption;
  /** the roaster energy consumption information for roast and ground */
  roastAndGroundEnergyConsumption: CoffeeRoasterEnergyConsumption;
  /** the roaster energy consumption information for roast and tassimo */
  tassimoEnergyConsumption: CoffeeRoasterEnergyConsumption;
  /** the resulting roaster tasks */
  roasterTaskResults: RoasterTaskResult[];
  /** the roaster task gaps given in the settings */
  roasterTaskGaps: RoasterTaskGap[];
  /** the resulting roaster subtasks */
  roasterSubtaskResults: RoasterSubtaskResult[];
  /** the resulting preroasted basket assignments */
  preroastedBasketAssignmentResults: PreroastedBasketAssignmentResult[];
  /** the resulting cured article tasks */
  curedArticleTaskResults: CuredArticleTaskResult[];
  /** the resulting packaging tasks */
  millingSubTaskResults: MillingSubTaskResult[];
  // TODO: docu
  millTaskGaps: MillingTaskGap[];
  /** the production assignment details */
  productionAssignments: CoffeeArticleProductionAssignment[];
  /** the silo utilization based on the solution variable values, null if the silo utilization model was turned off */
  siloUtilization: CoffeeSiloUtilization
  /** the silo utilization computed based on the scheduled milling/roaster tasks times of the solution */
  siloUtilizationComputed: CoffeeSiloUtilization
  /** increase or decrease of the basket preroasting needs */
  roastingDeviations: RoastingDeviation[]
}

export interface RoastingDeviation {
  roasterName?: string //only set for initial roasting deviations
  basketSpsNr: number
  basketWeightDeviation_ton: number
  plannedBasketWeight_ton: number
  deviationType?: RoastingDeviationType
  timeStep: number
}

export enum RoastingDeviationType {
  FOR_CURRENT_DAY = "ForCurrentDay",
  FOR_NEXT_DAYS = "ForNextDays",
  ALREADY_PLANNED_TASK = "AlreadyPlannedTask"
}