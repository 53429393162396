
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "SliderTableRow",
  components: {},
  props: {
    value: { type: Number, required: true },
    name: { type: String, required: false, default: "" },
    step: { type: Number, required: false, default: 0.05 },
    min: { type: Number, required: false, default: 0 },
    max: { type: Number, required: false, default: 1 },
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    ...mapMutations([""]),
  },
});
