
import {
  CoffeeBasket,


} from "@/scripts/models/coffee";
import { anyNull, anyNullOrNegative, camelCaseToWords} from "@/scripts/utils";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import FileUpload from "@/components/common/FileUpload.vue";
import GenericSettings from "./GenericSettings.vue";
import { BackendInterface } from "@/scripts/BackendInterface";
import { NameValueTuple } from "@/scripts/models/general";
import {CoffeeProductionInputData} from "@/scripts/models/calculation";
import {SolveRequest} from "@/scripts/models/request";
import {DayOfWeek} from "@/scripts/models/date"; 

export default Vue.extend({
  name: "DataSettings",
  props: {
    value: {
      type: Object, // SolveRequest
      required: true,
    },
  },

  components: {
    FileUpload,
    GenericSettings,
  },
  data: function() {
    return {
      // UserType,
      solveRequest: {} as SolveRequest,
      solverOutput: "",
      useDecafSilos: false,
      selectedBackend: "",
      updateVersion: true,
      solverParamsTab: null,

      objectiveTypes: [] as NameValueTuple[],
      solutionStrategies: [] as NameValueTuple[],

      selectedObjectiveType: "",
      selectedSolutionStrategy: "",

      loadingInputData: false,
    };
  },
  created() {
    this.solveRequest = this.value;
  },
  watch: {
    selectedBackend() {
      this.selectBackend(this.selectedBackend);
    },
  },
  computed: {
    ...mapState(["superuser", "user", "backendUrl", "availableSolveRequest", "availableBackends", "backendInterface"]),

    superuserSettings(): any[] {
      return [
        //
        // Input Data
        //
        {
          inputType: "heading",
          label: "Input Data Settings",
          cols: "12",
        },
        // max baskets
        {
          inputType: "text",
          items: [],
          label: "Max. Baskets",
          model: ["solveRequest", "inputSettings", "maxNumBaskets"],
          type: "number",
          cols: "3",
        },
        // max silos
        {
          inputType: "text",
          items: [],
          label: "Max. Silos",
          model: ["solveRequest", "inputSettings", "maxNumSilos"],
          type: "number",
          cols: "3",
        },

        // clamp stored volumes
        {
          inputType: "checkbox",
          items: [],
          label: "Clamp Stored Volumes",
          model: ["solveRequest", "inputSettings", "clampStoredSiloVolumes"],
          type: "",
          cols: "6",
        },
        // Apply Weight Deviations
        {
          inputType: "checkbox",
          items: [],
          label: "Apply Weight Deviations",
          model: ["solveRequest", "inputSettings", "applyCoffeeWeightDeviations"],
          type: "",
          cols: "6",
        },
        //
        // FILTER
        //
        // Keep Decaf
        // {
        //   inputType: "checkbox",
        //   items: [],
        //   label: "Keep Decaf Baskets",
        //   model: ["solveRequest", "inputFilter", "keepDecafBaskets"],
        //   type: "",
        //   cols: "6",
        // },
      ];
    },
    userSettings(): any[] {
      return [
        {
          inputType: "heading",
          label: "Verfügbare Waagengruppen",
          cols: "12",
        },
        // Keep scale group 1
        {
          inputType: "checkbox",
          items: [],
          label: "WG1 (301-309)",
          model: ["solveRequest", "inputFilter", "keepScaleGroup1"],
          type: "",
          cols: "4",
        },
        // Keep scale group 2
        {
          inputType: "checkbox",
          items: [],
          label: "WG2 (311-326)",
          model: ["solveRequest", "inputFilter", "keepScaleGroup2"],
          type: "",
          cols: "4",
        },
        // Keep scale group 3
        {
          inputType: "checkbox",
          items: [],
          label: "WG3 (327-345)",
          model: ["solveRequest", "inputFilter", "keepScaleGroup3"],
          type: "",
          cols: "4",
        },
      ];
    },
  },
  methods: {
    ...mapMutations(["selectSolveRequest", "selectBackend"]),
  },
});
