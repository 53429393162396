
import Vue, {PropType} from "vue";
import {mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CoffeeProductionInputFileType, SiloAssignmentInputFile} from "@/scripts/models/calculation";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import CellplanUpload from "@/components/upload/CellplanUpload.vue";
import {Weekday} from "@/scripts/models/date";
import {InputFileManager} from "@/scripts/InputFileManager";

export default Vue.extend({
  name: "CompliancePlanMultiUpload",
  components: {
    CellplanUpload,
  },
  props: {
    inputFileManager: {type: Object as PropType<InputFileManager>, required: true},
    modelType: {type: String as PropType<CoffeeProductionModelType>, default: CoffeeProductionModelType.SARA},
  },
  data() {
    return {
      loadingCompliancePlans: {} as { [key: string]: boolean },
      weeklyFileTypes: [
        [
          CoffeeProductionInputFileType.ComplianceRg,
          CoffeeProductionInputFileType.ComplianceTas,
          // CoffeeProductionInputFileType.ComplianceRgPreroast,
          // CoffeeProductionInputFileType.ComplianceTasPreroast,
        ],
        [
          CoffeeProductionInputFileType.ComplianceRgPreroast,
          CoffeeProductionInputFileType.ComplianceTasPreroast,
        ]
      ],
    };
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    displayWeeks(): number[] {
      if (this.inputFileManager.availableFiles === null) {
        return [];
      }
      return Object.keys(this.inputFileManager.availableFiles)
          .map((d) => +d)
          .filter((d) => d !== -1);
    },
  },
  methods: {
    getDisplayFileType(fileType: CoffeeProductionInputFileType){
      const map: any = {
        [CoffeeProductionInputFileType.ComplianceRg]: 'Roast and Ground',
        [CoffeeProductionInputFileType.ComplianceTas]: 'Tassimo',
        [CoffeeProductionInputFileType.ComplianceRgPreroast]: 'Roast and Ground (Vorröstung)',
        [CoffeeProductionInputFileType.ComplianceTasPreroast]: 'Tassimo (Vorröstung)',
      }
      return map[fileType] ?? "???"
    },
    shortFormatDate(date: string) {
      return new Intl.DateTimeFormat('de-DE', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(date))
    },
    async handleComplianceUpload(week: number | string, weekIndex: 0|1, fileType: CoffeeProductionInputFileType, event: any) {
      const key = `${week}_${fileType}`;
      this.loadingCompliancePlans[key] = true;
      this.$forceUpdate();
      setTimeout(async () => {
        if(weekIndex === 0){
          await this.doComplianceUpload(+week, fileType, event);
        } else if(weekIndex === 1){
          await this.doCompliancePreroastUpload(+week, fileType, event);
        } else {
          throw new Error("INVALID WEEK INDEX: " + weekIndex);
        }
        this.loadingCompliancePlans[key] = false;
        // @ts-ignore
        this.$refs[`complianceFileInput_${key}`][0].value = "";
        this.$forceUpdate();
      }, 0);
    },
    async doComplianceUpload(week: number, fileType: CoffeeProductionInputFileType, event: any) {
      // PREPARE
      const file: File = event.target.files[0];
      const bi = this.backendInterface as BackendInterface;
      const dates = this.inputFileManager.dateRange as [string, string];
      const [startDate, endDate] = dates;
      const year = startDate?.split("-")?.[0];
      if (!year) {
        console.error("INVALID YEAR:", year);
        return;
      }
      console.log("UPLOADING", {
        fileType: fileType,
        // dataValidityTimeUnit: "Week",
        dataValidityStartTime: startDate + "T12:00:00.000Z", //: "2021-11-08T08:00:00.000Z", // LocalDateTime
        modelType: this.modelType as CoffeeProductionModelType,
      });
      // return;
      // GET ID
      const uploadIdResult = await bi.getFileUploadId({
        fileType: fileType,
        // dataValidityTimeUnit: "Week",
        dataValidityStartTime: startDate + "T12:00:00.000Z", //: "2021-11-08T08:00:00.000Z", // LocalDateTime
        modelType: this.modelType as CoffeeProductionModelType,
      });
      if ("error" in uploadIdResult || "errorMessage" in uploadIdResult) {
        console.error("UPLOAD ERROR", uploadIdResult)
        this.$emit("upload-error", uploadIdResult);
        return;
      }
      // UPLOAD FILE
      const uploadResult = await bi.uploadFile(uploadIdResult.uploadId, file);
      if ("error" in uploadResult || "errorMessage" in uploadResult) {
        console.error("UPLOAD ERROR", uploadResult)
        this.$emit("upload-error", uploadResult);
        return;
      }
      this.$emit("upload-success", "Zellenbelegungs-Datei wurde erfolgreich hochgeladen.");
    },
    async doCompliancePreroastUpload(week: number, fileType: CoffeeProductionInputFileType, event: any) {
      // PREPARE
      const file: File = event.target.files[0];
      const bi = this.backendInterface as BackendInterface;
      const dates = this.inputFileManager.dateRange as [string, string];
      const [startDate, endDate] = dates;
      const year = endDate?.split("-")?.[0];
      if (!year) {
        console.error("INVALID YEAR:", year);
        return;
      }
      console.log("UPLOADING PREROAST", {
        fileType: fileType,
        dataValidityStartTime: endDate + "T12:00:00.000Z", //: "2021-11-08T08:00:00.000Z", // LocalDateTime
        // dataValidityTimeUnit: "Week",
        modelType: this.modelType as CoffeeProductionModelType,
      });
      // return
      // GET ID
      const uploadIdResult = await bi.getFileUploadId({
        fileType: fileType,
        // dataValidityTimeUnit: "Week",
        dataValidityStartTime: endDate + "T12:00:00.000Z", //: "2021-11-08T08:00:00.000Z", // LocalDateTime
        modelType: this.modelType as CoffeeProductionModelType,
      });
      if ("error" in uploadIdResult || "errorMessage" in uploadIdResult) {
        console.error("UPLOAD ERROR", uploadIdResult)
        this.$emit("upload-error", uploadIdResult);
        return;
      }
      // UPLOAD FILE
      const uploadResult = await bi.uploadFile(uploadIdResult.uploadId, file);
      if ("error" in uploadResult || "errorMessage" in uploadResult) {
        console.error("UPLOAD ERROR", uploadResult)
        this.$emit("upload-error", uploadResult);
        return;
      }
      this.$emit("upload-success", "Zellenbelegungs-Datei wurde erfolgreich hochgeladen.");
    },
    // async doComplianceUploadOld(week: number | string, fileType: string, event: any) {
    //   // TODO: change to use preroasting
    //   // const productionSiteUniqueName = this.productionSiteUniqueName;
    //   week = +week as number;
    //   const ft = fileType as CoffeeProductionInputFileType;
    //   // log({event})
    //   // console.log("UPLOADING");
    //   const file: File = event.target.files[0];
    //   if (file === undefined) return;
    //   // do file upload request
    //   const bi = this.backendInterface as BackendInterface;
    //   // const dataValidityTimeUnit =
    //   //     {
    //   //       ComplianceRg: "Minute",
    //   //       ComplianceTas: "Minute",
    //   //       ComplianceRgPreroast: "Week",
    //   //       ComplianceTasPreroast: "Week",
    //   //       Cellplan: "Hour",
    //   //       FulfilledDemands: "Hour",
    //   //       Basket: "Unknown",
    //   //       Roaster: "Unknown",
    //   //     }[ft] ?? "Unknown";
    //
    //   // get validity start time
    //   // console.log("YEAR:", this.dates[0].split("-")[0]);
    //   const dates = this.inputFileManager.dateRange as [string, string];
    //   const year = +dates?.[0]?.split("-")?.[0];
    //   if (!year) {
    //     console.error("INVALID YEAR:", year);
    //     return;
    //   }
    //   // const dataValidityStartTime: string = await bi.getValidityStart({
    //   //   // productionSiteUniqueName,
    //   //   timeUnit: dataValidityTimeUnit,
    //   //   dayOfWeekDate: {
    //   //     year,
    //   //     weekNumber: week,
    //   //     dayOfWeek: Weekday.MONDAY, // irrelevant, but must be set
    //   //   },
    //   // });
    //   // log({ dataValidityStartTime_Instant });
    //   // if (dataValidityStartTime_Instant == null) return;
    //
    //   // const dataValidityStartTime: string = await bi.fetch_InstantToDateTime({
    //   //   productionSiteUniqueName,
    //   //   instant: dataValidityStartTime_Instant,
    //   // });
    //   // log({ dataValidityStartTime });
    //   // if (dataValidityStartTime == null) {
    //   //   this.$emit(
    //   //       "upload-error",
    //   //       {
    //   //         translatedErrorMessage:
    //   //             `Compliance-Plan konnte nicht hochgeladen werden (Fehler: Gültigkeitszeitraum konnte nicht berechnet werden).`
    //   //       }
    //   //   );
    //   //   return;
    //   // }
    //
    //   // get upload id
    //   const uploadIdResult = await bi.getFileUploadId({
    //     // productionSiteUniqueName,
    //     fileType: ft,
    //     dataValidityStartTime: "TODO", //: "2021-11-08T08:00:00.000Z", // LocalDateTime
    //     // dataValidityTimeUnit, // TimeUnit,
    //     modelType: this.modelType as CoffeeProductionModelType,
    //   });
    //   // log({ uploadIdResult });
    //   if ("error" in uploadIdResult || "errorMessage" in uploadIdResult) {
    //     this.$emit(
    //         "upload-error",
    //         uploadIdResult,
    //         // {
    //         //   translatedErrorMessage:
    //         //       `Compliance-Plan konnte nicht hochgeladen werden (Fehler: Upload ID konnte nicht geladen werden).`
    //         // }
    //     );
    //     return;
    //   }
    //   // await this.updateInputFiles(false);
    //
    //   const uploadId = uploadIdResult.uploadId;
    //
    //   // do file upload
    //   const uploadResult = await bi.uploadFile(uploadId, file);
    //   // const uploadId
    //   if ("error" in uploadResult || "errorMessage" in uploadResult) {
    //     //this.$emit("upload-error", `Compliance-Plan konnte nicht hochgeladen werden (Fehler: Falsches Dateiformat).`);
    //     // const errorObject = uploadResult as unknown as ExceptionResponse;
    //     // const msg = errorObject.translatedErrorMessage ?
    //     //         errorObject.translatedErrorMessage: errorObject.errorMessage
    //     this.$emit("upload-error",
    //         uploadResult
    //         // {
    //         //   translatedErrorMessage: "Compliance-Plan konnte nicht hochgeladen werden (Fehler: " + msg + ")."
    //         // }
    //     );
    //     return;
    //   }
    //   console.log("emitting upload-success ...");
    //
    //   this.$emit("upload-success", "Zellenbelegungs-Datei wurde erfolgreich hochgeladen.");
    //   // await this.updateInputFiles(false);
    // },
    getDisplayFilename(file: SiloAssignmentInputFile | null | undefined) {
      // console.log("getDisplayFilename", file);
      if (file === null || file === undefined) return null;
      // @ts-ignore
      return file.originalFilename || file.orginalFilename || file.baseFilename;
    },
  },
});
