
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import BasketSettings from "@/components/settings/BasketSettings.vue";
import SiloSettings from "@/components/settings/SiloSettings.vue";
import OptimizationSettings from "@/components/settings/OptimizationSettings.vue";
import SolverParameterSettings from "@/components/settings/SolverParameterSettings.vue";
import DashboardElement from "@/components/common/DashboardElement.vue";
import DataSettings from "@/components/settings/DataSettings.vue";
import RoasterAvailabilitySettings from "@/components/settings/RoasterAvailabilitySettings.vue";
import PackagingLineSettings from "@/components/settings/PackagingLineSettings.vue";
import PreroastingSettings from "@/components/settings/PreroastingSettings.vue";
// import {UserType} from "@/scripts/models/general";
import {BackendInterface} from "@/scripts/BackendInterface";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import {SiloAssignmentInputFile} from "@/scripts/models/calculation";
import {CoffeeProductionModelType, PAPASolveResultRequest,} from "@/scripts/models/request";
import InputFileSummary from "../upload/InputFileSummary.vue";
import SliderTableRow from "../settings/SliderTableRow.vue";
import {CoffeeProductionPAPASolveRequest} from "@/scripts/models/papa";
import ArticlePrioritySettings from "../settings/ArticlePrioritySettings.vue";
import PapaGasConsumptionSettings from "../settings/PapaGasConsumptionSettings.vue";
import {UserInfo, UserRole} from "@/scripts/auth";

export default Vue.extend({
  name: "PapaSettingsSection",
  props: {
    value: {
      required: true,
    },
    timeRangeStart: {
      type: String,
      required: true,
    },
    timeRangeEnd: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  components: {
    BasketSettings,
    OptimizationSettings,
    SiloSettings,
    DashboardElement,
    SolverParameterSettings,
    DataSettings,
    RoasterAvailabilitySettings,
    PreroastingSettings,
    InputFileSummary,
    SliderTableRow,
    ArticlePrioritySettings,
    PapaGasConsumptionSettings,
    PackagingLineSettings,
  },
  data: function() {
    return {
      CoffeeProductionModelType,
      // UserType,
      tab: 0,
      solveRequest: null as CoffeeProductionPAPASolveRequest | null,
      inputFiles: [] as SiloAssignmentInputFile[],
      existingSolution: null as CoffeeProductionSARASummary | null,
      solutionId: null as string | null,
      loading: true,

      objectiveWeightConfigs: [
        { key: "objectiveWeightTotalRoasterEnergy", name: "Total Roaster Energy", min: 0, max: 200, step: 1 },
        { key: "objectiveWeightTotalRoasterTaskNum", name: "Total Roaster Task Num", min: 0, max: 10, step: 0.1 },
        { key: "objectiveWeightTotalPackagingTaskNum", name: "Total Packaging Task Num", min: 0, max: 10, step: 0.1 },
        {
          key: "objectiveWeightWeightedTaskStartTimeSum",
          name: "Weighted Task Start Time Sum",
          min: 0,
          max: 10,
          step: 0.1,
        },
        {
          key: "objectiveWeightTotalWeightedProductionDeviation",
          name: "Total Weighted Production Deviation",
          min: 0,
          max: 10000,
          step: 100,
        },
      ],
      sliderConfigs: [
        // { key: "weeklyProductionHours", name: "Verfügbare Produktionszeit (h)", min: 0, max: 168, step: 1 },
        // { key: "productionSubTaskNum", name: "Production Sub Task Num", min: 0, max: 10, step: 1 },
        // { key: "roasterSubtaskNum", name: "Roaster Subtask Num", min: 0, max: 20, step: 1 },
        { key: "firstDayStartHour", name: "First Day Start Hour", min: 0, max: 24, step: 1 },
      ],
    };
  },
  async created() {
    await this.init();
  },
  watch: {
    value: "init",
    solveRequest: {
      deep: true,
      async handler(v) {
        this.loading = true;
        // console.log("CHANGED: solveRequest", JSON.stringify(v,null,2));
        // if(v !== null) 
        await this.loadExistingSolution();
        this.loading = false;
      },
    },
  },
  computed: {
    ...mapState(["superuser", "user", "backendInterface", "productionSiteUniqueName"]),
    isPapaAdminOrMore(): boolean {
      const role = (this.user as UserInfo)?.papaUserRole;
      return role === UserRole.ADMIN || role === UserRole.SUPERUSER;
    },
    isPapaSuperuser(): boolean {
      return (this.user as UserInfo)?.papaUserRole === UserRole.SUPERUSER;
    },
    hasSolution(): boolean {
      return this.solutionId !== null;
    },
  },
  methods: {
    ...mapMutations(["toggleSuperuser"]),
    async init() {
      this.loading = true;
      // console.log("initializing");

      if (this.value !== null) {
        this.solveRequest = JSON.parse(JSON.stringify(this.value)) as CoffeeProductionPAPASolveRequest;
        const bi = this.backendInterface as BackendInterface;
        this.inputFiles = await bi.getFilesByIds(this.solveRequest.inputData.inputFileIds);
      }
      await this.loadExistingSolution();
      this.loading = false;
    },
    emitSettingsSaved() {
      this.$emit("input", this.solveRequest);
      this.$emit("settings-saved");
    },
    emitBack() {
      this.$emit("back");
    },
    async loadExistingSolution() {
      const bi: BackendInterface = this.backendInterface;
      const solveRequest = this.solveRequest as CoffeeProductionPAPASolveRequest;
      const request: PAPASolveResultRequest = {
        // productionSiteUniqueName: this.productionSiteUniqueName,
        timeRangeStart: this.timeRangeStart,
        timeRangeEnd: this.timeRangeEnd,
        inputFileIds: solveRequest.inputData.inputFileIds,
        inputSettings: solveRequest.inputSettings,
        coffeeBaskets: solveRequest.inputData.baskets,
        coffeeRoasters: solveRequest.inputData.roasters,
        siloAllocations: solveRequest.inputData.siloAllocations,
        fulfilledDemands: solveRequest.inputData.fulfilledDemands,
        solverParameters: solveRequest.solverParameters,
      };
      this.solutionId = await bi.papa_GetSolutionId(request);
      //
      // HASH CHECK: ENABLE IF NEEDED
      //
      // const request2: PAPASolveResultRequest = {
      //   productionSiteUniqueName: this.productionSiteUniqueName,
      //   timeRangeStart: this.timeRangeStart,
      //   timeRangeEnd: this.timeRangeEnd,
      //   inputFileIds: solveRequest.inputData.inputFileIds,
      //   inputSettings: solveRequest.inputSettings,
      //   coffeeBaskets: solveRequest.inputData.baskets,
      //   coffeeRoasters: solveRequest.inputData.roasters,
      //   siloAllocations: solveRequest.inputData.siloAllocations,
      //   fulfilledDemands: solveRequest.inputData.fulfilledDemands,
      //   solverParameters: solveRequest.solverParameters,
      // };
      // const existingSolution = await bi.papa_GetSolution(request);
      // this.existingSolution = existingSolution;
      // if (existingSolution === null) {
      //   const solutionHashes = await bi.papa_GetSolutionHashes(request);
      //   console.warn("No solution found. Solution hashes:", JSON.stringify(solutionHashes, null, 2));
      //   const solutionHashes2 = await bi.papa_GetSolutionHashes(request2);
      //   console.warn("No solution found. Solution hashes 2:", JSON.stringify(solutionHashes2, null, 2));
      // }
    },
    async emitExistingSolutionLoaded() {
      this.$emit("load-existing-solution", this.solutionId);
    },
  },
});
