
import {generateOutputHash, hash_java} from "@/scripts/utils";
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import BasketSettings from "@/components/settings/BasketSettings.vue";
import SiloSettings from "@/components/settings/SiloSettings.vue";
import OptimizationSettings from "@/components/settings/OptimizationSettings.vue";
import SolverParameterSettings from "@/components/settings/SolverParameterSettings.vue";
import DashboardElement from "@/components/common/DashboardElement.vue";
import DataSettings from "@/components/settings/DataSettings.vue";
import RoasterAvailabilitySettings from "@/components/settings/RoasterAvailabilitySettings.vue";
import PreroastingSettings from "@/components/settings/PreroastingSettings.vue";
// import { UserType } from "@/scripts/models/general";
import {BackendInterface} from "@/scripts/BackendInterface";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import {SiloAssignmentInputFile, CoffeeProductionInputFileType} from "@/scripts/models/calculation";
import {
  SolveRequest,
  SolveRequestRequest,
  SARASolveResultRequest,
  CoffeeProductionModelType
} from "@/scripts/models/request";
import {DayOfWeekDate} from "@/scripts/models/date";
import InputFileSummary from "../upload/InputFileSummary.vue";
import SliderTableRow from "../settings/SliderTableRow.vue";
import {UserInfo, UserRole} from "@/scripts/auth";
import UserSettingsDisplay from "@/components/settings/UserSettingsDisplay.vue";
import AvailabilityExclusionSettings from "@/components/settings/AvailabilityExclusionSettings.vue";
import RoasterAvailabilityPreview from "@/components/settings/RoasterAvailabilityPreview.vue";

export default Vue.extend({
  name: "SaraSettingsSection",
  props: {
    value: {
      type: Object,
      required: true,
    },
    preroastForNextWeek: {
      type: Boolean,
      required: true,
    },
    timeRangeStart: {
      type: String,
      required: true,
    },
    timeRangeEnd: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    recompute: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RoasterAvailabilityPreview,
    AvailabilityExclusionSettings,
    UserSettingsDisplay,
    BasketSettings,
    OptimizationSettings,
    SiloSettings,
    DashboardElement,
    SolverParameterSettings,
    DataSettings,
    RoasterAvailabilitySettings,
    PreroastingSettings,
    InputFileSummary,
    SliderTableRow,
  },
  data: function () {
    return {
      // UserType,
      tab: 0,
      solveRequest: null as SolveRequest | null,
      inputFiles: [] as SiloAssignmentInputFile[],
      existingSolution: null as CoffeeProductionSARASummary | null,
      solutionId: null as string | null,
      loading: true,
      userSettings: null as any
    };
  },
  async created() {
    await this.init();
  },
  watch: {
    value: "init",
    solveRequest: {
      deep: true,
      async handler(v) {
        this.loading = true;
        // console.log("CHANGED: solveRequest");
        await this.loadExistingSolution();
        // console.log("userSettings", this.userSettings)
        this.loading = false;
      },
    },
  },
  computed: {
    ...mapState(["superuser", "user", "backendInterface", "productionSiteUniqueName"]),
    isSaraAdminOrMore(): boolean {
      const role = (this.user as UserInfo)?.saraUserRole;
      return role === UserRole.ADMIN || role === UserRole.SUPERUSER;
    },
    isSaraSuperuser(): boolean {
      return (this.user as UserInfo)?.saraUserRole === UserRole.SUPERUSER;
    },
    hasSolution(): boolean {
      return this.solutionId !== null && !this.recompute;
    },
    basketFlowMultipliers(): Record<number, number> {
      return this.solveRequest?.inputSettings.basketFlowMultipliers.basketValues ?? {};
    },
    settingsTitle(): string {
      if (this.solveRequest?.inputData.firstDayStartTime) {
        const firstDayDate = new Date(this.solveRequest.inputData.firstDayStartTime);
        const dateString = firstDayDate.toLocaleDateString("de-DE");
        const timeString = firstDayDate.toLocaleTimeString("de-DE");
        return "Zusammenfassung (" + dateString + " " + timeString + ")";
      } else {
        return "Zusammenfassung";
      }
    },
    siloSettings: {
      get(): any {
        return {
          siloAllocations: this.solveRequest.inputData.siloAllocations,
          siloFlowMultipliers: this.solveRequest.inputSettings.siloFlowMultipliers,
        };
      },
      set(v: any): any {
        this.solveRequest.inputData.siloAllocations = v.siloAllocations;
        this.solveRequest.inputSettings.siloFlowMultipliers = v.siloFlowMultipliers;
      },
    } as any,
  },
  methods: {
    ...mapMutations(["toggleSuperuser"]),
    async init() {
      this.loading = true;
      // console.log("initializing");

      if (this.value !== null) {
        this.solveRequest = JSON.parse(JSON.stringify(this.value)) as SolveRequest;
        await this.loadUserSettingsInfo()
        const bi = this.backendInterface as BackendInterface;
        this.inputFiles = await bi.getFilesByIds(this.solveRequest.inputData.inputFileIds);
      }
      await this.loadExistingSolution();
      this.loading = false;
    },
    async loadUserSettingsInfo() {
      const userSettingsId = this.solveRequest?.userSettingsId ?? null;
      if (userSettingsId !== null && userSettingsId !== "") {
        this.userSettings = await this.backendInterface.getUserSettings(CoffeeProductionModelType.SARA, userSettingsId)
      }
    },
    emitSettingsSaved() {
      this.$emit("input", this.solveRequest);
      this.$emit("settings-saved");
    },
    emitBack() {
      this.$emit("back");
    },
    async loadExistingSolution() {
      const bi: BackendInterface = this.backendInterface;
      const solveRequest = this.solveRequest as SolveRequest;
      const request: SARASolveResultRequest = {
        // productionSiteUniqueName: this.productionSiteUniqueName,
        timeRangeStart: this.timeRangeStart,
        timeRangeEnd: this.timeRangeEnd,
        inputFileIds: solveRequest.inputData.inputFileIds,
        inputSettings: solveRequest.inputSettings,
        inputFilter: solveRequest.inputFilter,
        coffeeBaskets: solveRequest.inputData.baskets,
        coffeeRoasters: solveRequest.inputData.roasters,
        siloAllocations: solveRequest.inputData.siloAllocations,
        fulfilledDemands: solveRequest.inputData.fulfilledDemands,
        solverParameters: solveRequest.solverParameters,
      };
      // @ts-ignore
      this.solutionId = await bi.sara_GetSolutionId(request);
      //
      // HASH CHECK: ENABLE IF NEEDED
      //
      // const existingSolution = await bi.sara_GetSolution(request);
      // this.existingSolution = existingSolution;
      // if (existingSolution === null) {
      //   const solutionHashes = await bi.sara_GetSolutionHashes(request);
      //   console.warn("No solution found. Solution hashes:", JSON.stringify(solutionHashes, null, 2));
      //   const solutionHashes2 = await bi.sara_GetSolutionHashes(request);
      //   console.warn("No solution found. Solution hashes 2:", JSON.stringify(solutionHashes2, null, 2));
      // }
    },
    async emitExistingSolutionLoaded() {
      this.$emit("load-existing-solution", this.solutionId);
    },
    downloadSettings() {
      console.log("downloadSettings")
      const solveRequest = this.solveRequest as SolveRequest;
      if (solveRequest && solveRequest.id)
        (this.backendInterface as BackendInterface).downloadSettings(CoffeeProductionModelType.SARA, solveRequest.id);
    }
  },
});
