import { render, staticRenderFns } from "./AvailabilityExclusionSettings.vue?vue&type=template&id=a1ed4acc&scoped=true&"
import script from "./AvailabilityExclusionSettings.vue?vue&type=script&lang=ts&"
export * from "./AvailabilityExclusionSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1ed4acc",
  null
  
)

export default component.exports