
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {UserSettingsInfo} from "@/scripts/models/results";
import {BackendInterface} from "@/scripts/BackendInterface";
import UserSettingsAccessOptions from "@/components/settings/UserSettingsAccessOptions.vue";
import UserSettingsDisplay from "@/components/settings/UserSettingsDisplay.vue";
import ResetSettingsDialog from "@/components/settings/ResetSettingsDialog.vue";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import {UserRole, UserInfo} from "@/scripts/auth";
import SaraSettingsPreview from "@/components/settings/preview/SaraSettingsPreview.vue";

export default Vue.extend({
  name: "SettingsListItem",
  components: {SaraSettingsPreview, ResetSettingsDialog, UserSettingsDisplay, UserSettingsAccessOptions},
  props: {
    userSettingsInfo: {
      type: Object as PropType<UserSettingsInfo>,
      required: true,
    },
    defaultSettingsId: {
      type: String,
      required: false,
      default: null,
    },
    modelType: {
      type: String as PropType<CoffeeProductionModelType>,
      required: true,
    },
    readonly: { type: Boolean as PropType<boolean>, required: false, default: false, },
    canReset: { type: Boolean as PropType<boolean>, required: false, default: false, },
    canChangeAccess: { type: Boolean as PropType<boolean>, required: false, default: false, },
    hideOptions: { type: Boolean as PropType<boolean>, required: false, default: false, },
  },
  data() {
    return {
      CoffeeProductionModelType,
    };
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),

    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    isAdminOrMore(): boolean {
      if(this.modelType === CoffeeProductionModelType.SARA){
        return this.user.saraUserRole === UserRole.ADMIN || this.user.saraUserRole === UserRole.SUPERUSER
      }
      if(this.modelType === CoffeeProductionModelType.PAPA){
        return this.user.papaUserRole === UserRole.ADMIN || this.user.papaUserRole === UserRole.SUPERUSER
      }
      if(this.modelType === CoffeeProductionModelType.DORI){
        return this.user.doriUserRole === UserRole.ADMIN || this.user.doriUserRole === UserRole.SUPERUSER
      }
      return false;
    },
    isProductionSiteAdmin(): boolean {
      return this.user.productionSiteAdmin;
    }

  },
  methods: {
    ...mapMutations([""]),
    async deleteSettings() {
      console.log("deleteSettings")
      const res = await this.bi.deleteUserSettings(this.modelType, this.userSettingsInfo.id)
      this.$emit("settings-deleted")
    },
    async setDefault() {
      await this.bi.setDefaultUserSetting(this.modelType, this.userSettingsInfo.id)
      this.$emit("default-changed")
    },
    async setGlobalDefault() {
      const targetUserRole = UserRole.USER
      const getUserInfos: UserInfo[] = await this.loadUserInfos(this.modelType, targetUserRole)
      const baseMessage = "Möchten Sie die Einstellungen für alle Operatoren als Standard festlegen? Betroffene User:\n\n"
      const userInfoNames = getUserInfos.map(userInfo => userInfo.username).join("\n")
      if(confirm(baseMessage + userInfoNames)){
        const res: UserInfo[] = await this.bi.setGlobalDefaultUserSetting(
            this.modelType,
            this.userSettingsInfo.id,
            targetUserRole)
      }
      //console.log(res) //ToDo: show an info dialog with all affected user names
      this.$emit("default-changed")
    },
    async loadUserInfos(modelType: CoffeeProductionModelType, role: UserRole) {
      const userInfos = await this.bi.getAllUserInfosAdmin()
      let filteredUserInfos: UserInfo[] = [];
      if(modelType === CoffeeProductionModelType.SARA){
        filteredUserInfos = userInfos.filter(userInfo => userInfo.saraUserRole === role)
      } else if(modelType === CoffeeProductionModelType.PAPA){
        filteredUserInfos = userInfos.filter(userInfo => userInfo.papaUserRole === role)
      } else if(modelType === CoffeeProductionModelType.DORI){
        filteredUserInfos = userInfos.filter(userInfo => userInfo.doriUserRole === role)
      }
      filteredUserInfos.sort((a, b) => a.username.localeCompare(b.username))
      return filteredUserInfos
    },

  },
});
