
import Vue from "vue";
import {mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {SiloAssignmentInputFile, CoffeeProductionInputFileType} from "@/scripts/models/calculation";
// import {log} from "@/scripts/utils";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import {TimeUnit} from "@/scripts/models/date";

export default Vue.extend({
  name: "CellplanUpload",
  props: {
    items: {type: Array, default: () => []},
    value: {type: Object},
    date: {type: String},
    modelType: {type: String, default: CoffeeProductionModelType.SARA},
  },
  data() {
    return {
      drag: {
        currentlyDraggingOver: false,
        startTime: 0,
        intervalId: -1,
      },
      timeSinceLeave: Infinity,
      cellplanUpload: {
        file: null as File | null,
        loading: false,
      } as any,
    };
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    v: {
      get(): string {
        return this.value;
      },
      set(v): void {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    async handleCellplanUpload(event: any) {
      this.drag.currentlyDraggingOver = false;
      this.cellplanUpload.loading = true;
      this.cellplanUpload.file = event.target.files[0];
      this.$forceUpdate();
      setTimeout(async () => {
        await this.uploadCellplan();
        this.cellplanUpload.loading = false;
        // @ts-ignore
        this.$refs[`cellplanFileInput`].value = "";
        this.$forceUpdate();
      }, 0);
    },
    async uploadCellplan() {
      const productionSiteUniqueName = this.productionSiteUniqueName;
      const file: File = this.cellplanUpload.file;
      // console.log(this.cellplanUpload.files)
      // log({ file });
      if (file === undefined) return;
      // do file upload request
      const bi = this.backendInterface as BackendInterface;
      const dataValidityStartTime = await bi.getCellplanValidity(file.name, {
        // productionSiteUniqueName,
        date: this.date,
        modelType: this.modelType as CoffeeProductionModelType,
      });
      console.log("dataValidityStartTime", dataValidityStartTime)
      if (dataValidityStartTime == null || dataValidityStartTime.length > 100) {
        this.$emit(
            "upload-error",
            {
              translatedErrorMessage:
                  `Zellenbelegung konnte nicht hochgeladen werden (Mögliche Fehler: Falsche Datei oder der Zeitpunkt der Zellenbelegung ist ungültig).`
            }
        );
        return;
      }

      // get upload id
      const uploadIdResult = await bi.getFileUploadId({
        // productionSiteUniqueName,
        fileType: CoffeeProductionInputFileType.Cellplan,
        dataValidityStartTime, //: "2021-11-08T08:00:00.000Z", // LocalDateTime
        // dataValidityTimeUnit: TimeUnit.SECOND, // TimeUnit,
        modelType: this.modelType as CoffeeProductionModelType,
      });
      console.log({uploadIdResult});
      if ("error" in uploadIdResult || "errorMessage" in uploadIdResult) {
        this.$emit(
            "upload-error",
            uploadIdResult
            // {
            //   translatedErrorMessage:
            //       `Zellenbelegung konnte nicht hochgeladen werden (Fehler: Upload ID konnte nicht geladen werden).`
            // }
        );
        return;
      }
      const uploadId = uploadIdResult.uploadId;

      // do file upload
      const uploadResult = await bi.uploadFile(uploadId, file);
      this.cellplanUpload.file = null;

      if ("error" in uploadResult || "errorMessage" in uploadResult) {
        // const errorObject = uploadResult as unknown as ExceptionResponse;
        // const msg = errorObject.translatedErrorMessage ?
        //     errorObject.translatedErrorMessage : errorObject.errorMessage
        this.$emit("upload-error", uploadResult)
        // {translatedErrorMessage: "Zellenbelegung konnte nicht hochgeladen werden (Fehler: " + msg + ")."});
        return;
      }
      this.$emit("upload-success", "Zellenbelegungs-Datei wurde erfolgreich hochgeladen.");
    },

    getDisplayFilename(file: SiloAssignmentInputFile | null | undefined) {
      // console.log("getDisplayFilename", file);
      if (file === null || file === undefined) return null;
      // @ts-ignore
      return file.originalFilename || file.orginalFilename || file.baseFilename;
    },
    dragHandler() {
      this.drag.startTime = new Date().getTime();
      this.drag.currentlyDraggingOver = true;
      // console.log("setting startTime");
      if (this.drag.intervalId === -1) {
        this.drag.intervalId = setInterval(() => {
          // console.log("checking time since dragover");
          if (new Date().getTime() - this.drag.startTime > 2000) {
            // console.log("time since dragover too big, resetting");
            this.drag.currentlyDraggingOver = false;
            clearInterval(this.drag.intervalId);
            this.drag.intervalId = -1;
          }
        }, 1000);
      }
    },
  },
});
