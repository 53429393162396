
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";

export default Vue.extend({
  name: "VerifyUserEmail",
  components: {},
  props: {
    userActivationToken: {type: String as PropType<string>},
  },
  data() {
    return {
      loading: true,
      userVerificationStatus: {
        verified: false,
        message: "",
        emailAlreadyVerified: false,
        tokenAlreadyUsed: false,
        error: false,
        notAuthorized: false,
      }
    };
  },
  created() {
    console.log("CREATED")
  },
  watch: {
    userActivationToken: {
      immediate: true,
      async handler(newVal, oldVal) {
        console.log("WATCHER")
        this.loading = true
        const bi = this.backendInterface as BackendInterface;
/*      await bi.refreshAuthToken(false)
        console.log("is logged in", bi.isLoggedIn());
        if(!bi.isLoggedIn()) {
          this.userVerificationStatus.notAuthorized = true;
          this.loading = false
          return;
        } */
        bi.verifyUserEmail(newVal).then((res: any) => {
          console.log("VerifyUserEmail", res);
          // const stringRes = JSON.stringify(res);
          // this.userVerificationStatus.message = JSON.stringify(res);
          if ('errorMessage' in res) {
            this.userVerificationStatus.message = res.error;
            const emailAlreadyVerified = res.errorMessage.includes("email is already verified");
            const tokenAlreadyUsed = res.errorMessage.includes("token already used");
            if (emailAlreadyVerified) {
              this.userVerificationStatus.emailAlreadyVerified = true;
            } else if (tokenAlreadyUsed) {
              this.userVerificationStatus.tokenAlreadyUsed = true;
            } else {
              this.userVerificationStatus.error = true;
            }
          } else {
            this.userVerificationStatus.verified = true;
            this.userVerificationStatus.message = "Benutzer wurde verifiziert";
          }
          this.loading = false
        });
      },
    }
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
  },
  methods: {
    ...mapMutations([""]),
  },
});
