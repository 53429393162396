
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {SiloAllocationState, SiloAllocationStateAnalysis} from "@/scripts/models/statistics";
// @ts-ignore
import Plotly from "plotly.js-dist";
import {CoffeeSiloAllocation} from "@/scripts/models/coffee";
import {
  findContrastColorRGB,
  formatDateShort,
  getSolutionQualityColor,
  getSolutionQualityTranslation
} from "@/scripts/utils";
import {CoffeeProductionBasketWeekdaySiloPlan} from "@/scripts/models/results";
import DatePicker from "@/components/common/DatePicker.vue";


// 650ms

export default Vue.extend({
  name: "SiloAllocationStateAnalysis",
  components: {DatePicker},
  props: {},
  data() {
    return {
      datePickerDialog: false,
      dateRange: ["2023-11-13", "2023-11-17"],
      // startDate: "2023-11-13",
      // endDate: "2023-11-17",
      // startDate: "2023-11-06",
      // endDate: "2023-11-13",
      analysis: null as SiloAllocationStateAnalysis | null,
      selectedState: null as SiloAllocationState | null,
      plotConfig: {
        responsive: true,
        displayModeBar: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines'],
      },
      colors: {
        selected: 'rgb(55, 128, 191)',
        unselected: 'rgb(191, 191, 191)',
        allocationMatch: 'rgb(0, 200, 50)',
        additionalAllocation: 'rgb(240, 30, 0)',
        allocationMismatch: 'rgb(240, 130, 0)',
        unusedAllocation: 'rgb(60, 60, 60)',
      },
      loading: false,
    };
  },
  async created() {
    await this.load()
  },
  mounted() {
    // if (!!this.analysis) this.plot()
  },
  watch: {
    // analysis: {
    //   handler: function (val) {
    //     if (!!this.analysis) this.plot()
    //   },
    // },
    selectedState: {
      handler: function (val) {
        if (!!this.analysis) this.plot()
      }
    },
    dateRange: {
      handler: function (val) {
        // console.log("dateRange changed", val)
        this.load()
      }
    }
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    states(): SiloAllocationState[] {
      return this.analysis?.getStates() ?? []
    },
  },
  methods: {
    ...mapMutations([""]),
    formatDateShort,
    findContrastColorRGB,
    async load() {
      const startDate = this.dateRange[0]
      const endDate = this.dateRange[1]
      if (!startDate || !endDate) return
      this.loading = true
      console.time("load")
      const saStates = await this.bi.getSiloAllocationStates(startDate, endDate)
      saStates.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
      // console.log("saStates", saStates)
      this.analysis = new SiloAllocationStateAnalysis(saStates)
      if (saStates.length > 0) {
        this.selectedState = saStates[0]
        // const deltas = this.analysis.calculateAllocationDeltas(this.selectedState)
        // console.log("deltas", deltas)
      }
      // this.plotStateTimeline()
      this.loading = false
      console.timeEnd("load")
    },
    async plot() {
      // this.plotHeatmap
      if (this.selectedState !== null) {
        await this.plotTimeline(this.selectedState)
      }
    },

    async plotTimeline(fixedState: SiloAllocationState) {
      console.time("plotTimeline")
      // const
      // const states = this.states//this.analysis?.getStatesAfter(state) ?? []
      // const fixedStateAllocations: Record<number, CoffeeSiloAllocation> = {}
      // for (const sa of fixedState.siloAllocations) {
      //   fixedStateAllocations[sa.siloSpsNr] = sa
      // }

      console.time("plotTimeline: get sara solution")
      const saraSolution = await this.bi.sara_GetSolutionById(fixedState.saraPlanId)
      console.timeEnd("plotTimeline: get sara solution")

      // console.log("saraSolution", saraSolution)


      // const timestamps = this.states.map(s => new Date(s.timestamp))
      // const rectangleY = timestamps.map((t, index) => [Math.random(), Math.random()])
      const timestamps = []
      const siloAllocations = []
      const data = []

      const findColor = (state: SiloAllocationState, sa: CoffeeSiloAllocation, siloAllocation_sara?: CoffeeProductionBasketWeekdaySiloPlan) => {
        const siloAllocated_state = sa.storedWeight_t > 0 && !!sa.storedBasketSpsNr
        const siloAllocated_sara = siloAllocation_sara !== undefined
        const siloAllocatedToSameBasket = siloAllocated_state && siloAllocated_sara && siloAllocation_sara?.basketSpsNr === sa.storedBasketSpsNr
        const isFixedState = state.timestamp === fixedState.timestamp
        const isBeforeFixedState = new Date(state.timestamp).getTime() < new Date(fixedState.timestamp).getTime()


        // 1. Fixed state
        if (isFixedState) {
          if (siloAllocated_sara) return this.colors.selected
          // else return "transparent"
        }

        // 2. States before fixed state
        if (isBeforeFixedState) {
          return "transparent"
        }

        // 3. States after fixed state

        // Not allocated, 00
        if (!siloAllocated_state && !siloAllocated_sara)
          return "transparent"

        // Unused Allocation Sara, 01
        if (!siloAllocated_state && siloAllocated_sara)
          return this.colors.unusedAllocation

        // Additional Allocation State, 10
        if (siloAllocated_state && !siloAllocated_sara)
          return this.colors.additionalAllocation

        // Allocated to same basket
        if (siloAllocatedToSameBasket)
          return this.colors.allocationMatch


        // Allocation mismatch
        if (!siloAllocatedToSameBasket)
          return this.colors.allocationMismatch
      }

      console.time("plotTimeline: build data")
      for (const state of this.states) {
        for (const sa of state.siloAllocations) {
          const siloAllocation_sara = saraSolution.results.basketWeekdaySiloResults
              // @ts-ignore
              .find(bwsr => bwsr.siloSpsNr === sa.siloSpsNr && bwsr.dayOfWeek === state.dayOfWeekDate.dayOfWeek)
          // if (sa.storedWeight_t <= 0 || sa.siloSpsNr === null) continue
          const color = findColor(state, sa, siloAllocation_sara)
          if (color === "transparent") continue
          timestamps.push(new Date(state.timestamp))
          siloAllocations.push(sa.siloSpsNr)
          data.push({
            color,
            basketSara: siloAllocation_sara?.basketSpsNr ?? "-",
            basketReality: sa.storedBasketSpsNr,
            storedWeight: sa.storedWeight_t,
            siloCapacity: sa.nominalCapacity_t
          })
        }
      }
      console.timeEnd("plotTimeline: build data")
      // plot a rectangle at each timestamp at the height
      const plotData: any[] = [{
        x: timestamps,
        y: siloAllocations,
        type: 'scatter',
        mode: 'markers',
        yaxis: 'y',
        // name: 'Silo Allocation',
        // hoverdata from data
        customdata: data,
        hovertemplate:
            'Basket Reality: %{customdata.basketReality}<br>' +
            'Basket SARA:  %{customdata.basketSara}<br>' +
            'Stored: %{customdata.storedWeight}t / %{customdata.siloCapacity}t<br>' +
            ''
        ,
        // line: {
        //   color: 'rgb(55, 128, 191)',
        //   width: 1
        // },
        showlegend: false,
        marker: {
          symbol: 'square',
          color: data.map(d => d.color),
          size: 10,
          // marker padding
          // pad: 1,
          line: {
            color: 'white',
            width: 1
          },
        },
      }]

      /////////////////////////////////////////////////////////
      //// 6:00 vertical lines
      /////////////////////////////////////////////////////////

      console.time("plotTimeline: vertical lines")

      // vertical line at 6:00, 14:00 and 22:00 each day
      const verticalLineData = []
      const verticalLineTimestamps = []
      let minTime = new Date(timestamps[0])
      let maxTime = new Date(timestamps[0])
      for (const state of this.states) {
        const time = new Date(state.timestamp).getTime()
        if (time < minTime.getTime()) minTime = new Date(state.timestamp)
        if (time > maxTime.getTime()) maxTime = new Date(state.timestamp)
      }
      // find first 6:00 after minTime
      let time = new Date(minTime)
      time.setHours(6, 0, 0, 0)
      while (time.getTime() < maxTime.getTime()) {
        verticalLineTimestamps.push(new Date(time))
        time.setDate(time.getDate() + 1)
      }
      // create layout shapes
      const shapes = []
      for (const timestamp of verticalLineTimestamps) {
        shapes.push({
          type: 'line',
          xref: 'x',
          yref: 'paper',
          x0: timestamp,
          x1: timestamp,
          y0: 0.05,
          y1: 0.95,
          line: {
            color: 'black',
            width: 1,
            // dash: 'dash',
          },
        })
      }

      console.timeEnd("plotTimeline: vertical lines")


      /////////////////////////////////////////////////////////
      //// Alternating background
      /////////////////////////////////////////////////////////

      console.time("plotTimeline: alternating background")


      // create alternating background colors for each silo sps nr
      const siloSpsNrs = new Set<number>();
      const onlyCollectUsedSilos = true
      for (const state of this.states) {
        for (const sa of state.siloAllocations) {
          if (!onlyCollectUsedSilos || onlyCollectUsedSilos && sa.storedBasketSpsNr !== null && sa.storedWeight_t > 0) {
            siloSpsNrs.add(sa.siloSpsNr)
          }
        }
      }
      // const siloSpsNrs = Object.values(siloSpsNrsMap)
      const siloSpsNrsArray = Array.from(siloSpsNrs)
      for (let i = 0; i < siloSpsNrsArray.length; i++) {
        const siloSpsNr = siloSpsNrsArray[i];
        shapes.push({
          type: 'rect',
          xref: 'paper',
          yref: 'y',
          x0: 0,
          y0: siloSpsNr - 0.5,
          x1: 1,
          y1: siloSpsNr + 0.5,
          fillcolor: siloSpsNr % 2 === 0 ? 'rgb(255, 255, 255)' : 'rgb(230, 230, 230)',
          opacity: 0.5,
          line: {
            width: 0,
          },
          layer: 'below',
        })
      }

      console.timeEnd("plotTimeline: alternating background")


      /////////////////////////////////////////////////////////
      //// DORI Solution Quality
      /////////////////////////////////////////////////////////

      console.time("plotTimeline: DORI Solution Quality")

      const annotations = []
      for (const state of this.states) {
        const timestamp = new Date(state.timestamp)
        const color = getSolutionQualityColor(state.doriSolutionQuality)
        const text = getSolutionQualityTranslation(state.doriSolutionQuality)
        annotations.push({
          x: timestamp,
          y: 1.15,
          xref: 'x',
          yref: 'paper',
          text: text,
          showarrow: false,
          font: {
            family: 'sans-serif',
            size: 12,
            color: color,
          },
          bgcolor: 'white',
          align: 'center',
          // rotate the text 90deg
          textangle: -90,
          // marker: {
          //   size: 20,  // Adjust the circle size as needed
          //   color: 'red',  // Use the color from the circle data
          //   symbol: 'circle'  // Use 'circle' symbol for the markers
          // }
          // arrowhead: 2,
          // arrowsize: 2,
          // arrowwidth: 2,
          // arrowcolor: '#636363',
          // ax: 20,
          // ay: -30,
          bordercolor: color,
          borderwidth: 2,
          // borderpad: 4,
          // bgcolor: '#ff7f0e',
          // opacity: 0.8
        })
      }

      console.timeEnd("plotTimeline: DORI Solution Quality")

      /////////////////////////////////////////////////////////
      //// Layout
      /////////////////////////////////////////////////////////


      const layout = {
        // title: 'Silo Allocation',
        height: 600,
        shapes: shapes,
        annotations: annotations,
        // margins
        margin: {
          l: 30,
          r: 0,
          b: 120,
          t: 80,
          pad: 4
        },
        xaxis: {
          // title: 'Time',
          type: 'date',
          tickformat: '%Y-%m-%d %H:%M:%S',
          tickmode: 'array',
          tickvals: timestamps,
          ticktext: timestamps.map(formatDateShort),
          // rotate the text 90deg
          tickangle: -90,
        },

        yaxis: {
          // title: 'Silo Allocation',
          tickmode: 'array',
          tickvals: siloSpsNrsArray,
          ticktext: siloSpsNrsArray.map(siloSpsNr => `${siloSpsNr}`),
          // hide y grid
          showgrid: false,
        },
        legend: {
          x: 0,
          y: 1,
          traceorder: 'normal',
          font: {
            family: 'sans-serif',
            size: 12,
            color: '#000'
          },
          bgcolor: '#E2E2E2',
          bordercolor: '#FFFFFF',
          borderwidth: 2
        },
      }
      // console.log("plotData", plotData)
      // @ts-ignore
      console.time("plotTimeline: plotly")
      Plotly.newPlot('timelinePlot', plotData, layout, this.plotConfig)
      console.timeEnd("plotTimeline: plotly")
      // const toPaper = plot.toPaper({x: 0, y: 0})
      // console.log("toPaper", toPaper)
      console.timeEnd("plotTimeline")

    },

    plotHeatmap() {
      const states = this.analysis?.getStates() ?? []
      const timestamps = states.map(s => new Date(s.timestamp))
      const siloSpsNrs = states[0].siloAllocations.map(sa => sa.siloSpsNr)
      const siloSpsNrToIndexMap = new Map<number, number>()
      siloSpsNrs.forEach((siloSpsNr, index) => siloSpsNrToIndexMap.set(siloSpsNr, index))
      const siloSpsNrsIndices = siloSpsNrs.map((siloSpsNr, index) => index)
      const indexToSiloSpsNrMap = new Map<number, number>()
      siloSpsNrs.forEach((siloSpsNr, index) => indexToSiloSpsNrMap.set(index, siloSpsNr))

      const z = states.map(state => {
        const nAllocations = state.siloAllocations.filter(sa => sa.storedWeight_t > 0 && sa.siloSpsNr !== null).length
        // const values = siloSpsNrs.map(siloSpsNr => state.siloAllocations.find(sa => `${sa.siloSpsNr}` === siloSpsNr).siloSpsNr ?? 0)
        const indices = []
        const spsNrs = []
        const mask = []
        for (const sa of state.siloAllocations) {
          if (sa.siloSpsNr !== null && sa.storedWeight_t > 0) {
            const index = siloSpsNrToIndexMap.get(sa.siloSpsNr)
            indices.push(index)
            spsNrs.push(sa.siloSpsNr)
            mask.push(1)
          } else {
            indices.push(null)
            spsNrs.push(null)
            mask.push(null)
          }
        }
        console.log("indices", indices)
        console.log("spsNrs", spsNrs)
        // console.log(nAllocations)
        return mask
      })

      console.log("z", z)
      // transpose z
      const zT = []
      for (let i = 0; i < z[0].length; i++) {
        zT.push(z.map(row => row[i]))
      }


      const plotData = [{
        x: timestamps,
        y: siloSpsNrs,
        z: zT,
        type: 'heatmap',
        // mode: 'lines',
        name: 'Silo Allocation',

        // hovertemplate: '%{y} kg',
        // line: {
        //   color: 'rgb(55, 128, 191)',
        //   width: 1
        // },
        // heatmap gaps
        connectgaps: false,
      }]
      const layout = {
        title: 'Silo Allocation',
        xaxis: {
          title: 'Time',
          type: 'date',
          tickformat: '%Y-%m-%d %H:%M:%S',
        },
        // yaxis: {
        //   title: 'Silo Allocation',
        //   tickvals: siloSpsNrsIndices,
        //   ticktext: siloSpsNrs,
        // },

        legend: {
          x: 0,
          y: 1,
          traceorder: 'normal',
          font: {
            family: 'sans-serif',
            size: 12,
            color: '#000'
          },
          bgcolor: '#E2E2E2',
          bordercolor: '#FFFFFF',
          borderwidth: 2
        },
      }
      const config = {
        responsive: true,
        displayModeBar: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines'],
      }
      console.log("plotData", plotData)
      // @ts-ignore
      Plotly.newPlot('timelinePlot', plotData, layout, config)
    }
  },
});
