
import Vue from "vue";
import {mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CoffeeProductionInputFileType} from "@/scripts/models/calculation";
import {CoffeeProductionModelType} from "@/scripts/models/request";

export default Vue.extend({
  name: "RoasterUpload",
  props: {
    dataValidityStartTime: {type: String, required: true},
    selectedFileName: {type: String},
    modelType: {type: String, default: CoffeeProductionModelType.SARA},
  },
  data() {
    return {
      drag: {
        currentlyDraggingOver: false,
        startTime: 0,
        intervalId: -1,
      },
      roasterUpload: {
        file: null as File | null,
        loading: false,
      } as any,
    };
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
  },
  methods: {
    async handleRoasterUpload(event: any) {
      this.roasterUpload.file = event.target.files[0];
      this.roasterUpload.loading = true;
      this.$forceUpdate();
      setTimeout(async () => {
        await this.uploadRoaster();
        this.roasterUpload.loading = false;
        // @ts-ignore
        this.$refs[`roasterFileInput`].value = "";
        this.$forceUpdate();
      }, 0);
    },
    async uploadRoaster() {
      const productionSiteUniqueName = this.productionSiteUniqueName;
      const file: File = this.roasterUpload.file;
      // log({ file });
      if (file === undefined) {
        this.$emit("upload-error",
            {translatedErrorMessage: `Röster-Datei konnte nicht hochgeladen werden (Fehler: Keine Datei ausgewählt).`});
        return;
      }
      // do file upload request
      const bi = this.backendInterface as BackendInterface;
      // const dataValidityStartTime = await bi.getCurrentDateTime(productionSiteUniqueName);
      // const dataValidityStartTime = this.getSortedDates()[0] + `T00:00:00`;

      // get upload id
      const uploadIdResult = await bi.getFileUploadId({
        // productionSiteUniqueName,
        fileType: CoffeeProductionInputFileType.Roaster,
        dataValidityStartTime: this.dataValidityStartTime, //: "2021-11-08T08:00:00.000Z", // LocalDateTime
        // dataValidityTimeUnit: "Unknown", // TimeUnit,
        modelType: this.modelType as CoffeeProductionModelType,
      });
      // log({ uploadIdResult });
      if ("error" in uploadIdResult || "errorMessage" in uploadIdResult) {
        this.$emit(
            "upload-error",
            uploadIdResult
            // {
            //   translatedErrorMessage:
            //       `Röster-Datei konnte nicht hochgeladen werden (Fehler: Upload ID konnte nicht geladen werden).`
            // }
        );
        return;
      }
      const uploadId = uploadIdResult.uploadId;

      // do file upload
      const uploadResult = await bi.uploadFile(uploadId, file);
      // console.log("roaster uploadResult", uploadResult);
      this.roasterUpload.file = null;

      if ("error" in uploadResult || "errorMessage" in uploadResult) {
        // const errorObject = uploadResult as unknown as ExceptionResponse;
        // const msg = errorObject.translatedErrorMessage ?
        //     errorObject.translatedErrorMessage : errorObject.errorMessage
        this.$emit("upload-error", uploadResult)
            // {translatedErrorMessage: "Röster-Datei konnte nicht hochgeladen werden (Fehler: " + msg + ")."});
        return;
      }

      this.$emit("upload-success", "Röster Datei wurde erfolgreich hochgeladen.");
    },
    dragHandler() {
      this.drag.startTime = new Date().getTime();
      this.drag.currentlyDraggingOver = true;
      // console.log("setting startTime");
      if (this.drag.intervalId === -1) {
        this.drag.intervalId = setInterval(() => {
          // console.log("checking time since dragover");
          if (new Date().getTime() - this.drag.startTime > 2000) {
            // console.log("time since dragover too big, resetting");
            this.drag.currentlyDraggingOver = false;
            clearInterval(this.drag.intervalId);
            this.drag.intervalId = -1;
          }
        }, 1000);
      }
    },
  },
});
