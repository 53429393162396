
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import PerformanceDisplay from "@/components/common/PerformanceDisplay.vue";
import DoriSummary from "@/components/dori/DoriSummary.vue";
import {CoffeeProductionDORISummary} from "@/scripts/models/dori";
import {GAS_M3_TO_KWH} from "@/scripts/models/gas";
import GasConsumptionRange from "@/components/gas/GasConsumptionRange.vue";

interface PerformanceDisplayItem {
  title: string
  textCenter: string
  textTop?: string
  textCenterPrefix?: string
  textCenterSuffix?: string
  textBottom?: string
  performanceChange?: string
}

export default Vue.extend({
  name: "DoriPerformanceDashboard",
  components: {GasConsumptionRange, PerformanceDisplay},
  props: {
    doriSummary: {type: Object as PropType<CoffeeProductionDORISummary>, required: true}
  },
  data() {
    return {
      energyMode: "energy",
      energyModes: [
        {text: "Gasvolumen m³", value: "volume"},
        {text: "Energie MWh", value: "energy"},
      ],
    };
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    energyRangeDefinitions(): any[] {
      return [
        {name: "Gesamt", energyConsumption: this.doriSummary!.result.totalEnergyConsumption},
        {name: "Roast & Ground", energyConsumption: this.doriSummary!.result.roastAndGroundEnergyConsumption},
        {name: "Tassimo", energyConsumption: this.doriSummary!.result.tassimoEnergyConsumption},
      ];
    },
    energyFactor() {
      if (this.energyMode === "energy") {
        return 1;
      } else if (this.energyMode === "volume") {
        return (1 / GAS_M3_TO_KWH) * 1000;
      }
    },
    energyUnit() {
      if (this.energyMode === "volume") {
        return "m³";
      } else if (this.energyMode === "energy") {
        return "MWh";
      }
    },
    itemsRoasting(): PerformanceDisplayItem[] {
      return [
        {
          title: "Anzahl Rösteraufträge",
          textCenter: "" + this.doriSummary.result.roasterTaskResults.length,
          textCenterSuffix: ""
        },
        {
          title: "Röstzeit",
          // textCenterPrefix: "~",
          textCenter: "" + Math.round(this.doriSummary.result.roasterTaskResults.reduce((a, v) => a += v.roastingDuration_h, 0)),
          textCenterSuffix: "h"
        },
        {
          title: "Anzahl benutzte Röster",
          textCenter: "" + this.doriSummary.result.roasterTaskResults.map(a => a.roasterName).filter((val, pos, arr) => arr.indexOf(val) === pos).length,
          textCenterSuffix: ""
        },
        {
          title: "Rösterauslastung*",
          textCenter: "79",
          textCenterSuffix: "%",
          performanceChange: "-6"
        },
        {
          title: "Anteil Jupiter*",
          textCenter: "87",
          textCenterSuffix: "%"
        }
      ]
    },
    itemsMilling(): PerformanceDisplayItem[] {
      return [
        {
          title: "Anzahl Mühlenaufträge",
          textCenter: "" + this.doriSummary.result.millingSubTaskResults.length,
          textCenterSuffix: ""
        },
        {
          title: "Mahlzeit ",
          // textCenterPrefix: "~",
          textCenter: "" + Math.round(this.doriSummary.result.millingSubTaskResults.reduce((a, v) => a += v.millingSubTaskDuration_h, 0)),
          textCenterSuffix: "h"
        },
      ]
    },
    itemSilos(): PerformanceDisplayItem[] {
      return [
        {
          title: "Belegte Silozellen*",
          textCenter: "36",
          textCenterSuffix: "/ 44"
        },
        {
          title: "Siloauslastung*",
          // textCenterPrefix: "~",
          textCenter: "504",
          textCenterSuffix: "t / 607t"
        },
      ]
    },
  },
  methods: {
    ...mapMutations([""]),
  },
});
