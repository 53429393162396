import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const paletteJDE = {
  flatWhite: "#fcf9f4",
  crema: "#f7eedc",
  latte: "#d3ba91",
  mocha: "#8c7454",
  doppio: "#3f3a31",
  possibility: "#849fa4",
  focus: "#2a5962",
  direction: "#6b7c43",
  freedom: "#cc902d",
  ambition: "#a04830",
}

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: "#3e2314", // "#5d4037", // colors.brown.darken2
        secondary: "#a1887f", //colors.brown.lighten2,
        accent: colors.indigo.base,
        ...paletteJDE
      },
    },
  },
});
