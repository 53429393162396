
import {BackendInterface} from "@/scripts/BackendInterface";
import Vue from "vue";
import {mapState} from "vuex";
import CompliancePlanSelection from "@/components/common/CompliancePlanSelection.vue";
import ArticleDemandList from "@/components/gas/ArticleDemandList.vue";

// @ts-ignore
import Plotly from "plotly.js-dist";
import {
  AnalyzeGasConsumptionSettings, BasketRoasterGasConsumption,
  GasConsumptionAggregation,
  GasConsumptionPerRoaster,
  GasConsumptionsPerBasket,
} from "@/scripts/models/gas";
import {SiloAssignmentInputFile, CoffeeProductionInputFileType} from "@/scripts/models/calculation";
import {CoffeeProductionCompliancePlanSingle, ItemInfo, ItemInfoStatsKey} from "@/scripts/models/coffee";
import {defaultGasConsumptions} from "@/data/defaultGasConsumptions";
import {debounce, throttle} from "@/scripts/utils";
import { CoffeeProductionModelType } from "@/scripts/models/request";



export default Vue.extend({
  name: "AnalyzeGasConsumptionSection",
  props: {
    settings: {type: Object, required: true},
    showUpload: {type: Boolean, default: true},
    readonly: {type: Boolean, default: false},
    gasConsumptions: {type: Array, default: () => defaultGasConsumptions}
  },
  components: {
    CompliancePlanSelection,
    ArticleDemandList,
  },
  data: function () {
    return {
      tab: null,
      max: {
        article: 0,
        blend: 0,
        basket: 0,
        sum: 0,
      },
      planIsLoading: {
        rg: true,
        tas: true,
      },
      availablePlanFiles: {} as Record<"rg" | "tas", SiloAssignmentInputFile[]>,
      selectedPlanFile: {
        rg: null,
        tas: null,
      } as Record<"rg" | "tas", SiloAssignmentInputFile | null>,
      // selectedPlan: null as any,
      selectedPlan: {
        rg: null,
        tas: null,
      } as Record<"rg" | "tas", CoffeeProductionCompliancePlanSingle | null>,
      productions: {} as Record<string, number>,
      productionsMax: {} as Record<string, number>,
      baseLayout: {
        title: "Gasverbrauch pro Artikel",
        font: {size: 10},
        barmode: "stack",
        // barmode: "group",
        legend: {orientation: "h", x: 0, y: 1.2},
        xaxis: {
          type: "category",
          tickmode: "linear",
        },
        yaxis: {
          ticksuffix: " m³",
          automargin: true,
        },
        height: 200,
        autosize: true,
        // width: 700,
        margin: {
          t: 30,
          r: 0,
          l: 50,
          b: 80,
        },
      },
      color: {
        rg: "#1f77b4",
        tas: "#ff7f0e",
      },
      plotAll_throttled: () => {},
      plotAll_debounced: () => {},
      plotAll_deferred: () => {},
    };
  },
  async created() {

    const fn = () => this.plotAll()
    this.plotAll_throttled = throttle(fn, 2000)
    this.plotAll_debounced = debounce(fn, 50)
    this.plotAll_deferred = debounce(fn, 100)

    const settings = this.settings as AnalyzeGasConsumptionSettings
    if (settings.loadedPlans.rg !== null && settings.loadedPlans.tas !== null) {

      this.selectedPlan.rg = CoffeeProductionCompliancePlanSingle.fromObject(settings.loadedPlans.rg)
      this.selectedPlan.rg.setBasketRoasterGasConsumptions(this.gasConsumptions as BasketRoasterGasConsumption[])

      this.selectedPlan.tas = CoffeeProductionCompliancePlanSingle.fromObject(settings.loadedPlans.tas)
      this.selectedPlan.tas.setBasketRoasterGasConsumptions(this.gasConsumptions as BasketRoasterGasConsumption[])

      this.calculateMaxima();
      this.plotAll();

      this.planIsLoading.rg = false;
      this.planIsLoading.tas = false;


      // calculate real gas consumption
      const gcReal = 0;





    } else {
      this.planIsLoading.rg = true;
      this.planIsLoading.tas = true;
      const bi = this.backendInterface as BackendInterface;
      const params = {
        timeRangeStart: "2022-01-01",
        timeRangeEnd: "2022-07-01",
        productionSiteUniqueName: this.productionSiteUniqueName,
      };
      this.availablePlanFiles.rg = await bi.getInputFiles({
        ...params,
        fileType: CoffeeProductionInputFileType.ComplianceRg,
        modelType: CoffeeProductionModelType.SARA,
      });
      this.availablePlanFiles.tas = await bi.getInputFiles({
        ...params,
        fileType: CoffeeProductionInputFileType.ComplianceTas,
        modelType: CoffeeProductionModelType.SARA,
      });
      this.selectedPlanFile.rg = this.availablePlanFiles.rg[0];
      this.selectedPlanFile.tas = this.availablePlanFiles.tas[0];
      this.planIsLoading.rg = false;
      this.planIsLoading.tas = false;
    }

    // console.log("selected plan", this.selectedPlan)
  },
  mounted() {
  },
  watch: {
    "selectedPlan.rg": {
      deep: true,
      handler() {
        this?.selectedPlan?.rg?.calculateGasConsumptions()
        this.plotAll_debounced()
      },
    },
    "selectedPlan.tas": {
      deep: true,
      handler() {
        this?.selectedPlan?.tas?.calculateGasConsumptions()
        this.plotAll_debounced()
      },
    },
    "selectedPlanFile.rg": {
      async handler() {
        await this.loadPlanRG();
        this.settings.loadedPlans.rg = this.selectedPlanFile.rg?.originalFilename;
        if (this.selectedPlan.tas) {
          this.calculateMaxima();
          this.plotAll();
        }
      },
    },
    "selectedPlanFile.tas": {
      async handler() {
        await this.loadPlanTAS();
        this.settings.loadedPlans.tas = this.selectedPlanFile.tas?.originalFilename;
        if (this.selectedPlan.rg) {
          this.calculateMaxima();
          this.plotAll();
        }
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    loading(): boolean {
      return this.planIsLoading.rg || this.planIsLoading.tas;
    },
    sortedArticleDemands(): any {
      // if (!this.selectedPlan.rg) return [];
      const adsRG = Object.values(this.selectedPlan?.rg?.articleDemands ?? {});
      adsRG.sort((a: any, b: any) => a.index - b.index);
      const adsTAS = Object.values(this.selectedPlan?.tas?.articleDemands ?? {});
      adsTAS.sort((a: any, b: any) => a.index - b.index);
      return {rg: adsRG, tas: adsTAS};
      // return ads.map(ad => [ad.articleNumber, ad])
    },
  },
  methods: {
    async loadPlanRG() {
      if (!this.selectedPlanFile.rg) return;
      this.planIsLoading.rg = true;
      const bi = this.backendInterface as BackendInterface;
      const plan = await bi.getFile_ComplianceRG(this.selectedPlanFile.rg?.id!);
      this.selectedPlan.rg = CoffeeProductionCompliancePlanSingle.fromObject(plan)
      this.selectedPlan.rg.setBasketRoasterGasConsumptions(this.gasConsumptions as BasketRoasterGasConsumption[])
      // console.log("planRG", this.selectedPlan.rg);
      this.planIsLoading.rg = false;
    },
    async loadPlanTAS() {
      if (!this.selectedPlanFile.tas) return;
      this.planIsLoading.tas = true;
      const bi = this.backendInterface as BackendInterface;
      const plan: any = await bi.getFile_ComplianceTAS(this.selectedPlanFile.tas?.id!);
      for (const articleNumber of Object.keys(plan.articleDemands)) {
        const ad = plan.articleDemands[articleNumber];
        if (!ad.article) {
          delete plan.articleDemands[articleNumber];
        }
      }
      // this.selectedPlan.tas = plan;
      this.selectedPlan.tas = CoffeeProductionCompliancePlanSingle.fromObject(plan)
      this.selectedPlan.tas.setBasketRoasterGasConsumptions(this.gasConsumptions as BasketRoasterGasConsumption[])
      // console.log("planTAS", this.selectedPlan.tas);
      this.planIsLoading.tas = false;
    },
    round(n: number) {
      return Math.round(n * 10) / 10;
    },
    mapToTrace(map: Record<string, ItemInfo>, key: ItemInfoStatsKey) {
      const res: any = {x: [], y: []}
      for(const itemKey in map){
        res.x.push(itemKey)
        res.y.push(map[itemKey].gasConsumption[key])
      }
      return res
    },

    // Total
    getTotalDemandsGasConsumption(plan: CoffeeProductionCompliancePlanSingle, key: ItemInfoStatsKey): number {
      let sum = 0;
      for(const articleNr in plan.gasConsumptions?.perArticle ?? {}){
        const gc = plan.getGasConsumption_article(articleNr, key)
        sum += gc
        // console.log("sum", sum)
      }
      return sum;
    },
    getTotalDemandsGasConsumptionString(plan: CoffeeProductionCompliancePlanSingle, key: ItemInfoStatsKey) {
      const sum = this.getTotalDemandsGasConsumption(plan, key);
      // console.log("final sum", sum)
      return new Intl.NumberFormat("de-DE", {maximumFractionDigits: 0}).format(sum);
    },
    getArticleDemandsGasConsumption(plan: CoffeeProductionCompliancePlanSingle, key: ItemInfoStatsKey): Record<string, number> {
      const res: Record<string, number> = {};
      for(const articleNr in plan.gasConsumptions?.perArticle ?? {}){
        res[articleNr] = plan.getGasConsumption_article(articleNr, key)
      }
      return res;
    },
    getBlendDemandsGasConsumption(plan: CoffeeProductionCompliancePlanSingle, key: ItemInfoStatsKey) {
      const res: Record<string, number> = {};
      for(const blendSpsNr in plan.gasConsumptions?.perBlend ?? {}){
        res[blendSpsNr] = plan.getGasConsumption_blend(blendSpsNr, key)
      }
      return res;
    },

    // Basket
    getBasketDemands(articleDemands: any[]): Record<string, number> {
      const basketDemands: Record<string, number> = {};
      for (const ad of articleDemands) {
        if (!ad.article) continue;
        for (const basketSpsNr in ad.article.basketContents) {
          if (!(basketSpsNr in basketDemands)) {
            basketDemands[basketSpsNr] = 0;
          }
          basketDemands[basketSpsNr] += ad.plannedProduction * ad.article.basketContents[basketSpsNr];
        }
      }
      return basketDemands;
    },
    getBasketDemandsGasConsumption(plan: CoffeeProductionCompliancePlanSingle, key: ItemInfoStatsKey): Record<string, number> {
      const res: Record<string, number> = {};
      for(const basketSpsNr in plan.gasConsumptions?.perBasket ?? {}){
        res[basketSpsNr] = plan.getGasConsumption_basket(basketSpsNr, key)
      }
      return res;
    },
    // max
    getCombinedMax(itemsRG: Record<string, ItemInfo>, itemsTAS: Record<string, ItemInfo>, key: ItemInfoStatsKey) {
      const res: Record<string, number> = {};
      for (const itemKey in itemsRG) {
        if (!(itemKey in res)) res[itemKey] = itemsRG[itemKey].gasConsumption[key];
        else res[itemKey] = res[itemKey] + itemsRG[itemKey].gasConsumption[key];
      }
      for (const itemKey in itemsTAS) {
        if (!(itemKey in res)) res[itemKey] = itemsTAS[itemKey].gasConsumption[key];
        else res[itemKey] = res[itemKey] + itemsTAS[itemKey].gasConsumption[key];
      }
      let max = -Infinity;
      for (const itemKey in res) {
        max = Math.max(max, res[itemKey]);
      }
      return max;
    },
    calculateMaxima() {
      const key = "average";
      const f = 1.2;
      const planRG = this.selectedPlan.rg
      const planTAS = this.selectedPlan.tas
      if(planRG === null || planTAS === null) return;
      this.max.article = this.getCombinedMax(planRG.gasConsumptions?.perArticle ?? {}, planTAS.gasConsumptions?.perArticle ?? {}, key) * f;
      this.max.blend = this.getCombinedMax(planRG.gasConsumptions?.perBlend ?? {}, planTAS.gasConsumptions?.perBlend ?? {}, key) * f;
      this.max.basket = this.getCombinedMax(planRG.gasConsumptions?.perBasket ?? {}, planTAS.gasConsumptions?.perBasket ?? {}, key) * f;
      // total
      const gasTotalRG = this.getTotalDemandsGasConsumption(planRG, key);
      const gasTotalTAS = this.getTotalDemandsGasConsumption(planTAS, key);
      this.max.sum = (gasTotalRG + gasTotalTAS) * f;

      this.settings.maxTotalGasConsumption = (gasTotalRG + gasTotalTAS) * 0.9;
    },
    //
    // Plotting
    //
    plotAll() {
      // if (this.sortedArticleDemands.rg !== null || this.sortedArticleDemands.tas !== null) return;
      // console.log("plotAll")
      const planRG = this.selectedPlan.rg
      const planTAS = this.selectedPlan.tas
      if(planRG === null || planTAS === null) return;
      this.plotArticleDemands();
      this.plotBlendDemands();
      this.plotBasketDemands();
      this.plotSummaryTraces(
          planRG.gasConsumptions?.perArticle ?? {},
          planTAS.gasConsumptions?.perArticle ?? {},
          "articleBar",
          "Gasverbrauch pro Artikel",
          "average"
      );
      this.plotSummaryTraces(
          planRG.gasConsumptions?.perBlend ?? {},
          planTAS.gasConsumptions?.perBlend ?? {},
          "blendBar",
          "Gasverbrauch pro Sorten",
          "average"
      );
      this.plotSummaryTraces(
          planRG.gasConsumptions?.perBasket ?? {},
          planTAS.gasConsumptions?.perBasket ?? {},
          "basketBar",
          "Gasverbrauch pro Baskets",
          "average"
      );
      // this.plotSummaryTraces();
      // this.plotSummaryTraces();
    },

    plotSummaryTraces(
        itemsRG: Record<string, ItemInfo>,
        planTAS: Record<string, ItemInfo>,
        target: string,
        title: string,
        key: ItemInfoStatsKey
    ) {
      // const gcRG = itemsRG; //this.getBlendDemandsGasConsumption(itemsRG, "average");
      // const gcTAS = itemsTAS; //this.getBlendDemandsGasConsumption(itemsTAS, "average");
      const gc: Record<string, number> = {};
      for (const itemKey in itemsRG) {
        const v = itemsRG[itemKey].gasConsumption[key];
        if (!(itemKey in gc)) gc[itemKey] = v
        else gc[itemKey] += v
      }
      for (const itemKey in planTAS) {
        const v = planTAS[itemKey].gasConsumption[key];
        if (!(itemKey in gc)) gc[itemKey] = v
        else gc[itemKey] += v
      }

      const traces = [];
      // const annotations = [];
      // let annotationX = 0;
      // let i = 0;
      const items = Object.entries(gc);
      items.sort((a, b) => b[1] - a[1]);
      let acc = 0;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const x = item[1];
        const y = "";
        traces.push({
          name: item[0],
          type: "bar",
          x: [x],
          y: [y],
          orientation: "h",
          mode: "markers",
          text: [item[0]],
          textposition: "inside",
          width: [0.8],
          // base: acc,
          marker: {
            line: {width: 1, color: "white"},
            color: `rgba(31,119,180,${0.4 + (0.6 * (items.length - i)) / items.length})`,
          },
        });
        acc += x + 60;
      }

      if (!this.selectedPlan) return;

      // console.log("this.max.sum", this.max.sum);

      const layout = {
        title: title,
        font: {size: 10},
        height: 40,
        showlegend: false,
        barmode: "stack",
        margin: {l: 50, r: 0, b: 0, t: 20},
        xaxis: {range: [0, this.max.sum], showgrid: false},
        yaxis: {showgrid: false},
        bargap: 0.05,
        shapes: [
          {
            type: "line",
            x0: this.settings.maxTotalGasConsumption,
            y0: 0,
            x1: this.settings.maxTotalGasConsumption,
            yref: "paper",
            y1: 1,
            line: {
              color: "red",
              width: 2,
              // dash: "dot",
            },
          },
        ],
      };

      this.plot(target, traces, layout);
    },
    plot(id: string, traces: any, layout: any) {
      try {
        Plotly.newPlot(id, traces, layout, {
          displayModeBar: false,
        });
      } catch (error) {
        // console.warn(error);
      }
    },
    plotArticleDemands() {
      if (!this.selectedPlan) return;
      const planRG = this.selectedPlan.rg
      const planTAS = this.selectedPlan.tas
      if(planRG === null || planTAS === null) return;
      const rg = this.mapToTrace(planRG.gasConsumptions!.perArticle, "average");
      const tas = this.mapToTrace(planTAS.gasConsumptions!.perArticle, "average");
      // const tas = this.mapToTrace(this.getArticleDemandsGasConsumption(planTAS, "average"));
      const traces = [
        {
          name: `Roast & Ground`,
          ...rg,
          type: "bar",
          marker: {color: rg.x.map(() => this.color.rg)},
        },
        {
          name: `Tassimo`,
          ...tas,
          type: "bar",
          marker: {color: tas.x.map(() => this.color.tas)},
        },
      ];
      // console.log("article max", this.max.article, this.max);

      const layout = {
        ...this.baseLayout,
        yaxis: {range: [0, this.max.article]},
        xaxis: {...this.baseLayout.xaxis, tickfont: {size: 10}},
        // width: 700,
      };
      this.plot("articleDemandPlot", traces, layout);
    },

    plotBlendDemands() {
      if (!this.selectedPlan) return;
      const planRG = this.selectedPlan.rg
      const planTAS = this.selectedPlan.tas
      if(planRG === null || planTAS === null) return;
      const rg = this.mapToTrace(planRG.gasConsumptions!.perBlend, "average");
      const tas = this.mapToTrace(planTAS.gasConsumptions!.perBlend, "average");
      const traces = [
        {
          name: `Roast & Ground`,
          ...rg,
          type: "bar",
          marker: {color: rg.x.map(() => this.color.rg)},
        },
        {
          name: `Tassimo`,
          ...tas,
          type: "bar",
          marker: {color: tas.x.map(() => this.color.tas)},
        },
      ];
      const layout = {
        ...this.baseLayout,
        title: "Gasverbrauch pro Sorte",
        yaxis: {range: [0, this.max.blend]},
        xaxis: {...this.baseLayout.xaxis, tickfont: {size: 10}},
        // width: 700,
      };
      this.plot("blendDemandPlot", traces, layout);
    },

    plotBasketDemands() {
      const planRG = this.selectedPlan.rg
      const planTAS = this.selectedPlan.tas
      if(planRG === null || planTAS === null) return;
      if (!this.selectedPlan) return;
      const rg = this.mapToTrace(planRG.gasConsumptions!.perBasket, "average");
      const tas = this.mapToTrace(planTAS.gasConsumptions!.perBasket, "average");
      const traces = [
        {
          name: `Roast & Ground`,
          ...rg,
          type: "bar",
          marker: {color: rg.x.map(() => this.color.rg)},
        },
        {
          name: `Tassimo`,
          ...tas,
          type: "bar",
          marker: {color: tas.x.map(() => this.color.tas)},
        },
      ];
      const layout = {
        ...this.baseLayout,
        title: "Gasverbrauch pro Basket",
        yaxis: {range: [0, this.max.basket]},
        xaxis: {...this.baseLayout.xaxis, tickfont: {size: 10}},
        // width: 700,
      };
      this.plot("basketDemandPlot", traces, layout);
    },
  },
});
