
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";

export default Vue.extend({
  name: "ProgressInfo",
  components: {},
  props: {
    // between 0 and 1
    value: {type: Number as PropType<number>, default: 1, required: true},
    targetValue: {type: Number as PropType<number | null>, default: null},
    textTop: {type: String as PropType<string>, default: ""},
    textBottom: {type: String as PropType<string>, default: ""},
    color: {type: String as PropType<string>, default: "black"},
  },
  data() {
    return {};
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    percentage(): number {
      return this.value * 100
      // if (this.targetValue === null || this.targetValue === 0) return 100
      // return this.value / this.targetValue * 100
    },
  },
  methods: {
    ...mapMutations([""]),
    scaleDown() {
      const container = this.$refs.progressContainer as HTMLElement;
      const content = this.$refs.progressContent as HTMLElement;

      // Get the current font size
      const currentFontSize = parseFloat(window.getComputedStyle(content).fontSize);

      // Calculate the available width inside the container
      const availableWidth = container.clientWidth;

      // Measure the content's natural width
      const naturalWidth = content.scrollWidth;

      // Calculate the scale factor needed to fit the content
      const scale = availableWidth / naturalWidth;

      // Update the font size
      content.style.fontSize = currentFontSize * scale + 'px';
    }
  },
});
