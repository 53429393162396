
import {DashboardStatus} from "@/scripts/models/general";
import {parseTime} from "@/scripts/utils";
import {InputFileManager,} from "@/scripts/InputFileManager";
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import DashboardElement from "@/components/common/DashboardElement.vue";
import {BackendInterface} from "@/scripts/BackendInterface";
import DatePicker from "@/components/common/DatePicker.vue";
import SolutionSelection from "@/components/common/SolutionSelection.vue";

import {CoffeeProductionInputFileType} from "@/scripts/models/calculation";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import RoasterUpload from "@/components/upload/RoasterUpload.vue";
import BasketUpload from "@/components/upload/BasketUpload.vue";
import CellplanUpload from "@/components/upload/CellplanUpload.vue";
import FulfilledDemandsUpload from "@/components/upload/FulfilledDemandsUpload.vue";
import CompliancePlanMultiUpload from "@/components/upload/CompliancePlanMultiUpload.vue";
import ResetSettingsDialog from "@/components/settings/ResetSettingsDialog.vue";
import {UserSettingsInfo} from "@/scripts/models/results";
import UserSettingsDisplay from "@/components/settings/UserSettingsDisplay.vue";
import {UserInfo, UserRole} from "@/scripts/auth";
import SaraSettingsPreview from "@/components/settings/preview/SaraSettingsPreview.vue";

interface UploadSectionStatus {
  saraSolution: DashboardStatus;
  cellplan: DashboardStatus;
  fulfilledDemands: DashboardStatus;
  dateRange: DashboardStatus;
  compliance: DashboardStatus;
  basket: DashboardStatus;
  roaster: DashboardStatus;
}

export default Vue.extend({
  name: "UploadSection",
  components: {
    SaraSettingsPreview,
    UserSettingsDisplay,
    ResetSettingsDialog,
    CellplanUpload,
    FulfilledDemandsUpload,
    RoasterUpload,
    BasketUpload,
    DashboardElement,
    DatePicker,
    CompliancePlanMultiUpload,
    SolutionSelection,
  },
  props: {
    modelType: {type: String as PropType<CoffeeProductionModelType>, default: CoffeeProductionModelType.SARA},
    preselectedSaraPlanId: {type: String, default: null},
  },
  data: function () {
    return {
      uploadedSettings: null as null | any,
      uploadedSettingsErrorMessage: null as null | string,
      // UserType,
      DashboardStatus,
      CoffeeProductionModelType,
      showSnackbar: false,
      snackbarText: "",
      loading: false,
      // dates: ["2022-04-04"] as [string, string] | string[],
      dates: [] as [string, string] | string[],
      // settings
      preroastForNextWeek: false as boolean,
      substituteMissingBasketsByTestBaskets: false as boolean,
      //
      uniqueFileTypes: [
        CoffeeProductionInputFileType.Cellplan,
        CoffeeProductionInputFileType.FulfilledDemands,
        CoffeeProductionInputFileType.Basket,
        CoffeeProductionInputFileType.Roaster,
      ],
      // status
      status: {
        saraSolution: DashboardStatus.UNSET,
        cellplan: DashboardStatus.UNSET,
        fulfilledDemands: DashboardStatus.UNSET,
        dateRange: DashboardStatus.UNSET,
        compliance: DashboardStatus.UNSET,
        basket: DashboardStatus.UNSET,
        roaster: DashboardStatus.UNSET,
      } as UploadSectionStatus,
      inputFileManager: {} as InputFileManager,
      initialDate: null as null | string,
      dori: {
        saraSummaryId: null as null | string, // "638a174370c78a70c4da376a",
        availableSaraSolutions: [] as any,
      },
      cellplanAndFulfilledDemandsTimeDifference: 0,
      readableSettings: [] as UserSettingsInfo[],
      userSettingsId: "" as string,
      preselectedSaraPlan: null as null | any,
    };
  },
  created() {
    this.init();
  },
  mounted() {
  },
  watch: {
    modelType: "init",
    dates: {
      immediate: false,
      async handler(v) {
        // console.log("DATES", this.elementEnabled);
        if (v !== null && v.length === 2) {
          this.inputFileManager.setDateRange([this.dates[0], this.dates[1]]);
          this.loading = true;
          this.dori.saraSummaryId = null;
          await this.inputFileManager.updateInputFiles(true);
          // await this.updateCellplanAndFulfilledDemandsMatching();
          await this.loadSARASolutionsForDate();
          this.loading = false;
        }
      },
    },
    "inputFileManager.availableFiles": {
      immediate: false,
      deep: true,
      handler() {
        // console.log("watcher: inputFileManager.availableFiles");
        this.updateStatus();
      },
    },
    "inputFileManager.selectedFiles": {
      immediate: false,
      deep: true,
      async handler() {
        await this.updateCellplanAndFulfilledDemandsMatching();
        // console.log("watcher: inputFileManager.availableFiles");
        this.updateStatus();
      },
    },
    "dori.saraSummaryId": "updateStatus",
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    dataValidityStartTime(): string {
      return this.inputFileManager.dateRange[0] + `T00:00:00`;
    },
    complianceSelectionPlaceholder() {
      if (this.isPapa) {
        return `Wählen Sie zuerst einen Berechnungszeitraum`;
      } else if (this.isSara) {
        return `Wählen Sie zuerst eine Zellenbelegung`;
      }
    },
    elementEnabled(): any {
      // console.log("this.status.dateRange === DashboardStatus.SUCCESS", this.status.dateRange === DashboardStatus.SUCCESS)
      return {
        dateRange: true,
        cellplan: this.status.dateRange === DashboardStatus.SUCCESS,
        saraSolution: this.status.dateRange === DashboardStatus.SUCCESS,
        fulfilledDemands: this.status.dateRange === DashboardStatus.SUCCESS,
        compliance:
            this.status.dateRange === DashboardStatus.SUCCESS,
        // this.status.dateRange === DashboardStatus.SUCCESS &&
        // && this.status.fulfilledDemands === DashboardStatus.SUCCESS
        //     this.status.cellplan === DashboardStatus.SUCCESS ||
        //     (this.status.dateRange === DashboardStatus.SUCCESS && (this.isPapa || this.isDori)),
        basket: this.status.dateRange === DashboardStatus.SUCCESS,
        roaster: this.status.dateRange === DashboardStatus.SUCCESS,
      };
    },
    continueButtonEnabled(): boolean {
      if (this.isDori) {
        return this.status.saraSolution === DashboardStatus.SUCCESS;
      }
      // console.log("cellplanAndFulfilledDemandsMatching", this.cellplanAndFulfilledDemandsMatching)
      return (
          // this.cellplanAndFulfilledDemandsMatching &&
          (this.status.saraSolution === DashboardStatus.SUCCESS || !this.isDori) &&
          // (this.status.cellplan === DashboardStatus.SUCCESS || this.isPapa) &&
          // this.status.fulfilledDemands === DashboardStatus.SUCCESS &&
          this.status.dateRange === DashboardStatus.SUCCESS &&
          this.status.compliance === DashboardStatus.SUCCESS &&
          this.status.basket === DashboardStatus.SUCCESS &&
          this.status.roaster === DashboardStatus.SUCCESS
      );
    },
    isRoasterAndBasketUploadEnabled(): boolean {
      if (this.isSara) {
        const saraRole = (this.user as UserInfo)?.saraUserRole;
        return saraRole === UserRole.ADMIN || saraRole === UserRole.SUPERUSER;
      }
      if (this.isPapa) {
        const papaRole = (this.user as UserInfo)?.papaUserRole;
        return papaRole === UserRole.ADMIN || papaRole === UserRole.SUPERUSER;
      }
      return false
    },
    isPapaSuperuser(): boolean {
      const papaRole = (this.user as UserInfo)?.papaUserRole;
      return papaRole === UserRole.ADMIN || papaRole === UserRole.SUPERUSER;
    },
    isAnySuperuser(): boolean {
      for (const modelType of ['sara', 'dori', 'papa']) {
        // @ts-ignore
        const role = this.user?.[modelType + 'UserRole'];
        if (role === UserRole.SUPERUSER) return true;
      }
      return false;
    },
    // isSuperuser(): boolean {
    //   return this.userType === UserType.SUPERUSER;
    // },
    isSara(): boolean {
      return this.modelType === CoffeeProductionModelType.SARA;
    },
    isPapa(): boolean {
      return this.modelType === CoffeeProductionModelType.PAPA;
    },
    isDori(): boolean {
      return this.modelType === CoffeeProductionModelType.DORI;
    },
    cellplanAndFulfilledDemandsMatching(): boolean {
      return true;
      // return this.cellplanAndFulfilledDemandsTimeDifference < 10 * 1000 // 10 seconds
    }
  },
  methods: {
    parseTime,
    ...mapMutations([""]),
    handleSettingsUpload(file: File) {
      file.text().then((text) => {
        this.uploadedSettings = text;

        // DEBUG:
        try {
          this.uploadedSettingsErrorMessage = null
          console.log("Trying to parse using JSON.parse ...")
          const parsedSettings = JSON.parse(this.uploadedSettings)
          console.log("DEBUG: parsedSettings (JSON.parse): ", parsedSettings)
        } catch (e) {
          this.uploadedSettingsErrorMessage = "Error parsing uploaded settings: " + e
          console.error("DEBUG: error parsing settings", e)
          console.log("Trying to parse using eval ...")
          const parsedSettings = eval(`(${this.uploadedSettings})`)
          console.log("DEBUG: parsedSettings (eval):", parsedSettings)
        }
      })
    },
    init() {
      this.loadReadableSettings()
      this.getDefaultUserSetting()
      if (this.modelType === CoffeeProductionModelType.DORI) {
        this.substituteMissingBasketsByTestBaskets = true
      }
      // console.log("init");
      this.dates = [];
      const initialDate = this.$route.query?.date;
      // console.log("initialDate", initialDate);
      if (typeof initialDate === "string") {
        // if(initialDate !== undefined){
        this.initialDate = initialDate;
      }
      // await this.inputFileManager.updateInputFiles(false);
      // this.status = {
      //   cellplan: DashboardStatus.UNSET,
      //   fulfilledDemands: DashboardStatus.UNSET,
      //   dateRange: DashboardStatus.UNSET,
      //   compliance: DashboardStatus.UNSET,
      //   basket: DashboardStatus.UNSET,
      //   roaster: DashboardStatus.UNSET,
      // } as UploadSectionStatus;
      this.inputFileManager = new InputFileManager(this.backendInterface, {
        modelType: this.modelType as CoffeeProductionModelType,
        productionSiteUniqueName: this.productionSiteUniqueName,
      });
      // this.updateStatus();
      // console.log("status", this.status);
      // console.log("elementEnabled", this.elementEnabled);
      this.setPreselectedSaraResult();
    },

    async setPreselectedSaraResult() {
      if (this.modelType === CoffeeProductionModelType.DORI) {
        if (this.preselectedSaraPlanId) {
          // console.log("PRESELECTED SARA PLAN ID:" + this.preselectedSaraPlanId);
          const bi = this.backendInterface as BackendInterface
          const saraSummary = await bi.sara_GetSolutionById(this.preselectedSaraPlanId);
          const workDate = await bi.getWorkDate({localDateTime: saraSummary.firstDayStartTime})
          this.initialDate = workDate;
          this.dori.saraSummaryId = this.preselectedSaraPlanId;
          this.preselectedSaraPlan = saraSummary;
        }
      }
    },
    async loadReadableSettings(): Promise<void> {
      // this.loading = true
      const bi = this.backendInterface as BackendInterface
      let readableSettings = await bi.getReadableUserSettingInfos(this.modelType)
      // console.log("readableSettings", readableSettings)
      this.readableSettings = [
        // @ts-ignore
        {name: "Keine Einstellungen", id: null},
        ...readableSettings
      ]
    },
    handleUploadError(errorObject: any) {
      this.$emit("soft-error", errorObject)
      // this.showSnackbar = true;
      // this.snackbarText = errorMessage;
    },
    async handleUploadSuccess(successMessage: string) {
      this.showSnackbar = true;
      this.snackbarText = successMessage;
      this.loading = true;
      await this.inputFileManager.updateInputFiles(false);
      this.loading = false;
    },
    // async resetUserSettings() {
    //   let affectedUserTypes: string = "Superuser";
    //   if (this.userType === UserType.ADMIN) {
    //     affectedUserTypes = "User + Admin";
    //   } else if (this.userType === UserType.USER) {
    //     affectedUserTypes = "User";
    //   }
    //   if (!confirm(`Warnung: Möchten Sie die Einstellungen für "${affectedUserTypes}" wirklich zurücksetzen?`)) return;
    //   const bi: BackendInterface = this.backendInterface;
    //
    //   // SARA
    //   if (this.modelType == CoffeeProductionModelType.SARA) {
    //     await bi.sara_ResetUserSettings();
    //     if (this.userType === UserType.ADMIN) {
    //       await bi.sara_ResetUserSettings();
    //     }
    //   } else if (this.modelType == CoffeeProductionModelType.PAPA) {
    //     await bi.papa_ResetUserSettings();
    //     if (this.userType === UserType.ADMIN) {
    //       await bi.papa_ResetUserSettings();
    //     }
    //   } else if (this.modelType == CoffeeProductionModelType.DORI) {
    //     await bi.dori_ResetUserSettings();
    //     if (this.userType === UserType.ADMIN) {
    //       await bi.dori_ResetUserSettings();
    //     }
    //   }
    //   // DORI
    //   // TODO
    //
    //   this.snackbarText = `Einstellungen zurückgesetzt (${affectedUserTypes})!`;
    //   this.showSnackbar = true;
    // },
    async getDefaultUserSetting(): Promise<void> {
      const bi: BackendInterface = this.backendInterface;
      let info = await bi.getDefaultUserSettingInfo(this.modelType);
      //console.log(info);
      if (info !== null)
        this.userSettingsId = info.id;
    },
    async setDefaultUserSetting(): Promise<void> {
      if (this.userSettingsId !== null && this.userSettingsId !== "") {
        const bi: BackendInterface = this.backendInterface;
        await bi.setDefaultUserSetting(this.modelType, this.userSettingsId);
      }
    },
    async updateCellplanAndFulfilledDemandsMatching() {
      this.cellplanAndFulfilledDemandsTimeDifference = await this.inputFileManager.getSelectedCellplanAndFulfilledDemandsTimeDifference(this.dates[0])
      // console.log("matching:", matching)
    },
    updateStatus(): any {
      const nSelectedComplianceFiles = this.inputFileManager?.gatherSelectedComplianceFiles()?.length ?? 0;
      const nWeeks = Object.keys(this.inputFileManager.selectedFiles ?? {}).length - 1;
      const ifm = this.inputFileManager;
      const isCellplanSelected = ifm.isFiletypeSelected(CoffeeProductionInputFileType.Cellplan);
      const isFulfilledDemandsSelected = ifm.isFiletypeSelected(CoffeeProductionInputFileType.FulfilledDemands);
      const isBasketSelected = ifm.isFiletypeSelected(CoffeeProductionInputFileType.Basket);
      const isRoasterSelected = ifm.isFiletypeSelected(CoffeeProductionInputFileType.Roaster);
      const isSaraSolutionSelected = this.dori.saraSummaryId !== null;
      const toStatus = (b: boolean) => (b ? DashboardStatus.SUCCESS : DashboardStatus.ERROR);
      this.status = {
        saraSolution: toStatus(isSaraSolutionSelected),
        cellplan: toStatus(isCellplanSelected),
        fulfilledDemands: toStatus(isFulfilledDemandsSelected),
        dateRange: toStatus(this.dates.length == 2),
        compliance: toStatus(nSelectedComplianceFiles === nWeeks * 2),
        basket: toStatus(isBasketSelected),
        roaster: toStatus(isRoasterSelected),
      };
    },
    emitSelectedInputFiles(): void {
      // check if fulfilled demands are set
      // const fulfilledDemandsSet = this.status.fulfilledDemands !== DashboardStatus.SUCCESS;
      // const modelTypeSARA = this.modelType === CoffeeProductionModelType.SARA;
      // if (fulfilledDemandsSet && modelTypeSARA) {
      //   const warning = "Warnung: Es wurden keine chargierten Mengen hochgeladen. Trotzdem fortfahren?";
      //   if (!confirm(warning)) return;
      // }
      let [timeRangeStart, timeRangeEnd] = this.inputFileManager.dateRange;
      if (this.preroastForNextWeek) {
        let d = new Date(timeRangeEnd).getTime() - 24 * 60 * 60 * 1000;
        timeRangeEnd = new Date(d).toISOString().slice(0, 10);
      }
      // NOTE: fulfill demands are being set to null here
      // @ts-ignore
      this.inputFileManager.selectedFiles["-1"]["FulfilledDemands"] = null
      // @ts-ignore
      this.inputFileManager.selectedFiles["-1"]["Cellplan"] = null
      const inputFileIds = this.inputFileManager.gatherSelectedInputFileIds();
      // console.log("inputFileIds", inputFileIds)
      // ts-ignore
      const solveRequestRequest: any = {
        productionSiteUniqueName: this.productionSiteUniqueName,
        timeRangeStart,
        timeRangeEnd,
        inputFileIds: inputFileIds,
        // userType: this.userType,
        preroastForNextWeek: this.preroastForNextWeek,
        substituteMissingBasketsByTestBaskets: this.substituteMissingBasketsByTestBaskets,
        // ts-ignore
        saraSummaryId: this.dori.saraSummaryId,
        // userSettingsId: this.userSettingsId,
      };
      if (this.uploadedSettings === null) {
        solveRequestRequest.userSettingsId = this.userSettingsId;
      } else {
        solveRequestRequest.inputSettings = this.uploadedSettings;
      }
      // log({ solveRequestRequest });
      this.$emit("input-files-selected", solveRequestRequest);
    },
    async loadSARASolutionsForDate() {
      if (!this.isDori) return;
      const bi = this.backendInterface as BackendInterface
      let [timeRangeStart, timeRangeEnd] = this.inputFileManager.dateRange;
      // const userType = this.userType === UserType.USER ? UserType.ADMIN : this.userType;
      let availableSaraSolutions: any[] = await bi.dori_ListInputSaraSolutions({
        // productionSiteUniqueName: this.productionSiteUniqueName,
        timeRangeStart: timeRangeStart,
        timeRangeEnd: timeRangeStart,
      }) ?? [];
      // availableSaraSolutions = availableSaraSolutions.map((s) => new CoffeeProductionSARASummary(s));
      availableSaraSolutions?.sort((a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime());
      this.dori.availableSaraSolutions = availableSaraSolutions;
    },
  },
});
