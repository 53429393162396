
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import ArticleDemandListItem from "@/components/gas/ArticleDemandListItem.vue"

export default Vue.extend({
  name: "ArticleDemandList",
  components: {ArticleDemandListItem},
  props: {
    items: { type: Array, default: () => [] },
    readonly: {type: Boolean, default: false}
  },
  data() {
    return {
      articleDemandMap: {} as Record<string, any>,
      productionsMax: {} as Record<string, number>,
    };
  },
  created() {},
  watch: {
    items: {
      immediate: true,
      handler(articleDemands) {
        for (const ad of articleDemands) {
          this.articleDemandMap[ad.articleNumber] = ad;
        }
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
  },
  methods: {
    ...mapMutations([""]),
  },
});
