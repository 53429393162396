
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { LegendEntry, LegendEntryType } from "@/scripts/models/coffee";

export default Vue.extend({
  name: "Legend",
  components: {},
  props: {
    items: { type: Array, required: true }, // LegendEntry[]
    maxCols: { default: -1 },
    maxWidth: { type: Number },
  },
  data() {
    return {
      LegendEntryType,
    };
  },
  created() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    chunkedItems(): LegendEntry[][] {
      const items = this.items as LegendEntry[];
      if (this.maxCols > 0) {
        return this.chunk(items, this.maxCols);
      } else {
        return [items];
      }
    },
  },
  methods: {
    ...mapMutations([""]),
    chunk(array: any[], size: number): any[][] {
      return array.reduce((acc, _, i) => {
        if (i % size === 0) acc.push(array.slice(i, i + size));
        return acc;
      }, []);
    },
  },
});
