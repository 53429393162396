
import Vue, { PropType } from "vue";
import { mapMutations, mapState } from "vuex";
import { WEEKDAYS } from "@/scripts/models/date";
import { round } from "@/scripts/utils";
import { LegendEntry } from "@/scripts/models/coffee";
import Legend from "@/components/common/Legend.vue";
export default Vue.extend({
  name: "WeekdayTable",
  components: {
    Legend,
  },
  props: {
    title: { type: String as PropType<string> },
    firstColumnHeader: { type: String as PropType<string>, default: "" },
    map: { type: Object, required: true }, // rowkey -> dayIndex -> object
    noSearch: { type: Boolean as PropType<boolean>, default: false },
    popupTranslations: { type: Object as PropType<Record<string, string|undefined>> },
    showColumnTotal: { type: Boolean as PropType<boolean>, default: true },
    valueKey: { type: String as PropType<string>, required: true },
    // deprecated
    // numberOfDays: { type: Number as PropType<number>, default: 7 },
    startDayIndex: { type: Number as PropType<number>, default: 0 },
    endDayIndex: { type: Number as PropType<number>, default: 7 },
    rowKeyPopUps: { type: Object as PropType<Record<string, string> | null>, default: null },
    sumPopUps: { type: Array as PropType<string[]>, default: null },
    legendItems: { type: Object as PropType<LegendEntry[]>, default: null, required: false },
  },
  data() {
    return {
      WEEKDAYS,
      searchTerm: "",
    };
  },
  created() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    truncatedWeekdays(): string[] {
      const truncatedWeekdays = WEEKDAYS.slice(this.startDayIndex, this.endDayIndex);
      // console.log("truncatedWeekdays", truncatedWeekdays);
      return truncatedWeekdays;
    },
  },
  methods: {
    ...mapMutations([""]),
    matchesSearchTerm(str: string) {
      const searchTerm = this.searchTerm;
      if (searchTerm === "" || searchTerm === null) return true;
      return str.indexOf(searchTerm) !== -1;
    },
    format(s: string) {
      if (typeof s === "number") {
        return round(2)(s);
      }
      if (typeof s === "boolean") return s ? "Ja" : "Nein";
      if (typeof this.popupTranslations === "object") {
        if (s in this.popupTranslations) return this.popupTranslations[s];
      }
      return s;
    },
    formatTableCellValue(s: number) {
      if (s === 0) return "";
      return round(2)(s);
    },
    getSumForWeekday(weekdayIndex: number) {
      // console.log(this.map);
      let sum = 0;
      for (let key in this.map) {
        if (this.matchesSearchTerm(key)) sum += this.map?.[key]?.[weekdayIndex]?.[this.valueKey] ?? 0;
      }
      return sum;
    },
    getSumPopUpForWeekday(weekdayIndex: number, sumPopUpValues: string[]) {
      let sumPopUp: Record<string, any> = {};
      for (const value of sumPopUpValues) {
        sumPopUp[value] = 0;
      }
      for (let key in this.map) {
        if (this.matchesSearchTerm(key)) {
          for (const value of sumPopUpValues) {
            sumPopUp[value] += this.map?.[key]?.[weekdayIndex]?.[value] ?? 0;
          }
        }
      }
      return sumPopUp;
    },
    getTableCellValueString(item: any) {
      // <span style="color: red"> </span>
      const value = item?.[this.valueKey] ?? 0;
      return this.formatTableCellValue(value);
    },
  },
});
