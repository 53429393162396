
import { CoffeeProductionErrorReport } from "@/scripts/models/general";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "Support",
  components: {},
  props: {},
  data() {
    return {
      
    };
  },
  created() {
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
  },
  methods: {
    ...mapMutations([""]),
  },
});
