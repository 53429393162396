
// import { UserType } from "@/scripts/models/general";
import {hash_java} from "@/scripts/utils";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "Login",
  props: {
    // showLogin
    value: { required: true, default: false },
  },
  components: {},
  data() {
    return {
      password: "",
      loggedIn: false,
      showLogin: location.hostname !== "localhost",
      showLoginError: false,
    };
  },
  created() {},
  watch: {
    value: {
      immediate: true,
      handler(v){
        this.showLogin = v
      }
    },
    showLogin(v){
      if(v === true) this.$emit("logged-in")
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["setUserType"]),
    handleLogin() {
      // const hashedPassword = hash_java(this.password);
      // console.log("PASSWORD", hashedPassword);
      // // ¯\_(ツ)_/¯
      // const isUser = hashedPassword === -661717934; // jde2021silos
      // const isAdmin = hashedPassword === -678489497; // jde2021admin
      // const isSuperuser = hashedPassword === -587442192; // dagopt_superuser
      // const loginSuccessful = isUser || isAdmin || isSuperuser;
      // if (loginSuccessful) {
      //   this.loggedIn = true;
      //   // this.showLogin = false;
      //   this.$emit("input", false)
      //   this.showLoginError = false;
      //   this.$emit("logged-in")
      // } else {
      //   this.showLoginError = true;
      // }
      // if (isUser) {
      //   console.log("is USER, setting ", UserType.USER);
      //   this.setUserType(UserType.USER);
      // }
      // if (isAdmin) {
      //   console.log("is ADMIN, setting ", UserType.ADMIN);
      //   this.setUserType(UserType.ADMIN);
      // }
      // if (isSuperuser) {
      //   console.log("is SUPERUSER, setting ", UserType.SUPERUSER);
      //   this.setUserType(UserType.SUPERUSER);
      // }
    },
  },
});
