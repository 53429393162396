
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";

export default Vue.extend({
  name: "LoginView",
  components: {},
  props: {},
  data() {
    return {
      username: "", //"jde_app_owner",
      password: "", //"dagoptAppOwnerPassword",
      showLoginError: false,
      loginErrorMessage: "",
      loggingIn: false,
    };
  },
  mounted() {
    this.backendInterface.logout()
    // if(this.backendInterface.isLoggedIn()){
    //   this.$router.push({path: "/sara/plan/selection"});
    // }
  },
  watch: {},
  computed: {
    ...mapState(["version", "userType", "backendInterface", "productionSiteUniqueName"]),
    loginDisabled(): boolean {
      return this.username === "" || this.password === "";
    },
  },
  methods: {
    ...mapMutations(["setUser", "setProductionSite", "setLoggedIn"]),
    async handleLogin() {
      if(this.loggingIn) return;
      const bi = this.backendInterface as BackendInterface;
      try {
        this.loggingIn = true
        await bi.login(this.username, this.password)
        console.log("Login successful")
        //console.log(await bi.getUserInfo());
        this.setLoggedIn(true)
        const user = await bi.getCurrentUserInfo()
        this.setUser(user)
      } catch (e) {
        console.error(e)
        this.showLoginError = true
        // this.loginErrorMessage = e.message
        this.loginErrorMessage = "Benutzername und/oder Passwort sind inkorrekt"
      } finally {
        this.loggingIn = false
      }

      try {
        // const user = await bi.getUser()
        // this.setUser(user)
        await bi.setFrontendVersionIfNewer(this.version)
        // const prodSite = await bi.getProductionSite()
        // this.setProductionSite(prodSite)
        const targetAfterLogin = (this.$route?.query?.redirect as string) ?? "/dori/plan/selection";
        await this.$router.push({path: targetAfterLogin});
      } catch (e) {
        console.error(e)
        this.showLoginError = true
        this.loginErrorMessage = "Fehler beim login - überprüfen Sie Benutzername und Passwort."
      }
    },
    handleForgotPassword() {
      this.$router.push({name: "Reset Password"});
    },
  },
});
