
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {UserInfo} from "@/scripts/auth";
import UserInfoListItem from "@/views/UserInfoListItem.vue";

interface Notification {
  type: "success" | "error" | "info" | "warning" | "none"
  message: string
  show: boolean
}

export default Vue.extend({
  name: "ProfileView",
  components: {UserInfoListItem},
  props: {},
  data() {
    return {
      loading: false,
      editing: false,
      userInfo: null as null | UserInfo,
      breadcrumbs: [
        {text: "User", disabled: true, to: ""},
        {text: "Profil", disabled: true},
      ],
      notification: {
        type: "none",
        message: "",
        show: false
      },
      passwordReset: {
        oldPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
      }
    };
  },
  created() {
    this.loadUserInfo();
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
  },
  methods: {
    ...mapMutations(["loadUser"]),
    async changePassword(){
      const res:any = await this.bi.changePassword({
        oldPassword: this.passwordReset.oldPassword,
        newPassword: this.passwordReset.newPassword,
        newPasswordRepeat: this.passwordReset.newPasswordRepeat
      })
      if (res !== null && 'errorMessage' in res) {
        this.notification = {
          type: "error",
          message: res.translatedErrorMessage ?? res.errorMessage,
          show: true
        }
        return;
      } else {
        this.notification = {
          type: "success",
          message: "Passwort erfolgreich geändert! Seite wird neu geladen ...",
          show: true
        }
        setTimeout(() => {
          // reload page
          window.location.reload()
        }, 2000)
      }
    },
    async loadUserInfo() {
      this.loading = true
      this.userInfo = await this.bi.getCurrentUserInfo()
      console.log(this.userInfo)
      this.loading = false;
    },
    async save() {
      const userInfo = this.userInfo as UserInfo
      userInfo.email = userInfo.email.toLowerCase();
      const res: any = await this.bi.changeUserData(userInfo)
      console.log("res", res)
      if (res !== null && 'errorMessage' in res) {
        this.notification = {
          type: "error",
          message: res.translatedErrorMessage ?? res.errorMessage,
          show: true
        }
        return;
      }

      this.notification = {
        type: "success",
        message: "Daten erfolgreich gespeichert",
        show: true
      }
      await this.loadUser()
      this.editing = false;
    }
  },
});
