import {StringToNumberMap} from "@/scripts/models/general";

export interface ProductionDateRange {
    // productionSiteUniqueName: string;
    timeRangeStart: string;
    timeRangeEnd: string;
}

export enum TimeUnit {
    HOUR = "Hour",
    MINUTE = "Minute",
    SECOND = "Second",
}

export interface DayOfWeekDateRange {
    startDate: DayOfWeekDate;
    endDate: DayOfWeekDate;
}

export interface DayOfWeekDate {
    year: number;
    weekNumber: number;
    dayOfWeek: Weekday;
}

export enum DayOfWeek {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
}

export const WEEKDAYS = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
export const weekdayGerToEnum = {
    Montag: "MONDAY",
    Dienstag: "TUESDAY",
    Mittwoch: "WEDNESDAY",
    Donnerstag: "THURSDAY",
    Freitag: "FRIDAY",
    Samstag: "SATURDAY",
    Sonntag: "SUNDAY",
};
export const weekdayEnumToGer = {
    MONDAY: "Montag",
    TUESDAY: "Dienstag",
    WEDNESDAY: "Mittwoch",
    THURSDAY: "Donnerstag",
    FRIDAY: "Freitag",
    SATURDAY: "Samstag",
    SUNDAY: "Sonntag",
}
export const weekdayToTimestepMap: StringToNumberMap = {
    MONDAY: 0,
    TUESDAY: 1,
    WEDNESDAY: 2,
    THURSDAY: 3,
    FRIDAY: 4,
    SATURDAY: 5,
    SUNDAY: 6,
};

export enum Weekday {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
}