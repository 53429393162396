
// import {UserType} from "@/scripts/models/general";
import {getEnumKeys} from "@/scripts/utils";
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import {UserRole} from "@/scripts/auth";
import SolveProcessManager from "@/views/SolveProcessManager.vue";

export default Vue.extend({
  name: "Sidebar",
  props: {},
  components: {},
  data() {
    return {
      // UserType,
      drawer: true,
      mini: false,
    };
  },
  created() {
    // console.log("sidebar user", this.user)
  },
  watch: {
    user:{
      deep: true,
      handler: function () {
        // console.log("user", this.user)
        this.$forceUpdate()
      }
    }
    // userUpdateTrigger: function () {
    //   console.log("userUpdateTrigger", this.userUpdateTrigger)
    //   this.$forceUpdate()
    // },
  },
  computed: {
    ...mapState(["user", "userUpdateTrigger", "superuser", "user", "maxUserType", "version", "backendInterface"]),
    isAnyAdminOrMore(): boolean {
      for(const modelType of ['sara', 'dori', 'papa']){
        // @ts-ignore
        const role = this.user?.[modelType+'UserRole'];
        if(role === UserRole.SUPERUSER) return true;
        if(role === UserRole.ADMIN) return true;
      }
      return false;
    },
    topItems(): any[] {
      return this.items.filter((item: any) => !item.bottom);
    },
    bottomItems(): any[] {
      return this.items.filter((item: any) => item.bottom);
    },
    // userIsSet(): boolean {
    //   return this.user !== null
    // },
    moduleAccess(): Record<string, boolean> {
      if (!this.user) {
        return {
          papa: false,
          sara: false,
          dori: false,
        }
      }
      return {
        papa: this.user.papaUserRole !== UserRole.NONE,
        sara: this.user.saraUserRole !== UserRole.NONE,
        dori: this.user.doriUserRole !== UserRole.NONE,
      }
    },
    items(): any[] {
      return [
        // SARA
        {
          type: "section",
          title: "SARA",
          description: "Silo And Roaster Assignment",
          bottom: false,
          visible: this.moduleAccess.sara
        },
        {
          type: "link",
          title: "Zellenplan Berechnen",
          link: "/sara/plan/calculation",
          icon: "mdi-console",
          disabled: false,
          bottom: false,
          visible: this.moduleAccess.sara
        },
        {
          type: "link",
          title: "Zellenplan Laden",
          link: "/sara/plan/selection",
          icon: "mdi-calendar",
          disabled: false,
          bottom: false,
          visible: this.moduleAccess.sara
        },
        // PAPA
        {
          type: "section",
          title: "PAPA",
          description: "Production Assignment Planning Assistant",
          bottom: false,
          visible: this.moduleAccess.papa
        },
        {
          type: "link",
          title: "Produktionsplan Berechnen",
          link: "/papa/plan/calculation",
          icon: "mdi-console",
          disabled: true, // this.userType !== UserType.SUPERUSER,
          bottom: false,
          visible: this.moduleAccess.papa
        },
        {
          type: "link",
          title: "Produktionsplan Laden",
          link: "/papa/plan/selection",
          icon: "mdi-calendar",
          disabled: true, // this.userType !== UserType.SUPERUSER,
          bottom: false,
          visible: this.moduleAccess.papa
        },
        // DORI
        {
          type: "section",
          title: "DORI",
          description: "Production Assignment Planning Assistant",
          bottom: false,
          visible: this.moduleAccess.dori
        },
        // {
        //   type: "link",
        //   title: "Prototyp",
        //   link: "/dori/demo",
        //   icon: "mdi-console",
        //   disabled: this.userType === UserType.USER,
        //   bottom: false,
        // },
        {
          type: "link",
          title: "Tagesplan Berechnen",
          link: "/dori/plan/calculation",
          icon: "mdi-console",
          disabled: false,
          bottom: false,
          visible: this.moduleAccess.dori
        },
        {
          type: "link",
          title: "Tagesplan Laden",
          link: "/dori/plan/selection",
          icon: "mdi-calendar",
          disabled: false,
          bottom: false,
          visible: this.moduleAccess.dori
        },
        // {
        //   type: "link",
        //   title: "Produktionsplanung",
        //   link: "/papa",
        //   icon: "mdi-chart-gantt",
        //   disabled: this.userType === UserType.USER,
        // },
        // OTHER
        // {
        //   type: "section",
        //   title: "ANDERES",
        //   description: "Production Assignment Planning Assistant",
        // },
        // {
        //   type: "link",
        //   title: "Gasverbrauch",
        //   link: "/gas",
        //   icon: "mdi-fire",
        //   //@ts-ignore
        //   disabled: this.userType !== UserType.SUPERUSER,
        // },
        {
          type: "link",
          title: "Einstellungen",
          link: "/settings",
          icon: "mdi-cog",
          //@ts-ignore
          disabled: false, //this.userType !== UserType.SUPERUSER,
          bottom: true,
        },
        {
          type: "link",
          title: "Berechnungen",
          link: "/running-calculations",
          icon: "mdi-console",
          //@ts-ignore
          disabled: false, //this.userType !== UserType.SUPERUSER,
          bottom: true,
          visible: true
        },
        {
          type: "link",
          title: "Support",
          link: "/support",
          icon: "mdi-email",
          //@ts-ignore
          disabled: false,
          bottom: true,
        },
        {
          type: "link",
          title: "Sandbox",
          link: "/sandbox",
          icon: "mdi-hard-hat",
          //@ts-ignore
          disabled: false,
          bottom: true,
          visible: location.hostname === "localhost"
        },
        // {
        //   type: "link",
        //   title: "Changelog",
        //   link: "/changelog",
        //   icon: "mdi-format-list-checks",
        //   //@ts-ignore
        //   disabled: this.userType !== UserType.SUPERUSER,
        //   bottom: true,
        // },
        // {
        //   title: "Datenübertragung",
        //   link: "/datentransfer",
        //   icon: "mdi-cloud-upload-outline",
        //   disabled: true,
        // },
        // {
        //   title: "Datenbank",
        //   link: "/datenbank",
        //   icon: "mdi-database",
        //   disabled: true,
        // },
        // {
        //   title: "About",
        //   link: "/info",
        //   icon: "mdi-information-outline",
        //   disabled: true,
        // },
      ];
    },
  },
  methods: {
    ...mapMutations(["setUserType"]),
    getEnumKeys,
    logout() {
      this.backendInterface.logout()
      this.$router.push("/login")
      // logout()
      // window.location.href = window.location.origin
    },
    viewProfile() {
      this.$router.push("/user/profile")
    },
  },
});
