
import Vue, {PropType} from "vue";
import { mapMutations, mapState } from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {AvailabilityStatus, SummaryMetadata} from "@/scripts/models/results";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import {UserRole} from "@/scripts/auth";

export default Vue.extend({
  name: "SummaryActions",
  components: {},
  props: {
    modelType: {
      type: String as PropType<CoffeeProductionModelType>,
      required: true,
    },
    solutionSummary: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    isOwner(): boolean {
      return this.solutionSummary.userId === this.user.id
    },
    isSaraSuperuser(): boolean {
      return this.user.saraUserRole === UserRole.SUPERUSER
    },
  },
  methods: {
    ...mapMutations([""]),
    async togglePublicVisible() {
      await this.bi.togglePublicVisible(this.modelType, this.solutionSummary.id)
      // this.$nextTick(() => {
      // this.$emit("metadata-update", this.solutions)
      setTimeout(() => {
        this.solutionSummary.publicVisible = !this.solutionSummary.publicVisible
      }, 200)
      // })
    },
    async downloadEndOfWeekCellplan(){
      setTimeout(() => {
        this.bi.downloadEndOfWeekCellplan(this.solutionSummary.id)
      }, 200)
      setTimeout(() => {
        this.bi.downloadEmptyFulfilledDemands(this.solutionSummary.id)
      }, 400)
    },
    async downloadSaraSolution(){
      const bi = this.backendInterface as BackendInterface;
      return await bi.sara_DownloadSolution(this.solutionSummary.id)
    },
  },
});
