
import Vue, {PropType} from "vue";
import { mapMutations, mapState } from "vuex";
import PageActionBar from "@/components/common/PageActionBar.vue";
import { BackendInterface } from "@/scripts/BackendInterface";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import { WEEKDAY_TRANSLATIONS } from "@/scripts/utils";
import DatePicker from "@/components/common/DatePicker.vue";
import {CoffeeProductionModelSolutionStatus, SummaryMetadata} from "@/scripts/models/results";
import SolutionList from "@/components/cellplan/SolutionList.vue";
// import { UserType } from "@/scripts/models/general";
import { CoffeeProductionModelType } from "@/scripts/models/request";
import {UserInfo, UserRole} from "@/scripts/auth";

export default Vue.extend({
  name: "LoadSolutionView",
  props: {
    modelType: { type: String as PropType<CoffeeProductionModelType>, default: CoffeeProductionModelType.SARA },
  },
  components: { PageActionBar, DatePicker, SolutionList },
  data() {
    return {
      WEEKDAY_TRANSLATIONS,
      CoffeeProductionModelSolutionStatus: CoffeeProductionModelSolutionStatus,
      CoffeeProductionModelType,
      tab: 0,
      selectedDate: "" as string,
      initialDate: "" as string,
      availableSolutionsForDate: [] as any[],
      mostRecentSolutions: [] as CoffeeProductionSARASummary[],
      metadataMap: {} as Record<string, SummaryMetadata>,
      loading: false,
    };
  },
  created() {
    this.init();
    // console.log("this.$route.query", this.$route.query);

    // console.log(this.selectedDate);
    // console.log(WEEKDAY_TRANSLATIONS);
  },
  watch: {
    modelType: "init",
    async selectedDate() {
      this.tab = 0;
      // this.openSolutionPanelIndex = null;
      await this.loadSolutionsForSelectedDate();
      // await this.loadMostRecentSolutions();
      // console.log("Solutions Loaded!", this.availableSolutionsForDate);
    },
    async userType() {
      // TODO: this is a superuser functionality. fix later.
      // await this.loadSolutionsForSelectedDate();
      // await this.loadMostRecentSolutions();
    },
  },
  computed: {
    ...mapState(["superuser", "user", "translations", "backendInterface", "productionSiteUniqueName"]),
    datePickerTitle() {
      if (this.modelType === CoffeeProductionModelType.SARA) {
        return "Starttag Zellenplan";
      } else if (this.modelType === CoffeeProductionModelType.PAPA) {
        return "Starttag Produktionsplan";
      } else if (this.modelType === CoffeeProductionModelType.DORI) {
        return "Starttag Tagesplan";
      } else {
        return "";
      }
    },
    selectionTitle() {
      if (this.modelType === CoffeeProductionModelType.SARA) {
        return "Verfügbare Zellenpläne";
      } else if (this.modelType === CoffeeProductionModelType.PAPA) {
        return "Verfügbare Produktionspläne";
      } else if (this.modelType === CoffeeProductionModelType.DORI) {
        return "Verfügbare Tagespläne";
      } else {
        return "";
      }
    },
    title() {
      if (this.modelType === CoffeeProductionModelType.SARA) {
        return "Zellenpläne";
      } else if (this.modelType === CoffeeProductionModelType.PAPA) {
        return "Produktionspläne";
      } else if (this.modelType === CoffeeProductionModelType.DORI) {
        return "Tagespläne";
      } else {
        return "";
      }
    },
    breadcrumbs(): any[] {
      if (this.modelType === CoffeeProductionModelType.SARA) {
        return [
          { text: "SARA", disabled: true, to: "" },
          { text: "Zellenplan Laden", disabled: true },
        ];
      } else if (this.modelType === CoffeeProductionModelType.PAPA) {
        return [
          { text: "PAPA", disabled: true, to: "" },
          { text: "Produktionsplan Laden", disabled: true },
        ];
      } else if (this.modelType === CoffeeProductionModelType.DORI) {
        return [
          { text: "DORI", disabled: true, to: "" },
          { text: "Tagesplan Laden", disabled: true },
        ];
      } else {
        return [];
      }
    },
    placeholderText() {
      if (!this.selectedDate) {
        return "Wählen Sie einen Berechnungstag";
      }
      if (this.loading) {
        return "Pläne werden geladen ...";
      }
      if (this.availableSolutionsForDate.length == 0) {
        return "Keine Pläne vorhanden";
      }
      return "";
    },
    solutionFunctions() {
      // const bi = this.backendInterface as BackendInterface;
      if (this.modelType === CoffeeProductionModelType.SARA) {
        return {
          listSolutionsForDate: "sara_ListSolutions",
          listMostRecentSolutions: "sara_ListMostRecentSolutions",
          getUsedSolveRequest: "sara_GetUsedSolveRequest",
          deleteSolutionById: "sara_DeleteSolutionById",
          // listSolutionMetadata: "listSolutionMetadata",
        };
      } else if (this.modelType === CoffeeProductionModelType.PAPA) {
        return {
          listSolutionsForDate: "papa_ListSolutions",
          listMostRecentSolutions: "papa_ListMostRecentSolutions",
          getUsedSolveRequest: "papa_GetUsedSolveRequest",
          deleteSolutionById: "papa_DeleteSolutionById",
          // listSolutionMetadata: "listSolutionMetadata",
        };
      } else if (this.modelType === CoffeeProductionModelType.DORI) {
        return {
          listSolutionsForDate: "dori_ListSolutions",
          listMostRecentSolutions: "dori_ListMostRecentSolutions",
          getUsedSolveRequest: "dori_GetUsedSolveRequest",
          deleteSolutionById: "dori_DeleteSolutionById",
          // listSolutionMetadata: "listSolutionMetadata",
        };
      } else {
        throw new Error(`Model type DORI not yet implemented.`);
      }
    },
  },
  methods: {
    ...mapMutations([""]),
    init() {
      this.loadSolutionsForSelectedDate();
      this.loadMostRecentSolutions();
      this.initialDate = (this.$route.query?.date as string) ?? new Date().toISOString().slice(0, 10);
    },
    async loadSolutionsForSelectedDate() {
      // console.time("load solutions");
      this.availableSolutionsForDate = [];
      this.loading = true;
      if (this.selectedDate === "") return;
      const bi = this.backendInterface;
      const fns = this.solutionFunctions;
      // const userType = this.userType === UserType.USER ? UserType.ADMIN : this.userType;
      let availableSolutionsForDate: any[] = await bi[fns.listSolutionsForDate]({
        // productionSiteUniqueName: this.productionSiteUniqueName,
        timeRangeStart: this.selectedDate,
        timeRangeEnd: this.selectedDate,
      });
      // availableSolutionsForDate = availableSolutionsForDate.map((s) => new CoffeeProductionSARASummary(s));
      availableSolutionsForDate.sort((a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime());
      await this.loadSummaryMetadataMap(availableSolutionsForDate)
      this.availableSolutionsForDate = availableSolutionsForDate;
      this.loading = false;
      console.log("availableSolutionsForDate", availableSolutionsForDate);
    },
    async loadMostRecentSolutions() {
      // console.time("load solutions");
      this.mostRecentSolutions = [];
      this.loading = true;
      const bi = this.backendInterface;
      const fns = this.solutionFunctions;
      // const userType = this.userType === UserType.USER ? UserType.ADMIN : this.userType;
      let mostRecentSolutions: any[] = await bi[fns.listMostRecentSolutions]({
        // productionSiteUniqueName: this.productionSiteUniqueName,
        modelType: this.modelType,
        maximumSolutionCount: 20,
      });
      // console.log("mostRecentSolutions", mostRecentSolutions);
      // if(this.modelType === CoffeeProductionModelType.SARA){
      //   mostRecentSolutions = mostRecentSolutions.map((s) => new CoffeeProductionSARASummary(s));
      // }
      // console.log("mostRecentSolutions", mostRecentSolutions.map(s => s.creationTime));
      mostRecentSolutions = mostRecentSolutions.sort((a, b) => {
        const timeB = new Date(b.creationTime).getTime()
        const timeA = new Date(a.creationTime).getTime()
        // console.log(timeB - timeA);
        return timeB - timeA
        
      });
      // console.log("mostRecentSolutions", mostRecentSolutions.map(s => s.creationTime));
      await this.loadSummaryMetadataMap(mostRecentSolutions)
      this.mostRecentSolutions = mostRecentSolutions;
      this.loading = false;
      console.log("mostRecentSolutions", mostRecentSolutions);
    },
    async loadSummaryMetadataMap(solutions: any[]): Promise<void>{
      const bi = this.backendInterface as BackendInterface
      const metadata = await bi.listSummaryMetadata({
        summaryIds: solutions.map(s => s.id).filter(s => !!s),
        modelType: this.modelType!,
      })
      for(const m of metadata) this.metadataMap[m.summaryId] = m
    }
  },
});
