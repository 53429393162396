
import { CoffeeBasket } from "@/scripts/models/coffee";
import { anyNull, id, roasterSorter } from "@/scripts/utils";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import FileUpload from "@/components/common/FileUpload.vue";
import { CoffeeArticlePriorityItem } from "@/scripts/models/gas";
import { CoffeeRoasterGasConsumption } from "@/scripts/models/papa";
// @ts-ignore
// import testBaskets from "@/data/articlePriorities.json";
// import { SolveRequest } from "@/scripts/models/request";
// import data from "@/data/solution_bn5_sn37_d6.json";

export default Vue.extend({
  name: "PapaGasConsumptionSettings",
  props: {
    value: { type: Array, required: true }, // CoffeeRoasterGasConsumption[]
    // articles: { type: Array, required: true },
    autosave: { type: Boolean, required: false, default: false },
    readonly: { type: Boolean, required: false, default: false },
  },

  components: {},
  data: function() {
    return {
      gasConsumptions: [] as CoffeeRoasterGasConsumption[],
      search: "",
    };
  },
  created() {
    // this.init();
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value !== null) {
          this.gasConsumptions = JSON.parse(JSON.stringify(this.value)) as CoffeeRoasterGasConsumption[];
        } else {
          this.gasConsumptions = [];
        }
      },
    },
  },
  computed: {
    ...mapState(["backendUrl", "superuser", "availableSolveRequest", "selectedSolveRequest", "availableBackends"]),
    tableItems() {
      const items: Record<string, any> = {};
      const roasterNames = this.roasterNames;
      const initItem = () => Object.fromEntries(roasterNames.map((rn) => [rn, null]));
      for (const gc of this.gasConsumptions) {
        if (!(gc.basketSpsNr in items)) items[gc.basketSpsNr] = initItem();
        items[gc.basketSpsNr][gc.roasterName] = gc;
      }
      return items;
    },
    roasterNames() {
      const headerMap: Record<string, null> = {};
      for (const gc of this.gasConsumptions) {
        headerMap[gc.roasterName] = null;
      }
      const headers = Object.keys(headerMap);
      headers.sort(roasterSorter(id))
      return headers;
    },
  },
  methods: {
    ...mapMutations(["selectWeekData", "selectSolveRequest", "selectBackend"]),
    save() {
      this.$emit("input", this.gasConsumptions);
    },
    inputHandler() {
      if (this.autosave) this.save();
    },
    addGasConsumption(basketSpsNr: number | string, roasterName: string) {
      const newGasConsumption = {
        roasterName: roasterName,
        roasterCategory: roasterName.split(" ")[0],
        basketSpsNr: +basketSpsNr,
        gasConsumption_m3: 0,
        testBasket: false,
      };
      this.gasConsumptions.push(newGasConsumption)
      this.inputHandler()
    },
  },
});
