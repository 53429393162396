
import Vue from "vue";
import {mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";

// import { upload } from "../scripts/file-upload-service";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;
export default Vue.extend({
  name: "app",
  props: {
    uploadUrl: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    parseToJson: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: STATUS_INITIAL,
      uploadFieldName: "file",
      // parsedDemands: [],
      uploadFilename: "",
      formData: null,
      uploadUrl_: "",
    };
  },
  mounted() {
    this.reset();
  },
  computed: {
    ...mapState(["backendInterface"]),
    isInitial(): boolean {
      return this.currentStatus === STATUS_INITIAL;
    },
    uploadIsSaving(): boolean {
      return this.currentStatus === STATUS_SAVING;
    },
    uploadSuccess(): boolean {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed(): boolean {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    reset(): void {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      if (!this.uploadUrl) {
        // this.uploadUrl_ = "http://localhost:5000/upload";
      } else {
        this.uploadUrl_ = this.uploadUrl;
      }
    },
    uploadFiles(event: any) {
      // upload data to the server
      // parse form data
      console.log("UPLOAD PARSE", event.target.result);
      const fieldName = event.target.name;
      const fileList = event.target.files;
      // handle file changes
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });

      // start upload
      this.currentStatus = STATUS_SAVING;
      for (const [key, val] of formData) {
        // @ts-ignore
        this.uploadFilename = val.name;
      }
      const fetchOptions = {
        method: "post",
        mode: "cors",
        body: formData,
      } as RequestInit;
      const bi = this.backendInterface as BackendInterface
      bi.setAuthTokenInFetchOptions(fetchOptions)
      return fetch(this.uploadUrl_, fetchOptions)
        .then((res) => res.text())
        .then((text) => {
          const json = JSON.parse(text);
          if (json.error != null && json.error != undefined) {
            throw new Error("Server response contains an error.");
          }

          // if(json)
          // this.parsedDemands = json;
          // @ts-ignore
          this.uploadedFiles = [].concat(json);
          this.currentStatus = STATUS_SUCCESS;
          this.$emit("upload-success", this.uploadedFiles);
        })
        .catch((err) => {
          console.error(err);
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
          this.$emit("upload-fail");
        });
    },
    parseFile(event: any): void {
      if (event === null || event.target == null) {
        this.$emit("parse-fail", null);
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        // console.log("FILE PARSE", event.target.result);
        // @ts-ignore
        let response = event.target.result;
        // log({ parseToJson: this.parseToJson });
        if (this.parseToJson) {
          try {
            // @ts-ignore
            response = JSON.parse(response);
            this.currentStatus = STATUS_SUCCESS;
            this.$emit("parse-success", response);
          } catch (error) {
            response = null;
            this.currentStatus = STATUS_FAILED;
            this.$emit("parse-fail", response);
          }
        }
      };
      reader.readAsText(event.target.files[0]);
    },
    filesChange(event: any): void {
      if (!this.uploadUrl) {
        this.parseFile(event);
      } else {
        this.uploadFiles(event);
      }
    },
  },
});
