
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import UserSettingsManager from "@/components/settings/UserSettingsManager.vue";
import {UserInfo, UserRole} from "@/scripts/auth";
import {getEnumKeys} from "@/scripts/utils";
import UserManager from "@/views/UserManager.vue";
import SolveProcessManager from "@/views/SolveProcessManager.vue";

export default Vue.extend({
  name: "SettingsView",
  components: {SolveProcessManager, UserManager, UserSettingsManager},
  props: {},
  data() {
    return {
      title: "Einstellungen",
      modelType: CoffeeProductionModelType.SARA,
      loading: false,
      tab: null,
      // userInfos: [] as UserInfo[]
    };
  },
  async created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "userType", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    breadcrumbs(): any[] {
      return [
        // {text: this.modelType, disabled: true, to: ""},
        {text: "Einstellungen", disabled: true},
      ]
    },
    // userRoles(): string[] {
    //   return getEnumKeys(UserRole)
    // }
  },
  methods: {
    ...mapMutations([""]),
  },
});
