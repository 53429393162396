
import helpTextDataJson from "@/data/helpText.json";

const helpTextData = helpTextDataJson as Record<string, HelperTextLayout>
import Vue, {PropType} from "vue";

export interface HelperTextLayout {
  header: string,
  body: string,
  footer: string,
}

export default Vue.extend({
  name: 'HelpDialog',
  props: {
    value: {type: Boolean as PropType<boolean>, default: false},
    helpTextKey: {type: String as PropType<string>, default: "", required: false},

  },
  computed: {
    getHelpText(): HelperTextLayout | null {
      if (this.helpTextKey in helpTextData) {
        return helpTextData[this.helpTextKey]
      }
      return null
    },
  },
  data() {
    return {
      helpTextData: helpTextData,
      showDialog: false,

    }
  },
  watch: {
    value() {
      this.showDialog = this.value
    },
    showDialog() {
      this.$emit('input', this.showDialog)
    }
  }
})
