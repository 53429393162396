
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {
  CoffeeProductionModelType,
  DeleteSettingsPartRequest
} from "@/scripts/models/request";
// import {UserType} from "@/scripts/models/general";
import {UserSettingsInfo} from "@/scripts/models/results";

export default Vue.extend({
  name: "ResetSettingsDialog",
  components: {},
  props: {
    modelType: {type: String as PropType<CoffeeProductionModelType>, required: true},
    activatorText: {type: String, required: false, default: "zurücksetzen"},
    userSettingsId: {type: String, required: false, default: null}
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      settingTypes: [] as string[],
      settingTypesToDeleteMap: {} as Record<string, boolean>,
      deleteAll: false,
      userSettingsId_: null as string | null,
      writeableSettings: [] as UserSettingsInfo[],
      translations: {
        // DORI
        ARTICLE_PRIORITY: "Artikelprioritäten",
        GAS_CONSUMPTION: "Gasverbräuche",
        ROASTER_AVAILABILITY: "Rösterverfügbarkeiten",
        PACKAGING_LINE_AVAILABILITY: "Linienverfügbarkeiten",
        OBJECTIVE_SETTINGS: "Solver",
        // SARA
        ROASTER_AVAILABILITIES: "Rösterverfügbarkeiten",
        WEEKDAY_PARAMETERS: "WEEKDAY_PARAMETERS",
        BASKET_FLOW_MULTIPLIERS: "Basket-Durchflussfaktoren",
        SILO_FLOW_MULTIPLIERS: "Silo-Durchflussfaktoren",
        NEXT_WEEK_PREROASTING: "Vorröstung",
      } as Record<string, string>,
    };
  },
  created() {
    this.init()
  },
  mounted() {
    this.loading = false
  },
  watch: {
    modelType: "init",
    userSettingsId: {
      immediate: true,
      handler: function (val) {
        this.userSettingsId_ = val
      }
    }
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    settingTypesToDelete(): string[] {
      if (this.deleteAll) {
        return Object.keys(this.settingTypesToDeleteMap)
      } else {
        return Object.entries(this.settingTypesToDeleteMap).filter(s => s[1]).map(s => s[0])
      }
    },
    canDeleteSettings(): boolean {
      return this.settingTypesToDelete.length === 0 && !this.deleteAll || this.userSettingsId_ === null
    },
  },
  methods: {
    ...mapMutations([""]),
    async init() {
      const bi = this.backendInterface as BackendInterface
      this.settingTypes = await bi.getSettingPartTypes(this.modelType)
      this.settingTypesToDeleteMap = Object.fromEntries(this.settingTypes.map(s => [s, false]))
      await this.loadWriteableSettings()
    },

    async loadWriteableSettings(): Promise<void> {
      // start
      this.loading = true
      const bi = this.backendInterface as BackendInterface
      let writeableSettings = await bi.getWriteableUserSettingInfos(this.modelType)
      // let selection = [];
      // for(let info of writeableSettings){
      //   selection.push({text: info.name, value: info.id});
      // }
      // end
      this.showDialog = false
      this.loading = false
      this.writeableSettings = writeableSettings
      // return selection;
    },

    async deleteSettings() {
      // start
      this.loading = true
      const bi = this.backendInterface as BackendInterface
      if (this.userSettingsId_ === null) {
        throw new Error("No settings selected")
      }
      // all
      // if (this.deleteAll) {
      //   if (this.modelType === CoffeeProductionModelType.SARA) {
      //     await bi.sara_DeleteUserSettings(this.userSettingsId_)
      //   } else if (this.modelType === CoffeeProductionModelType.PAPA) {
      //     throw new Error("Unsupported model type: " + this.modelType)
      //     // await bi.papa_DeleteAllSettings()
      //   } else if (this.modelType === CoffeeProductionModelType.DORI) {
      //     throw new Error("Unsupported model type: " + this.modelType)
      //     // await bi.dori_DeleteAllSettings()
      //   } else {
      //     throw new Error("Unsupported model type: " + this.modelType)
      //   }
      // }
      // // parts
      // else {

      const request: DeleteSettingsPartRequest = {
        modelType: this.modelType,
        userSettingsId: this.userSettingsId_,
        deleteTypes: this.settingTypesToDelete
      }
      console.log("Deleting: " + request.deleteTypes.join(", "))
      await bi.deleteUserSettingsParts(this.modelType, request)
      // }

      // end
      this.showDialog = false
      this.loading = false
    },
    getDisplayName(name: string) {
      return this.translations[name] ?? name;
    },
  },
});
