import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import CalculationStepper from '@/views/CalculationStepper.vue'
import LoadSolutionView from '@/views/LoadSolutionView.vue'
import SaraSummaryView from '@/views/sara/SaraSummaryView.vue'
import GasConsumptionView from '@/views/papa/GasConsumptionView.vue'
import PapaSummaryView from '@/views/papa/PapaSummaryView.vue'
import DoriSummaryView from '@/views/papa/DoriSummaryView.vue'
import Support from '@/views/Support.vue'
import Changelog from '@/views/Changelog.vue'
import {CoffeeProductionModelType} from '@/scripts/models/request'
import {} from "@/scripts/auth";
import LoginView from "@/views/LoginView.vue";
import VerifyUserEmail from "@/components/auth/VerifyUserEmail.vue";
import ActivateUser from "@/components/auth/ActivateUser.vue";
import SignUp from "@/components/auth/SignUp.vue";
import ResetPassword from "@/components/auth/ResetPassword.vue";
import {BackendInterface} from "@/scripts/BackendInterface";
import store from "@/store";
import SettingsView from "@/views/SettingsView.vue";
import ProfileView from "@/views/ProfileView.vue";
import Sandbox from "@/views/Sandbox.vue";
import SolveProcessManager from "@/views/SolveProcessManager.vue";


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  //
  // REDIRECTS
  //
  {
    path: '/plan/:id',
    redirect: '/sara/plan/view/:id',
  },
  {
    path: '/',
    redirect: '/sara/plan/selection',
  },
  {
    path: '/plan/berechnung',
    redirect: '/sara/calculation',
  },
  //
  // SARA
  //
  {
    path: '/sara/plan/view/:id',
    name: 'SARA Plan',
    component: SaraSummaryView
  },
  {
    path: '/sara/plan/calculation',
    name: 'SARA Berechnung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.SARA},

  },
  {
    path: '/sara/plan/recompute/:id',
    name: 'SARA Neuberechnung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.SARA, recompute: true},
  },
  {
    path: '/sara/plan/calculation/:solveProcessId',
    name: 'SARA Berechnung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.SARA, loadActiveComputation: true},
  },
  {
    path: '/sara/plan/resolve/:id',
    name: 'SARA Fehlerbehebung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.SARA, resolve: true},
  },
  {
    path: '/sara/plan/selection',
    name: 'SARA Plan Auswahl',
    component: LoadSolutionView,
    props: {modelType: CoffeeProductionModelType.SARA},
  },
  {
    path: '/sara/plan/calculation/:solveProcessId',
    name: 'SARA Berechnung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.SARA, loadActiveComputation: true},
  },
  {
    path: '/sara/plan/improve/:resultSummaryId',
    name: 'SARA Plan Verbessern',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.SARA, improvePlan: true},
  },
  //
  // PAPA
  //
  {
    path: '/papa/plan/calculation',
    name: 'PAPA Berechnung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.PAPA},
  },
  {
    path: '/papa/plan/selection',
    name: 'PAPA Plan Auswahl',
    component: LoadSolutionView,
    props: {modelType: CoffeeProductionModelType.PAPA},
  },
  {
    path: '/papa/plan/view/:id',
    name: 'PAPA Plan',
    component: PapaSummaryView
  },
  // {
  //   path: '/papa',
  //   name: 'PAPA',
  //   component: PapaSummaryView
  // },

  //
  // DORI
  //
  // {
  //   path: '/dori/demo',
  //   name: 'DORI Plan',
  //   component: DoriSummaryView
  // },
  {
    path: '/dori/plan/selection',
    name: 'DORI Plan Auswahl',
    component: LoadSolutionView,
    props: {modelType: CoffeeProductionModelType.DORI},
    meta: {}
  },
  {
    path: '/dori/plan/view/:id',
    name: 'DORI Plan',
    component: DoriSummaryView
  },
  {
    path: '/dori/plan/calculation',
    name: 'DORI Berechnung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.DORI},
  },
  {
    path: '/dori/plan/recompute/:id',
    name: 'DORI Neuberechnung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.DORI, recompute: true},
  },
  {
    path: '/dori/plan/resolve/:id',
    name: 'DORI Fehlerbehebung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.DORI, resolve: true},
  },
  {
    path: '/dori/plan/calculation/:solveProcessId',
    name: 'DORI Berechnung',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.DORI, loadActiveComputation: true},
  },
  {
    path: '/dori/plan/improve/:resultSummaryId',
    name: 'DORI Plan Verbessern',
    component: CalculationStepper,
    props: {modelType: CoffeeProductionModelType.DORI, improvePlan: true},
  },
  //
  // GAS
  //
  {
    path: '/gas',
    name: 'Gasverbrauch Analysieren',
    component: GasConsumptionView
  },
  // 
  // OTHER
  //
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/running-calculations',
    name: 'Laufende Berechnungen',
    component: SolveProcessManager
  },

  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog
  },

  {
    path: '/settings',
    name: 'Settings Manager',
    component: SettingsView
  },

  {
    path: '/sandbox',
    name: 'Sandbox',
    component: Sandbox
  },

  //
  // AUTH
  //

  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      requiresAuth: false,
      hasSidebar: false,
    }
  },

  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      requiresAuth: false,
      hasSidebar: false,
    }
  },

  {
    path: '/user/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
      hasSidebar: false,
    }
  },

  {
    path: '/user/reset-password/:passwordResetToken',
    name: 'Reset Password With Token',
    component: ResetPassword,
    props: route => ({passwordResetToken: route.params.passwordResetToken ?? undefined}),
    meta: {
      requiresAuth: false,
      hasSidebar: false,
    }
  },

  {
    path: '/user/verify-email/:userActivationToken',
    name: 'Verify User Email',
    component: VerifyUserEmail,
    props: route => ({userActivationToken: route.params.userActivationToken}),
    meta: {
      requiresAuth: false,
      hasSidebar: false,
    },
  },
  
  {
    path: '/user/activate/:userActivationToken',
    name: 'Activate User',
    component: ActivateUser,
    props: route => ({userActivationToken: route.params.userActivationToken}),
    meta: {
      requiresAuth: true,
      hasSidebar: false,
    },
  },
  {
    path: '/user/profile',
    name: 'User Profile',
    component: ProfileView,
    meta: {
      requiresAuth: true,
      hasSidebar: true,
    }
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// console.log("ROUTER")
router.beforeResolve(async (to, from, next) => {
  // console.log("ROUTER before")
  const bi = store.state.backendInterface as BackendInterface;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth ?? true)
  // console.log("path requires auth", requiresAuth)
  // console.log("Logged in", isLoggedIn())
  if (requiresAuth) {
    await bi.refreshAuthTokenIfExpired(false)
    if (bi.isLoggedIn()) {
      store.state.user = await bi.getCurrentUserInfo();
      next()
    } else {
      next({path: '/login', query: {redirect: to.fullPath}});
    }
  } else {
    next()
  }
})

export default router
