
import Vue, {PropType} from "vue";
import { mapMutations, mapState } from "vuex";
import { CoffeeRoasterEnergyConsumption } from "@/scripts/models/results";

export default Vue.extend({
  name: "GasConsumptionRange",
  components: {},
  props: {
    name: { type: String as PropType<string> }, // "Roast and Ground"
    multiplicationFactor: { type: Number as PropType<number>, default: 1 }, // "Roast and Ground"
    unit: { type: String as PropType<string>, default: "" }, // "Roast and Ground"
    energyConsumption: { type: Object as PropType<CoffeeRoasterEnergyConsumption> }, // CoffeeRoasterEnergyConsumption
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    ec() {
      if (this.energyConsumption !== null) {
        return this.energyConsumption as CoffeeRoasterEnergyConsumption;
      } else {
        return {
          energy: null,
          energyLower: null,
          energyUpper: null,
          producedWeight_ton: null,
          energyUnitName: "kWh",
        };
      }
    },
  },
  methods: {
    ...mapMutations([""]),
    format(x: number) {
      return new Intl.NumberFormat("de-DE").format(Math.round(x));
    },
  },
});
