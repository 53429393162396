
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import GenericSettings from "@/components/settings/GenericSettings.vue";
// @ts-ignore
import testBaskets from "@/data/baskets.json";
import {BackendInterface} from "@/scripts/BackendInterface";
import {NameValueTuple} from "@/scripts/models/general";
import {CoffeeProductionSolverParameters} from "@/scripts/models/calculation";
import { CoffeeProductionModelType } from "@/scripts/models/request";
// import data from "@/data/solution_bn5_sn37_d6.json";

export default Vue.extend({
  name: "OptimizationSettings",
  props: {
    solveRequest: {
      type: Object, // SolverParameters
      required: false,
    },
    modelType: {type: String, default: CoffeeProductionModelType.SARA}
  },
  components: {
    GenericSettings
  },
  data() {
    return {
      CoffeeProductionModelType,
      tab: null,
      objectiveTypes: [] as NameValueTuple[],
      solutionStrategies: [] as NameValueTuple[],
    };
  },
  async created() {
    const bi = this.backendInterface as BackendInterface
    this.objectiveTypes = await bi.sara_ListObjectives()
    this.solutionStrategies = await bi.sara_ListSolutionStrategies()
  },
  watch: {

  },
  computed: {
    ...mapState(["backendUrl", "backendInterface", "superuser", "availableSolveRequest", "selectedSolveRequest", "availableBackends"]),
    settings(): any[] {
      return [
        // Solver Settings
        //
        {
          inputType: "heading",
          label: "Solver Settings",
          cols: "12",
        },

        // solution strategies
        {
          inputType: "select",
          items: this.solutionStrategies,
          label: "Solution Strategy",
          model: ["defaultSolutionStrategy"],
          type: "",
          cols: "12",
          itemText: "name",
          itemValue: "value",
        },
        // objective function
        {
          inputType: "select",
          items: this.objectiveTypes,
          label: "Objective Function",
          model: ["defaultObjectiveType"],
          type: "",
          cols: "12",
          itemText: "value",
          itemValue: "value",
        },

        //
        // Input Data Settings
        //
        {
          inputType: "heading",
          label: "Solver Parameters",
          cols: "12",
        },
      ]
    },
    solverParameterSettings(): any[] {
      const hide = ["solverType"];
      const readonly = ["objectiveType"];
      let params = this.solveRequest.solverParameters as CoffeeProductionSolverParameters[];

      if (!params) {
        return [];
      }
      if(!Array.isArray(params)){
        console.log("params is not array!", params)
        params = [params]
      } else {
        console.log("params is array!", params)
      }
      return (
        params.map((s, i) => {
          const entries = Object.entries(s);
          const f = (val: string) => ([key, value]: [string, any]) => typeof value === val;
          const strings = entries.filter(f("string"));
          const numbers = entries.filter(f("number"));
          const booleans = entries.filter(f("boolean"));
          const transformer = ([key, value]: [string, any]) => {
            if (hide.includes(key)) return null;
            const isBoolean = typeof value === "boolean";
            const isString = typeof value === "string";
            const isNumber = typeof value === "number" || value === "NaN";
            const res = {
              inputType: isBoolean ? "checkbox" : "text",
              items: [],
              // label: camelCaseToWords(key),
              label: key,
              model: ["solverParameters", `${i}`, key],
              type: isString ? "text" : isNumber ? "number" : "",
              cols: isBoolean ? "6" : "4",
              // readonly: ,
            };
            if(this.modelType === CoffeeProductionModelType.SARA){
              res.model = ["solverParameters", `${i}`, key]
            }
            else if(this.modelType === CoffeeProductionModelType.PAPA){
              res.model = ["solverParameters", key]
            } else if(this.modelType === CoffeeProductionModelType.DORI){
              res.model = ["solverParameters", key]
            } else {
              throw new Error("Model type not yet implemented! Model: " + this.modelType);
              
            }
            return res;
          };
          return [...strings, ...numbers, ...booleans].map(transformer).filter((d) => !!d);
        }) ?? []
      );
    },
  },
  methods: {
    ...mapMutations(["selectWeekData", "selectSolveRequest", "selectBackend"]),
    async updateSolveParameters() {
      const {defaultSolutionStrategy, defaultObjectiveType} = this.solveRequest
      if (!defaultSolutionStrategy || !defaultObjectiveType) {
        return;
      }
      // console.log("solveRequest", this.solveRequest);
      
      const bi = this.backendInterface as BackendInterface;
      this.solveRequest.solverParameters = await bi.sara_GetSolverParameters(defaultSolutionStrategy, defaultObjectiveType);
    },
  },
});
