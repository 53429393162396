
import Vue from "vue";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
// @ts-ignore
import {mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import SaraSummary from "@/components/cellplan/SaraSummary.vue";
// @ts-ignore
import solution_roaster from "@/data/solution_roaster.json";
import {SOLUTION_QUALITY_COLORS, SOLUTION_QUALITY_TRANSLATIONS, SolutionQuality} from "@/scripts/models/results";
import {CoffeeProductionModelType, InstantRequest} from "@/scripts/models/request";
// import { UserType } from "@/scripts/models/general";
import SaveSettingsDialog from "@/components/settings/SaveSettingsDialog.vue";
import {formatDateShort} from "@/scripts/utils";
import SummaryActions from "@/components/settings/SummaryActions.vue";

export default Vue.extend({
  name: "SaraSummaryView",
  props: ["data"],
  components: {
    SummaryActions,
    SaveSettingsDialog,
    SaraSummary,
  },
  data() {
    return {
      // UserType,
      loading: true,
      saraSummary: null as CoffeeProductionSARASummary | null,
      cellplanId: null as null | string,
      // TEMP
      solution_roaster,
      breadcrumbs: [
        {text: "SARA", disabled: true, to: ""},
        {text: "Zellenplan Ansehen", disabled: true},
      ],
      showSnackbar: false,
      snackbarText: "",
      userSettings: null as any,
    };
  },
  created() {
    // this.data = data;
  },
  mounted() {
    this.cellplanId = this.$route.params.id;
  },
  watch: {
    data() {
    },
    async cellplanId(v) {
      if (v === null) return;
      this.loading = true;
      const bi = this.backendInterface as BackendInterface;
      const saraSummary = await bi.sara_GetSolutionById(v);
      saraSummary.solveRequest = await bi.sara_GetUsedSolveRequest(saraSummary.id);
      // console.log("loaded sara summary solve request", saraSummary.solveRequest);
      // console.log("loaded sara summary solve request settings id", saraSummary.solveRequest.userSettingsId);
      const userSettingsId = saraSummary.solveRequest.userSettingsId;
      if (userSettingsId !== null) {
        this.userSettings = await bi.getUserSettings(CoffeeProductionModelType.SARA, userSettingsId);
      }
      // this.userSettings = await this.backendInterface.getUserSettings(CoffeeProductionModelType.SARA, saraSummary.solveRequest!.userSettingsId!)
      // console.log("loaded user settings", this.userSettings)
      this.saraSummary = new CoffeeProductionSARASummary(saraSummary);
      console.log(this.saraSummary);
      this.loading = false;
    },
  },
  computed: {
    CoffeeProductionModelType() {
      return CoffeeProductionModelType
    },
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    title(): string {
      return this.saraSummary?.getTitle() ?? "";
    },
    solutionQuality(): string {
      return this.saraSummary?.solutionQuality ?? "";
    },
    creationTime(): string {
      return formatDateShort(this.saraSummary?.creationTime!);
    },
    pageInfo(): Record<string, string> {
      if (!this.saraSummary) return {};
      const pageInfo: Record<string, string> = {
        "Berechnet am": this.creationTime,
        Planzeitraum: `${this.saraSummary?.getStartDateString()} - ${this.saraSummary?.getEndDateString()}`,
        // "Einstellungen": `${this.userSettings?.name} (${this.userSettings?.owner?.firstName} ${this.userSettings?.owner?.familyName})`,
      };
      if (!!this.userSettings) {
        pageInfo["Einstellungen"] = `${this.userSettings?.name} (${this.userSettings?.owner?.firstName} ${this.userSettings?.owner?.familyName})`
      }
      // private/public
      if (!this.saraSummary!.publicVisible) {
        pageInfo["Verfügbarkeit"] =
            `<span class="solution-quality-chip" style="color: orange">privat</span>`;
      } else {
        pageInfo["Verfügbarkeit"] =
            `<span class="solution-quality-chip" @click="togglePublicVisible" style="color: limegreen">öffentlich</span>`;
      }
      if (this.solutionQuality) {
        const solutionQualityColor = SOLUTION_QUALITY_COLORS[this.solutionQuality as SolutionQuality];
        const solutionQualityTranslation = SOLUTION_QUALITY_TRANSLATIONS[this.solutionQuality as SolutionQuality];
        pageInfo[
            "Lösungsqualität"
            ] = `<span class="solution-quality-chip" style="color: ${solutionQualityColor}">${solutionQualityTranslation}</span>`;
      }
      const parentSummaryId = this.saraSummary?.parentSummaryId
      if (!!parentSummaryId) {
        pageInfo["Ursprungs-Plan"] = `<a target="_blank" href="/sara/plan/view/${parentSummaryId}">${parentSummaryId}</a>`
      }
      return pageInfo;
    },
  },
  methods: {
    togglePublicVisible() {
      // this.saraSummary!.publicVisible = !this.saraSummary!.publicVisible
      // this.backendInterface.sara_UpdateSolution(this.saraSummary!)
      if (this.saraSummary !== null) {
        this.backendInterface.togglePublicVisible(CoffeeProductionModelType.SARA, this.saraSummary.id)
      }
    },
    async restart() {
      let source = this.$route.query.source;
      if (source !== "calculation" && source !== "selection") source = "calculation";
      //console.log("timeRangeStart", this.saraSummary.timeRangeStart);
      let date = await this.backendInterface.convertInstantToDateTime({
        productionSiteUniqueName: this.productionSiteUniqueName,
        instant: this.saraSummary?.timeRangeStart,
      } as InstantRequest);
      //console.log("timeRangeStart2", date);
      date = date.slice(0, 10);
      await this.$router.push({path: "/sara/plan/" + source, query: {date: date}});
    },
    async recompute() {
      await this.$router.push({path: "/sara/plan/recompute/" + this.saraSummary?.id});
    },
    async improvePlan() {
      await this.$router.push({path: "/sara/plan/improve/" + this.saraSummary?.id});
    },
    async computeDori() {
      await this.$router.push({path: "/dori/plan/calculation", query: {saraId: this.saraSummary?.id}});
    },
    // async saveSettings() {
    //   let affectedUserTypes: string = "Superuser";
    //   if (this.userType === UserType.ADMIN) {
    //     affectedUserTypes = "User + Admin";
    //   } else if (this.userType === UserType.USER) {
    //     affectedUserTypes = "User";
    //   }
    //   if (!confirm(`Möchten Sie die Einstellungen für "${affectedUserTypes}" wirklich speichern?`)) return;
    //   // @ts-ignore
    //   const solveRequestId = this.saraSummary?.solveRequest?.id ?? null;
    //   if (solveRequestId === null) {
    //     console.warn("solveRequestId is null");
    //     return;
    //   }
    //   const userSettingsId = this.saraSummary?.solveRequest?.userSettingsId ?? null;
    //   if (userSettingsId === null) {
    //     console.warn("userSettingsId is null");
    //     return;
    //   }
    //   const bi: BackendInterface = this.backendInterface;
    //   await bi.sara_UpdateUserSettingsBySolveRequestId(userSettingsId, solveRequestId);
    //   // console.log("saved user settings!");
    //   if (this.userType === UserType.ADMIN) {
    //     await bi.sara_UpdateUserSettingsBySolveRequestId(userSettingsId, solveRequestId);
    //     // console.log("copied user settings!");
    //   }
    //   this.snackbarText = `Einstellungen gespeichert (${affectedUserTypes})!`;
    //   this.showSnackbar = true;
    // },
  },
});
