
import Vue, { PropType } from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

import GroupGanttChart from "@/components/gantt/GroupGanttChart.vue";
import GanttChartSettings from "@/components/gantt/GanttChartSettings.vue";

import {
  DifferentiationEntries,
  GanttChartItemStatus,
  GanttChartItem,
  GanttChartItemProperties,
  UnitStatus,
  UnitStatusType,
  GanttChartViewConfiguration,
  GanttChartItemType,
} from "@/scripts/gantt/gantt";
import { palette, RandomNumberGenerator, timeSorters } from "@/scripts/utils";
import { GanttChartItemAggregation } from "@/scripts/gantt/GanttChartItemAggregation";
import { GanttChartItemAggregationGroup } from "@/scripts/gantt/GanttChartItemAggregationGroup";
import GanttChartState from "@/scripts/gantt/GanttChartState";
// import { UserType } from "@/scripts/models/general";

export default Vue.extend({
  name: "Gantt",
  components: {
    GroupGanttChart,
    GanttChartSettings,
  },
  props: {
    config: { type: Object as PropType<GanttChartViewConfiguration>, required: true },
    timeCursorTime: {type: Number as PropType<number>, default: () => Date.now()},
    state: {type: [Object, null] as PropType<GanttChartState|null>, default: null},
  },
  data() {
    return {
      // UserType,
      GanttChartItemStatus,
      UnitStatusType,
      palette,
      chipInfo: null as any,
      fixedTimePassedAtPercentage: true,
      fixedTimePassedPercentage: 0.15,
      percentageOfTimePassed: -1,
      state_: null as GanttChartState | null,
    };
  },
  beforeCreate() {},
  created() {
    // this.state = new GanttChartState(this.config);
  },
  mounted() {
    // this.state?.updateItemStatusType();
    // this.updatePercentageOfTimePassed()
    // @ts-ignore
    // clearInterval(window.timeUpdater);
    // @ts-ignore
    // window.timeUpdater = setInterval(() => {
    //   this.state?.updateItemStatusType();
    // }, 3_000);
  },
  watch: {
    state: {
      immediate: true,
      handler(v) {
        // console.log("state changed", v)
        if(!v){
          this.state_ = new GanttChartState(this.config);
        } else {
          this.state_ = v;
        }
        this.state_?.buildGroupsAndAggregations();
        // console.log("state changed", this.state_)
      },
    },
    fixedTimePassedAtPercentage: function () {
      this.state_?.updateItemStatusType();
    },
    "config.groupingKey": {
      handler(val: any) {
        const key = val as keyof GanttChartItemProperties;
        // console.log("this.config", this.config);

        if (this.config !== null) {
          // this.config
          this.config.differentiate[key] = true;
          // this.groups = this.calculateGroups();
          this.state_?.buildGroupsAndAggregations();
        }
      },
    },
    "config.differentiate": {
      deep: true,
      handler() {
        this.state_?.buildGroupsAndAggregations();
      },
    },
    config: {
      immediate: true,
      handler() {
        this.state_?.buildGroupsAndAggregations();
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    // TODO: fix types
    units(): any {
      const unitGetter = <P extends GanttChartItemProperties>(
        jobs: GanttChartItem<P>[],
        key: keyof GanttChartItemProperties
      ): any[] => {
        const items: any = {};
        for (const job of jobs) {
          const jobIdForKey = job.differentiatingProperties[key];
          if (!(jobIdForKey in items)) items[jobIdForKey] = job.differentiatingProperties[key];
        }
        return Object.values(items);
      };
      const jobs = this.config.items as GanttChartItem[];
      const keys = Object.keys(jobs?.[0]?.differentiatingProperties ?? {}) as Array<keyof GanttChartItemProperties>;
      return Object.fromEntries(keys.map((key) => [key, unitGetter(jobs, key as keyof GanttChartItemProperties)]));
    },
  },
  methods: {
    updatePercentageOfTimePassed(): void {
      // let [start, end] = this.itemsTimespan;
      if (!this.state_?.showTimeCursor) {
        this.percentageOfTimePassed = -1;
      } else if (this.fixedTimePassedAtPercentage) {
        this.percentageOfTimePassed = this.fixedTimePassedPercentage;
      }
      // else {
      //   this.percentageOfTimePassed = (this.time - start) / (end - start);
      // }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
});
