
import DashboardElement from "@/components/common/DashboardElement.vue";
import Legend from "@/components/common/Legend.vue";
import WeekdayTable from "@/components/WeekdayTable.vue";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import {CoffeeProductionBasketWeekdayPreroastingPlan} from "@/scripts/models/results";
import {weekdayToTimestepMap} from "@/scripts/models/date";
import {LegendEntry, LegendEntryType} from "@/scripts/models/coffee";
// import {UserType} from "@/scripts/models/general";
import {mapState} from "vuex";
import Vue, {PropType} from "vue";

export default Vue.extend({
  name: "PreroastingView",
  components: {DashboardElement, Legend, WeekdayTable},
  props: {
    data: {
      type: Object as PropType<CoffeeProductionSARASummary>,
      required: true,
    },
  },
  created() {
  },
  mounted() {
  },
  watch: {
    data() {
    },
  },
  data() {
    return {
      saraSummary: {...this.data} as CoffeeProductionSARASummary,
      SumPopUpValues: ["demandsRG", "demandsTas", "preroasting", "preroastingDeviation"],
      preroastingLegendItems: [
        {
          name: "Abweichung von Compliance: Überproduktion",
          color: "cornflowerblue",
          type: LegendEntryType.DOT,
        },
        {
          name: "Abweichung von Compliance: Unterproduktion",
          color: "orangered",
          type: LegendEntryType.DOT,
        },
      ] as LegendEntry[],
    };
  },
  computed: {
    ...mapState(["superuser", "user", "backendInterface", "productionSiteUniqueName"]),
    preroastingWeek(): number | null {
      if (this.saraSummary.results.basketWeekdayPreroastingResults[0]) {
        return this.saraSummary.results.basketWeekdayPreroastingResults[0].week;
      }
      return null;
    },
    preroastingPopUpTranslations(): Record<string, string | undefined> {
      // const isSuperuser = this.userType === UserType.SUPERUSER;
      return {
        isDecaf: "Decaf",
        testBasket: "Test Basket",
        exportBasket: "Export Basket",
        cafType: "Kaffeetyp",
        preroastingDuration_h: "Vorröstzeit (h)",
        minCuringDuration_h: "Lagerzeit min (h)",
        maxCuringDuration_h: "Lagerzeit max (h)",
        densityWeightScalingFactor: "Massenumrechnungsfaktor",
        basketSpsNr: "Basket SPS",
        year: "Jahr",
        week: "Kalenderwoche",
        dayOfWeek: "Wochentag",
        demandsRG: "Bedarf R&G (t)",
        demandsTas: "Bedarf Tassimo (t)",
        preroastingFactorsRG: "Vorröstungsfaktor R&G",
        preroastingFactorsTas: "Vorröstungsfaktor Tassimo",
        preroasting: "Gewünschte Vorröstungsmenge (t)",
        preroastingWithDeviation: "SARA Geplante Vorröstungsmenge (t)",
        preroastingDeviation: "Abweichung der Vorröstung (t)",
        demandsTotal: "Bedarf gesamt (t)",
        // expectedPreroasting: "Gewünschte Vorröstung (t)",
      };
    },
    basketPopUpInfos(): Record<string, any> {
      const basketMap = this.saraSummary.siloAssignment.basketMap;
      const popUpInfos: Record<string, any> = {};
      for (const basketSpsNr in basketMap) {
        const basket = basketMap[basketSpsNr];
        popUpInfos[basketSpsNr] = {
          basketSpsNr,
          exportBasket: basket.exportBasket,
          cafType: basket.cafType,
          testBasket: basket.testBasket,
          // densityWeightScalingPercentage: basket.coffeeWeightDeviation_pct,
          densityWeightScalingFactor: basket?.densityWeightScalingFactor || "",
          preroastingDuration_h: basket.preroastingDuration_h,
          minCuringDuration_h: basket.minCuringDuration_h,
          maxCuringDuration_h: basket.maxCuringDuration_h,
        };
      }
      return popUpInfos;
    },

    preroastMap() {
      //Initialising Maps
      const preroastingMap: any = {};
      //Initialising empty daily Map
      const initDayMap = (days: number = 3) => {
        const map: any = {};
        for (let i = 0; i < days; i++) {
          map[i] = null;
        }
        return map;
      };
      // Preroasting per Day

      if (this.saraSummary.preroastForNextWeek) {
        const preroastingPlans: CoffeeProductionBasketWeekdayPreroastingPlan[] =
            this.saraSummary.results.basketWeekdayPreroastingResults;
        if (preroastingPlans) {
          for (const plan of preroastingPlans) {
            if (plan.preroasting > 0 || Math.abs(plan.preroastingDeviation) > 0) {
              preroastingMap[plan.basketSpsNr] = initDayMap(3);
            }
          }

          for (const plan of preroastingPlans) {
            if (plan.preroasting > 0 || Math.abs(plan.preroastingDeviation) > 0) {
              // const plan2: any = { ...plan };
              // delete plan2.year;
              // delete plan2.week;
              // delete plan2.dayOfWeek;
              const plan2 = {
                demandsRG: plan.demandsRG,
                demandsTas: plan.demandsTas,
                demandsTotal: plan.demandsRG + plan.demandsTas,
                preroastingFactorsRG: plan.preroastingFactorsRG,
                preroastingFactorsTas: plan.preroastingFactorsTas,
                preroasting: plan.preroasting,
                preroastingWithDeviation: plan.preroasting + plan.preroastingDeviation,
                // preroastingDeviation: plan.preroastingDeviation,
              }
              const weekDayIndex = weekdayToTimestepMap[plan.dayOfWeek];
              preroastingMap[plan.basketSpsNr][weekDayIndex] = plan2;
            }
          }
        }
      }
      return preroastingMap;
    },
  },
  methods: {
    getWeekdayTableItemStyle(item: any) {
      return {
        fontWeight: Math.abs(item.preroastingDeviation) > 0.0001 ? "bold" : "medium",
        color:
            item.preroastingDeviation > 0.0001
                ? "cornflowerblue"
                : item.preroastingDeviation < -0.0001
                    ? "orangered"
                    : "inherit",
      };
    },
    formatItem(value: number, desiredValue: number): string {
      let color = "grey";
      if (value > desiredValue) color = "cornflowerblue"
      if (value < desiredValue) color = "orangered"
      if (Math.abs(value - desiredValue) < 0.0001) {
        return value.toFixed(2)
      } else {
        return `<span style="color: ${color}">${value.toFixed(2)}</span> <span style="color: grey">/ ${desiredValue.toFixed(2)}</span>`
      }
    }
  },
});
