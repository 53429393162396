import {CoffeeProductionSolverParameters} from "@/scripts/models/calculation";
import {
  CoffeeBasket,
  CoffeeRoaster,
  CoffeeProductionCompliancePlanSingle,
  SiloAssignment
} from "@/scripts/models/coffee";
import {DayOfWeek, DayOfWeekDate} from "@/scripts/models/date";
import {sumInMap} from "@/scripts/utils";
import {GAS_M3_TO_KWH} from "@/scripts/models/gas";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import {UserAccessType} from "./general";

export interface SiloAssignmentResult {
  // MongoDB
  id: string;
  // silo assignment
  siloAssignment: SiloAssignment;
  // problem solution results
  problemSolution: any;
  // the used solve parameter sequence
  parameterSequence: CoffeeProductionSolverParameters[];
  // the solution process output
  solutionTimeSec: number;
  solutionProcessOutput: string;
  // flat results
  basketWeekdaySiloResults: CoffeeProductionBasketWeekdaySiloPlan[];
  basketWeekdayRoasterResults: CoffeeProductionBasketWeekdayRoasterPlan[];
  basketWeekdayPreroastingResults: CoffeeProductionBasketWeekdayPreroastingPlan[];
  basketWeekdayResults: CoffeeProductionBasketWeekdayPlan[];
  weekdayResults: CoffeeProductionWeekdayPlan[];
  basketResults: CoffeeProductionBasketPlan[];
}

export interface SolveProcessOutput {
  // the elapsed time of the solution process
  solutionTimeSec: number;
  // the maximum allowed time for finding the solution
  maxSolutionTimeSec: number;
  // the current objective value
  currentObjectiveValue: number;
  // the best objective lower bound
  bestObjectiveLowerBound: number;
  // the current relative mip gap, computed from the current objective value
  // and the best objective lower bound
  relativeMIPGap: number;
  // the target relative mip gap
  targetRelativeMIPGap: number;
  // the current quality of the solution
  solutionQuality: SolutionQuality;
  // the solution process output
  solutionProcessOutput: string;
}

export interface CoffeeProductionBasketPlan {
  basketSpsNr: number; //6189
  demand: number; //380.0
  fulfilledDemand?: number; //3.4
  production: number; //430.0
  preproduction: number; //230.0
  nextWeekPreroasting: number; //4.5
  inflow: number; //380.0
  outflow: number; //430.0
  // deviations
  demandDeviation: number; //5.0
  preproductionDeviation: number; //2.0
  nextWeekPreroastingDeviation: number; //0.4
}

export interface CoffeeProductionBasketWeekdayPlan {
  // accessor
  basketSpsNr: number; // 6012,
  year: number; // 2021,
  week: number; // 46,
  dayOfWeek: DayOfWeek; // "MONDAY",
  // io
  production: number; // 32.430000000000845,
  preproduction: number
  usedFulfilledDemand?: number | string // NaN could happen
  remainingFulfilledDemand: number | string // NaN could happen
  inflow: number; // 32.4300000000004,
  demand: number; // 8.43,
  outflow: number; // 8.43,
  // deviations
  demandDeviation: number; // 0.0,
  preproductionDeviation: number
  // stored
  storedAtDayStart: number; // 0.0,
  storedAtDayEnd: number; // 24.0,
  // silos
  silosAllocated: number[]; // [332],
  reservedBaseCapacity: number; // 24.0
  // preproduction
}

export interface CoffeeProductionWeekdayPlan {
  // accessor
  year: number; // 2021,
  week: number; // 46,
  dayOfWeek: DayOfWeek; // "MONDAY",
  hasDecafDemand?: boolean
  // io
  demand: number; // 416.040790819305,
  production: number; // 430.0,
  preproduction: number
  preproductionDeviation: number
  inflow: number; // 429.9999999999996,
  outflow: number; // 416.04079081930496,
  // deviations
  demandDeviation: number; // 0.0,
  // stored
  storedAtDayStart: number; // 234.7,
  storedAtDayEnd: number; // 248.65920918069418,
  // silos
  silosAllocated: number[]; // [ 311, 312, 313, ...],
  silosUnallocated: number[]; // [ 318, 319, ...],
  siloInflowsBlocked: number[]; // [ 311, ...],
  siloOutflowsBlocked: number[]; // [ 311, 313, ..],
  reservedBaseCapacity: number; // 418.0,
  freeBaseCapacity: number; // 61.0
}

export interface CoffeeProductionBasketWeekdaySiloPlan {
  // accessor
  siloSpsNr: number; // 332,
  scaleGroupNr: number; // 3,
  basketSpsNr: number; // 6012,
  year: number; // 2021,
  week: number; // 46,
  dayOfWeek: DayOfWeek; // "MONDAY",
  // io
  inflow: number; // 32.4300000000004,
  outflow: number; // 8.43,
  // stored
  storedAtDayStart: number; // 0.0,
  storedAtDayEnd: number; // 24.0,
  nominalCapacity: number; // 0.0,
  usableCapacity: number; // 24.0,
  basketDependantCapacity: number; // 24.0,
  storageUtilizationAtDayEnd: number; // 1.0
  storedBasketMinCuringTime: number; // 6.0
  storedBasketMaxCuringTime: number; // 96.0
  //
  inflowLocked: boolean
  outflowLocked: boolean
}

export interface CoffeeProductionBasketWeekdayRoasterPlan {
  // accessor
  basketSpsNr: number; // 6012,
  roasterName: string; // "Jetzone",
  roasterCategory: string; // "",
  year: number; // 2021,
  week: number; // 46,
  dayOfWeek: DayOfWeek; // "MONDAY",
  // stored
  roastedWeight: number; // 0.0,
  roastedBatches: number; // 24.0,
  roastingTime: number; // 0.0,
  // bounds
  maxRoastingHours: number; // 24 (h)
  maxRoastingWeight: number; // 165.3 (t)
}

export interface CoffeeProductionBasketWeekdayPreroastingPlan {
  // accessor
  basketSpsNr: number;//6189
  year: number;//2021
  week: number;//42
  dayOfWeek: DayOfWeek;//MONDAY
  // io
  demandsRG: number;//4.3, 0.5
  demandsTas: number;//4.3, 0.5
  preroastingFactorsRG: number;//1.0 0.4
  preroastingFactorsTas: number;//1.0 0.4
  preroasting: number;//3.9, 0.2
  // deviations
  preroastingDeviation: number;//0.4 0.0
}

export enum CoffeeProductionModelSolutionStatus {
  SolutionFound = "SolutionFound",
  InfeasibleProblem = "InfeasibleProblem",
  SolverError = "SolverError",
}

export enum SolutionQuality {
  Perfect = "Perfect",
  High = "High",
  Medium = "Medium",
  Low = "Low",
  Unusable = "Unusable",
  Unknown = "Unknown",
}

export const SOLUTION_QUALITY_COLORS: Record<SolutionQuality, string> = {
  [SolutionQuality.Perfect]: "#2b7ec3",
  [SolutionQuality.High]: "#2bc38b",
  [SolutionQuality.Medium]: "#ffd934",
  [SolutionQuality.Low]: "#ffd934",
  [SolutionQuality.Unusable]: "#ffb234",
  [SolutionQuality.Unknown]: "#333333",
}

export const SOLUTION_QUALITY_TRANSLATIONS: Record<SolutionQuality, string> = {
  [SolutionQuality.Perfect]: "Sehr hoch",
  [SolutionQuality.High]: "Hoch",
  [SolutionQuality.Medium]: "Mittel",
  [SolutionQuality.Low]: "Mittel",
  [SolutionQuality.Unusable]: "Niedrig",
  [SolutionQuality.Unknown]: "Unbekannt",
}

//TODO: Check usage and change to new classes
export interface CoffeeProductionAssignmentResultSummaryInterface {
  creationTime: string // "2022-07-2T23:19.312767300",
  productionSiteUniqueName: string // "JDE_Berlin",
  timeRangeStart: string // "2022-01-1T20:00Z",
  timeRangeEnd: string // "2022-01-2T25:59.999999999Z",
  solutionStatus: CoffeeProductionModelSolutionStatus
  solutionQuality: SolutionQuality;
  hashes: Record<string, string>, // TODO: fix
  timeRange: {
    startDate: DayOfWeekDate,
    endDate: DayOfWeekDate,
  }
  baskets: CoffeeBasket[]
  roasters: CoffeeRoaster[]
  initialStoredBasketWeights: Record<string, number>
  compliancePlan: { planRG: CoffeeProductionCompliancePlanSingle, planTAS: CoffeeProductionCompliancePlanSingle }
  inputSettings: any // TODO: fix
  solverParameters: any // TODO: fix
  result: CoffeeProductionAssignmentResult
}

export interface BasketBlendArticleSummary {
  perBasket: Record<string, number>,
  perBlend: Record<string, number>,
  perArticle: Record<string, number>,
}

export class CoffeeProductionAssignmentResultSummary implements CoffeeProductionAssignmentResultSummaryInterface {

  baskets: CoffeeBasket[];
  compliancePlan: { planRG: CoffeeProductionCompliancePlanSingle, planTAS: CoffeeProductionCompliancePlanSingle };
  creationTime: string;
  solutionStatus: CoffeeProductionModelSolutionStatus
  solutionQuality: SolutionQuality;
  hashes: Record<string, string>;
  initialStoredBasketWeights: Record<string, number>;
  inputSettings: any;
  productionSiteUniqueName: string;
  result: CoffeeProductionAssignmentResult;
  roasters: any[];
  solverParameters: any;
  timeRange: { startDate: DayOfWeekDate; endDate: DayOfWeekDate };
  timeRangeEnd: string;
  timeRangeStart: string;

  constructor(resultSummary: CoffeeProductionAssignmentResultSummaryInterface) {
    this.baskets = resultSummary.baskets;
    this.compliancePlan = {
      planRG: CoffeeProductionCompliancePlanSingle.fromObject(resultSummary.compliancePlan.planRG),
      planTAS: CoffeeProductionCompliancePlanSingle.fromObject(resultSummary.compliancePlan.planTAS)
    }
    this.creationTime = resultSummary.creationTime;
    this.solutionStatus = resultSummary.solutionStatus
    this.solutionQuality = resultSummary.solutionQuality;
    this.hashes = resultSummary.hashes;
    this.initialStoredBasketWeights = resultSummary.initialStoredBasketWeights;
    this.inputSettings = resultSummary.inputSettings;
    this.productionSiteUniqueName = resultSummary.productionSiteUniqueName;
    this.result = resultSummary.result;
    this.roasters = resultSummary.roasters;
    this.solverParameters = resultSummary.solverParameters;
    this.timeRange = resultSummary.timeRange;
    this.timeRangeEnd = resultSummary.timeRangeEnd;
    this.timeRangeStart = resultSummary.timeRangeStart;
  }

  calculateEnergyConsumptions(): BasketBlendArticleSummary {
    const res: BasketBlendArticleSummary = {
      perBasket: {},
      perBlend: {},
      perArticle: {},
    }

    for (const subtask of this.result.roasterSubtaskResults) {
      const value = subtask.energyConsumption_kWh;
      sumInMap(res.perBasket, subtask.basketSpsNr.toString(), value)
      sumInMap(res.perBlend, subtask.blendSpsNr.toString(), value)
      // sumInMap(res.perArticle, subtask.articleNr.toString(), value)
    }

    return res;
  }

  calculateTotalEnergyConsumption(): number {
    let total = 0
    for (const subtask of this.result.roasterSubtaskResults) {
      // console.log(subtask.energyConsumption_kWh)
      total += subtask.energyConsumption_kWh
    }
    return total;
  }

  calculateTotalGasConsumption(): number {
    return this.calculateTotalEnergyConsumption() / GAS_M3_TO_KWH
  }

  calculateRoastedWeights(): BasketBlendArticleSummary {
    const res: BasketBlendArticleSummary = {
      perBasket: {},
      perBlend: {},
      perArticle: {},
    }

    for (const subtask of this.result.roasterSubtaskResults) {
      const value = subtask.roastingWeight_ton;
      sumInMap(res.perBasket, subtask.basketSpsNr.toString(), value)
      sumInMap(res.perBlend, subtask.blendSpsNr.toString(), value)
      // sumInMap(res.perArticle, subtask.articleNr.toString(), value)
    }

    return res;
  }

}

export interface CoffeeProductionAssignmentResult {
  discreteTimeModel: boolean // true,
  objectiveValue: number // 1320.1368726501726,
  bestObjectiveBound: number // 1318.8635652774,
  relativeMipGap: number // 9.645267844207065E-4,
  solutionStatus: string // "SolutionFound",
  solutionHash: number // 41578.10084209414,
  solutionTimeMS: number // 8083,
  solverOutput: number // string
  totalRoasterEnergy: number // 633006.2770345754,
  totalRoasterTaskNum: number // 42,
  totalPackagingTaskNum: number // 122,
  weightedTaskStartTimeSum: number // 30.186220314602522,
  totalWeightedProductionDeviation: number // 1.2859976460585356,
  totalRoasterEnergyLower: number // 593544.2961301733,
  totalRoasterEnergyUpper: number // 662129.5457222696,
  articleProductionDeviations: Record<string, number>
  roasterSubtaskResults: RoasterSubtaskResult[];
  roasterTaskResults: RoasterTaskResult[];
  curedArticleTaskResults: CuredArticleTaskResult[];
  millingSubTaskResults: MillingSubTaskResult[];
  roastAndGroundEnergyConsumption: CoffeeRoasterEnergyConsumption
  tassimoEnergyConsumption: CoffeeRoasterEnergyConsumption
  totalEnergyConsumption: CoffeeRoasterEnergyConsumption
}

export interface CoffeeRoasterEnergyConsumption {
  energy: number;
  energyLower: number;
  energyUpper: number;
  producedWeight_ton: number;
  energyUnitName: string;
}

export interface MillingSubTaskResult {
  // articleNr: string // "1680003",
  blendSpsNr: number // 3080,
  millNr: number // 1,
  millingTaskIndex: number // 0,
  millingSubTaskIndex: number // 0,
  subTaskBlendWeight_ton: number // 9.499999999999993,
  plannedTaskBlendWeight_ton?: number // 9.499999999999993,
  taskBlendWeight_ton?: number // 9.499999999999993,
  millingSubTaskStartTime_h: number // 6.0,
  millingSubTaskDuration_h: number // 3.799999999999997,
  millingSubTaskEndTime_h: number // 9.799999999999997
  timeStep: number
  plannedTaskStartTime_h?: number
  plannedTaskEndTime_h?: number
}

export interface RoasterSubtaskResult {
  basketSpsNr: number // 6096,
  roasterName: string // "Jetzone 1",
  roasterTaskIndex: number // 0,
  // articleNr: string // "69014259",
  blendSpsNr: number // 3535,
  millingTaskIndex: number // 0,
  millNr: number // 0,
  roastingWeight_ton: number // 3.4098,
  energyConsumption_kWh: number // 2489.3585880000005,
  roastingStartTime_h: number // 6.0,
  roastingDuration_h: number // 0.9742285714285714,
  roastingEndTime_h: number // 6.974228571428571
  timeStep: number
  reservedSiloSpsNrs?: number[]
  additionalReservedSiloSpsNrs?: number[]
}

export interface ReservedSiloInfo {
  siloSpsNr: number // 309,
  nominalCapacity_ton: number // 10,
  decaf: boolean // true
}

export interface RoasterTaskResult {
  basketSpsNr: number // 6096,
  timeStep: number //0
  roasterName: string // "Jetzone 1",
  roasterTaskIndex: number // 0,
  //total weight of the basket roasted in this roaster task
  roastingWeight_ton: number // 5.864928000000001,
  //the weight of the basket roasted in this roaster task used for
  //preroasting
  forPreroastWeight_ton: number // 2.864928000000001,
  //the weight of the basket roasted in this roaster task used for
  //the milling task of the current time step
  forMillingWeight_ton: number // 3.764928000000001,
  //planned basket weight for the roaster task, 0 if not planned
  plannedBasketWeight_ton: number // 8.12
  //planned basket sps number for the roaster task, 0 if not planned
  plannedBasketSpsNr: number // 6096
  //planned basket weight deviation for the roaster task
  plannedBasketWeightDeviation_ton: number //-2.2551
  //the total energy consumption of the roaster task in kWh
  energyConsumption_kWh: number // 4281.7493356800005,
  //roasting start time, duration and end time in hours
  roastingStartTime_h: number // 6.0,
  roastingDuration_h: number // 1.6756937142857145,
  roastingEndTime_h: number // 7.675693714285714
  //the silos that are reserved by SARA for the basket roasted in
  //this roaster task
  reservedSilos?: ReservedSiloInfo[]
  //the additional supplementary free silos that are free in SARA but are
  //reserved by DORI for the basket roasted in this roaster task
  additionalReservedSilos?: ReservedSiloInfo[]
  //total reserved silo capacity directly computed from reserved silos and
  //the reserved additional silos
  totalSiloCapacity_ton?: number;
  //the computed total silo utilization at the start of the roaster task
  siloUtilizationComputedAtTaskStart_ton?: number
  //the computed total silo utilization at the end of the roaster task
  siloUtilizationComputedAtTaskEnd_ton?: number
  //the total silo utilization at the end of the roaster task found by the
  //silo utilization model
  siloUtilizationModelAtTaskEnd_ton?: number
  //the used basket
  // basket?:CoffeeBasket
}

export interface CuredArticleTaskResult {
  alreadyStoredWeight_ton: number //0
  articleNr: string //"1680003"
  blendSpsNr: number //3080
  curedWeight_ton: number //5.1
  productionTaskDuration_h: number //0
  productionTaskEndTime_h: number //30
  productionTaskIndex: number //0
  productionTaskStartTime_h: number //30
  roastedWeight_ton: number //5.1
  roasterEnergyConsumedLower_kWh: number //2321.5727595
  roasterEnergyConsumedUpper_kWh: number //2770.301079
  roasterEnergyConsumed_kWh: number //2325.3498704999997
  tassimo: boolean //false
}

export enum AvailabilityStatus {
  AVAILABLE = "AVAILABLE",
  DELETED = "DELETED",
}

export interface SummaryMetadata {
  id: string
  summaryId: string
  modelType: CoffeeProductionModelType
  availabilityStatus: AvailabilityStatus
  ownerFullName: string
  ownerEmail: string
  ownerJobTitle: string
  ownerRole: string
  settingsId: string
  settingsName: string
}

export interface ExceptionResponse {
  time: string;
  requestDetails: string;
  errorMessage: string;
  translatedErrorMessage: string;
  errorTrace: string[];
  httpCodeMessage: string;
}

export interface UserSettingsInfo {
  id: string;
  modelType: CoffeeProductionModelType;
  name: string;
  ownerId: string;
  ownerUsername: string;
  readAccess: UserAccessType;
  writeAccess: UserAccessType;
  lastUpdated: string;
}

export enum SolveProcessStatus {
  SCHEDULED = "SCHEDULED",
  RUNNING = "RUNNING",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  INTERRUPTED = "INTERRUPTED",
}

export interface SolveProcessInfo {
  modelType: CoffeeProductionModelType// "DORI",
  solveProcessId: string// -3499718854773253600,
  ownerId: string// "app-owner",
  ownerName: string // "Team DAGOPT",
  status: SolveProcessStatus// "FINISHED"
  solverOutput: SolveProcessOutput
  createdTime: string // "2021-09-27T13:58:00.000Z",
  startTime: string // "2021-09-27T14:00:00.000Z",
  //interrupt time is only set if status is INTERRUPTED
  interruptTime: string // "2021-09-27T14:21:00.000Z",
  endTime: string // "2021-09-27T14:23:00.000Z",
  //exception message is only set if status is FAILED
  exceptionMessage: string
  //result summary id is only set if status is FINISHED or INTERRUPTED
  resultSummaryId: string
}
