
import Vue, { PropType } from "vue";
import { mapMutations, mapState } from "vuex";
import { WEEKDAY_TRANSLATIONS } from "@/scripts/utils";
// @ts-ignore
import Plotly from "plotly.js-dist";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import { CoffeeProductionSolverParameters } from "@/scripts/models/calculation";
import { CoffeeProductionBasketWeekdayRoasterPlan } from "@/scripts/models/results";
import { DayOfWeek, Weekday } from "@/scripts/models/date";

export default Vue.extend({
  name: "RoasterTables",
  components: {},
  props: {
    saraSummary: { type: Object as PropType<CoffeeProductionSARASummary>, required: true },
  },
  data() {
    return {
      WEEKDAY_TRANSLATIONS,
      tab: null,
      weekdays: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"],
      keys: [
        { value: "roastingTime", text: "Röstzeit" },
        { value: "roastedWeight", text: "Röstmenge" },
        { value: "roastedBatches", text: "Anzahl Batches" },
      ],
      key: "roastingTime",
      aggregate: false,
    };
  },
  created() {},
  mounted() {
    this.plot();
  },
  watch: {
    key: "plot",
    saraSummary(v) {
      if (v === null) return;
      this.plot();
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    roasters(): any {
      const res: any = {};

      const key = (this.aggregate
        ? "roasterCategory"
        : "roasterName") as keyof CoffeeProductionBasketWeekdayRoasterPlan;

      const names = this.saraSummary.results.basketWeekdayRoasterResults.map((bwr) => bwr[key]) as string[];
      names.sort();
      for (const name of names) res[name] = {};

      for (const bwr of this.saraSummary.results.basketWeekdayRoasterResults) {
        const roasterName = bwr[key];
        // console.log(bwr, key, roasterName)
        if (!(roasterName in res)) {
          res[roasterName] = {};
        }
        if (!(bwr.basketSpsNr in res[roasterName])) {
          res[roasterName][bwr.basketSpsNr] = {};
          for (const weekday of this.weekdays) {
            res[roasterName][bwr.basketSpsNr][weekday] = null;
          }
        }
        res[roasterName][bwr.basketSpsNr][bwr.dayOfWeek] = bwr;
      }
      return res;
    },
  },
  methods: {
    ...mapMutations([""]),
    getTableValue(bwr: CoffeeProductionBasketWeekdayRoasterPlan): number {
      if (bwr === null) return 0;
      let val = (bwr as any)?.[this.key] ?? 0.0;
      if (Number(val) !== val) val = 0.0;
      // console.log(val);
      return val;
    },
    getTableDisplayValue(bwr: CoffeeProductionBasketWeekdayRoasterPlan): string {
      const val = this.getTableValue(bwr);
      if (val == 0.0) return "-";
      else return val.toFixed(2);
    },
    getWeekdaySum(roasterName: string, dayOfWeek: DayOfWeek) {
      let sum = 0;
      for (const bwr of this.saraSummary.results.basketWeekdayRoasterResults) {
        if (bwr.roasterName === roasterName && bwr.dayOfWeek === dayOfWeek) sum += this.getTableValue(bwr);
      }
      return sum;
    },
    getWeekdayMaxHours(roasterName: string, dayOfWeek: DayOfWeek): string {
      for (const bwr of this.saraSummary.results.basketWeekdayRoasterResults) {
        if (bwr.roasterName === roasterName && bwr.dayOfWeek === dayOfWeek && isFinite(bwr.maxRoastingHours))
          return " / "+ bwr.maxRoastingHours.toFixed(2);
      }
      return "";
    },
    getBasketSum(roasterName: string, basketSpsNr: string | number) {
      let sum = 0;
      for (const bwr of this.saraSummary.results.basketWeekdayRoasterResults) {
        if (bwr.roasterName === roasterName && +bwr.basketSpsNr === +basketSpsNr) sum += this.getTableValue(bwr);
      }
      return sum.toFixed(2);
    },
    getSum(roasterName: string) {
      let sum = 0;
      for (const bwr of this.saraSummary.results.basketWeekdayRoasterResults) {
        if (bwr.roasterName === roasterName) sum += this.getTableValue(bwr);
      }
      return sum.toFixed(2);
    },
    getPlotId(roasterName: string): string {
      return `roaster_table_plot_${roasterName}`;
    },
    plot() {
      let ticksuffix = " t";
      if (this.key === "roastedBatches") ticksuffix = "";
      if (this.key === "roastingTime") ticksuffix = " h";
      for (const roasterName in this.roasters) {
        const labels = this.weekdays.map((w) => WEEKDAY_TRANSLATIONS[w as Weekday].slice(0, 2));
        const values = this.weekdays.map((dayOfWeek) => this.getWeekdaySum(roasterName, dayOfWeek as any));
        const traces = [
          {
            name: `roasterName`,
            x: labels,
            y: values,
            type: "bar",
            // marker: { color: Palette[i] },
          },
        ];

        const layout = {
          barmode: "stack",
          yaxis: {
            ticksuffix,
          },
          height: 150,
          width: 400,
          margin: {
            t: 0,
            r: 0,
            l: 40,
            b: 40,
          },
        };
        Plotly.newPlot(this.getPlotId(roasterName), traces, layout, {
          displayModeBar: false,
        });
      }
    },
  },
});
