
import Vue, { PropType } from "vue";
import { WEEKDAYS } from "@/scripts/models/date";
import { MillingSubTaskResult } from "@/scripts/models/results";

interface Keys {
  row: string; // 'packagingLineNr',
  time: string; // 'packagingTaskStartTime_h',
  cell1: string; // 'articleNr',
  cell2: string; // 'articleWeight_ton',
}

export default Vue.extend({
  name: "ProductionPlanTable",
  props: {
    tasks: { type: Array as PropType<MillingSubTaskResult[]>, default: () => [] },
    mode: { default: "packaging" },
    groups: { type: Array, default: () => [] },
    hideDisplayKeySelect: { type: Boolean, default: false },
    title: { type: String, default: "" },
    dayStartIndex: { type: Number as PropType<number>, default: 0 },
  },
  data() {
    return {
      weekdays: WEEKDAYS,
      displayKey: "articleNr",
      keys: {
        packaging: {
          row: "packagingLineNr",
          time: "packagingTaskStartTime_h",
          cell1: "blendSpsNr",
          cell2: "articleWeight_ton",
        } as Keys,
        roasting: {
          row: "roasterName",
          time: "roastingStartTime_h",
          cell1: "basketSpsNr",
          cell2: "roastingWeight_ton",
        } as Keys,
      },
    };
  },
  created() {
    // console.log("tasksPerGroup", this.tasksPerGroup);
  },
  watch: {
    // displayKey(){
    //   if(this.mode === 'roasting'){
    //     this.
    //   }
    // }
  },
  computed: {
    // effectiveDisplayKey(): string {
    //   return this.displayKey;
    // },
    tablePrefix() {
      if (this.mode === "packaging") {
        return "Linie";
      } else {
        return "Röster";
      }
    },
    activeKeys(): any {
      if (this.mode === "packaging") {
        return this.keys.packaging;
      } else {
        return this.keys.roasting;
      }
    },
    effectiveDisplayKey(): string {
      if (this.mode === "roasting") return "basketSpsNr";
      return this.displayKey ?? this.activeKeys.cell1;
    },
    tasksPerGroup() {
      const keys: Keys = this.activeKeys;
      // console.log("active keys", keys)
      const tasks = this.tasks as any[];
      const tpl: Record<string, any[]> = {};
      // init
      // const lines: string[] = this.groups as string[]
      const weekdays: readonly string[] = [...WEEKDAYS] as const;

      interface RowEntry {
        nTasks: number;
        tasks: Array<any | null>;
      }

      interface Row {
        nRows: number;
        tasksPerWeekday: Record<string, RowEntry>;
      }

      // interface
      const rows: Record<string, Row> = {};
      // build rows
      for (const line of this.groups as string[]) {
        rows[line] = {
          nRows: 0,
          tasksPerWeekday: {},
        };
        for (const weekday of weekdays) {
          rows[line].tasksPerWeekday[weekday] = {
            nTasks: 0,
            tasks: [],
          };
        }
      }

      // fill rows
      for (const task of tasks) {
        // console.log(task)
        const group = `${task[keys.row]}`;
        // console.log(group)
        if (group in rows) {
          // TODO: HACKY HACK REMOVE LATER (better to introduce a prop with the sort key name, or the mode "roaster" vs "packaging")
          let timestep;
            // console.log("calculating timestep", task, keys.time);
          if ("timeStep" in task) {
            timestep = task.timeStep;
          } else {
            timestep = Math.floor((task[keys.time] - 6) / 24);
            
          }
          const weekday = WEEKDAYS[timestep];
          const entry = rows[group].tasksPerWeekday[weekday];
          entry.tasks[entry.nTasks++] = task;
          rows[group].nRows = Math.max(rows[group].nRows, entry.nTasks);
        }
      }

      // sort tasks per weekday by their productionTaskIndex
      // TODO: HACKY HACK REMOVE LATER (better to introduce a prop with the sort key name, or the mode "roaster" vs "packaging")
      // DORI: this is necessary
      // PAPA: this does nothing
      for (const rowKey in rows) {
        const row = rows[rowKey];
        for (const weekday in row.tasksPerWeekday) {
          const tasksPerWeekday = row.tasksPerWeekday[weekday];
          tasksPerWeekday.tasks.sort((a, b) => a.millingTaskIndex - b.millingTaskIndex);
          tasksPerWeekday.tasks.sort((a, b) => a.roasterTaskIndex - b.roasterTaskIndex);
        }
      }
      // console.log("rows", rows)
      return rows;
    },
  },
  methods: {},
});
