import { GanttChartItem, GanttChartItemProperties, getTimespans } from "./gantt";
import { GanttChartItemAggregation } from "./GanttChartItemAggregation";

export class GanttChartItemAggregationGroup<
  ItemProperties extends GanttChartItemProperties = GanttChartItemProperties
> {
  jobs: GanttChartItem<ItemProperties>[] = [];
  jobIdsPerKey: Record<keyof ItemProperties, string[]>;
  timeStart: number = NaN;
  timeFinish: number = NaN;
  timeTotal: number = NaN;
  displayColor: string = "#e27d60";
  // imageURL: string = "placeholder.webp";
  imageURL: string = "";

  constructor(
    public name: string,
    public groupingKey: keyof ItemProperties,
    public aggregations: GanttChartItemAggregation<ItemProperties>[]
  ) {
    for (let g of aggregations) {
      for (let job of g.items) {
        // console.log('job id', job.id)
        this.jobs.push(job);
      }
    }

    // let keys = "article blend basket roasterTask roasterSubtask packagingTask".split(" ") as Array<keyof ItemProperties>;
    const keys = Object.keys(aggregations?.[0]?.items?.[0]?.differentiatingProperties ?? {}) as Array<
      keyof ItemProperties
    >;
    this.jobIdsPerKey = {} as Record<keyof ItemProperties, string[]>;
    for (const key of keys) {
      this.jobIdsPerKey[key] = [];
      for (const job of this.jobs) {
        const jobIdForKey = job.differentiatingProperties[key] as unknown as string;
        if (!this.jobIdsPerKey[key].includes(jobIdForKey)) this.jobIdsPerKey[key].push(jobIdForKey);
      }
    }

    this.findTimes();

    // console.log('JOBS',this.jobs);
    // console.log(getTimespans(jobs));
    // console.log(this.getTimespans());
  }

  private findTimes() {
    // find times
    let [timeStart, timeFinish] = [Infinity, -Infinity];
    let timeTotal = 0;
    for (const job of this.jobs) {
      const jts = job.timeStart.getTime();
      const jtf = job.timeFinish.getTime();
      if (jts < timeStart) timeStart = jts;
      if (jtf > timeFinish) timeFinish = jtf;
      const dt = jtf - jts;
      timeTotal += dt;
    }

    this.timeStart = timeStart;
    this.timeFinish = timeFinish;
    this.timeTotal = timeTotal;
  }

  getTimespans() {
    return getTimespans(this.jobs);
  }

  setDisplayColor(color: string) {
    this.displayColor = color;
  }

  setImageUrl(url: string) {
    this.imageURL = url;
  }

  public static calculateGroups<ItemProperties extends GanttChartItemProperties = GanttChartItemProperties>(
    aggregations: GanttChartItemAggregation<ItemProperties>[],
    groupingKey: keyof ItemProperties
  ): Array<GanttChartItemAggregationGroup<ItemProperties>> {
    let groups: Record<string, GanttChartItemAggregation<ItemProperties>[]> = {};
    for (let g of aggregations) {
      for (let keyItemId of g.itemIdsPerKey[groupingKey]) {
        if (keyItemId in groups) groups[keyItemId].push(g);
        else groups[keyItemId] = [g];
      }
    }
    const groupList = Object.entries(groups).map(
      (entry: [string, GanttChartItemAggregation<ItemProperties>[]], index: number) => {
        const job: GanttChartItem<ItemProperties> = entry[1][0].items[0];
        let groupName = `${job.differentiatingProperties[groupingKey]}`;
        let aggregations = entry[1];
        let g = new GanttChartItemAggregationGroup(groupName, groupingKey, aggregations);
        return g;
      }
    );
    return groupList ?? [];
  }
}