
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import { getRoasterNumber, parseTime } from "@/scripts/utils";
import {
  CoffeeProductionBasketWeekdayPlan,
  CoffeeProductionBasketWeekdayRoasterPlan,
  CoffeeProductionBasketWeekdaySiloPlan,
  SiloAssignmentResult,
} from "@/scripts/models/results";
import { DayOfWeek, weekdayEnumToGer, weekdayGerToEnum } from "@/scripts/models/date";

type SiloMap = { [key: string]: { [key: string]: { [key: string]: CoffeeProductionBasketWeekdaySiloPlan } } };
type RoasterMap = { [key: string]: { [key: string]: { [key: string]: CoffeeProductionBasketWeekdayRoasterPlan } } };

export default Vue.extend({
  name: "SolutionDayView",
  components: {},
  props: {
    saraSummary: {
      type: Object, // SiloAssignmentSolution
      required: true,
    },
  },
  data() {
    return {
      weekdays: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"],
      dayOfWeek: null as null | string | DayOfWeek,
      saraSummary_: {} as CoffeeProductionSARASummary,
      selectedBaskets: [] as number[],
      availableBaskets: [] as number[],
    };
  },
  created() {
    const saraSummary_: CoffeeProductionSARASummary = this.saraSummary_ as CoffeeProductionSARASummary;
    this.dayOfWeek = saraSummary_.timeRange.startDate.dayOfWeek;
    this.availableBaskets = Object.keys(saraSummary_.siloAssignment.basketMap).map((d) => +d);
    this.selectedBaskets = [...this.availableBaskets];
    const a = this.roasterAvailabilities;
    // this.saraSummary_ = solution_roaster;
    // log({ solution_roaster });
  },
  watch: {
    saraSummary: {
      immediate: true,
      handler(v) {
        this.saraSummary_ = new CoffeeProductionSARASummary(v);
      },
    },
    dayOfWeek: {
      immediate: true,
      handler(v) {
        // console.log("dayOfWeek", this.dayOfWeek);
        // this.selectedBaskets = [...v]
        // const filter = (basketSpsNr: number) => this.showSiloTable(basketSpsNr) || this.showRoasterTable(basketSpsNr);
        const filter = (basketSpsNr: number) => this.showRoasterTable(basketSpsNr);
        this.selectedBaskets = this.availableBaskets.filter(filter);
      },
    },
  },
  computed: {
    ...mapState(["superuser", "user"]),
    dayOfWeekGerman(): string | null {
      if (typeof this.dayOfWeek === "string") return (weekdayEnumToGer as any)[this.dayOfWeek];
      return null;
    },
    weekdayTuples(): any {
      const saraSummary_ = this.saraSummary_ as CoffeeProductionSARASummary;
      return saraSummary_.shiftedWeekdays.map((w: string) => ({ text: w, value: (weekdayGerToEnum as any)[w] ?? "" }));
    },
    roasterAvailabilities(): Array<{ roasterName: string; roasterCategory: string; availableHours: number }> {
      const weekdayIndex = this.weekdays.findIndex((w) => w === this.dayOfWeek);
      if (weekdayIndex === -1) return [];
      const saraSummary_ = this.saraSummary_ as CoffeeProductionSARASummary;
      const roasterAvailabilities = saraSummary_.solveRequest.inputSettings.roasterAvailabilities;
      const weekdayOffset = saraSummary_.weekdayOffset;
      // console.log("roasterAvailabilities", weekdayOffset, roasterAvailabilities);
      const roasterDayAvailabilities = roasterAvailabilities.map((ra) => ({
        roasterName: ra.roasterName,
        roasterCategory: ra.roasterCategory,
        availableHours: ra.weeklyMaxRoastingHoursPerDay[0][weekdayIndex],
      }));

      roasterDayAvailabilities.sort((a, b) => {
        // console.log(getRoasterNumber(a.roasterName))
        // @ts-ignore
        return getRoasterNumber(a.roasterName) - getRoasterNumber(b.roasterName);
      });
      // const availabilityPerRoasterCategory: Record<string, any> = {}
      return roasterDayAvailabilities;
    },
    title(): string {
      return this.saraSummary_.getTitle();
    },
    fulfilledPerBasket(): any {
      // fulfilled demands
      const fulfilledPerBasket: any = {};
      const basketWeekdayResults: CoffeeProductionBasketWeekdayPlan[] = this.saraSummary_.results.basketWeekdayResults;
      for (const bw of basketWeekdayResults) {
        // console.log(bw);
        if (!(bw.basketSpsNr in fulfilledPerBasket)) fulfilledPerBasket[bw.basketSpsNr] = {};
        fulfilledPerBasket[bw.basketSpsNr][bw.dayOfWeek] = this.cleanNumbers(bw);
      }
      // console.log("fulfilledPerBasket", fulfilledPerBasket);
      
      return fulfilledPerBasket;
    },
    allocationsPerBasket(): SiloMap {
      const allocationsPerBasket: SiloMap = {};
      // const sas: SiloAssignmentSolution = this.saraSummary_;
      const basketWeekdaySiloResults: CoffeeProductionBasketWeekdaySiloPlan[] = this.saraSummary_.results
        .basketWeekdaySiloResults;
      for (const bws of basketWeekdaySiloResults) {
        // console.log(bws);

        if (!(bws.basketSpsNr in allocationsPerBasket)) allocationsPerBasket[bws.basketSpsNr] = {};
        if (!(bws.siloSpsNr in allocationsPerBasket[bws.basketSpsNr])) {
          allocationsPerBasket[bws.basketSpsNr][bws.siloSpsNr] = {};
          // for (const dayOfWeek of this.weekdays) {
          //   allocationsPerBasket[bws.basketSpsNr][bws.siloSpsNr][dayOfWeek] = {
          //     storedAtDayStart: 0,
          //     storedAtDayEnd: 0,
          //     outflow: 0,
          //     inflow: 0,
          //   } as CoffeeProductionBasketWeekdaySiloPlan;
          // }
        }
        allocationsPerBasket[bws.basketSpsNr][bws.siloSpsNr][bws.dayOfWeek] = this.cleanNumbers(
          bws
        ) as CoffeeProductionBasketWeekdaySiloPlan;
      }
      // log({ allocationsPerBasket });
      return allocationsPerBasket;
    },
    roastersPerBasket(): RoasterMap {
      // const sas: SiloAssignmentSolution = this.saraSummary_;
      const basketWeekdayRoasterResults: CoffeeProductionBasketWeekdayRoasterPlan[] = this.saraSummary_.results
        .basketWeekdayRoasterResults;
      const res: RoasterMap = {};
      for (const bwr of basketWeekdayRoasterResults) {
        if (!(bwr.basketSpsNr in res)) res[bwr.basketSpsNr] = {};
        if (!(bwr.roasterName in res[bwr.basketSpsNr])) {
          res[bwr.basketSpsNr][bwr.roasterName] = {};
          // console.log(bwr.roasterName)
          // for (const dayOfWeek of this.weekdays) {
          //   res[bwr.basketSpsNr][bwr.roasterName][dayOfWeek] = null;
          // }
        }
        const cleanedBwr = this.cleanNumbers(bwr) as CoffeeProductionBasketWeekdayRoasterPlan;
        // if (cleanedBwr.roastedWeight !== 0)
        res[bwr.basketSpsNr][bwr.roasterName][bwr.dayOfWeek] = cleanedBwr;
      }
      // log({ res });
      return res;
    },
  },
  methods: {
    ...mapMutations(["toggleSuperuser"]),
    toFixed(n: any){
      if(typeof n === "number") return n.toFixed(2)
      return "-"
    },
    cleanNumbers(obj: { [key: string]: any }): { [key: string]: any } {
      for (const key in obj) {
        if (
          obj[key] === undefined ||
          obj[key] === null ||
          obj[key] === NaN ||
          obj[key] === "NaN" ||
          obj[key] === Infinity
        ) {
          obj[key] = 0;
        }
      }
      return obj;
    },
    getSiloWeekdaySum(basketSpsNr: number, dayOfWeek: DayOfWeek): CoffeeProductionBasketWeekdayPlan | null {
      for (const bw of this.saraSummary_.results.basketWeekdayResults) {
        if (+bw.basketSpsNr === +basketSpsNr && bw.dayOfWeek === dayOfWeek) {
          return bw;
        }
      }
      return null;
    },
    getRoasterWeekdaySum(basketSpsNr: number, dayOfWeek: DayOfWeek) {
      const sum = {
        storedAtDayStart: 0,
        roastedWeight: 0,
        roastedBatches: 0,
        roastingTime: 0,
      };
      for (const bwr of this.saraSummary_.results.basketWeekdayRoasterResults) {
        if (+bwr.basketSpsNr === +basketSpsNr && bwr.dayOfWeek === dayOfWeek) {
          sum.roastedWeight += +bwr.roastedWeight;
          sum.roastingTime += +bwr.roastingTime;
          sum.roastedBatches += +bwr.roastedBatches;
        }
      }
      return sum;
    },
    parseTime(time: number) {
      return parseTime(time);
    },
    showSiloTable(basketSpsNr: number) {
      if (this.dayOfWeek == null) return;
      const sum = this.getSiloWeekdaySum(basketSpsNr, this.dayOfWeek as DayOfWeek) as CoffeeProductionBasketWeekdayPlan;
      return sum.storedAtDayStart + sum.storedAtDayEnd + sum.inflow + sum.outflow > 0;
    },
    showRoasterTable(basketSpsNr: number) {
      if (this.dayOfWeek == null) return;
      const sum = this.getRoasterWeekdaySum(basketSpsNr, this.dayOfWeek as DayOfWeek);
      return sum.roastedWeight > 0;
    },
  },
});
