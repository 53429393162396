import {BasketRoasterGasConsumption} from "@/scripts/models/gas";

export const defaultGasConsumptions: BasketRoasterGasConsumption[] = [
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6231,
        "isTestBasket": false,
        "gasConsumption_m3": 66.1659822779338
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6231,
        "isTestBasket": false,
        "gasConsumption_m3": 40.2679793151244
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6252,
        "isTestBasket": false,
        "gasConsumption_m3": 77.4334272471144
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6344,
        "isTestBasket": false,
        "gasConsumption_m3": 43.4533114351861
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6344,
        "isTestBasket": false,
        "gasConsumption_m3": 53.4564820179152
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6344,
        "isTestBasket": false,
        "gasConsumption_m3": 49.2615739180396
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6344,
        "isTestBasket": false,
        "gasConsumption_m3": 48.7977723354918
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6344,
        "isTestBasket": false,
        "gasConsumption_m3": 34.8779272546088
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6348,
        "isTestBasket": false,
        "gasConsumption_m3": 47.0357108526386
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6348,
        "isTestBasket": false,
        "gasConsumption_m3": 65.0638634000743
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6348,
        "isTestBasket": false,
        "gasConsumption_m3": 51.8544473144683
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6348,
        "isTestBasket": false,
        "gasConsumption_m3": 56.2111535499529
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6348,
        "isTestBasket": false,
        "gasConsumption_m3": 48.5501908524743
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6353,
        "isTestBasket": false,
        "gasConsumption_m3": 34.2545119652789
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6353,
        "isTestBasket": false,
        "gasConsumption_m3": 34.032311180098
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6366,
        "isTestBasket": false,
        "gasConsumption_m3": 72.0171711529651
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6366,
        "isTestBasket": false,
        "gasConsumption_m3": 55.0585333385537
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6366,
        "isTestBasket": false,
        "gasConsumption_m3": 52.69350065956
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6369,
        "isTestBasket": false,
        "gasConsumption_m3": 59.4059852680736
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6369,
        "isTestBasket": false,
        "gasConsumption_m3": 47.1454104868785
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6369,
        "isTestBasket": false,
        "gasConsumption_m3": 42.475881678639
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6370,
        "isTestBasket": false,
        "gasConsumption_m3": 43.330355302041
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6370,
        "isTestBasket": false,
        "gasConsumption_m3": 55.4468493181514
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6370,
        "isTestBasket": false,
        "gasConsumption_m3": 50.1521144968979
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6370,
        "isTestBasket": false,
        "gasConsumption_m3": 52.2684238690468
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6371,
        "isTestBasket": false,
        "gasConsumption_m3": 70.0197730256052
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6371,
        "isTestBasket": false,
        "gasConsumption_m3": 52.3220519046896
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6371,
        "isTestBasket": false,
        "gasConsumption_m3": 70.2861952861952
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6384,
        "isTestBasket": false,
        "gasConsumption_m3": 45.5907852365618
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6384,
        "isTestBasket": false,
        "gasConsumption_m3": 57.9970150869568
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6384,
        "isTestBasket": false,
        "gasConsumption_m3": 54.5800272313254
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6384,
        "isTestBasket": false,
        "gasConsumption_m3": 54.679268476243
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6384,
        "isTestBasket": false,
        "gasConsumption_m3": 51.9736842105263
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6122,
        "isTestBasket": false,
        "gasConsumption_m3": 49.7561840412096
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6122,
        "isTestBasket": false,
        "gasConsumption_m3": 37.8187966746512
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6122,
        "isTestBasket": false,
        "gasConsumption_m3": 42.6332611609444
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6125,
        "isTestBasket": false,
        "gasConsumption_m3": 57.0912012856568
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6125,
        "isTestBasket": false,
        "gasConsumption_m3": 49.328980776206
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6125,
        "isTestBasket": false,
        "gasConsumption_m3": 49.4139334150634
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6127,
        "isTestBasket": false,
        "gasConsumption_m3": 58.5107141329607
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6127,
        "isTestBasket": false,
        "gasConsumption_m3": 45.7570715474209
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6129,
        "isTestBasket": false,
        "gasConsumption_m3": 71.6228467815049
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6129,
        "isTestBasket": false,
        "gasConsumption_m3": 65.6625044328723
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6012,
        "isTestBasket": false,
        "gasConsumption_m3": 47.0936600008112
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6012,
        "isTestBasket": false,
        "gasConsumption_m3": 66.6046842854882
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6012,
        "isTestBasket": false,
        "gasConsumption_m3": 61.8405999745773
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6012,
        "isTestBasket": false,
        "gasConsumption_m3": 64.4970335192298
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6012,
        "isTestBasket": false,
        "gasConsumption_m3": 50.8693732523179
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6012,
        "isTestBasket": false,
        "gasConsumption_m3": 46.860181454376
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6022,
        "isTestBasket": false,
        "gasConsumption_m3": 68.4292743431175
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6022,
        "isTestBasket": false,
        "gasConsumption_m3": 35.9978100191623
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6022,
        "isTestBasket": false,
        "gasConsumption_m3": 47.0762085708254
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6017,
        "isTestBasket": false,
        "gasConsumption_m3": 61.5131290067621
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6017,
        "isTestBasket": false,
        "gasConsumption_m3": 38.9979783207157
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6017,
        "isTestBasket": false,
        "gasConsumption_m3": 39.2068314311519
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6018,
        "isTestBasket": false,
        "gasConsumption_m3": 63.8670166229221
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6018,
        "isTestBasket": false,
        "gasConsumption_m3": 42.8028609745194
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6018,
        "isTestBasket": false,
        "gasConsumption_m3": 57.4509457309528
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6159,
        "isTestBasket": false,
        "gasConsumption_m3": 54.9614246983832
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6159,
        "isTestBasket": false,
        "gasConsumption_m3": 38.7078527989309
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6159,
        "isTestBasket": false,
        "gasConsumption_m3": 38.8787106550037
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6160,
        "isTestBasket": false,
        "gasConsumption_m3": 65.1081242109517
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6160,
        "isTestBasket": false,
        "gasConsumption_m3": 47.1555531643278
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6160,
        "isTestBasket": false,
        "gasConsumption_m3": 39.850489872199
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6722,
        "isTestBasket": false,
        "gasConsumption_m3": 62.9234366568739
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6722,
        "isTestBasket": false,
        "gasConsumption_m3": 67.7355016876342
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6722,
        "isTestBasket": false,
        "gasConsumption_m3": 55.6070435588507
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6723,
        "isTestBasket": false,
        "gasConsumption_m3": 62.5821587141555
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6723,
        "isTestBasket": false,
        "gasConsumption_m3": 42.5523968868107
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6723,
        "isTestBasket": false,
        "gasConsumption_m3": 43.4603015371905
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6189,
        "isTestBasket": false,
        "gasConsumption_m3": 45.0087017308798
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6189,
        "isTestBasket": false,
        "gasConsumption_m3": 41.5572823047121
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6870,
        "isTestBasket": false,
        "gasConsumption_m3": 73.3601354210749
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6870,
        "isTestBasket": false,
        "gasConsumption_m3": 53.8267451640033
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6870,
        "isTestBasket": false,
        "gasConsumption_m3": 52.9124457308248
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6203,
        "isTestBasket": false,
        "gasConsumption_m3": 67.4296959966854
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6203,
        "isTestBasket": false,
        "gasConsumption_m3": 62.900083495686
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6518,
        "isTestBasket": false,
        "gasConsumption_m3": 54.3881216265534
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6518,
        "isTestBasket": false,
        "gasConsumption_m3": 59.1546997389033
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6519,
        "isTestBasket": false,
        "gasConsumption_m3": 41.8022997119505
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6519,
        "isTestBasket": false,
        "gasConsumption_m3": 61.7015260576977
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6519,
        "isTestBasket": false,
        "gasConsumption_m3": 54.4440216027141
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6519,
        "isTestBasket": false,
        "gasConsumption_m3": 60.0328694098905
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6519,
        "isTestBasket": false,
        "gasConsumption_m3": 38.8623056097714
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6519,
        "isTestBasket": false,
        "gasConsumption_m3": 74.0618828176431
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6096,
        "isTestBasket": false,
        "gasConsumption_m3": 67.4157303370786
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6096,
        "isTestBasket": false,
        "gasConsumption_m3": 67.8667141728502
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6096,
        "isTestBasket": false,
        "gasConsumption_m3": 59.5459541726557
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6096,
        "isTestBasket": false,
        "gasConsumption_m3": 62.833707343496
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6096,
        "isTestBasket": false,
        "gasConsumption_m3": 89.4756838905775
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6096,
        "isTestBasket": false,
        "gasConsumption_m3": 46.0408163265306
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6226,
        "isTestBasket": false,
        "gasConsumption_m3": 42.0043634759733
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6226,
        "isTestBasket": false,
        "gasConsumption_m3": 54.1949349628703
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6226,
        "isTestBasket": false,
        "gasConsumption_m3": 52.14516142689
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6226,
        "isTestBasket": false,
        "gasConsumption_m3": 49.1724260602614
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6226,
        "isTestBasket": false,
        "gasConsumption_m3": 37.4635940042565
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6226,
        "isTestBasket": false,
        "gasConsumption_m3": 35.7895972654327
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6228,
        "isTestBasket": false,
        "gasConsumption_m3": 48.4456488393496
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6228,
        "isTestBasket": false,
        "gasConsumption_m3": 55.0486736085767
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6228,
        "isTestBasket": false,
        "gasConsumption_m3": 56.126403160079
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6228,
        "isTestBasket": false,
        "gasConsumption_m3": 53.49682149106
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6532,
        "isTestBasket": false,
        "gasConsumption_m3": 65.5750998003992
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6532,
        "isTestBasket": false,
        "gasConsumption_m3": 50.5392101604479
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6532,
        "isTestBasket": false,
        "gasConsumption_m3": 42.8225227786577
    },
    {
        "roasterName": "Jetzone 1",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6061,
        "isTestBasket": false,
        "gasConsumption_m3": 42.0937042459736
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6061,
        "isTestBasket": false,
        "gasConsumption_m3": 73.299814765811
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6061,
        "isTestBasket": false,
        "gasConsumption_m3": 82.4091313850886
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6061,
        "isTestBasket": false,
        "gasConsumption_m3": 63.8024510080759
    },
    {
        "roasterName": "Neptune 6",
        "roasterCategory": "Neptune",
        "basketSpsNr": 6061,
        "isTestBasket": false,
        "gasConsumption_m3": 58.0611715914981
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6061,
        "isTestBasket": false,
        "gasConsumption_m3": 36.1894624800425
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6101,
        "isTestBasket": false,
        "gasConsumption_m3": 56.673642809461
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6101,
        "isTestBasket": false,
        "gasConsumption_m3": 47.596217871887
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6101,
        "isTestBasket": false,
        "gasConsumption_m3": 50.8753005907907
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6101,
        "isTestBasket": false,
        "gasConsumption_m3": 47.4977856510186
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6101,
        "isTestBasket": false,
        "gasConsumption_m3": 36.6674586204165
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6501,
        "isTestBasket": false,
        "gasConsumption_m3": 62.7204585537918
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6501,
        "isTestBasket": false,
        "gasConsumption_m3": 55.3488372093023
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6501,
        "isTestBasket": false,
        "gasConsumption_m3": 58.2572614107883
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6581,
        "isTestBasket": false,
        "gasConsumption_m3": 52.3354964452614
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6581,
        "isTestBasket": false,
        "gasConsumption_m3": 53.1286894923258
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6871,
        "isTestBasket": false,
        "gasConsumption_m3": 80.1709914504274
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6871,
        "isTestBasket": false,
        "gasConsumption_m3": 43.4473748090186
    },
    {
        "roasterName": "Jupiter 8",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6871,
        "isTestBasket": false,
        "gasConsumption_m3": 44.2866560035975
    },
    {
        "roasterName": "Jetzone 11",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6972,
        "isTestBasket": false,
        "gasConsumption_m3": 55.3480756494223
    },
    {
        "roasterName": "Jetzone 3",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6972,
        "isTestBasket": false,
        "gasConsumption_m3": 64.5951397560272
    },
    {
        "roasterName": "Jetzone 4",
        "roasterCategory": "Jetzone",
        "basketSpsNr": 6972,
        "isTestBasket": false,
        "gasConsumption_m3": 57.3706390928444
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6203,
        "isTestBasket": false,
        "gasConsumption_m3": 62.900083495686
    },
    {
        "roasterName": "Jupiter 7",
        "roasterCategory": "Jupiter",
        "basketSpsNr": 6231,
        "isTestBasket": false,
        "gasConsumption_m3": 40.2679793151244
    }
]