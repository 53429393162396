
import {CoffeeBasket, CoffeeSiloAllocation} from "@/scripts/models/coffee";
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {CoffeeSiloValues} from "@/scripts/models/calculation";
// @ts-ignore
// import testBaskets from "@/data/baskets.json";

export default Vue.extend({
  name: "SiloSettings",
  props: {
    value: {
      type: Object as PropType<{
        siloAllocations: CoffeeSiloAllocation[];
        siloFlowMultipliers: CoffeeSiloValues;
      }>,
      required: true,
    },
    // siloFlowMultipliers: {
    //   type: Object as PropType<CoffeeSiloValues>,
    //   required: true,
    // },
    autosave: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    noFlowMultipliers: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  components: {},
  data: function () {
    return {
      silos: [] as CoffeeSiloAllocation[],
      siloFlowMultipliers: {siloValues: {}} as CoffeeSiloValues,
      search: "",
    };
  },
  created() {
    this.init();
  },
  watch: {
    value: "init",
  },
  computed: {
    ...mapState(["backendUrl", "superuser", "availableSolveRequest", "selectedSolveRequest", "availableBackends"]),
    headers() {
      const headers: any[] = [
        {text: "SPS", value: "siloSpsNr"},
        {text: "Waagengruppe", value: "scaleGroupNr"},
        {text: "Theor. Kapazität", value: "nominalCapacity_t"},
        {text: "Nutzbare Kapazität", value: "usableCapacity_t"},
      ];
      if (!this.noFlowMultipliers) {
        headers.push({text: "Durchflussfaktor", value: "siloFlowMultiplier", sortable: false});
      }
      return headers
    },
  },
  methods: {
    ...mapMutations(["selectWeekData", "selectSolveRequest", "selectBackend"]),
    init() {
      if (this.value !== null) {
        this.silos = JSON.parse(JSON.stringify(this.value.siloAllocations ?? [])) as CoffeeSiloAllocation[];
        this.siloFlowMultipliers = JSON.parse(
            JSON.stringify(this.value.siloFlowMultipliers ?? {siloValues: {}})
        ) as CoffeeSiloValues;
      } else {
        this.silos = [];
        this.siloFlowMultipliers = {siloValues: {}};
      }
      this.checkDataConsistency();
    },
    checkDataConsistency() {
      // for(const silo of this.silos){
      //   const siloSpsNr = silo.siloSpsNr
      //   if(!(silo.siloSpsNr in this.siloFlowMultipliers))
      // }
    },
    save() {
      this.$emit("input", {
        siloAllocations: this.silos,
        siloFlowMultipliers: this.siloFlowMultipliers,
      });
    },
    doAutosave() {
      // if (this.autosave)
      this.save();
    },
  },
});
