
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import ProductionPlanTable from "@/components/gas/ProductionPlanTable.vue";
// @ts-ignore
import Plotly from "plotly.js-dist";
// import {UserType} from "@/scripts/models/general";
import PackagingTaskPlot from "@/components/gas/TaskPlot.vue";


export default Vue.extend({
  name: "RoastingSummary",
  components: {PackagingTaskPlot, ProductionPlanTable},
  props: {
    title: {type: String, default: 'Packaging'},
    tasks: {type: Array, default: () => []},
  },
  data() {
    return {
      roasterNames: [
        'Jetzone 1',
        'Jetzone 3',
        'Jetzone 4',
        'Neptune 6',
        'Jupiter 7',
        'Jupiter 8',
        'Jupiter 9',
        'Jetzone 11',
      ],
    };
  },
  created() {
    // console.log("tasks", this.tasks);
    
  },
  mounted() {
    setTimeout(() => {
      // this.plot()
    }, 0)
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    hideSelection(): boolean {
      return true //return this.userType !== UserType.SUPERUSER;
    },
  },
  methods: {
    ...mapMutations([""]),

  },
});
