
import Vue, { PropType } from "vue";
import { mapMutations, mapState } from "vuex";
import {
  GanttChartItem,
  GanttChartItemProperties,
  GanttChartItemStatus,
  GanttChartItemType,
  GanttChartViewConfiguration,
} from "@/scripts/gantt/gantt";
import { GanttChartItemAggregationGroup } from "@/scripts/gantt/GanttChartItemAggregationGroup";
import { GanttChartItemAggregation } from "@/scripts/gantt/GanttChartItemAggregation";

export default Vue.extend({
  name: "GanttChartBar",
  components: {},
  props: {
    scroll: { type: Object as PropType<any> },
    config: { type: Object as PropType<GanttChartViewConfiguration> },
    group: { type: Object as PropType<GanttChartItemAggregationGroup> },
    agg: { type: Object as PropType<GanttChartItemAggregation> },
    // differentiate: { type: Object as PropType<any>, required: true },
    itemsTimespan: { type: Array as PropType<number[]> },
    chartWidth: { type: Number as PropType<number> },
  },
  data() {
    return {
      GanttChartItemType,
      chartPaddingLeft: 0, //px
    };
  },
  created() {
    // if(this.agg.items.length > 1) console.log("AGGREGATION", JSON.stringify(this.agg,null,2));
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
  },
  methods: {
    ...mapMutations([""]),
    barColorCSS(item: GanttChartItem<GanttChartItemProperties>) {
      let color;
      // console.log("item.status", item.status);

      if (item.status === GanttChartItemStatus.HIDDEN) {
        return `display: none;`;
      }
      if (item.status === GanttChartItemStatus.DONE && item.type === GanttChartItemType.TASK) {
        // color = "#c2c2c2";
        return `background-color: lightgray; `;
        // return `background-color: ${item.color}; filter: grayscale(1); `;
      } else if (item.status === GanttChartItemStatus.IN_PROGRESS) {
        return `background-color: ${item.color};`;
      } else if (item.status === GanttChartItemStatus.PENDING) {
        // return `background: repeating-linear-gradient(-45deg, ${item.color}, ${item.color} 4px, white 4px, white 8px);`
        return `background-color: ${item.color}; opacity: 0.5; color: gray`;
        // return `background: repeating-linear-gradient(0 | to side-or-corner, red, blue);`
      }
      return ''
      // const group = this.group;
      // let statusDone = true;
      // let statusPending = true;
      // let unitStatus = 0;
      // for (const job of agg.items) {
      //   statusDone = statusDone && job.status == GanttChartItemStatus.DONE;
      //   statusPending = statusPending && job.status == GanttChartItemStatus.PENDING;

      //   for (const key in this.differentiate) {
      //     const castedKey = key as keyof GanttChartItemProperties;
      //     // if (job.differentiatingProperties[castedKey].status.length) {
      //     //   for (let status of job.differentiatingProperties[castedKey].status) {
      //     //     if (status.type > unitStatus) unitStatus = status.type;
      //     //   }
      //     // }
      //   }
      // }

      // let color = group.displayColor;

      // // let animation =
      // //   unitStatus == 2 ? "animation: shadow-pulse 2s ease-out infinite;" : "";
      // let hueRotation = this.hueRotation(agg);
      // // console.log(`filter: hue-rotate(${hueRotation}deg);`);

      // return `
      // background-color: ${color};
      // filter: hue-rotate(${hueRotation}deg);`;
    },

    barAnimationCSS(agg: GanttChartItemAggregation<GanttChartItemProperties>) {
      let unitStatus = 0;
      for (const job of agg.items) {
        // for (const key in this.differentiate) {
        //   const castedKey = key as keyof GanttChartItemProperties;
          // if (job.differentiatingProperties[castedKey].status.length) {
          //   for (let status of job.differentiatingProperties[castedKey].status) {
          //     if (status.type > unitStatus) unitStatus = status.type;
          //   }
          // }
        // }
      }
      let animation = unitStatus == 2 ? "animation: shadow-pulse 2s ease-out infinite;" : "";

      return `${animation}`;
    },

    barDimensionsCSS(t0: number, t1: number) {
      let timespan: number[] = this.itemsTimespan as number[];
      let dt = timespan[1] - timespan[0];
      const pixelMarginRight = 2;

      const usableChartWidth = this.chartWidth - this.chartPaddingLeft;
      const getChartPosition = (t: number) => ((t - timespan[0]) / dt) * usableChartWidth + this.chartPaddingLeft;
      let x0 = getChartPosition(t0);
      let x1 = getChartPosition(t1) - pixelMarginRight;
      const width = Math.max(0, x1 - x0);

      return `transform: translateX(${x0}px); width: ${width}px;`;
    },
    hueRotation(agg: GanttChartItemAggregation<GanttChartItemProperties>): any {
      let key = this.config.coloringKey as keyof GanttChartItemProperties;
      // key = "basketSpsNr";
      // console.log("this.group.jobIdsPerKey", this.group.jobIdsPerKey);

      let unitIds: string[] = this.group.jobIdsPerKey[key];
      let id: string = agg.itemIdsPerKey[key][0];
      let deg: number = 30;
      return (unitIds.indexOf(id) - unitIds.length / 2) * deg;
      // return this.group.jobIdsPerKey[this.colorBy].indexOf(agg.jobIdsPerKey[this.colorBy][0])*20
      // let l = unitIds.length;
      // let hueRotations = Object.fromEntries(
      //   unitIds.map((id: string, i: number) => [id, (deg * i) / l - deg * l])
      // )
      // // console.log(itemIds, id);
      // console.log(hueRotations[id])
      // return hueRotations[id];
    },
    // centerString(item: GanttChartItem): string {
    //   const fn = this.config.content?.center ?? ((item: any) => "");
    //   return fn(item);
    // },
  },
});
