
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";

export default Vue.extend({
  name: "ResetPassword",
  components: {},
  props: {
    passwordResetToken: {type: String as PropType<string>, required: false},
  },
  data() {
    return {
      email: "",
      infoMessage: "",
      actionSuccessful: false,
      password: "",
      passwordConfirm: "",
      loading: false,
      fieldTypes: {
        password: 'password',
      }
    };
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    hasToken(): boolean {
      return !!this.passwordResetToken;
    },
    passwordsMatching(): boolean {
      return this.password === this.passwordConfirm;
    },
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
  },
  methods: {
    ...mapMutations([""]),
    handleType(event: any) {
      const {srcElement, type} = event;
      const {name, value} = srcElement;

      if (type === 'blur' && !value) {
        // @ts-ignore
        this.fieldTypes[name] = 'text'
      } else {
        // @ts-ignore
        this.fieldTypes[name] = 'password'
      }
    },
    async requestPasswordResetEmail() {
      this.loading = true;
      const res: any = await this.bi.requestPasswordResetEmail(this.email.toLowerCase())
      if (res !== null && 'errorMessage' in res) {
        this.infoMessage = res.translatedErrorMessage ?? res.errorMessage
        this.actionSuccessful = false
      } else {
        this.infoMessage = "E-Mail wurde versendet"
        this.actionSuccessful = true
      }
      this.loading = false;
    },
    async resetPassword() {
      const bi = this.backendInterface as BackendInterface;
      if (!this.passwordsMatching) {
        this.infoMessage = "Passwörter stimmen nicht überein"
        this.actionSuccessful = false
        return;
      }
      this.loading = true;
      try {
        const res: any = await bi.resetPassword({
          newPassword: this.password,
          newPasswordRepeat: this.passwordConfirm,
          passwordResetToken: this.passwordResetToken,
        })
        console.log(res)
        if (res !== null && 'errorMessage' in res) {
          this.infoMessage = res.translatedErrorMessage ?? res.errorMessage
          this.actionSuccessful = false
          return
        }
        this.infoMessage = "Passwort wurde zurückgesetzt"
        this.actionSuccessful = true
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    goToLogin() {
      this.$router.push({name: "Login"});
    }
  },
});
