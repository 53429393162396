import {
  CoffeeProductionAssignmentResult,
  CoffeeProductionAssignmentResultSummaryInterface,
  RoasterTaskResult,
} from "@/scripts/models/results";
import { createColorFromNumber, round, timeSorters } from "@/scripts/utils";
import translationsRaw from "@/data/translations";
import { CoffeeProductionDORIResult, CoffeeProductionDORISummary } from "../models/dori";

export interface GanttChartItem<ItemProperties extends GanttChartItemProperties = GanttChartItemProperties> {
  id: string;
  timeStart: Date;
  timeFinish: Date;
  status: GanttChartItemStatus;
  type: GanttChartItemType
  // differentiatingProperties: { [key in keyof ItemProperties]: string };
  differentiatingProperties: ItemProperties;
  itemInformation: Record<string, string>;
  color: string | [number, number, number, number];
  content: {
    center: string;
  };
  // differentiatingPropertyItems: { [key in JobFields]: any };
  // item: any
}

export interface GanttChartViewConfiguration<
  ItemProperties extends GanttChartItemProperties = GanttChartItemProperties
> {
  name?: string;
  description?: string;
  groupTitlePrefix?: string;
  icon: `mdi-${string}`;
  active: boolean;
  items: Array<GanttChartItem<ItemProperties>>;
  // blockedIntervals?: Array<GanttChartItem<ItemProperties>>;
  groupingKey: keyof ItemProperties;
  coloringKey: keyof ItemProperties;
  showSettings: boolean;
  sorter: (a: any, b: any) => number;
  differentiate: { [K in keyof ItemProperties]: boolean };
  visibleCols: { [K in keyof ItemProperties]: boolean };
  displayNames: { [K in keyof ItemProperties]: string };
  content?: {
    center: (item: GanttChartItem<ItemProperties>) => string;
  };
}

export enum GanttChartItemType {
  TASK,
  BLOCKED,
}

export enum GanttChartItemStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  HIDDEN = "HIDDEN",
}

export type GanttChartItemProperties = {
  none: string;
};

export type DifferentiationEntries<ItemProperties extends GanttChartItemProperties = GanttChartItemProperties> = Record<
  keyof ItemProperties,
  boolean
>;

export enum UnitStatusType {
  INFO,
  WARNING,
  ERROR,
  CRITICAL_ERROR,
}

export interface UnitStatus {
  type: UnitStatusType;
  text: string;
}

export function getTimespans(jobs: GanttChartItem<any>[]) {
  // console.log('jobs in gettimespans',jobs)
  let points = [];
  for (let job of jobs) {
    points.push({ time: job.timeStart.getTime(), type: "start" }, { time: job.timeFinish.getTime(), type: "end" });
  }
  points.sort((a, b) => a.time - b.time);
  let timespans = [];
  // console.log(points)
  let c = 0;
  let start = 0;
  let end = points.length - 1;
  for (let i = 0; i < points.length; i++) {
    let point = points[i];
    if (point.type == "start") {
      if (c++ == 0) start = i;
      // c++;
    } else {
      c--;
      if (c == 0) {
        timespans.push([points[start].time, points[i].time]);
      }
    }
  }

  return timespans;
}
