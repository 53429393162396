
import Vue, { PropType } from "vue";
import { mapMutations, mapState } from "vuex";
import { getSummaryTimeRangeIndices, round } from "@/scripts/utils";
import { CoffeeProductionAssignmentResultSummary, CuredArticleTaskResult } from "@/scripts/models/results";
import { WEEKDAYS, weekdayToTimestepMap } from "@/scripts/models/date";
import WeekdayTable from "@/components/WeekdayTable.vue";
import { CoffeeArticle, PackageType } from "@/scripts/models/coffee";
// import { UserType } from "@/scripts/models/general";
import { CoffeeProductionDORISummary } from "@/scripts/models/dori";
import { CoffeeProductionModelType } from "@/scripts/models/request";


enum FilterType {
  TAS_ONLY = "TAS_ONLY",
  RG_ONLY = "RG_ONLY",
  BOTH = "BOTH",
}

export default Vue.extend({
  name: "PackagingPlanOverview",
  components: {
    WeekdayTable,
  },
  props: {
    modelType: { type: String as PropType<CoffeeProductionModelType>, default: CoffeeProductionModelType.PAPA },
    activeMode: { type: String as PropType<string>, default: "article" },
    filter: { type: String as PropType<FilterType>, default: FilterType.RG_ONLY },
    resultSummary: { type: Object as PropType<CoffeeProductionDORISummary>, required: true }, // CoffeeProductionAssignmentResultSummary
  },
  data() {
    return {
      WEEKDAYS,
      PackageType,
      modes: [
        { text: "Artikel", value: "article" },
        { text: "Sorte", value: "blend" },
      ],
      // activeMode: "article",
      // filter: FilterType.RG_ONLY,
      filterTypes: [
        { text: "Tassimo + R&G", value: FilterType.BOTH },
        { text: "Tassimo", value: FilterType.TAS_ONLY },
        { text: "R&G", value: FilterType.RG_ONLY },
      ],

      packagingTypeTranslations: {
        P250g: "250g",
        P500g: "500g",
        Tassimo: "Tassimo",
      },
      chilledTypeTranslations: {
        chilled: "Chilled",
        notChilled: "Not Chilled",
      },
      cafTypeTranslations: {
        caf: "Koffeiniert",
        decaf: "Entkoffeiniert",
      },

      // TODO: some time in the future
      // filters: {
      //   tas: {
      //     selected: '',
      //     available: ['']
      //   }
      // }
    };
  },
  created() {
    // console.log("dori result summary", resultSummary);
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    weightKey(): string {
      if (this.modelType === CoffeeProductionModelType.PAPA) {
        return "curedWeight_ton";
      }
      if (this.modelType === CoffeeProductionModelType.DORI) {
        return "articleWeight_ton";
      }
      throw new Error("Model not yet implemented");
    },
    taskKey(): string {
      if (this.modelType === CoffeeProductionModelType.PAPA) {
        return "curedArticleTaskResults";
      }
      if (this.modelType === CoffeeProductionModelType.DORI) {
        return "packagingTaskResults";
      }
      throw new Error("Model not yet implemented");
    },
    timeRangeIndices(): { startDayIndex: number; endDayIndex: number } {
      return getSummaryTimeRangeIndices(this.resultSummary)
    },
    articlePopupTranslations(): Record<string, string | undefined> {
      // const isSuperuser = this.userType === UserType.SUPERUSER;
      return {
        articleNr: "Artikelnummer",
        tassimo: "Tassimo",
        blendSpsNr: "Blend SPS Nr",
        productionTaskIndex: "Zeitindex",
        curedWeight_ton: "Geplante Menge (t)",
        // alreadyStoredWeight_ton: isSuperuser ? "Bereits gelagerte Menge (t)" : undefined,
        // TODO: find out why this was only enable for superusers
        alreadyStoredWeight_ton: "Bereits gelagerte Menge (t)",
        roastedWeight_ton: "Zu röstende Menge (t)",
        roasterEnergyConsumed_kWh: "Energieverbrauch (kWh)",
        roasterEnergyConsumedLower_kWh: "Untergrenze Energieverbrauch (kWh)",
        roasterEnergyConsumedUpper_kWh: "Obergrenze Energieverbrauch (kWh)",
        productionTaskStartTime_h: undefined,
        productionTaskDuration_h: undefined,
        productionTaskEndTime_h: undefined,
        basketWeight_ton: "Geplante Menge (t)",
        weight_ton: "Geplante Menge (t)",
      };
    },
    taskMap(): any {
      if (this.activeMode === `article`) return this.taskMaps.articleMap;
      if (this.activeMode === `blend`) return this.taskMaps.blendMap;
    },
    taskMaps() {
      // const weightKey = "packagingTaskResults"
      const weightKey = this.weightKey;
      const taskKey = this.taskKey;
      const { startDayIndex, endDayIndex } = this.timeRangeIndices;
      const initDayMap = (v: () => any) => {
        const map: any = {};
        for (let i = 0; i < 7; i++) map[i] = v();
        return map;
      };
      const resultSummary = this.resultSummary;
      // @ts-ignore
      const tasks = resultSummary.result[taskKey];
      // console.log("tasks", tasks);
      const articleInfoMap: any = {}
      // map: article -> weekday -> value
      const articleMap: any = {};
      const blendMap: any = {};
      const basketMap: any = {};
      const packageMap: any = {
        P250g: initDayMap(() => ({ weight_ton: 0 })),
        P500g: initDayMap(() => ({ weight_ton: 0 })),
        Tassimo: initDayMap(() => ({ weight_ton: 0 })),
      };
      const chilledMap: any = {
        chilled: initDayMap(() => ({ weight_ton: 0 })),
        notChilled: initDayMap(() => ({ weight_ton: 0 })),
      };

      const decafMap: any = {
        caf: initDayMap(() => ({ weight_ton: 0 })),
        decaf: initDayMap(() => ({ weight_ton: 0 })),
      };

      for (const task of tasks) {
        let articleNr = task.articleNr;
        if (articleNr.endsWith("(T)")) {
          articleNr = articleNr.slice(0, -3);
        }
        const article =
          resultSummary.compliancePlan.planRG.articles[articleNr] ??
          resultSummary.compliancePlan.planTAS.articles[articleNr] ??
          null;

        if (article === null) {
          throw new Error(`No Article found for articleNr ${articleNr}`);
        }

        if(!(articleNr in articleInfoMap)){
          articleInfoMap[articleNr] = article
        }

        const isTas = article.packageTypes[0] === PackageType.Tassimo;
        if (this.filter === FilterType.TAS_ONLY) {
          if (!isTas) continue;
        }
        if (this.filter === FilterType.RG_ONLY) {
          if (isTas) continue;
        }
        // console.log(article.packageType);

        const productionTaskIndex = task.productionTaskIndex;
        // @ts-ignore
        const timestep = task.timeStep;
        const weekdayKey = startDayIndex + (timestep ?? productionTaskIndex);
        // console.log("startDayIndex", startDayIndex);
        // console.log("weekdayKey", weekdayKey);

        // HACK: TODO: IMPORTANT

        // articleMap
        // console.log(task);

        if (!(articleNr in articleMap))
          articleMap[articleNr] = initDayMap(() => ({ [weightKey]: 0 }));
        articleMap[articleNr][weekdayKey][weightKey] += task[weightKey];
        // articleMap[articleNr][weekdayKey] = task;
        // console.log("task[weightKey]", task[weightKey], articleMap[articleNr][weekdayKey][weightKey]);

        // blendMap
        if (!(task.blendSpsNr in blendMap) || blendMap[task.blendSpsNr] === null)
          blendMap[task.blendSpsNr] = initDayMap(() => ({ weight_ton: 0 }));
        blendMap[task.blendSpsNr][weekdayKey].weight_ton += task[weightKey];
        // console.log(`Set ${articleNr} (${task.blendSpsNr}) for ${WEEKDAYS[weekdayKey]}`);

        // packageMap
        const packageTypes: string[] = this.getArticlePackageTypes(articleNr);
        // TODO: implement a better solution
        for(const packageType of packageTypes){
          if (!(packageType in packageMap) || packageMap[packageType] === null)
            packageMap[packageType] = initDayMap(() => ({ weight_ton: 0 }));
          packageMap[packageType][weekdayKey].weight_ton += task[weightKey];
        }

        // chilledMap
        const chilled: boolean = this.isArticleChilled(articleNr);
        const chilledType = chilled ? "chilled" : "notChilled";
        if (!(chilledType in chilledMap) || chilledMap[chilledType] === null)
          chilledMap[chilledType] = initDayMap(() => ({ weight_ton: 0 }));
        chilledMap[chilledType][weekdayKey].weight_ton += task[weightKey];

        // decafMap
        const decaf: boolean = this.isArticleDecaf(articleNr);
        const cafType = decaf ? "decaf" : "caf";
        if (!(cafType in decafMap) || decafMap[cafType] === null)
          decafMap[cafType] = initDayMap(() => ({ weight_ton: 0 }));
        decafMap[cafType][weekdayKey].weight_ton += task[weightKey];

        // basketMap
        if (article !== null) {
          for (const basketSpsNr in article.basketContents) {
            const relativeContent = article.basketContents[basketSpsNr];
            const basketWeight = task[weightKey] * relativeContent;
            if (!(basketSpsNr in basketMap)) basketMap[basketSpsNr] = initDayMap(() => ({ basketWeight_ton: 0 }));
            basketMap[basketSpsNr][weekdayKey].basketWeight_ton += basketWeight;
          }
        } else {
          console.warn(`No Article found for articleNr ${articleNr}!`);
        }
      }

      return { articleInfoMap, articleMap, blendMap, basketMap, packageMap, chilledMap, decafMap };
    },
  },
  methods: {
    ...mapMutations([""]),
    getArticle(articleNr: string): CoffeeArticle {
      const article =
        this.resultSummary.compliancePlan.planRG.articles?.[articleNr] ??
        this.resultSummary.compliancePlan.planTAS.articles?.[articleNr] ??
        null;
      if (article === null) {
        throw `No article with articleNr ${articleNr}`;
      }
      return article;
    },
    getArticlePackageTypes(articleNr: string): string[] {
      return (
        this.resultSummary.compliancePlan.planRG.articles?.[articleNr]?.packageTypes ??
        this.resultSummary.compliancePlan.planTAS.articles?.[articleNr]?.packageTypes ??
        ""
      );
    },
    isArticleDecaf(articleNr: string): boolean {
      return (
        this.resultSummary.compliancePlan.planRG.articles?.[articleNr]?.decaf ??
        this.resultSummary.compliancePlan.planTAS.articles?.[articleNr]?.decaf ??
        ""
      );
    },
    isArticleChilled(articleNr: string): boolean {
      return (
        this.resultSummary.compliancePlan.planRG.articles?.[articleNr]?.chilled ??
        this.resultSummary.compliancePlan.planTAS.articles?.[articleNr]?.chilled ??
        ""
      );
    },
    getArticleWeightString(task: CuredArticleTaskResult) {
      // @ts-ignore
      if (task?.[this.weightKey]) return round(2)(task?.[this.weightKey]);
      return ``;
    },
    getBasketWeightString(task: CuredArticleTaskResult) {
      // @ts-ignore
      if (task?.basketWeight_ton) return round(2)(task.basketWeight_ton);
      return ``;
    },
    getWeightString(weight: number): string {
      return weight ? `${round(2)(weight)}` : "";
    },
    getBlendSpsNrFromArticleNr(articleNr: string): string {
      return (
        this.resultSummary.compliancePlan.planRG.articles?.[articleNr]?.blendSpsNr ||
        this.resultSummary.compliancePlan.planTAS.articles?.[articleNr]?.blendSpsNr ||
        ""
      );
    },
  },
});
