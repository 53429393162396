import { GanttChartItemProperties, GanttChartItem, getTimespans, DifferentiationEntries } from "./gantt";

export class GanttChartItemAggregation<ItemProperties extends GanttChartItemProperties = GanttChartItemProperties> {
  public itemIdsPerKey: Record<keyof ItemProperties, string[]>;

  name: string = "";
  timeStart: number = NaN;
  timeFinish: number = NaN;
  timeTotal: number = NaN;
  items: Array<GanttChartItem<ItemProperties>> = [];

  constructor(name: string, items: Array<GanttChartItem<ItemProperties>>) {
    this.name = name;
    this.items = items;
    // let keys = "article blend basket roasterTask roasterSubtask packagingTask".split(" ") as Array<keyof ItemProperties>;;
    const keys = Object.keys(items?.[0]?.differentiatingProperties ?? {}) as Array<keyof ItemProperties>;
    this.itemIdsPerKey = {} as Record<keyof ItemProperties, string[]>;
    for (let key of keys) {
      this.itemIdsPerKey[key] = [];
      for (let job of this.items) {
        const id: string = job.differentiatingProperties[key] as unknown as string;
        if (!this.itemIdsPerKey[key].includes(id)) this.itemIdsPerKey[key].push(id);
      }
      this.findTimes();
    }
  }

  private findTimes() {
    // find times
    let [timeStart, timeFinish] = [Infinity, -Infinity];
    let timeTotal = 0;
    // console.log("aggregation items", this.items);
    for (const job of this.items) {
      const jts = job.timeStart.getTime();
      const jtf = job.timeFinish.getTime();
      if (jts < timeStart) timeStart = jts;
      if (jtf > timeFinish) timeFinish = jtf;
      const dt = jtf - jts;
      timeTotal += dt;
    }

    this.timeStart = timeStart;
    this.timeFinish = timeFinish;
    this.timeTotal = timeTotal;
  }

  public getTimespans() {
    return getTimespans(this.items);
  }

  public static calculateAggregations<ItemProperties extends GanttChartItemProperties = GanttChartItemProperties>(
    jobs: GanttChartItem<ItemProperties>[],
    differentiate: DifferentiationEntries<ItemProperties>
  ): GanttChartItemAggregation<ItemProperties>[] {
    let aggregations: any[] = [];
    let keys = Object.keys(differentiate) as Array<keyof ItemProperties>;
    for (const job of jobs) {
      let jobAdded = false;
      for (const g of aggregations) {
        if (keys.every((key) => !differentiate[key] || g[key] == job.differentiatingProperties[key])) {
          g.jobs.push(job);
          jobAdded = true;
          break;
        }
      }
      if (!jobAdded) {
        const newAg: any = { jobs: [job] };
        for (const key of keys) {
          if (!differentiate[key]) newAg[key] = null;
          else newAg[key] = job.differentiatingProperties[key];
        }
        aggregations.push(newAg);
      }
    }

    return aggregations.map((g) => new GanttChartItemAggregation("", g.jobs));
  }
}
