// TABLE

import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import { CafType, CoffeeBasket } from "@/scripts/models/coffee";
import { basketSorter, chunkArray, round } from "@/scripts/utils";
import { CoffeeProductionBasketPlan, CoffeeProductionBasketWeekdayPlan } from "@/scripts/models/results";
import { DayOfWeek, weekdayGerToEnum } from "@/scripts/models/date";

// export type TableRowGroups = { [key: string]: SASTableCell[][] };
export type TableRowGroups = Array<[string, SASTableCell[][]]>;

export interface SASTableCell {
  type: CellType;
  value: number | string;
  basketSpsNr: number;
  weekday: number | null;
  scaleGroup?: number;
  utilization?: any;
  tableCellInfo?: SASTableCellInfo | any;
  rowspan: number;
  width?: string;
  span?: number;
  color?: string;
  borderColor?: string;
  highlightColors?: { [key: string]: string };
  tooltip?: { [key: string]: string };
}

export interface SASTableCellInfo {
  // totalBasketDemand: number;
  // totalBasketProduction: number;
  // startOfWeekSiloAllocation: number;
  storedAtDayEndSilo: number;
  storedAtDayStartSilo: number;
  siloInflow: number;
  siloOutflow: number;
  basketSpsNr: number;
  siloSpsNr: number;
  // timestep: number;
  siloCapacity: number;
  siloScaleGroup: number;
}

export enum CellType {
  BasketSpsNr = "BasketSpsNr",
  SiloSpsNr = "SiloSpsNr",
  Stored = "Stored",
  Demand = "Demand",
  Production = "Production",
  Capacity = "Capacity",
  Empty = "Empty",
}

const MAX_SILOS_PER_ROW = 3;

export default class CellplanTable {
  constructor(public saraSummary: CoffeeProductionSARASummary) {}

  getTableCellInfo(basketSpsNr: number, siloSpsNr: number, timestep: number): SASTableCellInfo | null {
    const dayOfWeek = this.saraSummary.getWeekdayFromTimestep(timestep);
    const bwsr = this.saraSummary.findCoffeeProductionBasketWeekdaySiloPlan(basketSpsNr, dayOfWeek, siloSpsNr);
    if (!bwsr) {
      console.error(
        `getTableCellInfo: No CoffeeProductionBasketWeekdaySiloPlan for {basketSpsNr: ${basketSpsNr}, dayOfWeek: ${dayOfWeek}}, siloSpsNr: ${siloSpsNr}`
      );
      return null;
    }

    const res = {
      siloSpsNr: siloSpsNr,
      siloScaleGroup: bwsr.scaleGroupNr,
      basketSpsNr: basketSpsNr,
      basketMinCuringTime: bwsr.storedBasketMinCuringTime,
      basketMaxCuringTime: bwsr.storedBasketMaxCuringTime,
      separator1: null,
      siloInflow: bwsr.inflow ?? 0,
      siloOutflow: bwsr.outflow ?? 0,
      siloCapacity: bwsr.usableCapacity,
      storedAtDayStartSilo: bwsr.storedAtDayStart,
      storedAtDayEndSilo: bwsr.storedAtDayEnd,
      storageUtilizationAtDayEnd: bwsr.storageUtilizationAtDayEnd,
      inflowLocked: bwsr.inflowLocked ?? false,
      outflowLocked: bwsr.outflowLocked,
    };

    return res;
  }

  getWeekdaySiloCellList(basketSpsNr: number): SASTableCell[][] {
    const weekdaySiloCells: SASTableCell[][] = [];

    for (let timestep = 0; timestep < this.saraSummary.shiftedWeekdays.length; timestep++) {
      // log({ timestep })
      const baseInfo = {
        basketSpsNr: basketSpsNr,
        weekday: timestep + this.saraSummary.weekdayOffset,
      };

      const dayOfWeek = this.saraSummary.getWeekdayFromTimestep(timestep);
      const bwr = this.saraSummary.findCoffeeProductionBasketWeekdayPlan(basketSpsNr, dayOfWeek);
      if (!bwr) {
        console.error(
          `getWeekdaySiloCellList: No CoffeeProductionBasketWeekdayPlan for {basketSpsNr: ${basketSpsNr}, dayOfWeek: ${dayOfWeek}}`
        );
        continue;
      }

      // ADD ALL SILOS
      const siloSpsNrs: number[] = this.saraSummary.getSilosForBasketAndTimestep(basketSpsNr, timestep);
      const rowPart: SASTableCell[] = [];
      for (const siloSpsNr of siloSpsNrs) {
        const tableCellInfo = this.getTableCellInfo(basketSpsNr, siloSpsNr, timestep);
        if (
          !tableCellInfo ||
          (tableCellInfo.storedAtDayEndSilo == 0 && tableCellInfo.siloInflow == 0 && tableCellInfo.siloOutflow == 0)
        ) {
          continue;
        }
        // totalCapacity += tableCellInfo.siloCapacity
        const divOrZero = (a: number, b: number): number => (b != 0 ? a / b : 0);
        const tableCell: SASTableCell = {
          type: CellType.SiloSpsNr,
          value: siloSpsNr,
          scaleGroup: tableCellInfo.siloScaleGroup,
          rowspan: 1,
          utilization: {
            storage: divOrZero(tableCellInfo.storedAtDayEndSilo, tableCellInfo.siloCapacity),
            inflow: divOrZero(tableCellInfo.siloInflow, bwr.production),
            outflow: divOrZero(tableCellInfo.siloOutflow, bwr.demand),
            leftover:
              bwr.demand == 0 &&
              bwr.production == 0 &&
              tableCellInfo.storedAtDayEndSilo > 0 &&
              tableCellInfo.storedAtDayEndSilo < 5
                ? 1
                : 0,
          },
          tableCellInfo,
          ...baseInfo,
        };
        rowPart.push(tableCell);
      }
      weekdaySiloCells.push(rowPart);
    }
    return weekdaySiloCells;
  }

  padWeekdaySiloCellList(rowPart: SASTableCell[], baseInfo: any) {
    // ADD EMPTY CELLS IN PLACE OF SILOS
    while (rowPart.length < MAX_SILOS_PER_ROW) {
      rowPart.push({
        type: CellType.SiloSpsNr,
        value: "",
        scaleGroup: undefined,
        utilization: undefined,
        ...baseInfo,
      });
    }
  }

  getBasketTableCell(basketSpsNr: number): SASTableCell {
    // add basket
    const basket: CoffeeBasket = this.saraSummary.siloAssignment.basketMap[basketSpsNr];
    let bres: CoffeeProductionBasketPlan = this.saraSummary.findCoffeeProductionBasketPlan(
      basketSpsNr
    ) as CoffeeProductionBasketPlan;
    const isTestBasket = basket.testBasket;
    const roundAndJoin = (d: number[]) => d.map(round(2)).join(", ");
    const postfix =
      {
        [CafType.Caf]: "",
        [CafType.Decaf]: "*",
        [CafType.Mixed]: "**",
      }[basket.cafType] ?? "";
    const basketCell: SASTableCell = {
      type: CellType.BasketSpsNr,
      value: `${basketSpsNr}${postfix}${isTestBasket ? " T" : ""}`,
      color: "#a7938ddd",
      // color: "red",
      basketSpsNr: basketSpsNr,
      weekday: null,
      rowspan: 1,
      width: "60px",
      tableCellInfo: {
        basketSpsNr,
        exportBasket: basket.exportBasket,
        cafType: basket.cafType,
        testBasket: isTestBasket,
        // densityWeightScalingPercentage: basket.coffeeWeightDeviation_pct,
        densityWeightScalingFactor: basket?.densityWeightScalingFactor || "",
        preroastingDuration_h: basket.preroastingDuration_h,
        minCuringDuration_h: basket.minCuringDuration_h,
        maxCuringDuration_h: basket.maxCuringDuration_h,
        separator1: null,
        demand: bres.demand,
        demandDeviation: bres.demandDeviation,
        preproduction: bres.preproduction,
        preproductionDeviation: bres.preproductionDeviation,
        nextWeekPreroasting: bres.nextWeekPreroasting,
        nextWeekPreroastingDeviation: bres.nextWeekPreroastingDeviation,
      },
    };
    return basketCell;
  }

  getSummaryCellInfo(rowPart: SASTableCell[], basketSpsNr: number, timestep: number): any {
    // log({rowPart})
    const dayOfWeek = this.saraSummary.getWeekdayFromTimestep(timestep);
    let bwr: CoffeeProductionBasketWeekdayPlan = this.saraSummary.findCoffeeProductionBasketWeekdayPlan(
      basketSpsNr,
      dayOfWeek
    ) as CoffeeProductionBasketWeekdayPlan;
    if (!bwr) {
      return null;
    }
    // const sum = (key: string) => rowPart.reduce((a, v) => (a += v.tableCellInfo?.[key] ?? 0), 0);
    // const totalCapacity = sum("siloCapacity");
    // bwr.basketSpsNr == 6159 && console.log(bwr.basketSpsNr, "bwr", bwr.storedAtDayStart)
    const usedFulfilledDemand: number =
      bwr?.usedFulfilledDemand === `NaN` ? 0.0 : ((bwr?.usedFulfilledDemand ?? 0.0) as number);
    const remainingFulfilledDemand: number =
      bwr?.remainingFulfilledDemand === `NaN` ? 0.0 : ((bwr?.remainingFulfilledDemand ?? 0.0) as number);
    const availableFulfilledDemand: number = usedFulfilledDemand + remainingFulfilledDemand;
    // if(usedFulfilledDemand * remainingFulfilledDemand < 0){
    //     console.warn("different signs!")
    // }
    // negative usedFulfilledDemand === not enough ...
    const summaryCellInfo: any = {
      "Weekday Totals:": "",
      separator1: null,
      basketSpsNr: basketSpsNr,

      separator2: null,
      basketDemandRaw: bwr.demand, // O_bt
      basketDemand: bwr.demand - Math.min(0, usedFulfilledDemand), //
      totalSiloOutflow: bwr.outflow + Math.max(0, usedFulfilledDemand), // SO_bt
      // Note: the translation for this is "Verpackung", which since the introduction of fulfilled demands is incorrect
      demandDeviation: bwr.demandDeviation,

      separator6: null,
      basketProduction: bwr.production,
      separator5: null,
      preproduction: bwr.preproduction,
      preproductionDeviation: bwr.preproductionDeviation,

      separator4: null,
      availableFulfilledDemand: availableFulfilledDemand,
      usedFulfilledDemand: usedFulfilledDemand,
      remainingFulfilledDemand: remainingFulfilledDemand,
      // "Inflow - Outflow": bwr.inflow - bwr.outflow,
      // "Demand - Outflow": bwr.demand - bwr.outflow,

      // SILOS
      separator3: null,
      // "SILOZELLEN": "",
      totalSiloCapacity: bwr.reservedBaseCapacity,
      storedAtDayStartRaw: bwr.storedAtDayStart,
      totalSiloInflow: bwr.inflow,
      totalSiloOutflowRaw: bwr.outflow,
      storedAtDayEndRaw: bwr.storedAtDayEnd,

      separator7: null,
      storedAtDayStart: bwr.storedAtDayStart + Math.max(0, availableFulfilledDemand),
      storedAtDayEnd: bwr.storedAtDayEnd + Math.max(0, remainingFulfilledDemand),
      freeAtDayStart: bwr.reservedBaseCapacity - bwr.storedAtDayStart, // - Math.max(0, availableFulfilledDemand),
      freeAtDayEnd: bwr.reservedBaseCapacity - bwr.storedAtDayEnd, // - Math.max(0, remainingFulfilledDemand),
    };
    // console.log(bwr.basketSpsNr, "summaryCellInfo", summaryCellInfo.storedAtDayStart)
    return summaryCellInfo;
  }

  getSummaryCells(weekdaySiloCells: SASTableCell[][], basketSpsNr: number, weekday: number): SASTableCell[] {
    const summaryCellInfo = this.getSummaryCellInfo(weekdaySiloCells[weekday], basketSpsNr, weekday);
    const eps = 0.0001;
    // const highlightBorder =  > 0.0001;
    const getDemandDeviationType = () => {
      const demandDeviation = Math.abs(summaryCellInfo.demandDeviation) > eps;
      if (demandDeviation) {
        if (summaryCellInfo.demandDeviation > 0) {
          return "cornflowerblue";
        } else {
          return "orangered";
        }
      }
      return "transparent";
    };

    const getProductionDeviationType = () => {
      const preproductionDeviation = Math.abs(summaryCellInfo.preproductionDeviation) > eps;
      if (preproductionDeviation) {
        return "yellow";
      }
      return "transparent";
    };

    const width = 52;
    let demandString: string = round(1)(summaryCellInfo.basketDemand).toString();
    if (Math.abs(summaryCellInfo.totalSiloOutflow - summaryCellInfo.basketDemand) > eps) {
      demandString = `${round(1)(summaryCellInfo.totalSiloOutflow)}<span class="table-cell-sub">/${round(1)(
        summaryCellInfo.basketDemand
      )}</span>`;
    }
    return [
      // add production cell
      {
        type: CellType.Stored,
        value: `${round(1)(summaryCellInfo.storedAtDayStart)}`,
        tableCellInfo: summaryCellInfo,
        basketSpsNr,
        weekday,
        color: "#a7938d99",
        rowspan: 1,
        width: `${width}px`,
        borderColor:
          summaryCellInfo.usedFulfilledDemand > 0 || summaryCellInfo.availableFulfilledDemand > 0
            ? "limegreen"
            : summaryCellInfo.availableFulfilledDemand < 0
            ? "moccasin"
            : "transparent",
        // borderColor: "orange",
      },
      {
        type: CellType.Production,
        value: `${round(1)(summaryCellInfo.basketProduction)}`,
        tableCellInfo: summaryCellInfo,
        basketSpsNr,
        weekday,
        color: "#a7938d99",
        rowspan: 1,
        width: `${width}px`,
        // preProductionDeviationType: getProductionDeviationType(),
        borderColor: getProductionDeviationType(),
      },
      {
        type: CellType.Demand,
        value: demandString,
        tableCellInfo: summaryCellInfo,
        basketSpsNr,
        weekday,
        color: "#a7938d99",
        rowspan: 1,
        width: `${width}px`,
        // demandDeviationType: getDemandDeviationType(),
        borderColor: getDemandDeviationType(),
      },
      // add demand cell
      // {
      //   type: CellType.Demand,
      //   value: round(1)(summaryCellInfo.basketDemand),
      //   color: "#a7938d99",
      //   tableCellInfo: summaryCellInfo,
      //   basketSpsNr,
      //   weekday,
      // }
    ];
  }

  getTableRowGroups(): TableRowGroups {
    // console.time("Create Solution Table");
    const rowGroups = new Map();
    const basketMap = this.saraSummary.siloAssignment.basketMap;
    const baskets: CoffeeBasket[] = Object.values(basketMap);
    baskets.sort((a, b) => Number(a.basketSpsNr) - Number(b.basketSpsNr));
    baskets.sort(basketSorter);
    const basketSpsNrs = baskets.map((b) => b.basketSpsNr);

    for (const basketSpsNr of basketSpsNrs) {
      const rows = [];
      const baseRow: SASTableCell[] = [];

      // add basket cell
      const basketCell = this.getBasketTableCell(basketSpsNr);
      baseRow.push(basketCell);

      // define weekday loop for convenience
      const weekdayLoop = (fn: (weekday: number) => any) => {
        for (let w = 0; w < this.saraSummary.shiftedWeekdays.length; w++) fn(w);
      };
      const weekdaySiloCellList = this.getWeekdaySiloCellList(basketSpsNr);

      // add start of day values
      // weekdayLoop((weekday) => this.addDayStartValues(weekdaySiloCellList, weekday));

      // determine the number of rows
      const nRows = Math.max(1, Math.ceil(Math.max(...weekdaySiloCellList.map((d) => d.length)) / MAX_SILOS_PER_ROW));
      const otherRows: SASTableCell[][] = [];
      let totalBasketWeekDemand = 0;
      let totalBasketWeekOutflow = 0;
      weekdayLoop((weekday) => {
        const baseInfo = { basketSpsNr, weekday };
        const weekdaySiloCells: SASTableCell[] = weekdaySiloCellList[weekday];
        // create chunks
        let siloCellChunks: SASTableCell[][] = chunkArray(weekdaySiloCells, MAX_SILOS_PER_ROW);
        // add chunks until nRows is reached
        while (siloCellChunks.length < nRows) {
          siloCellChunks.push([]);
        }
        // pad all chunks
        for (const siloCellChunk of siloCellChunks) {
          this.padWeekdaySiloCellList(siloCellChunk, baseInfo);
        }
        // add summary cells
        const summaryCells = this.getSummaryCells(weekdaySiloCellList, basketSpsNr, weekday);
        baseRow.push(...summaryCells);
        // add summary weekday value to totals
        totalBasketWeekDemand += summaryCells[0].tableCellInfo.basketDemand;
        totalBasketWeekOutflow += summaryCells[0].tableCellInfo.totalSiloOutflow;
        // add first chunk to base row
        baseRow.push(...siloCellChunks[0]);
        // add other chunks to other rows
        const nChunks = siloCellChunks.length;
        for (let c = 1; c < nChunks; c++) {
          if (otherRows.length < c) {
            otherRows.push([]);
          }
          this.padWeekdaySiloCellList(siloCellChunks[c], baseInfo);
          otherRows[c - 1].push(...siloCellChunks[c]);
        }
      });

      const r = round(1);
      if (this.saraSummary.preroastForNextWeek) {
        // add preroast column
        const br = this.saraSummary.findCoffeeProductionBasketPlan(basketSpsNr) as CoffeeProductionBasketPlan;
        let preroasting = br?.nextWeekPreroasting ?? 0.0;
        let preroastDev = br?.nextWeekPreroastingDeviation ?? 0.0;
        const noDev = (Math.abs(preroastDev) < 0.001) as boolean;
        const val = noDev ? r(preroasting) : r(preroasting + preroastDev) + "/" + r(preroasting);
        const col: string = noDev ? "transparent" : preroastDev > 0 ? "cornflowerblue" : "orangered";
        //const diff = totalBasketWeekDemand - totalBasketWeekOutflow;
        //const r = round(2);
        baseRow.push({
          type: CellType.Demand,
          value: val,
          tableCellInfo: null,
          basketSpsNr: 0,
          weekday: 0,
          color: "#a7938d99",
          rowspan: 1,
          width: `${15}px`,
          borderColor: col,
        } as SASTableCell);
      }
      // add summary column
      const diff = totalBasketWeekDemand - totalBasketWeekOutflow;
      baseRow.push(
        { value: r(totalBasketWeekDemand), color: "#a7938ddd", tableCellInfo: null } as SASTableCell
        // {
        //   value: diff < 0.01 ? "-" : `${r(diff)} ${r((100 * diff) / totalBasketWeekDemand)}%`,
        //   color: "#a7938ddd",
        // } as SASTableCell
      );

      rows.push(baseRow);
      for (const row of otherRows) rows.push(row);
      for (const tableCell of baseRow) {
        if (tableCell.type != CellType.SiloSpsNr) {
          tableCell.rowspan = nRows;
        }
      }
      // rowGroups[basketSpsNr] = rows;
      rowGroups.set(`${basketSpsNr}`, rows);
    }
    return [...rowGroups.entries()];
  }

  getTableHeaders() {
    const h = ["Best.", "Röst.", "Verp."];
    const weekdayHeaders = [{ value: "", span: 1, color: "transparent" }];
    const headers: any[] = [{ value: "Basket", span: 1, color: "#a7938ddd", width: "50px" }];
    for (let d = 0; d < this.saraSummary.shiftedWeekdays.length; d++) {
      const weekday = this.saraSummary.shiftedWeekdays[d];
      // @ts-ignore
      const weekdayEnum = weekdayGerToEnum[weekday];
      const wr = this.saraSummary.results.weekdayResults.find((wr) => wr.dayOfWeek === weekdayEnum);
      const hasDecaf = wr?.hasDecafDemand ?? false;
      const suffix = hasDecaf ? "*" : "";
      // @ts-ignore
      weekdayHeaders.push({ value: weekday + suffix, span: MAX_SILOS_PER_ROW + 3 });
      headers.push(...h.map((v) => ({ value: v, span: 1, color: "#a7938d99" })), {
        value: "Silos",
        span: MAX_SILOS_PER_ROW,
      });
    }
    // weekdayHeaders.push({ value: "", span: 3, color: "#a7938d" })
    if (this.saraSummary.preroastForNextWeek) {
      headers.push({ value: "Vorröst.", span: 1, color: "#a7938ddd", width: "80px" });
    }
    headers.push(
      // { value: "Σ Erreicht", span: 1, color: "#a7938d99", width: "80px"},
      { value: "Σ Verp.", span: 1, color: "#a7938ddd", width: "80px" }
      // { value: "Abw.", span: 1, color: "#a7938ddd", width: "70px" }
    );
    return [weekdayHeaders, headers];
  }

  getTableFooter(): Array<Partial<SASTableCell>> {
    const footer: Array<Partial<SASTableCell>> = [];
    footer.push({ value: "", span: 1, color: "transparent" });

    let totalDemand = 0;
    for (let weekday = 0; weekday < this.saraSummary.shiftedWeekdays.length; weekday++) {
      // get weekResult
      const dayOfWeek = this.saraSummary.getWeekdayFromTimestep(weekday);
      const wr = this.saraSummary.findCoffeeProductionWeekdayPlan(dayOfWeek);
      if (!wr) {
        console.error(`getTableFooter: No CoffeeProductionWeekdayPlan for {dayOfWeek: ${dayOfWeek}}`);
        continue;
      }
      // determine weekday summary
      const totalCapacity = wr.reservedBaseCapacity + wr.freeBaseCapacity;
      const totalSiloNumber = wr.silosAllocated.length + wr.silosUnallocated.length;

      // ${round(0)(wr.storedAtDayEnd)}t /
      const siloSummary = {
        value: `
        ${wr.silosAllocated.length} / ${round(1)(totalSiloNumber)} <br> 
        ${round(1)(wr.reservedBaseCapacity)}t / ${round(1)(totalCapacity)}t`,
        span: MAX_SILOS_PER_ROW,
        color: "white",
        tableCellInfo: {
          silosAllocated: wr.silosAllocated.length,
          silosTotal: totalSiloNumber,
          capacityUsedAtEndOfDay: wr.storedAtDayEnd,
          capacityAllocated: wr.reservedBaseCapacity,
          capacityTotal: wr.reservedBaseCapacity + wr.freeBaseCapacity,
        } as any,
      };
      if (!!wr.silosUnallocated && wr.silosUnallocated.length > 0) {
        siloSummary.tableCellInfo.separator1 = null;
        siloSummary.tableCellInfo.freeSilos = "";
        for (const siloSpsNr of wr.silosUnallocated ?? []) {
          siloSummary.tableCellInfo["Silo " + siloSpsNr] =
            this.saraSummary.siloAssignment.initialSiloAllocationMap[siloSpsNr].usableCapacity_t;
        }
      }
      if (!!wr.siloInflowsBlocked && wr.siloInflowsBlocked.length > 0) {
        siloSummary.tableCellInfo.separator2 = null;
        siloSummary.tableCellInfo.siloInflowsBlocked = "";
        for (const siloSpsNr of wr.siloInflowsBlocked ?? []) {
          siloSummary.tableCellInfo["Silo " + siloSpsNr + " "] =
            this.saraSummary.siloAssignment.initialSiloAllocationMap[siloSpsNr].usableCapacity_t;
        }
      }
      if (!!wr.siloOutflowsBlocked && wr.siloOutflowsBlocked.length > 0) {
        siloSummary.tableCellInfo.separator3 = null;
        siloSummary.tableCellInfo.siloOutflowsBlocked = "";
        for (const siloSpsNr of wr.siloOutflowsBlocked ?? []) {
          siloSummary.tableCellInfo["Silo " + siloSpsNr + "  "] =
            this.saraSummary.siloAssignment.initialSiloAllocationMap[siloSpsNr].usableCapacity_t;
        }
      }
      // add demand total demand, for last cell
      totalDemand += wr.demand;

      //
      // PUSH CELLS TO FOOTER
      //

      // stored at day end
      footer.push({
        value: round(1)(wr.storedAtDayStart),
        span: 1,
        color: "#a7938d99",
      });
      // production
      footer.push({
        value: round(1)(wr.production),
        span: 1,
        color: "#a7938d99",
      });
      // demands
      footer.push({
        value: round(1)(wr.demand),
        span: 1,
        color: "#a7938d99",
      });
      // summary
      footer.push(siloSummary);
    }

    if (this.saraSummary.preroastForNextWeek) {
      let totalPreroast = 0;
      const basketSpsNrs = this.saraSummary.getAllBasketSpsNrs() as number[];
      for (let basket = 0; basket < basketSpsNrs.length; basket++) {
        // get weekResult
        const br = this.saraSummary.findCoffeeProductionBasketPlan(basketSpsNrs[basket]) as CoffeeProductionBasketPlan;
        const preroasting = br?.nextWeekPreroasting | 0.0;
        if (preroasting != null) {
          totalPreroast += preroasting;
        }
      }
      // sum preroast
      footer.push({
        value: round(2)(totalPreroast),
        span: 1,
        color: "#a7938ddd",
      });
    }
    // sum demands
    footer.push({
      value: round(2)(totalDemand),
      span: 1,
      color: "#a7938ddd",
    });
    // console.log("footer", footer)
    return footer;
  }
}
