
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import FileUpload from "@/components/common/FileUpload.vue";
import GenericSettings from "./GenericSettings.vue";
import { NameValueTuple } from "@/scripts/models/general";
import {SolveRequest} from "@/scripts/models/request";

export default Vue.extend({
  name: "OptimizationSettings",
  props: {
    value: {
      type: Object, // SolveRequest
      required: true,
    },
    readonly: {type: Boolean, default: false},
  },

  components: {
    FileUpload,
    GenericSettings,
  },
  data: function() {
    return {
      solveRequest: {} as SolveRequest,
      solverOutput: "",
      useDecafSilos: false,
      selectedBackend: "",
      updateVersion: true,
      solverParamsTab: null,

      objectiveTypes: [] as NameValueTuple[],
      solutionStrategies: [] as NameValueTuple[],

      selectedObjectiveType: "",
      selectedSolutionStrategy: "",

      loadingInputData: false,
    };
  },
  created() {
    this.solveRequest = this.value;
    this.init();
  },
  watch: {
    selectedBackend() {
      this.selectBackend(this.selectedBackend);
    },
  },
  computed: {
    ...mapState(["backendUrl", "superuser", "availableSolveRequest", "availableBackends", "backendInterface"]),
    userSettings(): any[] {
      return [
        // Keep scale group 1
        {
          inputType: "switch",
          items: [],
          label: "Waagengruppe 1 (Silos 301-309)",
          model: ["solveRequest", "inputFilter", "keepScaleGroup1"],
          type: "",
          cols: "12",
          readonly: this.readonly,
        },
        // Keep scale group 2
        {
          inputType: "switch",
          items: [],
          label: "Waagengruppe 2 (Silos 311-326)",
          model: ["solveRequest", "inputFilter", "keepScaleGroup2"],
          type: "",
          cols: "12",
          readonly: this.readonly,
        },
        // Keep scale group 3
        {
          inputType: "switch",
          items: [],
          label: "Waagengruppe 3 (Silos 327-345)",
          model: ["solveRequest", "inputFilter", "keepScaleGroup3"],
          type: "",
          cols: "12",
          readonly: this.readonly,
        },
      ];
    },
  },
  methods: {
    ...mapMutations(["selectSolveRequest", "selectBackend"]),
    async init() {},
  },
});
