
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { timeSorters } from "@/scripts/utils";
import { GanttChartItemProperties } from "@/scripts/gantt/gantt";
import { GanttChartItemAggregationGroup } from "@/scripts/gantt/GanttChartItemAggregationGroup";

export default Vue.extend({
  name: "GanttChartSettings",
  components: {},
  props: {
    config: { type: Object },
    groups: { type: Array },
  },
  data() {
    return {
      sorter: ((a: any, b: any) => 1) as (a: any, b: any) => number,
      groupSorter: ((a: any, b: any) => 1) as (a: any, b: any) => number,
      sortingFunctions: {
        groups: {
          ...timeSorters,
        },
        aggregations: {
          ...timeSorters,
        },
      },
    };
  },
  created() {},
  watch: {
    sorter() {
      this.sortRows();
    },
    groupSorter() {
      this.sortGroups();
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
  },
  methods: {
    ...mapMutations([""]),
    sortRows() {
      const groups = this.groups as GanttChartItemAggregationGroup<GanttChartItemProperties>[];
      for (let group of groups) {
        group.aggregations.sort(this.sorter);
      }
    },
    sortGroups() {
      this.groups.sort(this.groupSorter);
    },
  },
});
