
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "ArticleDemandListItem",
  components: {},
  props: {
    articleDemand: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      articleDemandMap: {} as Record<string, any>,
      productionMax: 0 as number,
      originalPlannedProduction: 0 as number,
      color: "black",
      stepSize: 0.1,
      numberFormat: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
    };
  },
  created() {},
  watch: {
    articleDemand: {
      immediate: true,
      handler(ad) {
        this.originalPlannedProduction = Number(this.articleDemand.plannedProduction);
        this.calculateProductionMax();
        this.color = "black";
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
  },
  methods: {
    ...mapMutations([""]),
    calculateProductionMax() {
      this.productionMax = Math.ceil((this.articleDemand.plannedProduction + 20) / 50) * 50;
    },
    round(n: number) {
      return Math.round(n * 10) / 10;
    },
    calculateColor(): string {
      const diff = this.originalPlannedProduction - this.articleDemand.plannedProduction;
      if (Math.abs(diff) < this.stepSize) {
        return "black";
      }
      if (diff > 0) {
        return "orangered";
      }
      if (diff < 0) {
        return "cornflowerblue";
      }
      return "black";
    },
  },
});
