
import Vue from "vue";
import {mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CoffeeProductionInputFileType} from "@/scripts/models/calculation";
import {CoffeeProductionModelType} from "@/scripts/models/request";

export default Vue.extend({
  name: "BasketUpload",
  props: {
    dataValidityStartTime: {type: String, required: true},
    selectedFileName: {type: String},
    modelType: {type: String, default: CoffeeProductionModelType.SARA},
  },
  data() {
    return {
      drag: {
        currentlyDraggingOver: false,
        startTime: 0,
        intervalId: -1,
      },
      basketUpload: {
        file: null as File | null,
        loading: false,
      } as any,
    };
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
  },
  methods: {
    async handleBasketUpload(event: any) {
      this.basketUpload.file = event.target.files[0];
      this.basketUpload.loading = true;
      this.$forceUpdate();
      setTimeout(async () => {
        await this.uploadBasket();
        this.basketUpload.loading = false;
        // @ts-ignore
        this.$refs[`basketFileInput`].value = "";
        this.$forceUpdate();
      }, 0);
    },
    async uploadBasket() {
      this.drag.currentlyDraggingOver = false;
      const productionSiteUniqueName = this.productionSiteUniqueName;
      const file: File | undefined = this.basketUpload.file;
      // log({ file });
      if (file === undefined) return;
      // do file upload request
      const bi = this.backendInterface as BackendInterface;
      // const dataValidityStartTime = await bi.getCurrentDateTime(productionSiteUniqueName);
      // const dataValidityStartTime = this.getSortedDates()[0] + `T00:00:00`;

      // get upload id
      const uploadIdResult = await bi.getFileUploadId({
        // productionSiteUniqueName,
        fileType: CoffeeProductionInputFileType.Basket,
        dataValidityStartTime: this.dataValidityStartTime, //: "2021-11-08T08:00:00.000Z", // LocalDateTime
        // dataValidityTimeUnit: "Unknown", // TimeUnit,
        modelType: this.modelType as CoffeeProductionModelType,
      });
      // log({ uploadIdResult });
      if ("error" in uploadIdResult || "errorMessage" in uploadIdResult) {
        this.$emit(
            "upload-error",
            uploadIdResult,
            // {
            //   translatedErrorMessage:
            //       `Basket-Datei konnte nicht hochgeladen werden (Fehler: Upload ID konnte nicht geladen werden).`
            // }
        );
        return;
      }
      const uploadId = uploadIdResult.uploadId;

      // do file upload
      const uploadResult = await bi.uploadFile(uploadId, file);
      console.log("basket uploadResult", uploadResult);
      this.basketUpload.file = null;

      if ("error" in uploadResult || "errorMessage" in uploadResult) {
        // const errorObject = uploadResult as unknown as ExceptionResponse;
        // const msg = errorObject.translatedErrorMessage ?
        //     errorObject.translatedErrorMessage : errorObject.errorMessage
        this.$emit("upload-error", uploadResult)
        // {translatedErrorMessage: "Basket-Datei konnte nicht hochgeladen werden (Fehler: " + msg + ")."});
        return;
      }

      this.$emit("upload-success", "Basket Datei wurde erfolgreich hochgeladen.");
    },
    dragHandler() {
      this.drag.startTime = new Date().getTime();
      this.drag.currentlyDraggingOver = true;
      // console.log("setting startTime");
      if (this.drag.intervalId === -1) {
        this.drag.intervalId = setInterval(() => {
          // console.log("checking time since dragover");
          if (new Date().getTime() - this.drag.startTime > 2000) {
            // console.log("time since dragover too big, resetting");
            this.drag.currentlyDraggingOver = false;
            clearInterval(this.drag.intervalId);
            this.drag.intervalId = -1;
          }
        }, 1000);
      }
    },
  },
});
