import {
    CoffeeBasket,
    CoffeeBasketDailyValues,
    CoffeeSiloAllocation,
    CoffeeBlendFulfilledDemand,
    CoffeeProductionSite,
    CoffeeRoaster, CoffeeRoasterAvailability, CoffeeProductionCompliancePlanFull
} from "@/scripts/models/coffee";
import { DayOfWeek, DayOfWeekDateRange } from "@/scripts/models/date";
import { SolveRequest } from "./request";
import { SolveProcessOutput } from "./results";
import {SolverOutputLineCplex} from "@/scripts/models/statistics";

export interface SolverInfo {
    objectives: any[];
    objectiveWeights: number[];
    solverParameterSequence: CoffeeProductionSolverParameters[];
}

export interface CoffeeProductionInputData {
    firstDayStartTime: string;
    baskets: CoffeeBasket[];
    roasters: CoffeeRoaster[];
    basketDemands: CoffeeBasketDailyValues[];
    basketPreroastingWeights: CoffeeBasketDailyValues[];
    siloAllocations: CoffeeSiloAllocation[];
    fulfilledDemands: CoffeeBlendFulfilledDemand[];
    nextWeekDemandRG: CoffeeBasketDailyValues[];
    nextWeekDemandTas: CoffeeBasketDailyValues[];
    inputFileIds: string[];
    dataRange: DayOfWeekDateRange;
    compliancePlan: CoffeeProductionCompliancePlanFull;
    inputFileInfo: string;
}

export interface CoffeeProductionSARAInputSettings {
    // problem size restrictions
    maxNumBaskets: number;
    maxNumSilos: number;
    basketKeepingStrategy: string;
    siloKeepingStrategy: string;
    // general settings
    applyCoffeeWeightDeviations: boolean;
    clampStoredSiloVolumes: boolean;
    aggregateCoffeeRoasters: boolean;
    // objective settings
    scaleBalancedBasketSpsNrs: number[];
    weekdayParameters: CoffeeProductionWeekdayParameters[];
    //coffee roaster availabilities
    roasterAvailabilities: CoffeeRoasterAvailability[];
    //basket flow multiplier f_b in [0,1] for weighting the flow multiplier
    //for basket f_b := max(0, f_b * ceil(H^t/C^l_b - 1))
    basketFlowMultipliers: CoffeeBasketValues;
    siloFlowMultipliers: CoffeeSiloValues;
    //next week preroasting setting
    nextWeekPreroastingSettings: CoffeeBasketPreroastingSettings;
}

export interface CoffeeBasketValues {
    basketValues: Record<number,number>;//TreeMap: basketSps to value
}

export interface CoffeeSiloValues {
    siloValues: Record<number,number>;//TreeMap: basketSps to value
}

export interface CoffeeProductionWeekdayParameters {
    dayOfWeek: string;
    inflowReductionObjectiveWeight: number;
}

export interface CoffeeBasketPreroastingSettings {
    roastAndGroundPreroastingFactors: CuringTimeDependentPreroastingFactors[];
    tassimoPreroastingFactors: CuringTimeDependentPreroastingFactors[];
}

export interface CuringTimeDependentPreroastingFactors {
    roastAndGround: boolean;
    minCuringTime_h: number;
    weekdayPreroastingFactors: number[];
}

export interface SiloAssignmentInputFilter {
    keepScaleGroup1: boolean;
    keepScaleGroup2: boolean;
    keepScaleGroup3: boolean;
    keepScaleGroup4: boolean;
}

export interface SiloAssignmentInputFile {
    id: string;
    productionSite: CoffeeProductionSite;
    originalFilename: string;
    baseFilename: string;
    fileExtension: string;
    fileType: CoffeeProductionInputFileType;
    // dataValidityTimeUnit: string // TimeUnit;
    dataValidityStartTime:  Date | string; // LocalDateTime;
    dataValidityEndTime:  Date | string; // LocalDateTime;
    creationTime:  Date | string; // LocalDateTime;
}

export interface SiloAssignmentWeekdayParameters {
    dayOfWeek: DayOfWeek;
    // workingHourStartInclusive: number; // int
    // workingHourEndExclusive: number; /// int
    // maxRoastCapacity: number; // double
    inflowReductionObjectiveWeight: number; // double
}

export enum CoffeeProductionInputFileType {
    ComplianceRg = "ComplianceRg",
    ComplianceTas = "ComplianceTas",
    ComplianceRgPreroast = "ComplianceRgPreroast",
    ComplianceTasPreroast = "ComplianceTasPreroast",
    Cellplan = "Cellplan",
    FulfilledDemands = "FulfilledDemands",
    Basket = "Basket",
    Roaster = "Roaster",
}

// export interface SolutionStrategy {
//     solverParameters: CoffeeProductionSolverParameters;
// }

export enum SolveStatus {
    Scheduled = "Scheduled",
    Solving = "Solving",
    Finished = "Finished",
    Error = "Error",
    UnknownProcessId = "UnknownProcessId",
}

export interface CoffeeProductionSolverParameters {
    // objective type
    objectiveType: string; // SiloAssignmentProblemObjectiveType;
    // iterations
    timeStepsIterationLength: number; // int;
    // solver type
    solverType: string; // MILPSolverType;
    // solver settings
    primalFeasibilityTolerance: number; // double;
    useSOS: boolean; // boolean;
    // milp solver settings
    integerFeasibilityTolerance: number; // double;
    MIPGap: number; // double;
    useBranchPriority: boolean; // boolean;
    usePartitions: boolean; // boolean;
    presolve: number; // int;
    MIPfocus: number; // int;
    heuristics: number; // double;
    pumpPasses: number; // int;
    // callback time settings
    maxRuntimeSec: number; // int;
    maxSecondsBetweenSolutions: number; // int;
    minGapForNewSolution: number; // double;
    limitForEarlyTermination: number; // double;
    computeIIS: boolean; // boolean;
}

export interface CalculationConfig {
    fetchSolveStatus: (solveProcessId: string) => Promise<string>
    fetchSolveResult: (solveProcessId: string) => Promise<any> // TODO: SaraSummary or PapaSummary or DoriSummary
    fetchSolverOutput: (solveProcessId: string) => Promise<SolveProcessOutput>
    fetchSolve: (solveRequest: SolveRequest) => Promise<string>
    fetchSolverStatistics?: (solveProcessId: string) => Promise<SolverOutputLineCplex[]>
    fetchLastSolution?: (solveProcessId: string) => Promise<any> // a summary
}
