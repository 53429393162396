import { DayOfWeekDate } from "@/scripts/models/date";
import { CoffeeBasketMap, CoffeeSiloAllocationMap, DailyBasketValueMap } from "@/scripts/models/general";
import { BasketRoasterGasConsumption, CoffeeBasketWeekdayValues, GAS_M3_TO_KWH } from "@/scripts/models/gas";

export interface CoffeeSiloAllocation {
  siloSpsNr: number;
  nominalCapacity_t: number;
  usableCapacity_t: number;
  storedWeight_t: number;
  storedBasketAge_h: number;
  storedBaseBasketEquivalentWeight_t: number;
  storedBasketSpsNr: number;
  scaleGroupNr: number;
  inflowLocked?: boolean; // false,
  outflowLocked?: boolean; // false
}

export enum LoadingStatus {
  Pending = "Pending",
  Active = "Active",
  Stopped = "Stopped",
  Done = "Done",
}

export interface CoffeeBlendFulfilledDemand {
  basketValues: any; // false
  forDay: string;
  loadingStatus: LoadingStatus;
  blendSpsNr: number;
  targetWeight_t: number;
  plannedWeight_t: number;
  loadedWeight_t: number;
}

export enum CafType {
  Caf = "Caf",
  Decaf = "Decaf",
  Mixed = "Mixed",
}

export enum PackageType {
    P250g = "P250g",
    P500g = "P500g",
    Tassimo = "Tassimo",
}

export interface CoffeeBasket {
  // IDS
  basketName: string;
  basketTypeId: string;
  testBasket: boolean;
  basketSpsNr: number;
  basketSapNr: number;

  // OTHER PROPERTIES
  exportBasket: boolean;
  decaf: boolean;
  cafType: CafType;

  // DURATIONS
  preroastingDuration_h: number;
  minCuringDuration_h: number;
  maxCuringDuration_h: number;
  densityWeightScalingFactor: number;

  // JS only
  preproductionFactor?: number;

  // neptune: CoffeeBasketRoasterInfo;
  // jetzone: CoffeeBasketRoasterInfo;
  // jupiter: CoffeeBasketRoasterInfo;
}

export interface CoffeeBasketDailyValues {
  basketSpsNr: number;
  weekdayValues: number[];
}

//
// CUSTOMER
//

export interface CoffeeProductionCompany {
  id: string;
  name: string;
  productionSites: CoffeeProductionSite[];
}

export interface CoffeeProductionSite {
  id: string;
  name: string;
  timeZoneId: string;
  locale: string;
  company: CoffeeProductionCompany;
}

export interface CoffeeRoasterAvailability {
  roasterCategory: string; // "Neptune"
  roasterName: string; // "Neptune 6"
  startWeekNr: number; //11
  weeklyMaxRoastingHoursPerDay: [number, number, number, number, number, number, number][];
}

export interface SolutionHashes {
  inputFilesHash: string;
  inputSettingsHash: string;
  inputFilterHash: string;
  coffeeBasketsHash: string;
  coffeeRoasterHash: string;
  siloAllocationsHash: string;
  fulfilledDemandsHash: string;
  solverParametersHash: string;
}
//
// INPUT
//

export interface SiloAssignment {
  basketMap: CoffeeBasketMap;
  demandMap: DailyBasketValueMap;
  initialSiloAllocationCorrections: CoffeeSiloAllocationMap;
  initialSiloAllocationMap: CoffeeSiloAllocationMap;
  nb: number; // 25,
  ns: number; // 35,
  nt: number; // 7
}

export enum DataStatus {
  FullyFilledIn,
  NotFullyFilledIn,
  Invalid,
}

export interface SiloAssignmentDailyDataStatus {
  startDayDate: string;
  status: DataStatus | string;
}

export interface CoffeeRoasterBasketInfo {
  roasterNr: number; // 0,
  basketSpsNr: number; // 6017,
  basketSapNr?: number; // 6017,
  priority: number; // 2,

  energyConsumption_kwh_per_t: number; // 23.0,
  gasConsumption_kwh_per_t: number; // 650.9350000000001,
  minLotSize_t: number; // 1.65,
  changeoverTime_sec: number; // 0.0,
  coffeeWeightDeviation_pct: number; // 0.0

  // batch
  batchSize_kg: number; // 330.0,
  batchRoastingTime_sec: number; // 950.4,
  batchRoastingTimeDelay_sec: number; // 0.0,
  batchRoastingTimeTolerance_sec: number; // 0.0,
}

export interface CoffeeRoaster {
  basketInfos: CoffeeRoasterBasketInfo[];
  roasterName: string; // "Neptune 6",
  roasterCategory: string; //"Neptune",
  roasterProducer: string;
  availability: CoffeeRoasterAvailability;
}

export interface CoffeeArticle {
  allowedPackagingLineNumbers: string;//  number[];
  articleNr: string;//  "69018275";
  balance: boolean;//  false;
  basketContents: Record<string, number>;//  Object;
  blendSapNr: string;//  34004213;
  blendSpsNr: string;//  3213;
  chilled: boolean;//  false;
  conversionFactor_pc: number|"NaN";//  "NaN";
  counter_kg: number|"NaN";//  "NaN";
  decaf: boolean;//  false;
  denom_pc: number|"NaN";//  "NaN";
  grindType: string;//  "Unknown";
  machineLoss: number;//  0.04;
  newArticle: boolean;//  false;
  optigrind: boolean;//  false;
  packageTypes: PackageType[];//  "Tassimo";
  palletWeight_kg: number|"NaN";//  "NaN";
  reject: boolean;//  false;
}

export interface CoffeeArticleWeekDemand {
  articleNumber: string; //  "1680018",
  article: CoffeeArticle; //  CoffeeArticle
  plannedProduction: number; //  37.3,
  actualProduction: number; //  37.0,
  index: number; //  0
}

export class CoffeeProductionCompliancePlanSingle {
  public gasConsumptions?: ItemInfos;
  public basketRoasterGasConsumptionStruct?: BasketRoasterGasConsumptionStruct;

  constructor(
    public year: number,
    public weekNumber: number,
    public articles: Record<string, CoffeeArticle>,
    public blendingTable: Record<string, Record<string, number>>, // 3019 -> 6101 -> 0.4
    public parsedBasketDemands: Record<string, CoffeeBasketWeekdayValues>,
    public calculatedBasketDemands: Record<string, CoffeeBasketWeekdayValues>,
    public articleDemands: Record<string, CoffeeArticleWeekDemand>
  ) {
    // this.gasConsumptions = this.calculateGasConsumptions()
  }

  static fromObject(compliancePlan: CoffeeProductionCompliancePlanSingle) {
    return new CoffeeProductionCompliancePlanSingle(
      compliancePlan.year,
      compliancePlan.weekNumber,
      compliancePlan.articles,
      compliancePlan.blendingTable,
      compliancePlan.parsedBasketDemands,
      compliancePlan.calculatedBasketDemands,
      compliancePlan.articleDemands
    );
  }

  getArticle(articleNr: string) {
    return this.articles[articleNr];
  }

  setBasketRoasterGasConsumptions(basketRoasterGasConsumptions: BasketRoasterGasConsumption[]) {
    const perBasket: BasketRoasterGasConsumptionStruct = {};
    for (const brgc of basketRoasterGasConsumptions) {
      if (brgc.basketSpsNr in perBasket) {
        perBasket[brgc.basketSpsNr].perRoaster[brgc.roasterName] = brgc;
      } else {
        perBasket[brgc.basketSpsNr] = {
          min: NaN,
          max: NaN,
          average: NaN,
          perRoaster: {
            [brgc.roasterName]: brgc,
          },
        };
      }
    }
    for (const basketSpsNr in perBasket) {
      const gcForBasket = perBasket[basketSpsNr];
      let sum = 0;
      let min = Infinity;
      let max = -Infinity;
      let count = 0;
      for (const roasterName in gcForBasket.perRoaster) {
        const brgc = gcForBasket.perRoaster[roasterName];
        const gasConsumption_m3 = brgc.gasConsumption_m3;
        if (gasConsumption_m3 > 0) {
          min = Math.min(gasConsumption_m3, min);
          max = Math.max(gasConsumption_m3, max);
          sum += gasConsumption_m3;
          count++;
        }
      }
      gcForBasket.min = min;
      gcForBasket.max = max;
      gcForBasket.average = count > 0 ? sum / count : NaN;
    }

    this.basketRoasterGasConsumptionStruct = perBasket;
    this.calculateGasConsumptions();
  }

  public getGasConsumption_article(articleNr: string, key: "min" | "max" | "average"): number {
    return this.gasConsumptions?.perArticle?.[articleNr]?.gasConsumption?.[key] ?? 0;
  }

  public getGasConsumption_blend(blendSpsNr: string | number, key: "min" | "max" | "average"): number {
    return this.gasConsumptions?.perBlend?.[blendSpsNr]?.gasConsumption?.[key] ?? 0;
  }

  public getGasConsumption_basket(basketSpsNr: string | number, key: "min" | "max" | "average"): number {
    return this.gasConsumptions?.perBasket?.[basketSpsNr]?.gasConsumption?.[key] ?? 0;
  }

  public getTotalGasConsumption(key: ItemInfoStatsKey = "average"): number {
    let total = 0;
    for (const articleNr in this.gasConsumptions?.perArticle) {
      total += this.getGasConsumption_article(articleNr, key);
    }
    return total;
  }

  public getTotalEnergyConsumption(key: ItemInfoStatsKey = "average"): number {
    return this.getTotalGasConsumption(key) * GAS_M3_TO_KWH;
  }

  /**
   * Calculates the gas consumptions from the total article demands
   * @private
   */
  public calculateGasConsumptions(): ItemInfos {
    if (!this.basketRoasterGasConsumptionStruct) {
      throw "Gas consumptions not set. Call setBasketRoasterGasConsumptions first.";
    }

    const itemInfos: ItemInfos = {
      perArticle: {},
      perBlend: {},
      perBasket: {},
    };

    const initAndFill = (
      perX: keyof ItemInfos,
      itemId: string | number,
      basketWeight: number,
      basketGasMin: number,
      basketGasMax: number,
      basketGasAverage: number
    ) => {
      if (!(itemId in itemInfos[perX])) {
        itemInfos[perX][itemId] = {
          weight: 0,
          gasConsumption: {
            min: 0,
            max: 0,
            average: 0,
          },
        };
      }
      itemInfos[perX][itemId].weight += basketWeight;
      itemInfos[perX][itemId].gasConsumption.min += basketGasMin;
      itemInfos[perX][itemId].gasConsumption.max += basketGasMax;
      itemInfos[perX][itemId].gasConsumption.average += basketGasAverage;
    };

    for (const articleNr in this.articleDemands) {
      const articleDemand = this.articleDemands[articleNr];
      const article = articleDemand.article;
      const blendSpsNr = articleDemand.article.blendSpsNr;
      const articleWeight = articleDemand.plannedProduction;

      for (const basketSpsNr in article.basketContents) {
        const basketContent = article.basketContents[basketSpsNr];
        const basketWeight = basketContent * articleWeight;
        const gcInfo = this.basketRoasterGasConsumptionStruct[basketSpsNr];
        if(gcInfo===undefined){
          console.warn("gcInfo is undefined for basket "+basketSpsNr)
          continue
        }
        const basketGasMin = basketWeight * gcInfo.min;
        const basketGasMax = basketWeight * gcInfo.max;
        const basketGasAverage = basketWeight * gcInfo.average;

        initAndFill("perBasket", basketSpsNr, basketWeight, basketGasMin, basketGasMax, basketGasAverage);
        initAndFill("perBlend", blendSpsNr, basketWeight, basketGasMin, basketGasMax, basketGasAverage);
        initAndFill("perArticle", articleNr, basketWeight, basketGasMin, basketGasMax, basketGasAverage);
      }
    }

    // console.log("itemInfos", itemInfos)

    this.gasConsumptions = itemInfos;
    return itemInfos;
  }
}

export interface CoffeeArticlePackagingLine {
  packagingLineNumber: number; // 3,
  packageType: string; // "P250g",
  packagingFrequency_ppm: number; // 55,
  packagingThroughput_tph: number; // 1.3
  chilled: boolean;
  workingHoursPerWeekdayIndex: number[]; //[18,24,24,24,24,24,10]
}

export interface CoffeeArticleProductionAssignment {
  dayOfWeekDate: DayOfWeekDate; // DayOfWeekDate
  packagingLineNumber: number; // 28,
  article: CoffeeArticle; // CoffeeArticle,
  amount_t: number; // 1.854624,
  productionPosition: number; // 2,
  productionStartHour: number; // 9.910705712914066,
  productionEndHour: number; // 14.362515602496398,
  productionDuration_h: number; // 4.451809889582333
}

export type CoffeeProductionCompliancePlanFull =
    {
      planRG: CoffeeProductionCompliancePlanSingle;
      planTAS: CoffeeProductionCompliancePlanSingle;
      coffeeBlendDemands: Record<string, CoffeeBlendDemand>;
      productionAssignments: CoffeeArticleProductionAssignment[]
    };

export interface CoffeeBlend {
  blendSpsNr: number;
  basketContents: Record<string, number>;
}

export interface CoffeeBlendDemand {
  demand_t: number;
  blend: CoffeeBlend;
}

//
// GAS
//

export interface CompliancePlanGasConsumptions {
  perArticle: Record<string, number>;
  perBlend: Record<string, number>;
  perBasket: Record<string, number>;
  total: number;
  min: number;
  max: number;
}

interface GasConsumptionPerBasketPerRoaster {
  min: number;
  max: number;
  average: number;
  perRoaster: { [roasterName: string]: BasketRoasterGasConsumption };
}

interface BasketRoasterGasConsumptionStruct {
  [basketSpsNr: string]: GasConsumptionPerBasketPerRoaster;
}

export interface ItemInfo {
  weight: number;
  gasConsumption: ItemInfoStats;
}

export type ItemInfoStatsKey = "min" | "max" | "average";

interface ItemInfoStats {
  min: number;
  max: number;
  average: number;
}

interface ItemInfos {
  perArticle: { [articleNr: string]: ItemInfo };
  perBlend: { [blendSpsNr: string]: ItemInfo };
  perBasket: { [basketSpsNr: string]: ItemInfo };
}

//
// NOT COFFEE
//

/**
 * All types will console.error the occuring error in any case.
 */
export enum SpringErrorHandlingType {
  RETURN_NULL_AND_LOG,
  RETURN_NULL_AND_SWALLOW,
  RETURN_ERROR_AND_LOG,
  THROW_ERROR,
}

export enum SpringReturnType {
  TEXT,
  JSON,
  TEXT_OR_NULL,
  JSON_OR_NULL,
  BLOB,
  RAW
}

export interface SpringOptions {
  returnType?: SpringReturnType;
  errorHandling?: SpringErrorHandlingType;
  headers?: { [key: string]: string|null };
  autoRefreshToken?: boolean,
}

export const defaultSpringOptions: SpringOptions = {
  returnType: SpringReturnType.JSON,
  errorHandling: SpringErrorHandlingType.RETURN_NULL_AND_LOG,
  headers: {},
  autoRefreshToken: true,
};

export enum LegendEntryType {
  DOT,
  OVERLINE,
  UNDERLINE,
}

export interface LegendEntry {
  name: string;
  color: string;
  type: LegendEntryType;
}