
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {SolverOutputLineCplex} from "@/scripts/models/statistics";
// @ts-ignore
import Plotly from "plotly.js-dist";

export default Vue.extend({
  name: "SolverStatisticsPlot",
  components: {},
  props: {
    // showLogin
    value: {type: Array as PropType<SolverOutputLineCplex[]>, required: true},
  },
  data() {
    return {
      plot_target_id: "",
    };
  },
  created() {
    this.plot_target_id = "solver_statistics_plot_" + Math.random().toString(36).substring(7);
  },
  mounted() {
    this.plot()
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        console.log("SolverStatisticsPlot", v)
        this.plot()
      }
    }
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
  },
  methods: {
    ...mapMutations([""]),
    plot() {
      const lines = this.value ?? []
      const traces = [
        // best integer trace
        {
          name: 'Best Integer',
          x: lines.map(line => line.elapsedTimeEstimate),
          y: lines.map(line => line.bestInteger),
          yaxis: 'y',
          mode: 'lines',
          line: {
            color: 'rgb(31, 119, 180)',
          }
        },
        // best bound
        {
          name: 'Best Bound',
          x: lines.map(line => line.elapsedTimeEstimate),
          y: lines.map(line => line.bestBound),
          yaxis: 'y',
          mode: 'lines',
          line: {
            color: '#666',
            dash: 'dash',
          }
        },
        // objective value trace
        // objective value trace
        // {
        //   x: lines.map(line => line.elapsedTimeEstimate),
        //   y: lines.map(line => line.objective),
        //   yaxis: 'y2',
        //   // show markers
        //   mode: 'lines',
        //   line: {
        //     color: 'rgb(176, 196, 222)',
        //   }
        // },
        // relative gap trace
        {
          name: 'Relative MIP Gap',
          x: lines.map(line => line.elapsedTimeEstimate),
          y: lines.map(line => line.relativeMipGap),
          yaxis: 'y3',
          mode: 'lines',
          line: {
            color: 'rgb(255, 127, 14)',
          }
        }
      ]
      const layout = {
        xaxis: {
          title: "Elapsed Time",
        },
        yaxis: {
          title: "Best Integer",
        },
        yaxis2: {
          title: "Objective Value",
          overlaying: 'y',
          side: 'right',
        },
        yaxis3: {
          title: "Relative MIP Gap",
          overlaying: 'y',
          side: 'right',
          // value range
          range: [0, 100],
          // remove grid
          showgrid: false,
        },
        height: 200,
        margin: {
          l: 40,
          r: 40,
          b: 40,
          t: 40,
        },
        // legend at bottom
        legend: {
          orientation: 'h',
          y: 1.1,
          x: 0.5,
          xanchor: 'center',
          yanchor: 'bottom',
        },
      }
      const config = {
        responsive: true,
      }

      const plot_target = document.getElementById(this.plot_target_id)
      if (plot_target) {
        Plotly.newPlot(plot_target, traces, layout, config)
      }
    },
  },
});
