
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {getEnumKeys} from "@/scripts/utils";
import {UserDataAdminRequest, UserInfo, UserRole} from "@/scripts/auth";
import UserInfoListItem from "@/views/UserInfoListItem.vue";

export default Vue.extend({
  name: "UserManager",
  components: {UserInfoListItem},
  props: {},
  data() {
    return {
      userInfos: [] as UserInfo[],
      showLockedUsers: false,
    };
  },
  async created() {
    await this.loadUserInfos()
    // console.log("userInfos", this.userInfos)
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    userRoles(): Record<string, string[]> {
      // return getEnumKeys(UserRole)
      const roles = {
        sara: getEnumKeys(UserRole),
        papa: getEnumKeys(UserRole),
        dori: getEnumKeys(UserRole),
      }

      const roleToValue = {
        [UserRole.NONE]: 0,
        [UserRole.USER]: 1,
        [UserRole.ADMIN]: 2,
        [UserRole.SUPERUSER]: 3,
      }

      const modelTypes = ['sara', 'papa', 'dori']
      for (const modelType of modelTypes) {
        // @ts-ignore
        roles[modelType] = roles[modelType].map(role => ({
          text: role.slice(0,1) + role.slice(1).toLowerCase(),
          value: role,
          // @ts-ignore
          disabled: roleToValue[role] > roleToValue[this.user[modelType + 'UserRole']]
        }))
      }
      console.log("roles", roles)
      return roles

    },
    filteredUserInfos(): UserInfo[] {
      if (this.showLockedUsers) return this.userInfos
      return this.userInfos.filter(userInfo => userInfo.accountNonLocked)
    }
  },
  methods: {
    ...mapMutations([""]),
    canLockAccount(userInfo: UserInfo): boolean {
      // don't allow to edit yourself
      if (userInfo.username === this.user.username) return false
      // app owner can lock all accounts
      if (this.user.applicationOwner) return true
      // don't allow to edit site admins unless you are the app owner
      return (!userInfo.productionSiteAdmin && !userInfo.applicationOwner)
    },
    canMakeAdmin(userInfo: UserInfo): boolean {
      // don't allow to edit yourself
      if (userInfo.username === this.user.username) return false
      // app owner can make all accounts admins
      return this.user.applicationOwner;
    },
    async loadUserInfos() {
      const userInfos = await this.bi.getAllUserInfosAdmin()
      userInfos.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      this.userInfos = userInfos
      // console.log("userInfos", userInfos)
    },
    canEditUser(userInfo: UserInfo): boolean {
      const isSelf = userInfo.username === this.user.username
      if(this.user.applicationOwner){
        // app owner can edit all users except himself
        return !isSelf
      }
      // production site admins cant be edited
      if (userInfo.productionSiteAdmin) return false;
      // don't allow to edit anonymous users
      if (userInfo.username === "user" || userInfo.username === "admin" || userInfo.username === "superuser") return false
      // don't allow to edit the owner
      if (userInfo.applicationOwner && !this.user.applicationOwner) return false;
      return true
    },
    async updateUserInfo(userInfo: UserInfo) {
      console.log("updateUserInfo", userInfo)
      await this.bi.changeUserDataAdmin({
        username: userInfo.username,
        productionSiteAdmin: userInfo.productionSiteAdmin,
        saraUserRole: userInfo.saraUserRole,
        papaUserRole: userInfo.papaUserRole,
        doriUserRole: userInfo.doriUserRole,
      })
      await this.loadUserInfos()
    },
    async toggleAccountLocked(userInfo: UserInfo) {
      // if (confirm(`Soll der Benutzer ${userInfo.firstName} ${userInfo.familyName} (${userInfo.email}) wirklich gelöscht werden?`)) {
      const request: UserDataAdminRequest = {
        username: userInfo.username,
        accountNonLocked: !userInfo.accountNonLocked
      }
      await this.bi.changeUserDataAdmin(request)
      await this.loadUserInfos()
      // }
    },
    async toggleSiteAdmin(userInfo: UserInfo) {
      // if (confirm(`Soll der Benutzer ${userInfo.firstName} ${userInfo.familyName} (${userInfo.email}) wirklich gelöscht werden?`)) {
      const request: UserDataAdminRequest = {
        username: userInfo.username,
        productionSiteAdmin: !userInfo.productionSiteAdmin
      }
      await this.bi.changeUserDataAdmin(request)
      await this.loadUserInfos()
      // }
    },
    async confirmUser(userInfo: UserInfo) {
      console.log("confirm user", userInfo)
      if (confirm(`Soll der Benutzer ${userInfo.firstName} ${userInfo.familyName} (${userInfo.email}) freigeschaltet werden?`)) {
        await this.bi.changeUserDataAdmin({
          username: userInfo.username,
          enabled: true
        })
        await this.loadUserInfos()
      }
    }
  },
});
