
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {UserSettingsInfo} from "@/scripts/models/results";
import UserSettingsListItem from "@/components/settings/UserSettingsListItem.vue";
import UserSettingsAccessOptions from "@/components/settings/UserSettingsAccessOptions.vue";
import UserSettingsDisplay from "@/components/settings/UserSettingsDisplay.vue";
import {CoffeeProductionModelType} from "@/scripts/models/request";
import ModuleUserSettingsManager from "@/components/settings/ModuleUserSettingsManager.vue";
import {UserRole} from "@/scripts/auth";

interface SettingsSection {
  title: string,
  settings: UserSettingsInfo[],
  readonly: boolean,
  showHeader: boolean
}

export default Vue.extend({
  name: "UserSettingsManager",
  components: {ModuleUserSettingsManager, UserSettingsDisplay, UserSettingsAccessOptions, UserSettingsListItem},
  props: {},
  data() {
    return {
      CoffeeProductionModelType,
      tab: 1,
      title: "Einstellungen",
      modelType: CoffeeProductionModelType.SARA,
      loading: false,
      showAccessOptionsDialog: false,
      ownedSettings: [] as UserSettingsInfo[],
      readableSettings: [] as UserSettingsInfo[],
      writeableSettings: [] as UserSettingsInfo[],
      defaultUserSettings: null as UserSettingsInfo | null,
      settingsToEdit: null as UserSettingsInfo | null,
    };
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    isAnySuperuser(): boolean {
      for(const modelType of ['sara', 'dori', 'papa']){
        // @ts-ignore
        const role = this.user?.[modelType+'UserRole'];
        if(role === UserRole.SUPERUSER) return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations([""]),
  },
});
