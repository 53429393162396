import translationsRaw from "@/data/translations";
import {CoffeeProductionDORISummary, CoffeeProductionDORIResult, RoasterTaskGap, MillingTaskGap} from "../models/dori";
import {
  CoffeeProductionAssignmentResultSummaryInterface,
  CoffeeProductionAssignmentResult,
  RoasterTaskResult, MillingSubTaskResult,
} from "../models/results";
import { round, createColorFromNumber, timeSorters, roasterSorter, roasterTypeSorter } from "../utils";
import {
  GanttChartItemProperties,
  GanttChartItem,
  GanttChartItemStatus,
  GanttChartViewConfiguration,
  GanttChartItemType,
} from "./gantt";

const translations = translationsRaw as Record<string, string>;

export interface CoffeeProductionItemProperties_RoasterSubtask extends GanttChartItemProperties {
  // none: string;
  // articleNr: string; // "69014259",
  basketSpsNr: string; // 6096,
  blendSpsNr: string; // 3535,
  millingTaskIndex: string; // 0,
  roasterName: string; // "Jetzone 1",
  roasterTaskIndex: string; // 0,
  timeStep: string; // 0,
  // roastingWeight_ton: number // 3.4098,
  // energyConsumption_kWh: number // 2489.3585880000005,
  // roastingStartTime_h: number // 6.0,
  // roastingDuration_h: number // 0.9742285714285714,
  // roastingEndTime_h: number // 6.974228571428571
}

export interface CoffeeProductionItemProperties_RoasterTask extends GanttChartItemProperties {
  none: string;
  // articleNr: string; // "69014259",
  basketSpsNr: string; // 6096,
  // blendSpsNr: string; // 3535,
  // millingTaskIndex: string; // 0,
  roasterName: string; // "Jetzone 1",
  roasterTaskIndex: string; // 0,
  timeStep: string; // 0,
  // roastingWeight_ton: number // 3.4098,
  // energyConsumption_kWh: number // 2489.3585880000005,
  // roastingStartTime_h: number // 6.0,
  // roastingDuration_h: number // 0.9742285714285714,
  // roastingEndTime_h: number // 6.974228571428571
}

export interface CoffeeProductionItemProperties_MillingTask extends GanttChartItemProperties {
  none: string;
  // articleNr: string; // "1680003",
  blendSpsNr: string; // 3080,
  millNr: string; // 1,
  millingTaskIndex: string; // 0,
  timeStep: string
}

function createGanttChartBlockedIntervals_RoasterGap(resultSummary: CoffeeProductionDORISummary) {
  const results: CoffeeProductionDORIResult = resultSummary.result;
  const roasterTaskGaps: RoasterTaskGap[] = [...results.roasterTaskGaps];
  roasterTaskGaps.sort(roasterSorter(task => task.roasterName))
  // console.log("roasterTaskResults", roasterTaskResults);

  const ganttChartItems: Array<GanttChartItem<CoffeeProductionItemProperties_RoasterTask>> = [];
  const startDate = new Date(resultSummary.timeRangeStart);
  const startDateMS = startDate.getTime();
  for (let r = 0; r < roasterTaskGaps.length; r++) {
    const res = roasterTaskGaps[r];
    if (res.startTime_h > res.endTime_h) {
      throw new Error("res.startTime_h > res.endTime_h");
    }
    // console.log(res.roasterName);

    if(res.startTime_h <= 0) {
      console.warn("NEGATIVE TASK START TIME: ", res)
    }
    if(res.endTime_h <= 0) {
      console.warn("NEGATIVE TASK END TIME: ", res)
      continue;
    }
    res.startTime_h = Math.max(0, res.startTime_h);
    
    const item: GanttChartItem<CoffeeProductionItemProperties_RoasterTask> = {
      id: `Roaster Gap ${r}`,
      type: GanttChartItemType.BLOCKED,
      timeStart: new Date(startDateMS + res.startTime_h * 60 * 60 * 1000),
      timeFinish: new Date(startDateMS + res.endTime_h * 60 * 60 * 1000),
      status: GanttChartItemStatus.PENDING,
      differentiatingProperties: {
        none: ``,
        basketSpsNr: ``,
        roasterName: `${res.roasterName}`,
        roasterTaskIndex: ``,
        timeStep: `${res.timeStep}`,
      },
      itemInformation: {
        Beschreibung: res.description,
        // Energieverbrauch: `${round(2)(res.energyConsumption_kWh)} kWh`,
      },
      content: {
        center: `${res.exclusionReason}`,
      },
      color: "transparent",
    };
    ganttChartItems.push(item);
  }
  return ganttChartItems;
}

function createGanttChartBlockedIntervals_MillingGap(resultSummary: CoffeeProductionDORISummary) {
  const results: CoffeeProductionDORIResult = resultSummary.result;
  const millingTaskGaps: MillingTaskGap[] = [...results.millTaskGaps];
  // millingTaskGaps.sort(roasterSorter(task => task.roasterName))
  // console.log("roasterTaskResults", roasterTaskResults);

  const ganttChartItems: Array<GanttChartItem<CoffeeProductionItemProperties_MillingTask>> = [];
  const startDate = new Date(resultSummary.timeRangeStart);
  const startDateMS = startDate.getTime();
  for (let r = 0; r < millingTaskGaps.length; r++) {
    const res = millingTaskGaps[r];
    if (res.startTime_h > res.endTime_h) {
      throw new Error("res.startTime_h > res.endTime_h");
    }
    // console.log(res.roasterName);

    if(res.startTime_h <= 0) {
      console.warn("NEGATIVE TASK START TIME: ", res)
    }
    if(res.endTime_h <= 0) {
      console.warn("NEGATIVE TASK END TIME: ", res)
      continue;
    }
    res.startTime_h = Math.max(0, res.startTime_h);

    const item: GanttChartItem<CoffeeProductionItemProperties_MillingTask> = {
      id: `Roaster Gap ${r}`,
      type: GanttChartItemType.BLOCKED,
      timeStart: new Date(startDateMS + res.startTime_h * 60 * 60 * 1000),
      timeFinish: new Date(startDateMS + res.endTime_h * 60 * 60 * 1000),
      status: GanttChartItemStatus.PENDING,
      differentiatingProperties: {
        none: ``,
        millNr: `${res.millNumber}`,
        blendSpsNr: ``,
        millingTaskIndex: ``,
        timeStep: `${res.timeStep}`,
      },
      itemInformation: {
        Beschreibung: res.description,
        // Energieverbrauch: `${round(2)(res.energyConsumption_kWh)} kWh`,
      },
      content: {
        center: `${res.exclusionReason}`,
      },
      color: "transparent",
    };
    ganttChartItems.push(item);
  }
  return ganttChartItems;
}

export function getTaskTime(resultSummary: CoffeeProductionDORISummary|CoffeeProductionAssignmentResultSummaryInterface, relativeTaskTime: number){
  const startDate = new Date(resultSummary.timeRangeStart);
  const startDateMS = startDate.getTime();
  return new Date(startDateMS + relativeTaskTime * 60 * 60 * 1000);
}

function createGanttChartItems_RoasterTask(
  resultSummary: CoffeeProductionAssignmentResultSummaryInterface | CoffeeProductionDORISummary
): Array<GanttChartItem<CoffeeProductionItemProperties_RoasterTask>> {
  const results: CoffeeProductionAssignmentResult | CoffeeProductionDORIResult = resultSummary.result;
  const roasterTaskResults: RoasterTaskResult[] = [...results.roasterTaskResults];
  // roasterTaskResults.sort(roasterSorter(task => task.roasterName))
  
  // console.log("roasterTaskResults", roasterTaskResults);
  
  // console.log("hello", roasterTaskResults);
  const ganttChartItems: Array<GanttChartItem<CoffeeProductionItemProperties_RoasterTask>> = [];
  const startDate = new Date(resultSummary.timeRangeStart);
  const startDateMS = startDate.getTime();
  for (let r = 0; r < roasterTaskResults.length; r++) {
    // console.log("hello");
    const res = roasterTaskResults[r];
    if (res.roastingStartTime_h > res.roastingEndTime_h) {
      throw res;
    }
    // console.log(res.roasterName);
    const formatUsedSiloCapacity = (sc: number|undefined, availableCapacity: number|undefined) => {
      if(sc === undefined) return "-";
      const formatted = `${round(2)(sc)}t`;
      if(availableCapacity !== undefined && sc > availableCapacity) {
        return `<span style="color: red">${formatted}</span>`
      }
      return formatted;
    }
    const item: GanttChartItem<CoffeeProductionItemProperties_RoasterTask> = {
      id: `Roaster Task ${r}`,
      type: GanttChartItemType.TASK,
      timeStart: getTaskTime(resultSummary, res.roastingStartTime_h),
      timeFinish: getTaskTime(resultSummary, res.roastingEndTime_h),
      status: GanttChartItemStatus.PENDING,
      differentiatingProperties: {
        none: ``,
        basketSpsNr: `${res.basketSpsNr}`,
        roasterName: `${res.roasterName}`,
        roasterTaskIndex: `${res.roasterTaskIndex}`,
        timeStep: `${res.timeStep}`,
      },
      itemInformation: {
        "Röstmenge": `${round(2)(res.roastingWeight_ton)}t`,
        "Röstmenge für aktuellen Tag": `${round(2)(res.forMillingWeight_ton)}t`,
        "Röstmenge für nächste Tage": `${round(2)(res.forPreroastWeight_ton)}t`,
        "Energieverbrauch": `${round(2)(res.energyConsumption_kWh)} kWh`,
        "Silozellen": res.reservedSilos?.map(i => `${i.siloSpsNr}(${i.nominalCapacity_ton}t)`).join(", ") ?? "-",
        "Temporäre Silozellen": res.additionalReservedSilos?.map(i => `${i.siloSpsNr}(${i.nominalCapacity_ton}t)`).join(", ") ?? "-",
        "Verfügbare Silokapazität": res.totalSiloCapacity_ton ? `${round(2)(res.totalSiloCapacity_ton)}t`: "-",
        "Belegte Silokapazität am Start": formatUsedSiloCapacity(res.siloUtilizationComputedAtTaskStart_ton, res.totalSiloCapacity_ton),
        "Belegte Silokapazität am Ende": formatUsedSiloCapacity(res.siloUtilizationComputedAtTaskEnd_ton, res.totalSiloCapacity_ton),
        "Belegte Silokapazität am Ende (Model)": (res.siloUtilizationModelAtTaskEnd_ton ?? -1) >=0 ? formatUsedSiloCapacity(res.siloUtilizationModelAtTaskEnd_ton, res.totalSiloCapacity_ton):"-",
        "Geplante Menge": `${round(2)(res.plannedBasketWeight_ton)}t`,
        "Geplanter Basket": `${res.plannedBasketSpsNr > 0 ? res.plannedBasketSpsNr:"-"}`,
        "Plan Abweichung": `${round(2)(res.plannedBasketWeightDeviation_ton)}t`,
      },
      content: {
        center: `${res.basketSpsNr} (${round(2)(res.roastingWeight_ton)}t)`,
      },
      color: createColorFromNumber(res.basketSpsNr),
    };
    ganttChartItems.push(item);
  }
  // add gaps
  if ("roasterTaskGaps" in resultSummary.result) {
    const gaps = createGanttChartBlockedIntervals_RoasterGap(resultSummary as CoffeeProductionDORISummary);
    ganttChartItems.push(...gaps);
  }
  ganttChartItems.sort(roasterTypeSorter(item => item.differentiatingProperties.roasterName))
  return ganttChartItems;
}

function createGanttChartItems_RoasterSubtask(resultSummary: CoffeeProductionDORISummary) {
  const results: CoffeeProductionAssignmentResult | CoffeeProductionDORIResult = resultSummary.result;
  const roasterSubtaskResults = results.roasterSubtaskResults;
  // console.log("roasterSubtaskResults", roasterSubtaskResults);

  const ganttChartItems: Array<GanttChartItem<CoffeeProductionItemProperties_RoasterSubtask>> = [];
  const startDate = new Date(resultSummary.timeRangeStart);
  const startDateMS = startDate.getTime();
  for (let r = 0; r < roasterSubtaskResults.length; r++) {
    const res = roasterSubtaskResults[r];

    // find preroastedBasketAssignmentResults
    let preroasted = [];
    let totalPreroastedWeight = 0;
    for (const preroastedTask of resultSummary.result.preroastedBasketAssignmentResults) {
      const t = preroastedTask;
      if (
        // t.articleNr === res.articleNr &&
        t.basketSpsNr === res.basketSpsNr &&
        t.millingTaskIndex === res.millingTaskIndex &&
        t.timeStep === res.timeStep
      ) {
        preroasted.push(preroastedTask);
        totalPreroastedWeight += preroastedTask.basketWeight_ton;
      }
    }

    // if(preroasted.length > 0){
    //   console.log("preroasted", preroasted);

    // }

    // find packaging task
    let packagingTask = null;
    for (const task of resultSummary.result.millingSubTaskResults) {
      if (
        task.millNr === res.millNr &&
        task.millingTaskIndex === res.millingTaskIndex &&
        task.timeStep === res.timeStep
      ) {
        packagingTask = task;
        break;
      }
    }

    if (packagingTask === null) {
      console.error(
        `Roaster Subtasks: No packaging task found for millNr=${res.millNr}, millingTaskIndex=${res.millingTaskIndex}, timeStep=${res.timeStep}`
      );
      packagingTask = {};
    }

    if (res.roastingStartTime_h > res.roastingEndTime_h) {
      throw res;
    }
    const item: GanttChartItem<CoffeeProductionItemProperties_RoasterSubtask> = {
      id: `Roaster Subtask ${r}`,
      type: GanttChartItemType.TASK,
      timeStart: new Date(startDateMS + res.roastingStartTime_h * 60 * 60 * 1000),
      timeFinish: new Date(startDateMS + res.roastingEndTime_h * 60 * 60 * 1000),
      status: GanttChartItemStatus.PENDING,
      differentiatingProperties: {
        roasterName: `${res.roasterName}`,
        none: ``,
        // articleNr: `${res.articleNr}`,
        basketSpsNr: `${res.basketSpsNr}`,
        blendSpsNr: `${res?.blendSpsNr ?? "0000"}`,
        millingTaskIndex: `${res.millingTaskIndex}`,
        roasterTaskIndex: `${res.roasterTaskIndex}`,
        timeStep: `${res.timeStep}`,
      },
      // @ts-ignore
      itemInformation: {
        // "Article Nr": `${res.articleNr}`,
        "Blend SPS": `${res.blendSpsNr}`,
        "Basket SPS": `${res.basketSpsNr}`,
        "Prod. Task Index": `${res.millingTaskIndex}`,
        "Röstmenge": `${round(2)(res.roastingWeight_ton)} Tonnen`,
        "Energieverbrauch": `${round(2)(res.energyConsumption_kWh)} kWh`,
        "Preroasted weight": `${round(2)(totalPreroastedWeight)}`,
        "Silozellen": res.reservedSiloSpsNrs?.join(", ") ?? "-",
        "Temporäre Silozellen": res.additionalReservedSiloSpsNrs?.join(", ") ?? "-",
        // ...res
        ...packagingTask,
      },
      content: {
        center: `${res.blendSpsNr} / ${res.basketSpsNr} / ${res.millingTaskIndex}`,
      },
      color: createColorFromNumber(res.basketSpsNr + res.blendSpsNr),
    };
    ganttChartItems.push(item);
  }
  // add gaps
  if ("roasterTaskGaps" in resultSummary.result) {
    const gaps = createGanttChartBlockedIntervals_RoasterGap(resultSummary as CoffeeProductionDORISummary);
    // @ts-ignore
    ganttChartItems.push(...gaps);
  }
  return ganttChartItems;
}

function createGanttChartItems_MillingTask(
  resultSummary: CoffeeProductionAssignmentResultSummaryInterface | CoffeeProductionDORISummary
) {
  const results: CoffeeProductionAssignmentResult | CoffeeProductionDORIResult = resultSummary.result;
  const millingTasks = results.millingSubTaskResults;
  // console.log("millingTasks", millingTasks);

  const ganttChartItems: Array<GanttChartItem<CoffeeProductionItemProperties_MillingTask>> = [];
  // console.log("resultSummary.timeRangeStart", resultSummary.timeRangeStart);
  
  const startDate = new Date(resultSummary.timeRangeStart);
  const startDateMS = startDate.getTime();
  // console.log("startDate", startDate);
  // console.log("startDateMS", startDateMS);
  for (let r = 0; r < millingTasks.length; r++) {
    const res = millingTasks[r];
    if (res.millingSubTaskStartTime_h > res.millingSubTaskEndTime_h) {
      throw res;
    }
    // console.log(res.millNr);
    // console.log(res)

    // console.log(res.millingTaskStartTime_h, res.millingTaskEndTime_h)
    const timeStart = new Date(startDateMS + res.millingSubTaskStartTime_h * 60 * 60 * 1000);
    const timeFinish = new Date(startDateMS + res.millingSubTaskEndTime_h * 60 * 60 * 1000);
    // console.log(timeStart  +" - " + timeFinish);
    // if (+res.articleNr === 4031640)
    //   console.log(timeStart.toISOString().slice(0, 19), "-", timeFinish.toISOString().slice(0, 19));
    const getCenterText = (res: MillingSubTaskResult): string => {
      if (Math.abs(res.subTaskBlendWeight_ton - res.plannedTaskBlendWeight_ton!) > 0.001) {
        return `${res.blendSpsNr}: ${round(2)(res.subTaskBlendWeight_ton)}/${round(2)(
          res.plannedTaskBlendWeight_ton!
        )}t`;
      } else {
        return `${res.blendSpsNr} (${round(2)(res.subTaskBlendWeight_ton)}t)`;
      }
    };
    const item: GanttChartItem<CoffeeProductionItemProperties_MillingTask> = {
      id: `Milling Task ${r}`,
      type: GanttChartItemType.TASK,
      timeStart,
      timeFinish,
      status: GanttChartItemStatus.PENDING,
      differentiatingProperties: {
        none: ``,
        // articleNr: "a",//`${res.articleNr}`,
        blendSpsNr: `${res?.blendSpsNr ?? "0000"}`,
        millNr: `${res?.millNr ?? "-1"}`,
        millingTaskIndex: `${res.millingTaskIndex}`,
        timeStep: `${res.timeStep}`,
      },
      // @ts-ignore
      itemInformation: {
        Verpackungsmenge: `${round(2)(res.subTaskBlendWeight_ton)}t`,
        Abweichung: `${round(2)(res.subTaskBlendWeight_ton - (res.plannedTaskBlendWeight_ton ?? res.subTaskBlendWeight_ton))}t`,
        // ...res,
      },
      content: {
        center: getCenterText(res),
      },
      color: createColorFromNumber(res.blendSpsNr),
      // borderStyle: "none",
    };
    ganttChartItems.push(item);
  }
  if ("millTaskGaps" in resultSummary.result) {
    const gaps = createGanttChartBlockedIntervals_MillingGap(resultSummary as CoffeeProductionDORISummary);
    console.log("Adding milling task gaps:", gaps.length)
    ganttChartItems.push(...gaps);
  }
  return ganttChartItems;
}

export function createGanttChartConfig_RoasterTask(
  resultSummary: CoffeeProductionAssignmentResultSummaryInterface | CoffeeProductionDORISummary
): GanttChartViewConfiguration<CoffeeProductionItemProperties_RoasterTask> {
  return {
    name: "Röstaufträge",
    description: "...",
    icon: "mdi-factory",
    active: false,
    groupTitlePrefix: "",
    groupingKey: "none",
    coloringKey: "basketSpsNr",
    sorter: timeSorters.timeStartAsc.function,
    showSettings: true,
    items: createGanttChartItems_RoasterTask(resultSummary),
    differentiate: {
      roasterName: true,
      basketSpsNr: false,
      none: false,
      roasterTaskIndex: false,
      timeStep: false,
    },
    visibleCols: {
      none: false,
      roasterName: true,
      basketSpsNr: true,
      roasterTaskIndex: false,
      timeStep: false,
    },
    displayNames: {
      none: translations["id"] ?? "id",
      roasterName: translations["roasterName"] ?? "roasterName",
      basketSpsNr: translations["basketSpsNr"] ?? "basketSpsNr",
      roasterTaskIndex: translations["roasterTaskIndex"] ?? "roasterTaskIndex",
      timeStep: translations["timeStep"] ?? "timeStep",
    },
  };
}

export function createGanttChartConfig_RoasterSubtask(
  resultSummary: CoffeeProductionAssignmentResultSummaryInterface | CoffeeProductionDORISummary
): GanttChartViewConfiguration<CoffeeProductionItemProperties_RoasterSubtask> {
  return {
    name: "Röstaufträge",
    description: "...",
    icon: "mdi-factory",
    active: false,
    groupTitlePrefix: "Röster: ",
    groupingKey: "none",
    coloringKey: "basketSpsNr",
    sorter: timeSorters.timeStartAsc.function,
    showSettings: true,
    // @ts-ignore
    items: createGanttChartItems_RoasterSubtask(resultSummary),
    differentiate: {
      basketSpsNr: false,
      none: false,
      // articleNr: false,
      blendSpsNr: false,
      roasterName: true,
      roasterTaskIndex: false,
      millingTaskIndex: false,
      timeStep: false,
    },
    visibleCols: {
      roasterName: true,
      basketSpsNr: true,
      none: false,
      // articleNr: false,
      blendSpsNr: false,
      roasterTaskIndex: false,
      millingTaskIndex: false,
      timeStep: false,
    },
    // differentiate: {
    //   roasterName: true,
    //   basketSpsNr: false,
    //   none: false,
    //   roasterTaskIndex: false,
    //   timeStep: false,
    // },
    // visibleCols: {
    //   none: false,
    //   roasterName: true,
    //   basketSpsNr: true,
    //   roasterTaskIndex: false,
    //   timeStep: false,
    // },
    displayNames: {
      roasterName: translations["roasterName"] ?? "roasterName",
      none: translations["id"] ?? "id",
      // articleNr: translations["articleNr"] ?? "articleNr",
      blendSpsNr: translations["blendSpsNr"] ?? "blendSpsNr",
      basketSpsNr: translations["basketSpsNr"] ?? "basketSpsNr",
      roasterTaskIndex: translations["roasterTaskIndex"] ?? "roasterTaskIndex",
      millingTaskIndex: translations["millingTaskIndex"] ?? "millingTaskIndex",
      timeStep: translations["timeStep"] ?? "timeStep",
    },
  };
}

export function createGanttChartConfig_MillingTask(
  resultSummary: CoffeeProductionAssignmentResultSummaryInterface | CoffeeProductionDORISummary
): GanttChartViewConfiguration<CoffeeProductionItemProperties_MillingTask> {
  return {
    name: "Mühlenaufträge",
    description: "...",
    icon: "mdi-factory",
    active: false,
    groupTitlePrefix: "Linie ",
    groupingKey: "millNr",
    coloringKey: "blendSpsNr",
    sorter: timeSorters.timeStartAsc.function,
    showSettings: true,
    items: createGanttChartItems_MillingTask(resultSummary),
    differentiate: {
      none: false,
      // articleNr: true,
      blendSpsNr: true,
      millNr: true,
      millingTaskIndex: true,
      timeStep: false
    },
    visibleCols: {
      none: false,
      // articleNr: true,
      blendSpsNr: true,
      millNr: true,
      millingTaskIndex: false,
      timeStep: false,
    },
    displayNames: {
      none: translations["id"] ?? "id",
      // articleNr: translations["articleNr"] ?? "articleNr",
      blendSpsNr: translations["blendSpsNr"] ?? "blendSpsNr",
      millNr: translations["millNr"] ?? "millNr",
      millingTaskIndex: translations["millingTaskIndex"] ?? "millingTaskIndex",
      timeStep: "Zeitschritt"
    },
  };
}

export function createGanttChartConfig_MillingTaskSummary(
  resultSummary: CoffeeProductionAssignmentResultSummaryInterface | CoffeeProductionDORISummary
): GanttChartViewConfiguration<CoffeeProductionItemProperties_MillingTask> {
  return {
    name: "Mühlenaufträge",
    description: "...",
    icon: "mdi-factory",
    active: false,
    groupTitlePrefix: "",
    groupingKey: "none",
    coloringKey: "blendSpsNr",
    sorter: timeSorters.timeStartAsc.function,
    showSettings: true,
    items: createGanttChartItems_MillingTask(resultSummary),
    differentiate: {
      millNr: true,
      none: false,
      // articleNr: false,
      blendSpsNr: false,
      millingTaskIndex: false,
      timeStep: false,
    },
    visibleCols: {
      none: false,
      // articleNr: true,
      blendSpsNr: true,
      millNr: true,
      millingTaskIndex: false,
      timeStep: false
    },
    displayNames: {
      none: translations["id"] ?? "id",
      // articleNr: translations["articleNr"] ?? "articleNr",
      blendSpsNr: translations["blendSpsNr"] ?? "blendSpsNr",
      millNr: translations["millNr"] ?? "millNr",
      millingTaskIndex: translations["millingTaskIndex"] ?? "millingTaskIndex",
      timeStep: "Zeitschritt"
    },
    // content: {
    //   center: (item: GanttChartItem<CoffeeProductionItemProperties_PackagingTask>) => {
    //     return item.itemInformation["Verpackungsmenge"]
    //   }
    // },
    // itemColorizer:
  };
}

export function createGanttChartConfig_RoasterSubtaskSummary(
  resultSummary: CoffeeProductionAssignmentResultSummaryInterface | CoffeeProductionDORISummary
): GanttChartViewConfiguration<CoffeeProductionItemProperties_RoasterSubtask> {
  return {
    name: "Röstaufträge",
    description: "...",
    icon: "mdi-factory",
    active: false,
    groupTitlePrefix: "",
    groupingKey: "none",
    coloringKey: "basketSpsNr",
    sorter: timeSorters.timeStartAsc.function,
    showSettings: true,
    // @ts-ignore
    items: createGanttChartItems_RoasterSubtask(resultSummary),
    differentiate: {
      roasterName: true,
      none: true,
      // articleNr: false,
      blendSpsNr: false,
      basketSpsNr: false,
      roasterTaskIndex: false,
      millingTaskIndex: false,
      timeStep: false,
    },
    visibleCols: {
      none: false,
      // articleNr: true,
      blendSpsNr: true,
      roasterName: true,
      basketSpsNr: true,
      roasterTaskIndex: true,
      millingTaskIndex: true,
      timeStep: true,
    },
    displayNames: {
      none: translations["id"] ?? "id",
      // articleNr: translations["articleNr"] ?? "articleNr",
      blendSpsNr: translations["blendSpsNr"] ?? "blendSpsNr",
      roasterName: translations["roasterName"] ?? "roasterName",
      basketSpsNr: translations["basketSpsNr"] ?? "basketSpsNr",
      roasterTaskIndex: translations["roasterTaskIndex"] ?? "roasterTaskIndex",
      millingTaskIndex: translations["millingTaskIndex"] ?? "millingTaskIndex",
      timeStep: translations["timeStep"] ?? "timeStep",
    },
  };
}
