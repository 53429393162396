
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import {AvailabilityStatus, CoffeeProductionModelSolutionStatus, SummaryMetadata} from "@/scripts/models/results";
import {CoffeeProductionModelType} from "@/scripts/models/request";
// import {UserType} from "@/scripts/models/general";
import RoasterAvailabilitySettings from "@/components/settings/RoasterAvailabilitySettings.vue";
import SolutionItem from "@/components/common/SolutionItem.vue";
import {UserInfo, UserRole} from "@/scripts/auth";
import {BackendInterface} from "@/scripts/BackendInterface";
import SolutionQualityChip from "@/components/common/SolutionQualityChip.vue";
import {formatSeconds} from "@/scripts/utils";

export default Vue.extend({
  name: "SolutionList",
  components: {
    SolutionQualityChip,
    RoasterAvailabilitySettings,
    SolutionItem,
  },
  props: {
    solutions: {type: Array, required: true},
    functions: {type: Object, required: true},
    modelType: {type: String as PropType<CoffeeProductionModelType>, default: CoffeeProductionModelType.SARA},
    metadataMap: {
      type: Object as PropType<Record<string, SummaryMetadata>>,
      required: true
    }
  },
  data() {
    return {
      UserRole,
      CoffeeProductionModelSolutionStatus,
      CoffeeProductionModelType,
      openSolutionPanelIndex: null as null | number,
    };
  },
  created() {
  },
  watch: {
    solutions(v) {
      console.log("solutions", v);
    },
    // metadataMap: {
    //   deep: true,
    //   handler(){
    //     console.log("WATCHER metadataMap")
    //   }
    // }
  },
  computed: {
    // UserType() {
    //   return UserType
    // },
    ...mapState(["user", "translations", "backendInterface"]),
    canDeleteSolution(): boolean {
      const modelType = this.modelType as CoffeeProductionModelType;
      if (modelType === CoffeeProductionModelType.DORI) {
        return this.modelUserRole === UserRole.ADMIN || this.modelUserRole === UserRole.SUPERUSER;
      }
      if (modelType === CoffeeProductionModelType.PAPA) {
        return this.modelUserRole === UserRole.ADMIN || this.modelUserRole === UserRole.SUPERUSER;
      }
      if (modelType === CoffeeProductionModelType.SARA) {
        return this.modelUserRole === UserRole.ADMIN || this.modelUserRole === UserRole.SUPERUSER;
      }
      return false;
    },
    modelUserRole(): UserRole {
      const modelType = this.modelType as CoffeeProductionModelType;
      const user = this.user as UserInfo;
      if (modelType === CoffeeProductionModelType.DORI) {
        return user?.doriUserRole ?? UserRole.NONE;
      }
      if (modelType === CoffeeProductionModelType.PAPA) {
        return user?.papaUserRole ?? UserRole.NONE;
      }
      if (modelType === CoffeeProductionModelType.SARA) {
        return user?.saraUserRole ?? UserRole.NONE;
      }
      return UserRole.NONE;
    },
    modelTypeString() {
      if (this.modelType === CoffeeProductionModelType.SARA) return "sara"
      if (this.modelType === CoffeeProductionModelType.PAPA) return "papa"
      if (this.modelType === CoffeeProductionModelType.DORI) return "dori"
      throw new Error(`Unknown model type: ${this.modelType}`)
    },
    userFullName(): string {
      const user = this.user as UserInfo;
      console.log(user.firstName + " " + user.familyName);
      return user.firstName + " " + user.familyName; 
    }
  },
  methods: {
    formatSeconds,
    ...mapMutations([""]),
    hideSummary(summaryId: string): boolean {
      return ((this.modelUserRole === UserRole.USER || this.modelUserRole === UserRole.ADMIN)
      && !this.isOwner(summaryId)) && this.isDeleted(summaryId)
    },
    isDeleted(summaryId: string) {
      return this.metadataMap[summaryId]?.availabilityStatus === AvailabilityStatus.DELETED
    },
    isOwner(summaryId: string):boolean {
      return this.metadataMap[summaryId]?.ownerFullName === this.userFullName; 
    },
    openSolutionPanelByIndex(solutionIndex: number) {
      if (this.openSolutionPanelIndex !== solutionIndex) this.openSolutionPanelIndex = solutionIndex;
      else this.openSolutionPanelIndex = null;
      // console.log("openSolutionPanelIndex", this.openSolutionPanelIndex);
    },
    async deleteForever(solution: any, index: number) {
      if (solution === null) return;
      if (!confirm(`Warnung: Möchten Sie die selektierte Lösung wirklich löschen?`)) return;
      // console.log("delete solution", JSON.stringify(metadata))
      // console.log("metadata updated", this.metadataMap[metadata.summaryId])
      // console.log(m[solution.id])
      const bi = this.backendInterface;
      this.$emit("metadata-update", this.solutions)
      // OLD
      const fns = this.functions;
      await bi[fns.deleteSolutionById](solution.id);
      console.log("Solution Deleted");
      this.solutions.splice(index, 1);
      // NEW
    },
    async togglePublicVisible(solution: any, index: number) {
      const bi = this.backendInterface as BackendInterface
      await bi.togglePublicVisible(this.modelType, solution.id)
      // this.$nextTick(() => {
      // this.$emit("metadata-update", this.solutions)
      setTimeout(() => {
        solution.publicVisible = !solution.publicVisible
      }, 200)
      // })
      // this.$forceUpdate()
    },
    async deleteSolution(metadata: SummaryMetadata) {
      const bi = this.backendInterface;
      metadata.availabilityStatus = AvailabilityStatus.DELETED
      const res = await bi.saveSummaryMetadata(metadata)
      this.metadataMap[res.summaryId] = res
      this.$forceUpdate()

    },
    async undeleteSolution(metadata: SummaryMetadata) {
      if (!metadata.id) {
        console.log("NO METADATA ID")
        return;
      }
      // console.log("undelete solution", JSON.stringify(metadata))
      const bi = this.backendInterface;
      metadata.availabilityStatus = AvailabilityStatus.AVAILABLE
      const res = await bi.saveSummaryMetadata(metadata)
      this.metadataMap[res.summaryId] = res
      // console.log("metadata updated", this.metadataMap[metadata.summaryId])
      this.$forceUpdate()
      // this.$emit("metadata-update", this.solutions)
    },
    async loadSolveRequestForSolution(solution: CoffeeProductionSARASummary, solutionIndex: number) {
      if (solution.solveRequest === null) {
        // console.log("loadSolveRequestForSolution");
        const bi = this.backendInterface;
        const fns = this.functions;
        solution.solveRequest = await bi[fns.getUsedSolveRequest](solution.id);
      }
      if (solutionIndex === this.openSolutionPanelIndex) {
        this.openSolutionPanelIndex = null;
      } else {
        this.$nextTick(() => {
          this.openSolutionPanelByIndex(solutionIndex);
        });
      }
    },
  },
});
